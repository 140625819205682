import React, { Dispatch, useCallback, useEffect, useState } from "react";
import * as S from "./search-results.style";
import { AnimatePresence } from "framer-motion";
import { motionOpacity } from "@components/ui/animations/animations";
import { Wrapper } from "@components/ui/wrapper/wrapper.style";
import debounce from "lodash/debounce";
import { graphQLClient } from "../../../../../utils/graphql-api";
import { SearchDocument, SearchQuery } from "../../../../../gql/generated";
import { SearchResult } from "../search-result/search-result";
import { SearchResultsModal } from "./search-results-modal";
import { observer } from "mobx-react-lite";
import { useStore } from "@stores/root";
import classNames from "classnames";

type Props = {
  searchString: string;
  setSearchString: Dispatch<string>;
  isFocused: boolean;
  isModal: boolean;
  setIsModal: Dispatch<boolean>;
  className?: string;
};

type SearchResultType = SearchQuery['search']['suggest'];

export const SearchResults = observer((props: Props) => {
  const [result, setResult] = useState<SearchResultType>(null);
  const [isFirstRender, setIsFirstRender] = useState(true)
  const { ui } = useStore();

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      graphQLClient()
        .query({
          query: SearchDocument,
          variables: {
            query: value,
          },
        })
        .then((result) => {
          if (!result.data?.search) return;
          setResult(result.data.search.suggest);
        });
    }, 200),
    []
  );

  useEffect(() => {
    setIsFirstRender(false)
  }, [])

  useEffect(() => {
    if (isFirstRender) return;
    debouncedSearch(props.searchString);
  }, [props.searchString, debouncedSearch]);

  if (props.searchString.trim().length > 0 && props.isModal && ui.viewport <= 992) {
    return (
      <SearchResultsModal
        debouncedSearch={debouncedSearch}
        isModal={props.isModal}
        setIsModal={props.setIsModal}
        result={result}
        setSearchString={props.setSearchString}
        searchString={props.searchString}
      />
    );
  }


  return (
    <AnimatePresence>
      {props.searchString !== '' && props.isFocused && (
        <S.StyledSearchResults
          {...motionOpacity}
          className={classNames({ fixedHeader: ui.header.fixed })}
        >
          <Wrapper>
            <SearchResult {...result} searchString={props.searchString} />
          </Wrapper>
        </S.StyledSearchResults>
      )}
    </AnimatePresence>
  );
});

import React, { useState } from 'react';
import * as S from './product-card-slider.style';
import { ProductCardSliderIndicator } from '@components/product/elements/product-card/slider/product-card-slider-indicator';

type Props = {
  length: number;
  selectedIndex: number;
  setSelectedIndex(index: number): void;
};

const LIMIT = 5;

export const ProductCardSlider = (props: Props) => {
  const fakeArray = Array(props.length > 5 ? LIMIT : props.length).fill(null);
  const [canChange, setCanChange] = useState(false);
  const [showSlider, setShowSlider] = useState(false);

  const onMouseOver = () => {
    setShowSlider(true);
    setTimeout(() => {
      setCanChange(true);
    }, 100);
  };

  const onMouseLeave = () => {
    setShowSlider(false);
    setCanChange(false);
  };

  return (
    <S.StyledProductCardSlider onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
      {showSlider && (
        <S.Indicators>
          {props.length > 1 &&
            fakeArray.map((image, index) => (
              <ProductCardSliderIndicator
                canChange={canChange}
                key={index}
                active={index === props.selectedIndex}
                setSelectedIndex={props.setSelectedIndex}
                idx={index}
              />
            ))}
        </S.Indicators>
      )}
    </S.StyledProductCardSlider>
  );
};

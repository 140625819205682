import { theme } from '@components/ui/theme';
import { Button } from '@components/ui/button/button';
import styled from 'styled-components';

export const LocationButtonContainer = styled.div`
  position: relative;
`;

export const LocationButton = styled.button`
  padding: 6.5px 14px;
  background: #3a373b;
  border-radius: 3px;
  height: 42px;

  display: flex;
  align-items: center;

  &:hover {
    background: #444045;
  }
`;

export const LocationButtonIcon = styled.div`
  color: ${theme.colors.primary};
`;

export const LocationButtonText = styled.div`
  font-size: 14px;
  color: #f6f5f7;
  margin-left: 6px;
`;

export const LocationButtonPopup = styled.div`
  top: 60px;

  padding: 24px;
  background: #ffffff;
  border-radius: 3px;

  position: absolute;
  filter: drop-shadow(0px 2px 12px rgba(99, 47, 117, 0.14));

  width: 280px;

  z-index: 6;

  &:before {
    display: block;
    content: '';

    position: absolute;
    top: -10px;
    left: 40px;

    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    border-bottom: 17px solid white;
  }
`;

export const LocationButtonPopupTitle = styled.div`
  font-size: 17px;
  line-height: 120%;
  color: #302e30;

  span {
    font-weight: bold;
  }
`;

export const LocationButtonPopupButtons = styled.div`
  display: flex;
  margin-top: 20px;

  ${Button} {
    width: fit-content;
    background-color: ${theme.colors.purple};

    &:hover {
      background-color: ${theme.colors.purpleHover};
    }

    &:not(:first-child) {
      margin-left: 16px;
      background: #fafafa;
      color: #686a70;

      &:hover {
        background-color: #f9f7fa;
        color: #302e30;
      }
    }
  }
`;

export const ContinueWithout = styled.div`
  font-size: 14px;

  text-decoration-line: underline;
  color: ${theme.colors.greySecondary};
  margin-top: 16px;

  cursor: pointer;

  &:hover {
    text-decoration-line: none;
  }
`;

import React from "react";

export const IconCartPlus = () => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5 14.4C5.5 13.9029 5.90294 13.5 6.4 13.5C6.89706 13.5 7.3 13.9029 7.3 14.4L7.3 21.6C7.3 22.0971 6.89706 22.5 6.4 22.5C5.90294 22.5 5.5 22.0971 5.5 21.6L5.5 14.4Z"
        fill="currentColor"
      />
      <path
        d="M10.0001 17.0999C10.4972 17.0999 10.9001 17.5028 10.9001 17.9999C10.9001 18.4969 10.4972 18.8999 10.0001 18.8999L2.80015 18.8999C2.30309 18.8999 1.90015 18.4969 1.90015 17.9999C1.90015 17.5028 2.30309 17.0999 2.80015 17.0999L10.0001 17.0999Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 7.05005H13.5621C14.4569 7.05005 15.2368 7.65903 15.4539 8.5271L16.1989 11.5072H29.5C30.577 11.5072 31.45 12.3802 31.45 13.4572V17.2877C31.45 18.1459 30.8888 18.9033 30.0678 19.1532L18.9826 22.5269C17.9051 22.8549 16.7747 22.2044 16.5167 21.108L14.5339 12.6812L13.6106 8.98792C13.605 8.96566 13.585 8.95005 13.5621 8.95005H11V7.05005ZM16.6566 13.4072L18.3662 20.6729C18.3728 20.701 18.4018 20.7177 18.4294 20.7093L29.5146 17.3355C29.5356 17.3291 29.55 17.3097 29.55 17.2877V13.4572C29.55 13.4296 29.5276 13.4072 29.5 13.4072H16.6566Z"
        fill="currentColor"
      />
      <path
        d="M18.4999 25.3999C18.4999 26.394 17.694 27.1999 16.6999 27.1999C15.7058 27.1999 14.8999 26.394 14.8999 25.3999C14.8999 24.4057 15.7058 23.5999 16.6999 23.5999C17.694 23.5999 18.4999 24.4057 18.4999 25.3999Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6999 26.1999C17.1417 26.1999 17.4999 25.8417 17.4999 25.3999C17.4999 24.958 17.1417 24.5999 16.6999 24.5999C16.2581 24.5999 15.8999 24.958 15.8999 25.3999C15.8999 25.8417 16.2581 26.1999 16.6999 26.1999ZM16.6999 27.1999C17.694 27.1999 18.4999 26.394 18.4999 25.3999C18.4999 24.4057 17.694 23.5999 16.6999 23.5999C15.7058 23.5999 14.8999 24.4057 14.8999 25.3999C14.8999 26.394 15.7058 27.1999 16.6999 27.1999Z"
        fill="currentColor"
      />
      <path
        d="M29.6427 25.3999C29.6427 26.394 28.8368 27.1999 27.8427 27.1999C26.8486 27.1999 26.0427 26.394 26.0427 25.3999C26.0427 24.4057 26.8486 23.5999 27.8427 23.5999C28.8368 23.5999 29.6427 24.4057 29.6427 25.3999Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.8427 26.1999C28.2846 26.1999 28.6427 25.8417 28.6427 25.3999C28.6427 24.958 28.2846 24.5999 27.8427 24.5999C27.4009 24.5999 27.0427 24.958 27.0427 25.3999C27.0427 25.8417 27.4009 26.1999 27.8427 26.1999ZM27.8427 27.1999C28.8368 27.1999 29.6427 26.394 29.6427 25.3999C29.6427 24.4057 28.8368 23.5999 27.8427 23.5999C26.8486 23.5999 26.0427 24.4057 26.0427 25.3999C26.0427 26.394 26.8486 27.1999 27.8427 27.1999Z"
        fill="currentColor"
      />
    </svg>
  );
};

import styled from 'styled-components';
import { theme } from '@components/ui/theme';

export const LastViewedCardImage = styled.a`
  display: block;
  margin-right: 25px;
  width: 116px;
  height: 91px;
  min-width: 116px;

  img {
    width: 116px !important;
    height: 91px !important;
  }
`;

export const LastViewedCardInfo = styled.div`
  position: relative;
  flex: 1;
`;

export const LastViewedCardTitle = styled.a`
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 140%;
  color: ${theme.colors.black};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const LastViewedCardPrice = styled.div`
  font-size: 17px;
  font-weight: 500;
  line-height: 120%;
`;

export const LastViewedCardPriceRange = styled.div`
  margin-top: 5px;
  font-size: 12px;
  line-height: 130%;
  color: #686a70;
`;

export const LastViewedCardCartButton = styled.div`
  display: none;
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const StyledLastViewedCard = styled.div`
  padding: 25px;
  display: flex;
  border: 1px solid #f1eff2;

  &:hover {
    box-shadow: 0 2px 12px rgba(99, 47, 117, 0.14);

    ${LastViewedCardCartButton} {
      display: block;
    }

    ${LastViewedCardTitle} {
      color: ${theme.colors.primary};
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    min-width: 220px;
    width: fit-content;
    align-items: center;
    text-align: center;
    background-color: #ffff;
    border: 1px solid #f9f7fa;
    border-radius: 3px;

    &:hover {
      box-shadow: unset;
      ${LastViewedCardCartButton} {
        display: none;
      }
    }

    ${LastViewedCardImage} {
      margin-bottom: 15px;
      margin-right: 0;
      width: 90px;
      height: 90px;
      min-width: 90px;
    }

    ${LastViewedCardTitle} {
      font-size: 13px;
      margin-bottom: 10px;
    }

    ${LastViewedCardInfo} {
      display: flex;
      flex-direction: column;
    }

    ${LastViewedCardPriceRange} {
      font-size: 11px;
    }

    ${LastViewedCardPrice} {
      margin-top: auto;
    }
  }
`;

import React, { useRef } from 'react';
import * as S from './banner-slide.style';
import { Button } from '@components/ui/button/button';
import { Wrapper } from '@components/ui/wrapper/wrapper.style';
import { useStore } from '@stores/root';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';

type Props = {
  title: string;
  subtitle: string;
  features?: string[];
  text?: string;
  image: string;
  bg: string;
  titleClass: string;
  idx: number;
};

export const BannerSlide = observer((props: Props) => {
  const { project, geo, ui } = useStore();
  const router = useRouter();
  const slideRef = useRef(null);
  const isDuplicate =
    String(slideRef.current?.parentNode?.className).search('swiper-slide-duplicate') > 0;
  const TitleComponent = props.idx === 0 && !isDuplicate ? S.FirstSlideTitle : S.SlideTitle; // чтобы у первого баннера был h1

  return (
    <S.StyledBannerSlide ref={slideRef} className={`slide-${props.idx}`} bg={props.bg}>
      <Wrapper>
        <S.Relative>
          <S.SlideLeft>
            <TitleComponent className={props.titleClass}>
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    props.title
                      ?.replace('%brandname%', `${project.name}`)
                      .replace(/%br%/gi, ui.viewport <= 480 ? '<br />' : '') ?? '',
                }}
              />

              <S.SlideSubtitle
                className={props.idx === 0 && 'first'}
                dangerouslySetInnerHTML={{
                  __html: props.subtitle
                    .replace('%geo%', `<span>${geo.slug ? geo.toponim : ''}</span>`)
                    .replace('%brand%', project.name)
                    .replace(/%br%/gi, ui.viewport <= 480 ? '<br />' : ''),
                }}
              />
            </TitleComponent>

            {props.features && (
              <S.SlideFeatures>
                {props.features.map((feature, index) => (
                  <S.SlideFeature key={index}>{feature}</S.SlideFeature>
                ))}
              </S.SlideFeatures>
            )}

            {props.text && (
              <S.SlideText>
                {props.text
                  .replace('%geo%', geo.slug ? `в ${geo.name}` : '')
                  .replace('%brand%', project.name)}
              </S.SlideText>
            )}

            <S.SlideButton>
              <Button onClick={() => router.push('/about')}>Подробнее о сервисе</Button>
            </S.SlideButton>
          </S.SlideLeft>

          <S.Illustration>
            <img
              alt={project.name}
              title={project.name}
              width="750"
              height="560"
              src={props.image}
            />
          </S.Illustration>

          <S.SlideFakePagination />
        </S.Relative>
      </Wrapper>
    </S.StyledBannerSlide>
  );
});

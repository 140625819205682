import styled from 'styled-components';
import { device, theme } from '@components/ui/theme';
import { motion } from 'framer-motion';

export const MobileCatalogue = styled(motion.div)`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 5;
  padding: 25px 20px 160px 30px;
  top: 0;
  left: 0;

  @media (max-width: 480px) {
    padding-left: 20px;
    padding-right: 10px;
  }
`;

export const MobileCatalogueSearchContainer = styled.div`
  margin-top: 20px;
`;

export const MobileCatalogueTitle = styled.div`
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: ${theme.colors.black};
  display: flex;
  align-items: center;

  @media (max-width: 504px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const MobileCatalogueGoBack = styled.div`
  margin-right: 15px;

  svg {
    width: 30px;
    height: 30px;
  }

  @media (max-width: 480px) {
    margin-right: 10px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const MenuBurger = styled.div`
  position: absolute;
  width: 18px;
  height: 14px;
  right: 16px;
  top: 25px;
  z-index: 7;

  div {
    position: absolute;
    width: 18px;
    height: 2px;
    border-radius: 1px;
    background: ${theme.colors.black};
    border-radius: 1px;

    transition: 0.25s;

    &:nth-child(2) {
      width: 13px;
      top: 7px;
      right: 0;
    }

    &:nth-child(3) {
      top: 14px;
    }
  }

  &.active {
    div {
      //background: #e64e8d;
      border-radius: 0;

      &:nth-child(1) {
        top: 6px;
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        top: 6px;
        transform: rotate(-45deg);
        width: 18px;
        margin-top: 0;
      }

      &:nth-child(3) {
        opacity: 0;
        top: 6px;
        transform: rotate(45deg);
      }
    }
  }
`;

export const MobileCatalogueItems = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(235px, 1fr));
  justify-content: center;
  margin-top: 30px;
  max-height: calc(100% - 95px);
  overflow: auto;
  padding-right: 10px;

  // @media ${device.mobileM} {
  //   grid-template-columns: repeat(auto-fit, minmax(223px, 223px));
  // }

  @media (max-width: 504px) {
    margin-top: 25px;
    grid-template-columns: 1fr 1fr;
  }

  @media ${device.tabletS} {
    grid-template-columns: repeat(3, 1fr);
  }

  &.products {
    grid-template-columns: repeat(auto-fill, minmax(577px, 1fr));
    grid-row-gap: 10px;
    grid-column-gap: 40px;

    @media (max-width: 650px) {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const MobileCatalogueItem = styled.a`
  width: 100%;
  height: 152px;
  border: 1px solid #f9f7fa;
  padding: 17px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${device.tabletS} {
    height: 154px;
  }

  @media (max-width: 480px) {
    height: 122px;
    padding: 15px;
  }
`;

export const MobileCatalogueItemImage = styled.div`
  position: relative;
  width: 82px;
  height: 69px;

  @media ${device.tabletS} {
    width: 70px;
    height: 70px;

    img {
      min-height: 70px;
    }
  }

  @media (max-width: 480px) {
    width: 50px;
    height: 50px;

    img {
      min-height: 50px;
    }
  }
`;

export const MobileCatalogueItemName = styled.div`
  font-size: 14px;
  line-height: 120%;
  color: ${theme.colors.black};
  margin-top: 10px;
  text-align: center;

  @media (max-width: 576px) {
    font-size: 13px;
  }
`;

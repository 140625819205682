import styled from 'styled-components';
import { theme } from '@components/ui/theme';
import { FilterInputName } from '@components/category/elements/filter-input/filter-input.style';

export const StyledFilterInputs = styled.div`
  display: flex;
  align-items: center;

  .rheostat {
    width: 220px;
    height: 1px;
    margin-left: 14px;
    margin-right: 14px;
    position: relative;
    cursor: pointer;

    .DefaultBackground_1 {
      position: relative;
      width: 100%;
      height: 1px;
      bottom: -1px;
      background: ${theme.colors.greyPrimary};
    }

    .DefaultProgressBar_progressBar_1 {
      position: absolute;
      width: 100%;
      height: 1px;
      top: 1px;
      background: ${theme.colors.primary};
    }

    .track-0 {
      width: 100%;
      height: 100%;
    }

    .track-1 {
      width: 100%;
      height: 1px;
      background: ${theme.colors.primary};
    }

    .track-2 {
      width: 100%;
      height: 1px;
      background: ${theme.colors.greyPrimary};
    }

    .handleContainer {
      position: relative;
      z-index: 2;
      .DefaultHandle_handle {
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: #e64e8d;

        //position: absolute;
        top: -5.5px;

        display: flex;
        align-items: center;
        justify-content: center;

        &:active:after,
        &:hover:after {
          background: #e64e8d;
        }

        &:after {
          content: '';
          display: block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: white;
        }
      }
    }
  }

  .second ${FilterInputName} {
    margin-left: 9px;
  }
`;

export const FilterInputsName = styled.div`
  font-size: 14px;
  color: ${theme.colors.black};

  margin-right: 12px;
`;

import React from 'react';
import { useStore } from '@stores/root';

type Props = {
  video: Video;
};

export const VideoSchema = (props: Props) => {
  const { project } = useStore();

  return (
    <div className="hidden" itemScope itemType="http://schema.org/VideoObject">
      <a itemProp="url" href={`https://www.youtube.com/watch?v=${props.video.id}`}>
        <span itemProp="name">{props.video.title}</span>
      </a>
      <p itemProp="description">{props.video.title}</p>
      <span itemProp="duration">PT6M58S</span>
      <span itemProp="isFamilyFriendly">true</span>
      <meta itemProp="thumbnailUrl" content={`https://${project.domain}${props.video.thumb}`} />
      {props.video.createdAt && (
        <span itemProp="uploadDate">{'' + props.video.createdAt.toString().split('.')[0]}</span>
      )}
      <span itemProp="thumbnail" itemScope itemType="http://schema.org/ImageObject">
        <img itemProp="contentUrl" src={`https://${project.domain}${props.video.thumb}`} />
      </span>
    </div>
  );
};

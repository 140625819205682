import React from "react";

export const IconChevronRight = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.7175 9.99999L7.59253 5.87499L8.77086 4.69666L14.0742 9.99999L8.77086 15.3033L7.59253 14.125L11.7175 9.99999Z"
        fill="currentColor"
      />
    </svg>
  );
};

import React from "react";

export const IconUserNobg = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.7056 23.4216C22.4396 21.5162 18.9337 20.2939 14.9997 20.2939C11.0653 20.2939 7.56009 21.5156 5.29432 23.4211C4.76788 23.8638 4.98288 24.5808 5.65942 24.8474L5.80393 24.9044C6.29099 25.0963 6.86666 24.9859 7.2594 24.6789C9.07313 23.2611 11.8666 22.3523 14.9997 22.3523C18.1331 22.3523 20.9267 23.2613 22.7404 24.6793C23.1329 24.9861 23.7082 25.0966 24.1951 24.905L24.3399 24.8481C25.017 24.5817 25.2323 23.8645 24.7056 23.4216Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 15.4565C17.2013 15.4565 18.9859 13.6719 18.9859 11.4706C18.9859 9.26924 17.2013 7.4847 15 7.4847C12.7987 7.4847 11.0141 9.26924 11.0141 11.4706C11.0141 13.6719 12.7987 15.4565 15 15.4565ZM15 17.9412C18.5736 17.9412 21.4706 15.0442 21.4706 11.4706C21.4706 7.89698 18.5736 5 15 5C11.4264 5 8.52942 7.89698 8.52942 11.4706C8.52942 15.0442 11.4264 17.9412 15 17.9412Z"
        fill="currentColor"
      />
    </svg>
  );
};

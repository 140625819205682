import styled from 'styled-components';
import { device, theme } from '@components/ui/theme';

export const FooterTop = styled.div`
  background: #2b292b;
`;

export const FooterBottom = styled.div`
  background: #2b292b;
`;

export const FooterBottomContent = styled.div<{ extendPadding?: boolean }>`
  padding-top: 30px;
  padding-bottom: 48px;
  display: flex;

  font-size: 12px;
  line-height: 150%;
  color: ${theme.colors.greySecondary};

  @media (max-width: 992px) {
    padding-bottom: 100px;
  }

  @media (max-width: 480px) {
    padding-bottom: ${({ extendPadding }) => (extendPadding ? '170px' : '40px')};
    padding-top: 15px;
  }
`;

export const LogoBlock = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
`;

export const Logo = styled.div`
  background: linear-gradient(219.96deg, #6f5ee2 9.42%, #e04c8a 91%);
  height: 42px;
  width: 42px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 900;
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
`;

export const LogoMarketNameBlock = styled.div`
  margin-left: 9px;
`;

export const LogoMarketSubname = styled.div`
  font-size: 13px;
  line-height: 140%;
  color: ${theme.colors.greyPrimary};
  margin-top: -5px;
`;

export const LogoDivider = styled.div`
  background: #686a70;
  height: 42px;
  width: 1px;
  margin-left: 10px;
  margin-right: 10px;
`;

export const LogoMarketDescription = styled.div`
  font-size: 13px;
  line-height: 140%;
  color: ${theme.colors.greyPrimary};
  max-width: 135px;
`;

export const LogoMarketName = styled.div<{ fontSize: number; isUpperCase: boolean }>`
  font-weight: ${({ isUpperCase }) => (isUpperCase ? 700 : 500)};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  line-height: 120%;
  color: white;

  @media (max-width: 480px) {
    line-height: 20px;
  }
`;

export const FooterNav = styled.nav`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 70px;
  grid-row-gap: 12px;

  a {
    font-size: 14px;
    line-height: 140%;
    color: ${theme.colors.greyPrimary};

    &:hover {
      color: white;
    }
  }
`;

export const FooterBlock = styled.div``;

export const FooterBlockTitle = styled.div`
  font-size: 14px;
  line-height: 140%;
  color: ${theme.colors.greySecondary};
  margin-bottom: 15px;
`;

export const FooterBlockContent = styled.div``;

export const FooterBlockSocialsContent = styled.div`
  display: flex;
`;

export const FooterSocial = styled.div`
  width: 34px;
  height: 34px;
  border: 1px solid #686a70;
  box-sizing: border-box;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #9ca0a9;

  &:hover {
    color: white;
    &.vk {
      background-color: #4c6c91;
      border-color: #4c6c91;
    }

    &.facebook {
      background-color: #3b5998;
      border-color: #3b5998;
    }

    &.twitter {
      background-color: #55acee;
      border-color: #55acee;
    }

    &.ok {
      background-color: #f68634;
      border-color: #f68634;
    }

    &.youtube {
      background-color: #ff0000;
      border-color: #ff0000;

      svg {
        path {
          &:nth-child(2) {
            fill: #ff0000;
          }
        }
      }
    }

    &.instagram {
      background-color: #ffffff;
      border-color: #ffffff;
      color: black;
    }
  }
`;

export const FooterSocials = styled.div`
  ${FooterSocial} {
    &:not(:first-child) {
      margin-left: 14px;
    }
  }
`;

export const FooterLocation = styled.div``;

export const FooterTopSEO = styled.div`
  font-size: 12px;
  line-height: 150%;
  color: ${theme.colors.greySecondary};
  margin-top: 50px;
  width: 100%;
`;

export const FooterBottomLeft = styled.div``;

export const FooterBottomRight = styled.div`
  width: 600px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const FooterBottomOffer = styled.div`
  a {
    margin-left: 2px;
    color: ${theme.colors.primary};
  }

  br {
    display: none;
  }
`;

export const FooterBottomLinks = styled.nav`
  margin-top: 20px;
  display: flex;

  a {
    display: block;
    line-height: 120%;
    padding: 0px 1px;

    border-bottom: 1px solid ${theme.colors.greySecondary};

    &:hover {
      border: none;
    }

    &:not(:first-child) {
      margin-left: 30px;
    }
  }
`;

export const FooterBottomText = styled.div`
  margin-top: 20px;

  &.responsive {
    display: none;
  }

  @media (max-width: 480px) {
    display: none;
    &.responsive {
      display: block;
    }
  }
`;

export const FooterBottomUpdateDate = styled.div`
  text-align: right;

  @media (max-width: 480px) {
    margin-top: 15px;
    width: 100%;
    text-align: left;
  }
`;

export const FooterBootomCopyright = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export const FooterBootomCopyrightText = styled.div`
  width: max-content;
`;

export const FooterBootomCopyrightLogo = styled.div`
  margin-left: 15px;
`;

export const FooterTopContentFlex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`;

export const FooterTopContent = styled.div`
  padding-top: 60px;
  padding-bottom: 30px;
  border-bottom: 1px solid #3d3b3d;

  @media (max-width: 1353px) {
    ${FooterTopContentFlex} {
      flex-wrap: wrap;

      ${FooterNav} {
        margin-left: 0;
        grid-template-columns: 1fr 1fr;
      }
    }

    ${FooterLocation} {
      flex-basis: 20%;
    }
  }

  @media (max-width: 1215px) {
    ${FooterTopContentFlex} ${FooterNav} {
      margin-top: 38px;
      width: 560px;
      order: 4;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (max-width: 1170px) {
    ${FooterLocation} {
      flex-basis: unset;
    }
  }

  @media (max-width: 1000px) {
    padding-top: 50px;
    padding-bottom: 30px;
    border-bottom: 1px solid #3d3b3d;

    ${LogoBlock} {
      ${Logo} {
        width: 39px;
        height: 39px;
      }

      ${LogoMarketSubname} {
        font-size: 12px;
      }

      ${LogoMarketDescription} {
        line-height: 15px;
      }
    }

    ${FooterSocials} {
      ${FooterSocial} {
        width: 30px;
        height: 30px;
        &:not(:first-child) {
          margin-left: 15px;
        }

        &.instagram div svg {
          width: 14px;
          height: 14px;
        }

        &.youtube div svg {
          width: 14px;
          height: 10px;
        }

        &.ok div svg {
          height: 15px;
          width: 10px;
        }

        &.twitter div svg {
          width: 14px;
          height: 12px;
        }

        &.facebook div svg {
          width: 8px;
          height: 16px;
        }

        &.vk div svg {
          width: 16px;
          height: 9px;
        }

        div {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    ${FooterNav} {
      width: 540px;
    }

    ${FooterTopSEO} {
      margin-top: 35px;
      font-size: 12px;
      line-height: 18px;
    }
  }

  @media (max-width: 836px) {
    ${FooterLocation} {
      width: 100%;
    }

    ${FooterTopContentFlex} {
      justify-content: flex-start;
    }

    ${FooterSocials} {
      margin-left: 36px;
    }
  }

  @media (max-width: 780px) {
    ${FooterSocials} {
      margin-left: 37px;
    }
  }

  @media (max-width: 768px) {
    ${FooterLocation} {
      margin-top: 10px;
    }
  }

  @media (max-width: 741px) {
    ${FooterTopContentFlex} {
      ${LogoBlock} {
        flex-basis: 100%;
      }

      ${FooterLocation} {
        margin-top: 0;
      }

      ${FooterNav} {
        margin-top: 25px;
        margin-bottom: 0;
        grid-column-gap: 36px;
        grid-row-gap: 8px;
        order: 0;
      }

      ${FooterSocials} {
        margin-top: 25px;
        margin-bottom: 25px;
        margin-left: 0;
        flex-basis: 100%;

        ${FooterSocial} {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
  }

  @media (max-width: 517px) {
    ${FooterTopContentFlex} ${FooterNav} {
      grid-template-columns: 1fr 0.9fr 1.1fr;
    }
  }

  @media (max-width: 505px) {
    padding-top: 36px;
    padding-bottom: 0;
    border-bottom: none;

    ${FooterTopContentFlex} ${FooterNav} {
      margin-top: 15px !important;
      margin-bottom: 0;
      width: 100%;
      grid-column-gap: 30px;
    }

    ${FooterSocials} {
      margin-bottom: 0 !important;
      margin-top: 15px !important;
    }

    ${FooterLocation} {
      margin-top: 15px !important;
    }

    ${FooterTopSEO} {
      margin-top: 30px;
    }
  }

  @media (max-width: 472px) {
    ${FooterTopContentFlex} ${FooterNav} {
    }
  }

  @media (max-width: 480px) {
    padding: 0;

    ${FooterNav} {
      justify-content: flex-start;

      a {
        font-size: 14px;
      }
    }

    ${FooterBlockTitle} {
      margin-bottom: 15px;
      font-size: 14px;
    }

    ${LogoBlock} {
      ${Logo} {
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 7%;
        width: 30px;
        min-width: 30px;
        height: 30px;
      }

      ${LogoMarketSubname} {
        font-size: 11px;
      }

      ${LogoDivider} {
        margin: 0 8px;
      }

      ${LogoMarketDescription} {
        font-size: 12px;
      }
    }

    ${FooterTopSEO} {
      line-height: 18px;
    }

    ${FooterTopContentFlex} {
      padding-top: 35px;
    }
  }

  @media (max-width: 460px) {
    ${FooterTopContentFlex} {
      flex-direction: column;
      align-items: flex-start;

      ${FooterNav} {
        width: 100%;
        order: 0 !important;
        margin: 25px 0 0 0 !important;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 16px;
      }

      ${FooterLocation} {
        margin-top: 0;
        margin-left: 0;
      }

      ${FooterSocials} {
        margin-bottom: 25px;
      }
    }
  }
`;

export const StyledFooter = styled.footer<{ noMargin?: boolean }>`
  margin-top: ${(props) => (props.noMargin ? '0px' : '100px')};
  position: relative;

  @media ${device.tabletM} {
    ${FooterBottomContent} {
      padding-top: 30px;
      position: relative;
    }

    ${FooterBottomRight} {
      position: absolute;
      bottom: 52px;
      right: 0;
    }

    ${FooterBottomLinks} {
      margin-top: 20px;
      gap: 20px;
    }

    ${FooterNav} {
      margin-top: 25px;
    }
  }

  @media (max-width: 802px) {
    ${FooterBottomLinks} {
      flex-direction: column;
      display: flex;
      align-items: flex-start;

      a {
        margin: 0;
      }
    }
  }

  @media (max-width: 662px) {
    ${FooterBottomRight} {
      width: 100%;
      margin-top: 20px;
      margin-left: 0;
      position: static;
      justify-content: flex-start;
    }

    ${FooterBootomCopyright} {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    ${FooterBottomContent} {
      flex-direction: column;
    }
  }

  @media (max-width: 505px) {
    ${FooterBottomContent} {
      padding-top: 15px;
      padding-bottom: 65px;
    }

    ${FooterBottomLinks} {
      margin-top: 15px;
      margin-bottom: 0;
      gap: 10px;
    }

    ${FooterBottomText} {
      margin-top: 15px;
    }

    ${FooterBottomOffer} br {
      display: block;
    }

    ${FooterBootomCopyright} {
      margin-top: 15px;
    }
  }

  @media (max-width: 483px) {
    padding-bottom: 40px;

    ${FooterTopContent} ${FooterTopContentFlex} ${FooterNav} {
      grid-template-columns: 1fr 0.87fr 1.13fr;
    }
  }

  @media ${device.mobileM} {
    margin-top: ${(props) => (props.noMargin ? '0px' : '60px')};

    ${FooterBottomLinks} {
      margin-bottom: 0 !important;
    }

    ${FooterBottomRight} {
      margin-top: 0 !important;
    }
  }

  @media (max-width: 474px) {
    ${FooterTopContent} ${FooterTopContentFlex} ${FooterNav} {
      grid-template-columns: 126px 107px 141px;
    }
  }

  @media (max-width: 445px) {
    ${FooterTopContent} ${FooterTopContentFlex} ${FooterNav} {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 30px;
    }
  }
`;

import styled from 'styled-components';
import { theme } from '@components/ui/theme';

export const StyledPopoverOrders = styled.div`
  width: 300px;
  padding: 12px;
  font-size: 13px;
  line-height: 18px;
  color: #686a70;
`;

export const PopoverOrdersLink = styled.span`
  color: ${theme.colors.purple};
  text-decoration: underline;
  cursor: pointer;
`;

export const theme = {
  colors: {
    primary: '#E54E8D',
    primaryHover: '#F25292',
    primaryDisabled: '#E64E8D',
    black: '#302E30',
    greyPrimary: '#9CA0A9',
    greySecondary: '#686A70',
    purple: '#825BD3',
    purpleHover: '#8C62E3',
    red: '#ED5060',
  },
};

export const shadow = {
  primary: `
    box-shadow: 0px 2px 16px rgba(49, 18, 59, 0.1);
  `,
};

const size = {
  mobileS: '360px',
  mobileM: '480px',
  tabletS: '768px',
  tabletM: '1000px',
};

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  tabletS: `(max-width: ${size.tabletS})`,
  tabletM: `(max-width: ${size.tabletM})`,
};

export const disableAutoFill = `
  &:-webkit-autofill, &:-webkit-autofill:focus, &:-internal-autofill-selected {
      -webkit-box-shadow: 0 0 0px 1000px white inset;
  }
`;

export const scrollbar = {
  disable: `
    -ms-overflow-style: none;
    scrollbar-width: none;
 
    
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  `,

  vertical: `
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }

    &::-webkit-scrollbar-track {
      background: none;
      
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: #D9DDE7;
      width: 100px;
    }

    scrollbar-width: thin;
    scrollbar-color: #D9DDE7;
  `,
  alignSidesDesktop: `
    width: calc(100% + 60px);
    position: relative;
    left: -30px;
    padding: 0 30px;
  `,
  alignSidesMobile: `
    width: calc(100% + 40px);
    position: relative;
    left: -20px;
    padding: 0 20px;
  `,
  border: {
    desktop: `
      &::-webkit-scrollbar-thumb {
        border-left: 30px white solid;
        border-right: 30px white solid;
      }
    `,
    mobile: `
      &::-webkit-scrollbar-thumb {
        border-left: 20px white solid;
        border-right: 20px white solid;
      }
    `,
  },
};

import styled from 'styled-components';
import { IconButton } from '@components/ui/icon-button/icon-button';
import { device, theme } from '@components/ui/theme';

export const Image = styled.div`
  min-width: 627px;
  max-width: 627px;
  width: 100%;
  height: 543px;
  position: relative;
  margin: 0 auto;

  @media screen and (max-width: 992px) {
    min-width: 220px;
    height: 400px;
  }
`;

export const ControlButton = styled(IconButton)`
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(99, 47, 117, 0.09);
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 2;

  &:disabled {
    background-color: #fff;
  }

  &:hover:not(:disabled) {
    color: ${theme.colors.primary};
  }
`;

export const Prev = styled(ControlButton)<{ sliderWidth?: number }>`
  left: 30px;

  &.bottom {
    left: calc((100vw - ${({ sliderWidth }) => `${sliderWidth}px`} - 30px) / 2 - 25px);
  }

  @media (max-width: 1000px) {
    &.bottom {
      left: calc((100vw - ${({ sliderWidth }) => `${sliderWidth}px`} - 120px) / 2 - 25px);
    }
  }
`;

export const Next = styled(ControlButton)<{ sliderWidth?: number }>`
  right: 30px;

  &.bottom {
    right: calc((100vw - ${({ sliderWidth }) => `${sliderWidth}px`} - 30px) / 2 - 25px);
  }

  @media (max-width: 1000px) {
    &.bottom {
      right: calc((100vw - ${({ sliderWidth }) => `${sliderWidth}px`} - 120px) / 2 - 25px);
    }
  }
`;

export const TopSlider = styled.div`
  width: 100vw;

  @media screen and (max-width: 992px) {
    box-sizing: border-box;
    padding-bottom: 60px;
    height: calc(100% - 60px - 60px - 60px);
    width: 100%;

    display: flex;
    align-items: center;
  }
`;

export const Slider = styled.div`
  bottom: 30px;
  width: 100%;
  position: absolute;
  z-index: 100;
  margin-top: 117px;

  @media screen and (max-width: 992px) {
    margin-top: 0;
  }

  @media (max-width: 610px) {
    ${Prev}, ${Next} {
      display: none;
    }
  }
`;

export const Slides = styled.div`
  height: 60px;
  position: relative;
  margin: 0 auto;
  max-width: 1494px;
  width: fit-content;

  .swiper-slide {
    width: auto;
    cursor: pointer;
  }

  .swiper-wrapper {
    max-width: 1494px;
    margin: 0 auto;
  }

  @media (max-width: 1518px) {
    max-width: 1188px;

    .swiper-wrapper {
      max-width: 1188px;
    }
  }

  @media (max-width: 1307px) {
    max-width: 986px;

    .swiper-wrapper {
      max-width: 986px;
    }
  }

  @media (max-width: 1024px) {
    max-width: 683px;

    .swiper-wrapper {
      max-width: 683px;
    }
  }

  @media (max-width: 852px) {
    max-width: 481px;

    .swiper-wrapper {
      max-width: 481px;
    }
  }

  @media (max-width: 524px) {
    max-width: 380px;

    .swiper-wrapper {
      max-width: 380px;
    }
  }

  @media (max-width: 394px) {
    max-width: 279px;

    .swiper-wrapper {
      max-width: 279px;
    }
  }
`;

export const SlideImageBox = styled.div`
  width: 77px;
  height: 60px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    border-color: ${theme.colors.primary};
    box-sizing: border-box;
    border-radius: 3px;
  }
`;

export const SlideImage = styled.div`
  width: 80%;
  height: 80%;
  position: relative;
`;

export const StyledImageSliderModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  & > ${Prev} {
    left: 46.5px;
  }

  & > ${Next} {
    right: 46.5px;
  }

  @media screen and (max-width: 1000px) {
    position: relative;
    margin-top: 24px;
    padding: 0;
    width: 100%;
    height: 91vh;

    & > ${Prev} {
      left: 8px;
    }

    & > ${Next} {
      right: 8px;
    }
  }

  @media ${device.mobileM} {
    & > ${Prev}, & > ${Next} {
      width: 38px !important;
      height: 38px !important;
    }
  }
`;

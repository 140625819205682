import React from "react";
import * as S from "./layout-buttons.style";
import { Icon } from "@components/ui/icons/icon";
import { observer } from "mobx-react-lite";
import { useStore } from "@stores/root";
import { ListKeys } from "@stores/cookies";
import { ListType } from "../../../../pages/c/[slug]";

type Props = {
  useLargeCards?: boolean;
  listKey: ListKeys;
};

export const LayoutButtons = observer((props: Props) => {
  const { cookie } = useStore();

  return (
    <S.StyledLayoutButtons>
      <S.LayoutButton
        active={
          cookie.listTypes[props.listKey] == (props.useLargeCards ? ListType.LARGE : ListType.LIST)
        }
        onClick={() =>
          cookie.setListType(props.listKey, props.useLargeCards ? ListType.LARGE : ListType.LIST)
        }
      >
        <Icon icon="burger" />
      </S.LayoutButton>

      <S.LayoutButton
        active={cookie.listTypes[props.listKey] == ListType.CARDS}
        onClick={() => cookie.setListType(props.listKey, ListType.CARDS)}
      >
        <Icon icon="grid" />
      </S.LayoutButton>
    </S.StyledLayoutButtons>
  );
});

import React from 'react';
import * as S from './popular-category.style';
import styled from 'styled-components';
import { Image } from '@components/Image';
import Link from 'next/link';
import plural from 'plural-ru';
import { imageLoader } from '../../../../utils/image-loader';

type Props = {
  name: string;
  productsCount: number;
  offersCount: number;
  image: string;
  slug: string;
  className?: string;
  onClick?(): void;
};

const Component = (props: Props) => {
  const porudctCount = plural(props.productsCount, '%d товар', '%d товара', '%d товаров');
  const offersCount = plural(
    props.offersCount,
    '%d предложение',
    '%d предложения',
    '%d предложений'
  );

  return (
    <Link legacyBehavior prefetch={false} href={`/c/${props.slug}`} passHref>
      <S.StyledPopularCategory className={props.className} onClick={props.onClick}>
        <S.PopularCategoryLeft>
          <S.PopularCategoryTitle>{props.name}</S.PopularCategoryTitle>
          <S.PopularCategorySubtitle>{porudctCount}</S.PopularCategorySubtitle>
          <S.PopularCategorySubtitle className="offers">{offersCount}</S.PopularCategorySubtitle>
        </S.PopularCategoryLeft>
        <S.PopularCategoryRight>
          {props.image ? (
            <Image
              style={{ objectFit: 'contain' }}
              loader={imageLoader({
                width: 256,
                src: props.image,
              })}
              fill
              src={`s3:/${props.image}`}
              alt={props.name}
              title={props.name}
            />
          ) : (
            <Image src="s3://pics/nophotocard.svg" fill alt={props.name} title={props.name} />
          )}
        </S.PopularCategoryRight>
      </S.StyledPopularCategory>
    </Link>
  );
};

export const PopularCategory = styled(Component)``;

import React from "react";

export const IconCheckboxCircle = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0001 18.3334C5.39758 18.3334 1.66675 14.6026 1.66675 10.0001C1.66675 5.39758 5.39758 1.66675 10.0001 1.66675C14.6026 1.66675 18.3334 5.39758 18.3334 10.0001C18.3334 14.6026 14.6026 18.3334 10.0001 18.3334ZM10.0001 16.6668C11.7682 16.6668 13.4639 15.9644 14.7141 14.7141C15.9644 13.4639 16.6667 11.7682 16.6667 10.0001C16.6667 8.23197 15.9644 6.53628 14.7141 5.28604C13.4639 4.03579 11.7682 3.33342 10.0001 3.33342C8.23197 3.33342 6.53628 4.03579 5.28604 5.28604C4.03579 6.53628 3.33341 8.23197 3.33341 10.0001C3.33341 11.7682 4.03579 13.4639 5.28604 14.7141C6.53628 15.9644 8.23197 16.6668 10.0001 16.6668ZM9.16925 13.3334L5.63341 9.79758L6.81175 8.61925L9.16925 10.9767L13.8826 6.26258L15.0617 7.44092L9.16925 13.3334Z"
        fill="currentColor"
      />
    </svg>
  );
};

import React, { useMemo, useRef, useState } from 'react';
import * as S from './available-package.style';
import { TextWithIcon } from '@components/ui/text-with-icon/text-with-icon';
import { IconHeart } from '@components/ui/icons/icon-heart';
import { Button } from '@components/ui/button/button';
import { Label } from '@components/ui/label/label';
import { Image } from '@components/Image';
import plural from 'plural-ru';
import Link from 'next/link';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores/root';
import { IconHeartFilled } from '@components/ui/icons/icon-heart-filled';
import { Product } from '../../../../gql/generated';
import styled from 'styled-components';
import { PopoverProductCart } from '@components/popovers/popover-product-cart/popover-product-cart';
import { imageLoader } from '../../../../utils/image-loader';
import { RatingStars } from '@components/ui/rating-stars/rating-stars';
import { ProductCardSlider } from '@components/product/elements/product-card/slider/product-card-slider';
import { ProductCardDescription } from '@components/product/elements/product-card-description/product-card-description';
import { Icon } from '@components/ui/icons/icon';
import { ReadMoreText } from '@components/ui/read-more-text/read-more-text';
import { removeTags } from 'utils/formaters';

type StatisticReviews = {
  avg: number;
};

type ProductProps = Pick<
  Product,
  | 'images'
  | 'slug'
  | 'price'
  | 'id'
  | 'hit'
  | 'name'
  | 'priceFrom'
  | 'priceTo'
  | 'rating'
  | 'ratingCount'
  | 'discount'
> & {
  disableResponsivePrice?: boolean;
  offersCount?: number;
  description?: string;
  shortDescription?: string;
  withoutExtInfo?: boolean;
};

type Props = ProductProps & {
  className?: string;
  isHoverable?: boolean;
  withBorder?: boolean;
  altPadding?: boolean;
};

const Component = observer((props: Props) => {
  const [selectedImage, setSelectedImage] = useState(0);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const ratingPlural = plural(props.ratingCount, '%d оценка', '%d оценки', '%d оценок');
  const offersPlural = plural(
    props?.offersCount,
    '%d предложение',
    '%d предложения',
    '%d предложений'
  );
  const { favorites, cart, modal, ui } = useStore();

  const isFavorite = favorites.has(props.id);
  const isInCart = cart.has(props.id);

  const oldPrice = useMemo(() => (props.price / (100 - props.discount)) * 100, []);

  const addToFavorites = (): void => {
    if (isFavorite) {
      return favorites.remove(props.id);
    }
    favorites.add({
      id: props.id,
      name: props.name,
      slug: props.slug,
      price: props.price,
      priceFrom: props.priceFrom,
      priceTo: props.priceTo,
      images: props.images,
      hit: props.hit,
      rating: props.rating,
      ratingCount: props.ratingCount,
      discount: props.discount,
    });
  };

  const addToCart = (): void => {
    cart.addItem({
      id: props.id,
      name: props.name,
      price: props.price,
      slug: props.slug,
      images: props.images,
      priceFrom: props.priceFrom,
      priceTo: props.priceTo,
      hit: props.hit,
      rating: props.rating,
      discount: props.discount,
      ratingCount: props.ratingCount,
    });
  };

  const openOrderModal = () => {
    modal.show('oneClickOrder', {
      id: props.id,
      name: props.name,
      slug: props.slug,
      price: props.price,
      image: props.images[0],
      discount: props.discount,
    });
  };

  const image = props.images[selectedImage];

  return (
    <S.StyledPackage
      disableResponsivePrice={props.disableResponsivePrice}
      isHoverable={props.isHoverable}
      withBorder={props.withBorder}
      className={`${props.className} ${props.altPadding && 'altPadding'}`}
    >
      <Link legacyBehavior prefetch={false} href={`/p/${props.slug}`} passHref>
        <S.PackageImage>
          {image ? (
            <>
              <Image
                src={`s3:/${image}`}
                fill
                loader={imageLoader({
                  src: image,
                  width: 256,
                })}
                style={{ objectFit: 'contain' }}
                alt={props.name}
                title={props.name}
              />
              <ProductCardSlider
                length={props.images.length}
                selectedIndex={selectedImage}
                setSelectedIndex={setSelectedImage}
              />
            </>
          ) : (
            <Image
              src="s3://pics/nophotocard.svg"
              fill
              style={{ objectFit: 'contain' }}
              alt={props.name}
              title={props.name}
            />
          )}
          <S.Labels>
            {props.hit && <Label color="purple">Хит</Label>}
            {props.discount > 0 && <Label color="pink">-{props.discount}%</Label>}
          </S.Labels>
        </S.PackageImage>
      </Link>

      <S.Labels className="responsive">
        {props.hit && <Label color="purple">Хит</Label>}
        {props.discount > 0 && <Label color="pink">-{props.discount}%</Label>}
      </S.Labels>

      <S.PackageInfo>
        <Link legacyBehavior prefetch={false} href={`/p/${props.slug}`} passHref>
          <S.PackageInfoTitle>{props.name}</S.PackageInfoTitle>
        </Link>
        <S.PackageInfoRating>
          {props.withoutExtInfo ? (
            <>
              <S.PackageInfoRatingStar>
                <Icon icon="starFilled" />
              </S.PackageInfoRatingStar>

              <S.PackageInfoRatingScore>{props.rating.toFixed(1)}</S.PackageInfoRatingScore>
            </>
          ) : (
            <RatingStars rating={props.rating} votes={props.ratingCount} />
          )}
          <S.PackageInfoRatingFavorites isFavorite={isFavorite}>
            <TextWithIcon
              onClick={addToFavorites}
              icon={isFavorite ? IconHeartFilled : IconHeart}
              notLink
            >
              {isFavorite ? 'В избранном' : 'В избранное'}
            </TextWithIcon>
          </S.PackageInfoRatingFavorites>
          {ui.viewport >= 782 && props.offersCount > 0 && (
            <S.PackageInfoRatingValue>{offersPlural}</S.PackageInfoRatingValue>
          )}
        </S.PackageInfoRating>

        {props.shortDescription && !props.withoutExtInfo && (
          <S.PackageDescription ref={descriptionRef}>
            <ReadMoreText
              text={removeTags(props.shortDescription)}
              hideButton
              href={`/p/${props.slug}`}
              blank
            />
          </S.PackageDescription>
        )}
      </S.PackageInfo>
      <S.PackagePrice>
        <S.PackagePriceTop>
          {props.discount > 0 && !props.withoutExtInfo && (
            <S.PackagePriceOld>
              {Number(oldPrice.toFixed(0)).toLocaleString('ru-RU')} ₽
            </S.PackagePriceOld>
          )}
          <S.PackagePriceMain>{props.price.toLocaleString('ru-RU')} ₽</S.PackagePriceMain>

          {(ui.viewport < 782 || props.priceTo !== props.priceFrom) && (
            <S.PackagePriceFrom>
              {props.priceTo !== props.priceFrom && (
                <>
                  от {props.priceFrom.toLocaleString('ru-RU')} до{' '}
                  {props.priceTo.toLocaleString('ru-RU')} ₽
                </>
              )}
              {ui.viewport < 782 && (
                <S.PackagePriceFromOffers>{offersPlural}</S.PackagePriceFromOffers>
              )}
            </S.PackagePriceFrom>
          )}
        </S.PackagePriceTop>
        <S.PackagePriceButtons>
          {!props.withoutExtInfo && (
            <Button outline onClick={openOrderModal}>
              Хочу купить
            </Button>
          )}
          <PopoverProductCart addToCart={addToCart} isInCart={isInCart} productID={props.id} />
        </S.PackagePriceButtons>
      </S.PackagePrice>
    </S.StyledPackage>
  );
});

export const ProductAvailablePackage = styled(Component)``;

import React from 'react';
import * as S from './loader.style';
import styled from 'styled-components';

type Props = {
  className?: string;
  color?: string;
};

const Component = (props: Props) => {
  return <S.StyledLoader className={props.className} color={props.color}></S.StyledLoader>;
};

export const Loader = styled(Component)``;

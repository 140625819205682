import styled from 'styled-components';
import { theme } from '@components/ui/theme';

export const CategoryMenuItemImage = styled.div`
  width: 40px;
  height: 34px;
  position: relative;
  flex-shrink: 0;
`;

export const CategoryMenuItemText = styled.div`
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  padding: 0px 12px;
  border-radius: 3px;
  font-size: 14px;
  line-height: 140%;
  margin-left: 7px;
`;

export const StyledCategoryMenuItem = styled.a`
  display: flex;
  align-items: center;

  &.active {
    ${CategoryMenuItemText} {
      color: ${theme.colors.primary};
      background: #f9f7fa;
    }
  }

  &:hover {
    ${CategoryMenuItemText} {
      background: #f9f7fa;
      color: ${theme.colors.primary};
    }
  }
`;

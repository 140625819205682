import styled from 'styled-components';
import { device } from '@components/ui/theme';
import { Icon } from '@components/ui/icons/icon';

export const StyledPopoverProductCart = styled.div`
  padding: 12px;
`;

export const PopoverProductCartWrapper = styled.div`
  @media (min-width: 1001px) {
    padding-left: 45px;
  }

  button {
    max-width: 40px;
  }
`;

export const CartButton = styled.div`
  svg path {
    fill: #fff;
  }

  @media ${device.tabletM} {
    button {
      width: 40px !important;
    }
  }

  @media ${device.tabletS} {
    button {
      width: 40px !important;
      height: 40px !important;
    }
  }
`;

import styled from 'styled-components';
import React from 'react';

const StyledIconHeart = styled.div`
  @media (max-width: 768px) {
    path.broken {
      display: none;
    }
  }
`;

export const IconHeart = () => {
  return (
    <StyledIconHeart>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.4787 15.7714L14.9988 24.5L6.52098 15.7714C6.49542 15.742 6.47019 15.7124 6.44528 15.6826C6.44224 15.679 6.43921 15.6753 6.43617 15.6717C4.43131 13.2628 4.52565 9.6417 6.71826 7.3461C6.73109 7.33267 6.744 7.31928 6.75697 7.30593C6.77531 7.28709 6.79373 7.2684 6.81224 7.24986C7.97843 6.08168 9.49227 5.4984 11.0056 5.5C12.2897 5.50137 13.5735 5.92387 14.6442 6.76751C14.6443 6.76761 14.6445 6.7677 14.6446 6.7678C14.6461 6.769 14.6476 6.77019 14.6491 6.77139C14.7691 6.86616 14.8865 6.96622 15.0008 7.07158C15.1146 6.96654 15.2315 6.86675 15.351 6.77223C15.3512 6.7721 15.3513 6.77197 15.3515 6.77184C15.3526 6.77097 15.3537 6.7701 15.3548 6.76923C16.423 5.92599 17.7068 5.50244 18.9916 5.50009C20.5301 5.49728 22.0699 6.09837 23.2427 7.30593C25.5046 9.63712 25.5826 13.3498 23.4787 15.7714ZM21.8267 8.7583C23.3169 10.2932 23.3935 12.7377 22.0244 14.3586C22.0229 14.3605 22.0213 14.3624 22.0197 14.3643L14.9998 21.5901L7.97995 14.3632C7.97863 14.3617 7.97731 14.3601 7.97599 14.3586C7.97594 14.3585 7.97588 14.3584 7.97582 14.3584C6.60713 12.7384 6.68347 10.2903 8.17195 8.76036C8.18416 8.7478 8.19701 8.73477 8.2094 8.72236C8.21008 8.72167 8.21076 8.72099 8.21143 8.72032C9.70479 7.22657 12.0631 7.16434 13.6249 8.56484C13.6284 8.56801 13.632 8.57118 13.6355 8.57436C13.6456 8.58354 13.6558 8.59277 13.6658 8.60207L15.0018 9.83242L16.3368 8.60104C16.3448 8.59365 16.3528 8.5863 16.3609 8.57899C16.3665 8.57387 16.3722 8.56877 16.3778 8.56368C16.4631 8.48711 16.5508 8.41492 16.6406 8.3471C18.191 7.17644 20.3799 7.30895 21.7878 8.71882C21.8008 8.73187 21.8138 8.74503 21.8267 8.7583Z"
          fill="#9CA0A9"
        />
      </svg>
    </StyledIconHeart>
  );
};

import React from "react";
import * as S from "./catalogue-button.style";
import classNames from "classnames";

type Props = {
  active: boolean;
  onClick?(): void;
};

export const CatalogueButton = (props: Props) => {
  const classes = classNames({
    active: props.active,
  });

  const toggle = () => {
    props.onClick();
  };

  return (
    <S.StyledCatalogueButton className={classes} onClick={toggle}>
      <S.ButtonIcon>
        <div></div>
        <div></div>
        <div></div>
      </S.ButtonIcon>
      <S.ButtonText>Каталог</S.ButtonText>
    </S.StyledCatalogueButton>
  );
};

import React from 'react';
import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(24, minmax(0, 1fr));
  -moz-column-gap: 40px;
  column-gap: 40px;
  position: relative;

  @media screen and (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

import dynamic from 'next/dynamic';
import React, { useEffect, useState, useMemo } from 'react';
import { Loader } from './ui/loader/loader';
import axios from 'axios';
import { CpaProduct } from './product/elements/cpa-product/cpa-product';
import { off } from 'process';
import { WH } from '@components/WH';
import { useStore } from '@stores/root';
import { MarketWidget } from './MarketWidget';
import { uniqid } from '../utils/uniqid';

const Component: FCC = ({ searchText, name, ...props }) => {
  // const { project } = useStore();
  // const { clid } = project;

  // if(clid) {
  //   return <MarketWidget searchText={searchText} />;
  // }

  const id = useMemo(uniqid, []);

  useEffect(() => {
    (function (w) {
      function start() {
        w.removeEventListener('cpaWidgetLoad', start);
        w.createCpaWidget(id);
      }
      w.createCpaWidget ? start() : w.addEventListener('cpaWidgetLoad', start);
    })(window);
  }, []);

  return (
    <div {...props}>
      <div id={id} data-query={searchText} data-name={name}></div>
    </div>
  );

  // return (
  //   <div id="cpa" {...props}>
  //     {!offers.length ? (
  //       <div
  //         style={{
  //           display: 'flex',
  //           alignItems: 'center',
  //           justifyContent: 'center',
  //           padding: '80px',
  //         }}
  //       >
  //         <div style={{ padding: '0 20px' }}>Подождите идет загрузка предложений...</div>
  //         <Loader />
  //       </div>
  //     ) : offers.map((offer) => <CpaProduct key={offer.external_id} {...offer} />)}
  //   </div>
  // );
};

export const CpaWidget = dynamic(() => Promise.resolve(Component), {
  ssr: false,
});

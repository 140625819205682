import styled from 'styled-components';

const titleStyles = `
    font-weight: 500;
  font-size: 34px;
  line-height: 120%;
  color: #302e30;
  //display: flex;
  word-break: break-word;
`;

export const Title = styled.h1`
  ${titleStyles}
`;

export const TitleH2 = styled.h2`
  ${titleStyles}
`;

export const SubTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #9ca0a9;
  margin-top: 17px;
  margin-left: 16px;
`;

export const StyledH1 = styled.div`
  display: flex;

  @media (max-width: 1050px) {
    ${Title} {
      font-size: 30px;
      line-height: 36px;
    }

    ${SubTitle} {
      display: none;
    }
  }

  @media (max-width: 520px) {
    ${Title} {
      font-size: 24px;
      line-height: 28px;
    }
  }
`;

import React from "react";
import * as S from "./count-picker.style";
import { Icon } from "@components/ui/icons/icon";

type Props = {
  count: number;
  disableMinus?: boolean;
  onPlus?(): void;
  onMinus?(): void;
};

export const CountPicker = (props: Props) => {
  const disableMinus = props.disableMinus && props.count === 1;

  return (
    <S.StyledCountPicker>
      <S.CountPickerButtonMinus type="button" onClick={props.onMinus} disabled={disableMinus}>
        <Icon icon="minus" />
      </S.CountPickerButtonMinus>
      <S.CountPickerNumber>{props.count}</S.CountPickerNumber>
      <S.CountPickerButtonPlus type="button" onClick={props.onPlus}>
        <Icon icon="plus" />
      </S.CountPickerButtonPlus>
    </S.StyledCountPicker>
  );
};

import styled from 'styled-components';
import { theme } from '@components/ui/theme';

export const SearchResultImage = styled.a`
  margin-right: 10px;
  position: relative;
  width: 40px;
  height: 34px;
`;

export const SearchResultName = styled.a`
  flex: 1;
  font-size: 14px;
  line-height: 140%;
  color: #3c3a3d;
`;

export const SearchResultPrice = styled.a`
  margin-left: 30px;
  font-size: 14px;
  line-height: 140%;
  color: #3c3a3d;
`;

export const StyledSearchResultElement = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:first-child) {
    margin-top: 12px;
  }

  &:hover {
    ${SearchResultName}, ${SearchResultPrice} {
      color: ${theme.colors.primary};
    }
  }
`;

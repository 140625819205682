import styled from 'styled-components';
import { BlockTitle } from '@components/ui/block-title/block-title';
import { device, theme } from '@components/ui/theme';
import { LikeBox, LikeIcon } from '@components/product/elements/likes/likes.style';
import {
  ReviewCategory,
  ReviewCategoryText,
  ReviewCategoryTitle,
  ReviewInfo,
  ReviewLeft,
  ReviewLikes,
  ReviewTitle,
  ReviewTop,
  ReviewUser,
  ReviewUserAvatar,
  ReviewUserName,
  StyledReview,
} from '@components/category/elements/review/review.style';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 20px;
`;

export const MoreReviewsButton = styled.button`
  background: #f9f7fa;
  font-size: 15px;
  color: ${theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 60px;

  margin-top: 20px;

  &:hover {
    background: #ffffff;
  }

  svg {
    margin-right: 10px;
  }

  @media (max-width: 480px) {
    height: 43px;
  }
`;

export const Reviews = styled.section`
  margin-top: 80px;
  background: #fafafa;
  padding: 60px 0px;

  ${BlockTitle} {
    margin-bottom: 39px;
  }

  ${LikeIcon} {
    color: ${theme.colors.greyPrimary};
  }

  @media ${device.tabletM} {
    margin-top: 46px;
    padding: 50px 0;

    ${BlockTitle} {
      margin-bottom: 24px;
    }

    ${Grid} {
      grid-column-gap: 20px;
    }

    ${StyledReview}.responsive {
      flex-direction: column;
      padding: 20px 20px 30px 20px;

      ${ReviewUser}, ${ReviewCategory}, ${ReviewLikes} {
        padding-left: 92px;
      }

      ${ReviewTitle} {
        margin-bottom: 10px;
        font-size: 17px;
      }

      ${ReviewTop} ${ReviewLeft} {
        margin-right: 15px;
      }

      ${ReviewUser} {
        margin-top: 14px;

        ${ReviewUserAvatar} {
          width: 26px;
          height: 26px;
          min-width: 26px;

          svg {
            height: 12px;
            width: 12px;
          }
        }

        ${ReviewUserName} {
          font-size: 15px;
          line-height: 21px;
        }
      }

      ${ReviewLikes} {
        margin-top: 20px;
      }

      ${LikeBox} {
        padding: 6px;
      }

      ${ReviewCategory} {
        margin-top: 15px;

        ${ReviewCategoryTitle} {
          display: none;
        }

        ${ReviewCategoryText} {
          margin-top: 0;
          font-size: 12px;
          line-height: 15px;
          overflow: hidden;
        }
      }
    }

    @media (max-width: 850px) {
      ${Grid} {
        grid-template-columns: 1fr;
      }

      ${MoreReviewsButton} {
        height: 40px;
        font-size: 14px;
        background-color: #fff;

        svg {
          width: 22px;
          height: 22px;

          path {
            fill: #302e30;
          }
        }
      }
    }

    ${StyledReview}.responsive {
      ${ReviewTop} ${ReviewInfo} {
        flex: 1;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
      }

      ${ReviewUser} {
        margin-top: 10px;
      }

      ${ReviewTitle} {
        line-height: 140%;
        margin-right: 25px;
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 768px) {
    ${StyledReview}.responsive {
      ${ReviewTop} ${ReviewInfo} {
        flex-direction: row;
        justify-content: space-between;
        gap: 25px;
      }
    }
  }

  @media (max-width: 550px) {
    margin-top: 35px;
    padding: 35px 0;

    ${Grid} {
      grid-row-gap: 15px;
    }

    ${MoreReviewsButton} {
      margin-top: 15px;
    }

    ${StyledReview}.responsive {
      ${ReviewTop} ${ReviewInfo} {
        flex-direction: column;
        gap: 5px;
      }

      ${ReviewTitle} {
        margin-right: 0;
        margin-bottom: 4px;
        font-size: 15px;
        line-height: 140%;
      }

      ${ReviewUser} {
        padding-top: 15px;
        margin-top: 15px;
        border-top: 1px solid #f1eff2;

        ${ReviewUserName} {
          margin-left: 10px;
          font-size: 14px;
          line-height: 140%;
        }
      }

      ${ReviewCategory} {
        margin-top: 10px;
      }
      ${ReviewLikes} {
        margin-top: 15px;
      }

      ${ReviewUser}, ${ReviewCategory}, ${ReviewLikes} {
        padding-left: 0;
      }
    }
  }
`;

import styled from 'styled-components';

export const RatingNumber = styled.div`
  margin-left: 5px;
  font-size: 13px;
  text-align: center;
  position: relative;

  color: #302e30;

  @media (max-width: 1000px) {
    font-size: 12px;
  }
`;

export const Votes = styled.div`
  margin-left: 6px;
  font-size: 12px;
  color: #9ca0a9;
`;

export const Stars = styled.div<{
  coeff?: number;
  hideStarsOnTabletM?: boolean;
  hideStarsOnTabletS?: boolean;
  hideStarsOnMobileM?: boolean;
  hideStarsOnMobileS?: boolean;
}>`
  padding-bottom: 19px;
  display: block;
  position: relative;
  width: ${({ coeff }) => (coeff ? `${113 * coeff}px` : '113px')};
  min-width: ${({ coeff }) => (coeff ? `${113 * coeff}px` : '113px')};
  height: ${({ coeff }) => (coeff ? `${16 * coeff}px` : '16px')};
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='114' height='18' viewBox='0 0 114 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 1.30198L10.9189 5.9155L11.0362 6.19748L11.3406 6.22188L16.3213 6.62118L12.5265 9.87179L12.2946 10.0705L12.3654 10.3675L13.5248 15.2278L9.26063 12.6233L9 12.4641L8.73937 12.6233L4.47522 15.2278L5.63458 10.3675L5.70544 10.0705L5.4735 9.87179L1.67875 6.62118L6.65943 6.22188L6.96385 6.19748L7.08113 5.9155L9 1.30198Z' stroke='%23D9DDE7'/%3E%3Cpath d='M33 1.30198L34.9189 5.9155L35.0362 6.19748L35.3406 6.22188L40.3213 6.62118L36.5265 9.87179L36.2946 10.0705L36.3654 10.3675L37.5248 15.2278L33.2606 12.6233L33 12.4641L32.7394 12.6233L28.4752 15.2278L29.6346 10.3675L29.7054 10.0705L29.4735 9.87179L25.6787 6.62118L30.6594 6.22188L30.9638 6.19748L31.0811 5.9155L33 1.30198Z' stroke='%23D9DDE7'/%3E%3Cpath d='M57 1.30198L58.9189 5.9155L59.0362 6.19748L59.3406 6.22188L64.3213 6.62118L60.5265 9.87179L60.2946 10.0705L60.3654 10.3675L61.5248 15.2278L57.2606 12.6233L57 12.4641L56.7394 12.6233L52.4752 15.2278L53.6346 10.3675L53.7054 10.0705L53.4735 9.87179L49.6787 6.62118L54.6594 6.22188L54.9638 6.19748L55.0811 5.9155L57 1.30198Z' stroke='%23D9DDE7'/%3E%3Cpath d='M81 1.30198L82.9189 5.9155L83.0362 6.19748L83.3406 6.22188L88.3213 6.62118L84.5265 9.87179L84.2946 10.0705L84.3654 10.3675L85.5248 15.2278L81.2606 12.6233L81 12.4641L80.7394 12.6233L76.4752 15.2278L77.6346 10.3675L77.7054 10.0705L77.4735 9.87179L73.6787 6.62118L78.6594 6.22188L78.9638 6.19748L79.0811 5.9155L81 1.30198Z' stroke='%23D9DDE7'/%3E%3Cpath d='M105 1.30198L106.919 5.9155L107.036 6.19748L107.341 6.22188L112.321 6.62118L108.527 9.87179L108.295 10.0705L108.365 10.3675L109.525 15.2278L105.261 12.6233L105 12.4641L104.739 12.6233L100.475 15.2278L101.635 10.3675L101.705 10.0705L101.473 9.87179L97.6787 6.62118L102.659 6.22188L102.964 6.19748L103.081 5.9155L105 1.30198Z' stroke='%23D9DDE7'/%3E%3C/svg%3E%0A");
  background-position: left top;
  background-size: 100%;

  span {
    span {
      display: block;
      position: relative;
      width: ${({ coeff }) => (coeff ? `${113 * coeff}px` : '113px')};
      min-width: ${({ coeff }) => (coeff ? `${113 * coeff}px` : '113px')};
      height: ${({ coeff }) => (coeff ? `${16 * coeff}px` : '16px')};
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='113' height='16' viewBox='0 0 113 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.94043 0L11.3044 5.62463L17.4404 6.11144L12.7655 10.0744L14.1938 16L8.94043 12.8246L3.68714 16L5.11543 10.0744L0.44043 6.11144L6.57645 5.62463L8.94043 0Z' fill='%23E64E8D'/%3E%3Cpath d='M32.5 0L34.864 5.62463L41 6.11144L36.325 10.0744L37.7533 16L32.5 12.8246L27.2467 16L28.675 10.0744L24 6.11144L30.136 5.62463L32.5 0Z' fill='%23E64E8D'/%3E%3Cpath d='M56.9404 0L59.3044 5.62463L65.4404 6.11144L60.7655 10.0744L62.1938 16L56.9404 12.8246L51.6871 16L53.1154 10.0744L48.4404 6.11144L54.5765 5.62463L56.9404 0Z' fill='%23E64E8D'/%3E%3Cpath d='M80.5 0L82.864 5.62463L89 6.11144L84.325 10.0744L85.7533 16L80.5 12.8246L75.2467 16L76.675 10.0744L72 6.11144L78.136 5.62463L80.5 0Z' fill='%23E64E8D'/%3E%3Cpath d='M104.5 0L106.864 5.62463L113 6.11144L108.325 10.0744L109.753 16L104.5 12.8246L99.2466 16L100.675 10.0744L96 6.11144L102.135 5.62463L104.5 0Z' fill='%23E64E8D'/%3E%3C/svg%3E");
      background-position: left top;
      background-size: 100%;
    }
  }

  & > span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    overflow: hidden;

    & > span {
      background-image: url("data:image/svg+xml,%3Csvg width='114' height='18' viewBox='0 0 114 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 0L11.3805 5.72348L17.5595 6.21885L12.8518 10.2515L14.2901 16.2812L9 13.05L3.70993 16.2812L5.14822 10.2515L0.440492 6.21885L6.61947 5.72348L9 0Z' fill='%23E64E8D'/%3E%3Cpath d='M33 0L35.3805 5.72348L41.5595 6.21885L36.8518 10.2515L38.2901 16.2812L33 13.05L27.7099 16.2812L29.1482 10.2515L24.4405 6.21885L30.6195 5.72348L33 0Z' fill='%23E64E8D'/%3E%3Cpath d='M57 0L59.3805 5.72348L65.5595 6.21885L60.8518 10.2515L62.2901 16.2812L57 13.05L51.7099 16.2812L53.1482 10.2515L48.4405 6.21885L54.6195 5.72348L57 0Z' fill='%23E64E8D'/%3E%3Cpath d='M81 0L83.3805 5.72348L89.5595 6.21885L84.8518 10.2515L86.2901 16.2812L81 13.05L75.7099 16.2812L77.1482 10.2515L72.4405 6.21885L78.6195 5.72348L81 0Z' fill='%23E64E8D'/%3E%3Cpath d='M105 0L107.381 5.72348L113.56 6.21885L108.852 10.2515L110.29 16.2812L105 13.05L99.7099 16.2812L101.148 10.2515L96.4405 6.21885L102.619 5.72348L105 0Z' fill='%23E64E8D'/%3E%3C/svg%3E%0A");
    }
  }

  @media (min-width: 768px) and (max-width: 1000px) {
    margin-bottom: ${({ hideStarsOnTabletM }) => (hideStarsOnTabletM ? '3px' : 0)};
    width: ${({ coeff, hideStarsOnTabletM }) =>
      hideStarsOnTabletM ? '18px' : coeff ? `${113 * coeff}px` : '113px'};
    min-width: ${({ coeff, hideStarsOnTabletM }) =>
      hideStarsOnTabletM ? '18px' : coeff ? `${113 * coeff}px` : '113px'};
    overflow: hidden;
  }

  @media (min-width: 480px) and (max-width: 768px) {
    margin-bottom: ${({ hideStarsOnTabletS }) => (hideStarsOnTabletS ? '3px' : 0)};
    width: ${({ coeff, hideStarsOnTabletS }) =>
      hideStarsOnTabletS ? '18px' : coeff ? `${113 * coeff}px` : '113px'};
    min-width: ${({ coeff, hideStarsOnTabletS }) =>
      hideStarsOnTabletS ? '18px' : coeff ? `${113 * coeff}px` : '113px'};
    overflow: hidden;
  }

  @media (min-width: 361px) and (max-width: 480px) {
    margin-bottom: ${({ hideStarsOnMobileM }) => (hideStarsOnMobileM ? '3px' : 0)};
    width: ${({ coeff, hideStarsOnMobileM }) =>
      hideStarsOnMobileM ? `18px` : coeff ? `${113 * coeff}px` : '113px'};
    min-width: ${({ coeff, hideStarsOnMobileM }) =>
      hideStarsOnMobileM ? '18px' : coeff ? `${113 * coeff}px` : '113px'};
    overflow: hidden;
  }

  @media (min-width: 1px) and (max-width: 361px) {
    margin-bottom: ${({ hideStarsOnMobileS }) => (hideStarsOnMobileS ? '3px' : 0)};
    width: ${({ coeff, hideStarsOnMobileS }) =>
      hideStarsOnMobileS ? '18px' : coeff ? `${113 * coeff}px` : '113px'};
    min-width: ${({ coeff, hideStarsOnMobileS }) =>
      hideStarsOnMobileS ? '18px' : coeff ? `${113 * coeff}px` : '113px'};
    overflow: hidden;
  }
`;

export const Rating = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

import styled from 'styled-components';
import { motion } from 'framer-motion';
import { theme } from '@components/ui/theme';

export const StyledMobileMenu = styled(motion.div)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 6;
  padding: 30px 16px 25px 16px;
  display: none;

  @media screen and (max-width: 992px) {
    display: block;
  }

  @media (max-width: 768px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (max-width: 480px) {
    padding-top: 25px;
    padding-right: 20px;
    padding-left: 20px;
  }
`;

export const MobileMenuTitle = styled.div`
  font-weight: 500;
  font-size: 30px;
  color: ${theme.colors.black};
  line-height: 120%;

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

import React from "react";
import { HowItWorksElement } from "./how-it-works-element";
import * as S from "./how-it-works.style";
import { HowItWorksProps } from "./how-it-works.types";

export const HowItWorksTriangle: FCC<HowItWorksProps & { viewport: number }> = ({
  data,
  pathname,
  viewport,
  lessMargin,
}) => {
  return (
    <S.HowItWorksWrapper>
      <S.StyledHowItWorks isWithoutSlider={pathname.trim() !== '/'} lessMargin={lessMargin}>
        <S.HowItWorksTriangle>
          <S.HowItWorksTriangleSvg>
            {viewport > 1237 ? (
              <svg
                width="185"
                height="126"
                viewBox="0 0 185 126"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_802_14796"
                  style={{ maskType: 'alpha' }}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="185"
                  height="126"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M183.5 65.4389C184.169 64.2327 184.169 62.7673 183.5 61.5611L150.241 1.54583C149.712 0.59189 148.707 0 147.617 0H3C1.34315 0 0 1.34315 0 3V123C0 124.657 1.34314 126 3 126H148.171C149.262 126 150.266 125.408 150.795 124.454L183.5 65.4389Z"
                    fill="#825BD3"
                  />
                </mask>
                <g mask="url(#mask0_802_14796)">
                  <rect
                    x="-77.6746"
                    width="321.081"
                    height="126"
                    rx="3"
                    fill="url(#paint0_linear_802_14796)"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_802_14796"
                    x1="98.0949"
                    y1="-177.188"
                    x2="-146.891"
                    y2="-41.0102"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#785DDB" />
                    <stop offset="1" stopColor="#E54E8D" />
                  </linearGradient>
                </defs>
              </svg>
            ) : (
              <svg
                width="127"
                height="126"
                viewBox="0 0 127 126"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_3958_73741"
                  style={{ maskType: 'alpha' }}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="127"
                  height="126"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M125.5 65.4389C126.169 64.2327 126.169 62.7673 125.5 61.5611L92.2408 1.54583C91.7121 0.59189 90.7074 0 89.6168 0H3C1.34315 0 0 1.34315 0 3V123C0 124.657 1.34315 126 3 126H90.171C91.2616 126 92.2663 125.408 92.795 124.454L125.5 65.4389Z"
                    fill="#825BD3"
                  />
                </mask>
                <g mask="url(#mask0_3958_73741)">
                  <rect
                    x="-79.6746"
                    width="321.081"
                    height="126"
                    rx="3"
                    fill="url(#paint0_linear_3958_73741)"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_3958_73741"
                    x1="96.0949"
                    y1="-177.188"
                    x2="-148.891"
                    y2="-41.0102"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#785DDB" />
                    <stop offset="1" stopColor="#E54E8D" />
                  </linearGradient>
                </defs>
              </svg>
            )}
          </S.HowItWorksTriangleSvg>
          <S.HowItWorksTriangleText>Как это работает?</S.HowItWorksTriangleText>
        </S.HowItWorksTriangle>
        <S.HowItWorksElements>
          {data.map((item, index) => (
            <React.Fragment key={item.id}>
              <HowItWorksElement {...item} />

              {viewport > 1323 && index !== data.length - 1 && <S.HowItWorksElementDivider />}
            </React.Fragment>
          ))}
        </S.HowItWorksElements>
      </S.StyledHowItWorks>
    </S.HowItWorksWrapper>
  );
};

import React, { useState } from 'react';
import * as S from './category-products-list.style';
import {
  ProductCard,
  ProductCardType,
} from '@components/product/elements/product-card/product-card';
import { ListType } from '../../../../pages/c/[slug]';
import { ProductAvailablePackage } from '@components/product/elements/available-package/available-package';
import classNames from 'classnames';
import { CategoryProductsLargeList } from '@components/category/blocks/category-products-list/category-products-large-list';
import { Loading } from '@components/ui/loading/loading';
import { useStore } from '@stores/root';
import { observer } from 'mobx-react-lite';
import { ListKeys } from '@stores/cookies';
import { ResponsiveKitProduct } from '@components/product/elements/responsive-kit-product/responsive-kit-product';

type Props = {
  products: ProductCardType[];
  totalProductsCount?: number;
  isKind?: boolean;
  categoryId?: string;
  withoutPagination?: boolean;
  loading?: boolean;
  perPage?: number;
  listKey: ListKeys;
};

export const CategoryProductsList = observer((props: Props) => {
  const { cookie, ui } = useStore();
  const [page, setPage] = useState(1);
  const className = classNames({
    grid: cookie.listTypes[props.listKey] === ListType.CARDS,
    list: cookie.listTypes[props.listKey] === ListType.LIST,
    kind: props.isKind,
  });

  if (cookie.listTypes[props.listKey] === ListType.LARGE)
    return (
      <CategoryProductsLargeList
        totalProductsCount={props.totalProductsCount}
        products={props.products}
        loading={props.loading}
      />
    );

  return (
    <S.StyledCategoryProductList className={className}>
      <Loading show={props.loading} />
      {props.products.map((product) =>
        cookie.listTypes[props.listKey] === ListType.CARDS ? (
          <ProductCard key={`product-card-${product.id}`} product={product} />
        ) : ui.viewport <= 1000 ? (
          <ResponsiveKitProduct key={`responsive-package-${product.id}`} {...product} />
        ) : (
          <ProductAvailablePackage key={`availabe-package-${product.id}`} {...product} />
        )
      )}
    </S.StyledCategoryProductList>
  );
});

import React, { useMemo, useState } from 'react';
import * as S from './product-card.style';
import styled from 'styled-components';
import { Icon } from '@components/ui/icons/icon';
import { Image } from '@components/Image';
import { useStore } from '@stores/root';
import { ProductCardSlider } from '@components/product/elements/product-card/slider/product-card-slider';
import { formatPlural } from '../../../../utils/formaters';
import { observer } from 'mobx-react-lite';
import Link from 'next/link';
import { Product } from '../../../../gql/generated';
import { NoSSR } from '@components/NoSSR';
import { Label } from '@components/ui/label/label';
import { PopoverProductCart } from '@components/popovers/popover-product-cart/popover-product-cart';
import { useSwipeable } from 'react-swipeable';
import { RatingStars } from '@components/ui/rating-stars/rating-stars';

export type ProductCardType = Pick<
  Product,
  | 'id'
  | 'images'
  | 'name'
  | 'price'
  | 'priceFrom'
  | 'priceTo'
  | 'slug'
  | 'hit'
  | 'rating'
  | 'discount'
  | 'ratingCount'
> & {
  attributes?: any;
  description?: string;
};

type Props = {
  product: ProductCardType;
  className?: string;
  onTouchStart?(e: React.TouchEvent): void;
  onTouchEnd?(e: React.TouchEvent): void;
};

const Component = observer((props: Props) => {
  const [selectedImage, setSelectedImage] = useState(0);
  const { favorites, cart, ui } = useStore();

  const isFavorite = favorites.has(props.product.id);
  const isInCart = cart.has(props.product.id);
  const isPriceFromToSame = props.product.priceFrom === props.product.priceTo;

  const imageSlides = useMemo(() => props.product.images.slice(0, 5), []);

  const [mobileImage, setMobileImage] = useState(0);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (mobileImage >= imageSlides.length - 1) {
        return setMobileImage(0);
      }

      setMobileImage(mobileImage + 1);
    },
    onSwipedRight: () => {
      if (mobileImage === 0) {
        return setMobileImage(imageSlides.length - 1);
      }

      setMobileImage(mobileImage - 1);
    },
    preventScrollOnSwipe: true,
  });

  const oldPrice = useMemo(
    () =>
      props.product.discount
        ? (props.product.price / (100 - props.product.discount)) * 100
        : props.product.price,
    []
  );

  const addToFavorites = (): void => {
    if (isFavorite) {
      return favorites.remove(props.product.id);
    }
    favorites.add({
      id: props.product.id,
      name: props.product.name,
      slug: props.product.slug,
      price: props.product.price,
      priceFrom: props.product.priceFrom,
      priceTo: props.product.priceTo,
      images: props.product.images,
      hit: props.product.hit,
      rating: props.product.rating,
      discount: props.product.discount,
      ratingCount: props.product.ratingCount,
    });
  };

  const addToCart = (): void => {
    cart.addItem({
      id: props.product.id,
      name: props.product.name,
      price: props.product.price,
      slug: props.product.slug,
      images: props.product.images,
      rating: props.product.rating,
      priceFrom: props.product.priceFrom,
      priceTo: props.product.priceTo,
      hit: props.product.hit,
      discount: props.product.discount,
      ratingCount: props.product.ratingCount,
    });
  };

  const imageSrc = props.product.images[selectedImage]
    ? `http:/${props.product.images[selectedImage]}`
    : undefined;

  const image = imageSrc ? (
    <Image
      src={imageSrc}
      width={192}
      height={127}
      style={{ objectFit: 'contain' }}
      alt={props.product.name}
      title={props.product.name}
    />
  ) : (
    <Image
      src="s3://pics/nophotocard.svg"
      width={192}
      height={127}
      style={{ objectFit: 'contain' }}
      alt={props.product.name}
      title={props.product.name}
    />
  );

  return (
    <S.StyledProductCard className={props.className}>
      <Link legacyBehavior prefetch={false} href={`/p/${props.product.slug}`} passHref>
        <S.ProductCardImage
         
          noPhoto={!imageSrc}
          onMouseLeave={() => setSelectedImage(0)}
        >
          {image}
          <ProductCardSlider
            length={props.product.images.length}
            selectedIndex={selectedImage}
            setSelectedIndex={setSelectedImage}
          />
        </S.ProductCardImage>
      </Link>

      <S.ProductCardImageSlider
        onTouchStartCapture={props.onTouchStart}
        onTouchEndCapture={props.onTouchEnd}
        {...swipeHandlers}
      >
        {imageSlides.length === 0 && (
          <Link legacyBehavior prefetch={false} href={`/p/${props.product.slug}`} passHref>
            <a>
              <Image
                src="s3://pics/nophotocard.svg"
                width={192}
                height={127}
                style={{ objectFit: 'contain' }}
                alt={props.product.name}
                title={props.product.name}
              />
            </a>
          </Link>
        )}

        {imageSlides.length > 0 && (
          <S.ProductCardImageSlide>
            <Link legacyBehavior prefetch={false} href={`/p/${props.product.slug}`} passHref>
              <a>
                <Image
                  src={`s3:/${imageSlides[mobileImage]}`}
                  width={192}
                  height={127}
                  style={{ objectFit: 'contain' }}
                  alt={props.product.name}
                  title={props.product.name}
                />
              </a>
            </Link>

            <S.Bullets>
              {imageSlides.map((img, index) => (
                <S.Bullet key={img} active={index === mobileImage} />
              ))}
            </S.Bullets>
          </S.ProductCardImageSlide>
        )}
      </S.ProductCardImageSlider>

      <S.Labels>
        {props.product.hit && <Label color="purple">Хит</Label>}
        {props.product.discount > 0 && <Label color="pink">-{props.product.discount}%</Label>}
      </S.Labels>

      <NoSSR>
        <S.ProductCardFavorites className={isFavorite && 'active'} onClick={addToFavorites}>
          <Icon icon={isFavorite ? 'heartFilled' : 'heart'} />
        </S.ProductCardFavorites>
      </NoSSR>

      <Link legacyBehavior prefetch={false} href={`/p/${props.product.slug}`} passHref>
        <S.ProductCardTitle>{props.product.name}</S.ProductCardTitle>
      </Link>

      <S.ProductCardRating>
        <RatingStars
          rating={props.product.rating}
          votes={props.product.ratingCount}
          hideStarsOnTabletM
          hideStarsOnTabletS
          hideStarsOnMobileM
          hideStarsOnMobileS
        />
      </S.ProductCardRating>

      <S.ProductCardPrice>
        <S.ProductCardPriceBlock>
          <S.ProductCardPriceBlockLeft>
            <S.ProductCardPriceValue>
              {props.product.discount > 0 && (
                <S.ProductCardOldPrice>
                  {Number(oldPrice.toFixed(0)).toLocaleString('ru-RU')} ₽
                </S.ProductCardOldPrice>
              )}
              <span>{props.product.price.toLocaleString('ru-RU')} ₽</span>
            </S.ProductCardPriceValue>

            {!isPriceFromToSame && (
              <S.ProductCardPriceFrom>
                <span>от {props.product.priceFrom.toLocaleString('ru-RU')} ₽</span>
                <span>до {props.product.priceTo.toLocaleString('ru-RU')} ₽</span>
              </S.ProductCardPriceFrom>
            )}
          </S.ProductCardPriceBlockLeft>
          <S.ProductCardPriceBlockRight>
            <NoSSR>
              <PopoverProductCart
                addToCart={addToCart}
                isInCart={isInCart}
                productID={props.product.id}
                alignRight
              />
            </NoSSR>
          </S.ProductCardPriceBlockRight>
        </S.ProductCardPriceBlock>
      </S.ProductCardPrice>
    </S.StyledProductCard>
  );
});

export const ProductCard = styled(Component)``;

import styled from 'styled-components';

export const StyledAnchorLinkButton = styled.a`
  margin: 40px auto 0 auto;
  display: none;
  background: #d9dde7;
  width: 36px;
  height: 36px;
  justify-content: center;
  border-radius: 50%;
  align-items: center;

  @media (max-width: 1000px) {
    display: flex;
  }

  @media (max-width: 480px) {
    margin-top: 30px;
  }
`;

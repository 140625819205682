import React from 'react';
import styled from 'styled-components';
import { BlockTitle } from '@components/ui/block-title/block-title';
import { device } from '@components/ui/theme';

type CommonBlockWithTitleProps = Readonly<{
  disable?: boolean;
  className?: string;
}>;

export const CommonBlockWithTitle: FCC<CommonBlockWithTitleProps> = (props) => {
  if (!props.children) return null;

  return (
    <StyledCommonBlock className={props.className} disable={props.disable}>
      {props.children}
    </StyledCommonBlock>
  );
};

export const StyledCommonBlock = styled.section<CommonBlockWithTitleProps>`
  margin-top: 80px;
  display: ${({ disable }) => (disable ? 'none' : 'block')};

  ${BlockTitle} {
    margin-bottom: 40px;
  }

  @media ${device.tabletM} {
    margin-top: 50px;

    ${BlockTitle} {
      margin-bottom: 35px;
    }
  }

  @media ${device.mobileM} {
    margin-top: 35px;

    ${BlockTitle} {
      margin-bottom: 25px;
    }
  }
`;

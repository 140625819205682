import React, { useId } from "react";
import * as S from "./select.style";
import SelectComponent from "react-select";
import { theme } from "@components/ui/theme";
import { observer } from "mobx-react-lite";
import { useStore } from "@stores/root";

type Props = {
  options: SelectOption[];
  value: SelectOption;
  onChange(data: SelectOption): void;
  className?: string
  isSearchable?: boolean
};

const getCustomStyles = (viewport: number) => ({
  container: (provided, state) => ({
    ...provided,
    height: '100%',
  }),
  control: (provided, state) => {
    return ({
      ...provided,
      padding: '0 15px',
      border: '1px solid transparent',
      outline: 'none',
      boxShadow: 'none',
      fontSize: '14px',
      height: '100%',
      color: state.selectProps.menuIsOpen ? theme.colors.primary : theme.colors.black,
      '&:hover, &:focus': {
        border: `1px solid ${theme.colors.primary}`
      },
      background: viewport > 630 ? '#fff' : '#F9F7FA'
    })
  },
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '0',
  }),
  singleValue: (provided, state) => {
    return ({
      ...provided,
      margin: '0',
      color: state.selectProps.menuIsOpen ? theme.colors.primary : theme.colors.black,
    })
  },
  indicatorSeparator: (provided, state) => ({}),
  dropdownIndicator: (provided, state) => {
    return ({
      width: '14px',
      svg: {
        width: '100%',
        height: 'auto',
      },
      marginLeft: '5px',
      paddingBottom: '2px',
      transform: `rotate(${state.selectProps.menuIsOpen ? 180 : 0}deg)`
    })
  },
  menu: (provided, state) => ({
    ...provided,
    background: '#FFFFFF',
    boxShadow: '0px 3px 20px rgba(0, 0, 0, 0.09)',
    borderRadius: '2px',
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '16px',
    color: theme.colors.black,
    padding: '14px 0',
    width: '251px',
    zIndex: 3
  }),
  menuList: (provided, state) => ({
    padding: '0',
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '8px 25px',
    background: state.isSelected ? '#F9FAFC' : 'transparent',
    color: theme.colors.black,
    '&:hover': {
      background: '#F9FAFC',
    },
  }),
});

export const Select = observer((props: Props) => {
  const { ui } = useStore()
  const instanceId = useId()


  return (
    <S.StyledSelect>
      <SelectComponent
        instanceId={instanceId}
        className={props.className}
        value={props.value}
        options={props.options}
        styles={getCustomStyles(ui.viewport)}
        onChange={props.onChange}
        isSearchable={props.isSearchable}
      />
    </S.StyledSelect>
  );
})

import React from "react";
import * as S from "./mobile-menu.style";
import { AnimatePresence } from "framer-motion";
import { motionSlideRTL } from "@components/ui/animations/animations";
import { useStore } from "@stores/root";
import { observer } from "mobx-react-lite";
import { MobileMenuMainPage } from "@components/layout/header/blocks/mobile-menu/pages/main/main";
import { MobileCatalogue } from "@components/layout/header/blocks/mobile-menu/pages/catalogue/catalogue";
import { InitialDataQuery } from "../../../../../gql/generated";

type Props = {
  categories: InitialDataQuery['categories'];
};

export const MobileMenu = observer((props: Props) => {
  const { ui } = useStore();


  return (
    <AnimatePresence>
      {ui.mobileMenu.show && (
        <S.StyledMobileMenu {...motionSlideRTL}>
          <S.MobileMenuTitle>Меню</S.MobileMenuTitle>
          <MobileMenuMainPage />

          <AnimatePresence initial={false}>
            {ui.mobileMenu.showCatalogue && <MobileCatalogue categories={props.categories} />}
          </AnimatePresence>
        </S.StyledMobileMenu>
      )}
    </AnimatePresence>
  );
});

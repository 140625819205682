import React from "react";
import debounce from "lodash/debounce";
import * as S from "./filter-inputs.style";
import { CategoryFilterInput } from "@components/category/elements/filter-input/filter-input";
import Rheostat from "rheostat";
import "rheostat/initialize";

type Props = {
  pricesRange: number[];
  searchPrices: number[];
  onPricesChange(prices: number[]): void;
};

export const CategoryFilterInputs = (props: Props) => {
  const from = props.searchPrices[0] <= 0 ? props.pricesRange[0] : props.searchPrices[0]
  const to = props.searchPrices[1] <= 0 ? props.pricesRange[1] : props.searchPrices[1]

  const onSliderChange = debounce((data) => {
    props.onPricesChange(data.values);
  }, 200)


  const onFromChange = debounce((value: string) => {
    onSliderChange({ values: [Number(value), props.searchPrices[1]] });
  }, 500)

  const onToChange = debounce((value: string) => {
    onSliderChange({ values: [props.searchPrices[0], Number(value)] });
  }, 500)

  return (
    <S.StyledFilterInputs>
      <S.FilterInputsName>Цена:</S.FilterInputsName>
      <CategoryFilterInput
        min={props.pricesRange[0]}
        max={props.pricesRange[1] === props.pricesRange[0] ? props.pricesRange[1] + 1 : props.pricesRange[1]}
        title="от"
        className="first"
        value={String(from)}
        onChange={onFromChange}
      />
      <Rheostat
        min={props.pricesRange[0]}
        max={props.pricesRange[1] === props.pricesRange[0] ? props.pricesRange[1] + 1 : props.pricesRange[1]}
        values={[from, to === from ? to + 1 : to]}
        onValuesUpdated={onSliderChange}
        disabled={props.pricesRange[0] === props.pricesRange[1]}
      />
      <CategoryFilterInput
        min={props.pricesRange[1] === props.pricesRange[0] ? props.pricesRange[1] + 1 : props.pricesRange[1]}
        max={props.pricesRange[1]}
        title="до"
        className='second'
        value={String(to)}
        onChange={onToChange}
      />
    </S.StyledFilterInputs>
  );
};

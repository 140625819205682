import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import * as S from './input-search.style';
import { Icon, Icons } from '@components/ui/icons/icon';
import styled from 'styled-components';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  icon?: keyof typeof Icons;
  value?: string;
  setValue?(value: string): void;
  clearValue?(): void;
  onSearchIconClick?(): void;
  disableClose?: boolean;
  className?: string;
  focusOnRender?: boolean;
  onSubmit?(): void;
};

const Component = (props: Props) => {
  const notEmpty = !!props.value.length;
  const inputRef = useRef<HTMLInputElement>(null);
  const inputProps = { ...props };
  delete inputProps.onSearchIconClick;

  useEffect(() => {
    if (props.focusOnRender && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const onIconClick = () => {
    setTimeout(() => {
      props.clearValue();
      inputRef?.current?.focus();
    }, 25);
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    props.onSubmit?.();
  };

  return (
    <S.StyledInput notEmpty={notEmpty}>
      <form onSubmit={onSubmit}>
        <S.InputElement disableClose={props.disableClose} {...inputProps} ref={inputRef} />
      </form>

      <S.IconContainers disableClose={props.disableClose}>
        {props.value.length > 0 && (
          <>
            <S.Icon className="close">
              <Icon icon="close" onClick={onIconClick} />
            </S.Icon>

            <S.Divider />
          </>
        )}

        <S.Icon className="searchIcon">
          <Icon onClick={props.onSearchIconClick} icon={props.icon ?? 'magnifier'} />
        </S.Icon>
      </S.IconContainers>
    </S.StyledInput>
  );
};

export const InputSearch = styled(Component)``;

import React, { useEffect, useRef, useState } from 'react';
import * as S from './read-more-text-styles';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores/root';
import { formatEllipsis } from '../../../utils/formaters';
import Link from 'next/link';

type Props = {
  text: string;
  rowsCount?: number;
  letterWidth?: number;
  readAllText?: string;
  hideButton?: boolean;
  href?: string;
  blank?: boolean;
};

export const ReadMoreText: FCC<Props> = observer((props) => {
  const { ui } = useStore();
  const [textToShow, setTextToShow] = useState(props.text);
  const [readAll, setReadAll] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  const letterWidth = props.letterWidth ?? 8.9;
  const readAllText = props.readAllText ?? 'Читать полностью';

  useEffect(() => {
    const totalWidth = textRef.current.clientWidth * Number(props.rowsCount ?? 2);
    const lettersToView = Math.floor((totalWidth - readAllText.length * letterWidth) / letterWidth);

    setTextToShow(formatEllipsis(props.text, lettersToView));
  }, [ui.viewport, props.text]);

  const showAll = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setReadAll(true);
    setTextToShow(props.text);
  };

  return (
    <S.StyledReadMoreText ref={textRef}>
      {textToShow}

      {textToShow.length < props.text.length && !readAll && !props.hideButton && (
        <S.ReadMoreButton onClick={showAll}>{readAllText}</S.ReadMoreButton>
      )}

      {props.hideButton && props.href && (
        <S.ReadMoreButton href={props.href} target={props.blank ? '_BLANK' : undefined}>
          Подробнее
        </S.ReadMoreButton>
      )}
    </S.StyledReadMoreText>
  );
});

import React from "react";
import { HowItWorksElement } from "./how-it-works-element";
import * as S from "./how-it-works.style";
import { HowItWorksProps } from "./how-it-works.types";

export const HowItWorksExpanded: FCC<HowItWorksProps> = ({ data, pathname, lessMargin }) => {
  return (
    <S.HowItWorksWrapper>
      {pathname === '/' && <S.HowItWorksBlockTitle>Как это работает</S.HowItWorksBlockTitle>}

      <S.StyledHowItWorks
        className={pathname.trim() === '/' ? 'mainPage' : ''}
        isWithoutSlider={pathname !== '/'}
        lessMargin={lessMargin}
      >
        <S.HowItWorksElements>
          {data.map((item) => (
            <HowItWorksElement key={item.id} {...item} />
          ))}
        </S.HowItWorksElements>
      </S.StyledHowItWorks>
    </S.HowItWorksWrapper>
  );
};

import React from 'react';
import * as S from './search-result-element.style';
import { Image } from '@components/Image';
import Link from 'next/link';
import { imageLoader } from '../../../../../utils/image-loader';

type Props = {
  name: string;
  slug: string;
  searchString: string;
  type: 'product' | 'category' | 'tag';
  image?: string;
  price?: number;
};

export const SearchResultElement = (props: Props) => {
  const regex = new RegExp(props.searchString, 'giu');
  const name = props.name.replace(regex, (match) => `<b>${match}</b>`);

  let linkPrefix: string;

  switch (props.type) {
    case 'category':
      linkPrefix = '/c/';
      break;
    case 'product':
      linkPrefix = '/p/';
      break;
    case 'tag':
      linkPrefix = '/t/';
      break;
    default:
      break;
  }

  return (
    <S.StyledSearchResultElement>
      {props.type !== 'tag' && (
        <Link legacyBehavior prefetch={false} href={`${linkPrefix}${props.slug}`} passHref>
          <S.SearchResultImage>
            {props.image ? (
              <Image
                loader={imageLoader({
                  width: 48,
                  src: props.image,
                })}
                fill
                style={{ objectFit: 'contain' }}
                src={`s3:/${props.image}`}
                alt={props.name}
                title={props.name}
              />
            ) : (
              <Image
                fill
                style={{ objectFit: 'contain' }}
                src="s3://pics/nophotosmall.svg"
                alt={props.name}
                title={props.name}
              />
            )}
          </S.SearchResultImage>
        </Link>
      )}

      <Link legacyBehavior prefetch={false} href={`${linkPrefix}${props.slug}`} passHref>
        <S.SearchResultName dangerouslySetInnerHTML={{ __html: name }} />
      </Link>

      {props.price && (
        <Link legacyBehavior prefetch={false} href={`${linkPrefix}${props.slug}`} passHref>
          <S.SearchResultPrice>{props.price.toLocaleString('ru-RU')} ₽</S.SearchResultPrice>
        </Link>
      )}
    </S.StyledSearchResultElement>
  );
};

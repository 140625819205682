import React from "react";
import * as S from "./loading.style";
import { AnimatePresence } from "framer-motion";

type Props = {
  show: boolean;
};

const animation = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },

  transition: {
    duration: 0.3,
  },
};

export const Loading = (props: Props) => {
  return (
    <AnimatePresence>
      {props.show && (
        <S.StyledLoading {...animation}>
          <S.LoadingIndicator>
            <div className="loader"></div>
          </S.LoadingIndicator>
        </S.StyledLoading>
      )}
    </AnimatePresence>
  );
};

import styled from 'styled-components';
import { device, scrollbar, theme } from '@components/ui/theme';

export const CategoryHeaderDescription = styled.div`
  display: flex;
  align-items: center;
`;

export const CategoryHeaderShare = styled.div`
  margin-left: 16px;
`;

export const StyledHeaderText = styled.div`
  display: flex;
  margin-top: 10px;

  font-size: 14px;
  color: ${theme.colors.greySecondary};

  ${CategoryHeaderShare} svg {
    width: 20px;
    height: 20px;
  }

  @media (max-width: 1000px) {
    ${scrollbar.alignSidesDesktop}
    ${scrollbar.disable}
    overflow: auto;
    white-space: nowrap;
  }

  @media (max-width: 600px) {
    ${scrollbar.alignSidesMobile}
  }

  @media ${device.tabletS} {
    flex-direction: column;

    ${CategoryHeaderDescription} {
      font-size: 13px;
    }

    ${CategoryHeaderShare} {
      margin-top: 6px;
      margin-left: 0;
      font-size: 13px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  @media (max-width: 670px) {
    ${CategoryHeaderDescription} {
      white-space: nowrap;
      max-width: 100%;
      overflow-x: auto;
      ${scrollbar.disable}
    }
  }

  @media (max-width: 480px) {
    margin-top: 15px;
  }
`;

import { Icon } from '@components/ui/icons/icon';
import React from 'react';
import * as S from './tap-bar.style';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores/root';
import Link from 'next/link';

type Props = {};

export const TapBar = observer((props: Props) => {
  const { ui, favorites, cart } = useStore();

  return (
    <S.StyledTapBar>
      <S.TapBarItem
        href="#"
        active={ui.mobileMenu.showCatalogue}
        onClick={ui.toggleMobileCatalogueFromTapBar}
      >
        <S.TapBarItemIcon>
          <Icon icon="iconLoupeMenu" />
        </S.TapBarItemIcon>
        <S.TapBarItemText>Каталог</S.TapBarItemText>
      </S.TapBarItem>

      <Link legacyBehavior prefetch={false} href="/favorites" passHref>
        <S.TapBarItem>
          <S.TapBarItemIcon>
            <Icon icon="heart" />

            {favorites.count > 0 && (
              <S.TapBarItemIconValue>{favorites.count}</S.TapBarItemIconValue>
            )}
          </S.TapBarItemIcon>
          <S.TapBarItemText>Избранное</S.TapBarItemText>
        </S.TapBarItem>
      </Link>

      <Link legacyBehavior prefetch={false} href="/cart" passHref>
        <S.TapBarItem>
          <S.TapBarItemIcon>
            <Icon icon="cart" />
            {cart.size > 0 && <S.TapBarItemIconValue>{cart.size}</S.TapBarItemIconValue>}
          </S.TapBarItemIcon>
          <S.TapBarItemText>Корзина</S.TapBarItemText>
        </S.TapBarItem>
      </Link>

      <S.TapBarItem href="#">
        <S.TapBarItemIcon>
          <Icon icon="userNoBg" />
        </S.TapBarItemIcon>
        <S.TapBarItemText>Вход</S.TapBarItemText>
      </S.TapBarItem>
    </S.StyledTapBar>
  );
});

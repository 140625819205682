import React from "react";

export const IconThumbUp = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1666 6.66669H17.4999C17.9419 6.66669 18.3659 6.84228 18.6784 7.15484C18.991 7.4674 19.1666 7.89133 19.1666 8.33335V10.0867C19.1668 10.3045 19.1243 10.5202 19.0416 10.7217L16.4624 16.9842C16.3995 17.1369 16.2926 17.2674 16.1554 17.3593C16.0182 17.4511 15.8567 17.5001 15.6916 17.5H1.66659C1.44557 17.5 1.23361 17.4122 1.07733 17.2559C0.921049 17.0997 0.833252 16.8877 0.833252 16.6667V8.33335C0.833252 8.11234 0.921049 7.90038 1.07733 7.7441C1.23361 7.58782 1.44557 7.50002 1.66659 7.50002H4.56825C4.70167 7.50005 4.83314 7.46806 4.95161 7.40672C5.07009 7.34538 5.17211 7.25649 5.24908 7.14752L9.79325 0.708355C9.8507 0.626949 9.93541 0.568822 10.032 0.544513C10.1287 0.520205 10.2308 0.531324 10.3199 0.575855L11.8316 1.33169C12.257 1.54432 12.5969 1.89612 12.7947 2.32862C12.9925 2.76112 13.0364 3.24829 12.9191 3.70919L12.1666 6.66669ZM5.83325 8.82335V15.8334H15.1333L17.4999 10.0867V8.33335H12.1666C11.9128 8.33332 11.6623 8.2753 11.4343 8.16374C11.2063 8.05217 11.0068 7.89 10.851 7.68959C10.6952 7.48919 10.5872 7.25585 10.5353 7.00737C10.4835 6.7589 10.489 6.50186 10.5516 6.25585L11.3041 3.29919C11.3276 3.20696 11.3189 3.10945 11.2793 3.02288C11.2398 2.93631 11.1717 2.86589 11.0866 2.82335L10.5358 2.54835L6.61075 8.10835C6.40242 8.40335 6.13575 8.64502 5.83325 8.82335ZM4.16659 9.16669H2.49992V15.8334H4.16659V9.16669Z"
        fill="currentColor"
      />
    </svg>
  );
};

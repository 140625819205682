import React from "react";

export const IconCart = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4 4H6.79729C7.77422 4 8.62579 4.66489 8.86273 5.61265L9.67615 8.86632H24.1983C25.3742 8.86632 26.3274 9.81951 26.3274 10.9953V15.1775C26.3274 16.1145 25.7147 16.9414 24.8182 17.2142L12.7154 20.8977C11.539 21.2557 10.3048 20.5455 10.0231 19.3485L7.85833 10.1481L6.85025 6.11577C6.84417 6.09147 6.82233 6.07442 6.79729 6.07442H4V4ZM10.1759 10.9407L12.0424 18.8734C12.0496 18.9041 12.0813 18.9223 12.1115 18.9131L24.2142 15.2297C24.2372 15.2227 24.2529 15.2015 24.2529 15.1775V10.9953C24.2529 10.9652 24.2285 10.9407 24.1983 10.9407H10.1759Z" fill="#E64E8D"/>
      <path d="M12.1883 24.0348C12.1883 25.1202 11.3084 26.0001 10.2231 26.0001C9.13768 26.0001 8.25781 25.1202 8.25781 24.0348C8.25781 22.9494 9.13768 22.0696 10.2231 22.0696C11.3084 22.0696 12.1883 22.9494 12.1883 24.0348Z" fill="#E64E8D"/>
      <path d="M24.3543 24.0348C24.3543 25.1202 23.4744 26.0001 22.3891 26.0001C21.3037 26.0001 20.4238 25.1202 20.4238 24.0348C20.4238 22.9494 21.3037 22.0696 22.3891 22.0696C23.4744 22.0696 24.3543 22.9494 24.3543 24.0348Z" fill="#E64E8D"/>
    </svg>
  );
};

import styled from 'styled-components';
import { device, theme } from '@components/ui/theme';

export const ButtonIcon = styled.div`
  position: relative;
  width: 18px;
  height: 12px;
  top: -1px;

  div {
    position: absolute;
    width: 18px;
    height: 2px;
    border-radius: 1px;
    background: white;

    transition: 0.25s;

    &:nth-child(2) {
      width: 13px;
      top: 6px;
    }

    &:nth-child(3) {
      top: 12px;
    }
  }
`;

export const ButtonText = styled.div`
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #ffffff;
  margin-left: 10px;
`;

export const StyledCatalogueButton = styled.button`
  background: #e64e8d;
  border-radius: 3px;
  box-sizing: border-box;
  width: 124px;
  height: 42px;

  transition: background-color 0.25s;
  will-change: backround-color;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid transparent;
  border-radius: 3px;

  &:hover {
    background: #f25292;

    ${ButtonIcon} {
      div {
        &:nth-child(2) {
          width: 18px;
        }

        &:nth-child(3) {
          width: 14px;
        }
      }
    }
  }

  &.active {
    background-color: transparent;
    border-color: #e64e8d;

    ${ButtonText} {
      color: ${theme.colors.primary};
    }

    ${ButtonIcon} {
      div {
        background: #e64e8d;
        border-radius: 0;

        &:nth-child(1) {
          top: 6px;
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          top: 6px;
          transform: rotate(-45deg);
          width: 18px;
          margin-top: 0;
        }

        &:nth-child(3) {
          opacity: 0;
          transform: rotate(-45deg);
        }
      }
    }
  }

  @media ${device.tabletM} {
    width: 58px;

    ${ButtonText} {
      display: none;
    }
  }
`;

import 'swiper/css';
import 'swiper/css/pagination';
import '@assets/styles/scroll.css';
import '@assets/styles/global.css';
import '@assets/styles/global.scss';
import { StoreProvider } from '@stores/root';
import { YMaps } from 'react-yandex-maps';
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import SwiperCore, { Navigation } from 'swiper';
import { ApolloProvider } from '@apollo/client';
import { graphQLClient } from '../utils/graphql-api';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import { IncomingHttpHeaders } from 'http';
import {
  CurrentGeoDocument,
  CurrentGeoQuery,
  InitialDataDocument,
  InitialDataQuery,
} from '../gql/generated';
import { getCookies } from 'cookies-next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { LoadingScreen } from '@components/ui/loading-screen/loading-screen';
import { createClient, JitsuProvider, usePageView } from '@jitsu/nextjs';
import { getExternalRelink } from '../utils/relink';
import { usePostHog } from 'next-use-posthog';
import Router from 'next/router';

const jitsuClient = createClient({
  tracking_host: 'https://analytics.lidopad.live.revoluterra.ru',
  key: 'js.t3smbykjiixfjky8cy3zpa.bicop74aou8rqcvd5lk6b',
});

// global.initialDataCache = new Map<string, any>();

dayjs.extend(utc);

SwiperCore.use([Navigation]);
// Временный фикс клавиши F8
if (process.browser) {
  document.addEventListener(
    'keydown',
    (e) => {
      if (e.keyCode == 119) {
        // F8
        debugger;
      }
    },
    {
      capture: true,
    }
  );
}

// setInterval(() => {
//   global.initialDataCache.clear();
// }, 30 * 60 * 1000);

MyApp.getInitialProps = async (appContext: AppContext) => {
  const { ctx } = appContext;
  const { req, res } = ctx;

  const t0 = Date.now();

  // if (req.headers.host.match(/^www/) !== null) {
  //   res.writeHead(301, {
  //     location: 'https://' + req.headers.host.replace(/^www./, '') + req.url,
  //   });
  //   res.end();
  // }

  const appPropsQuery = App.getInitialProps(appContext);

  const { headers } = appContext.ctx.req;

  const userAgent = headers['user-agent'];

  const currentGeoQuery = graphQLClient(headers).query({
    query: CurrentGeoDocument,
    context: { headers: { 'update-cache': 'true' } },
  });

  const externalProductRelinkQuery = getExternalRelink(req, req.url);

  const queries: any[] = [appPropsQuery, currentGeoQuery, externalProductRelinkQuery];

  // const cachedInitialData = global.initialDataCache.get(headers.host);

  // if (!cachedInitialData) {
  //   const initialDataQuery = graphQLClient(headers).query({
  //     query: InitialDataDocument,
  //   });

  //   queries.push(initialDataQuery);
  // }

  const initialDataQuery = graphQLClient(headers).query({
    query: InitialDataDocument,
  });

  queries.push(initialDataQuery);

  const [appProps, currentGeoData, externalProductRelink, initialData] = await Promise.all(queries);

  // if (!cachedInitialData) {
  //   global.initialDataCache.set(headers.host, initialData.data);
  // }

  // const parsedInitialData = cachedInitialData || initialData.data;
  const parsedInitialData = initialData.data;

  appContext.ctx.res.setHeader('Cache-Control', 'public, s-maxage=3600, stale-while-revalidate=59');
  appContext.ctx.res.setHeader(
    'Last-Modified',
    dayjs(parsedInitialData.project.updatedAt).utc().format('ddd, DD MMM YYYY HH:mm:ss [GMT]')
  );

  const cookies = getCookies(appContext.ctx);

  const t1 = Date.now();

  const time = ((t1 - t0) / 1000).toFixed(2);

  console.log(`https://${req.headers.host}${req.url}: ${time} seconds`);

  return {
    ...appProps,
    headers,
    initialData: parsedInitialData,
    host: headers.host,
    cookies,
    userAgent,
    pathname: appContext.ctx.req.url,
    geo: currentGeoData.data.geo,
    externalProductRelink,
  };
};

const MATOMO_URL = process.env.NEXT_PUBLIC_MATOMO_URL;

function MyApp({
  Component,
  pageProps,
  headers,
  initialData,
  host,
  cookies,
  userAgent,
  pathname,
  geo,
  externalProductRelink,
}: AppProps & {
  headers: IncomingHttpHeaders;
  initialData: InitialDataQuery;
  host: string;
  cookies: Record<string, unknown>;
  userAgent: string;
  pathname: string;
  geo: CurrentGeoQuery['geo'];
  externalProductRelink: ExternalProductRelink[];
}) {
  const router = useRouter();

  usePostHog('phc_UtlnYjF3EoZan1T2v0gPydFtIzf7BcPQJekIkI4e2j', {
    api_host: 'https://ph.live.revoluterra.ru',
    disable_session_recording: true,
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.opt_out_capturing();
    },
  });

  useEffect(() => {
    // router.events.on('routeChangeStart', () => {
    //   NProgress.start();
    //   document.body.style.overflow = 'auto';
    // });
    // router.events.on('routeChangeComplete', NProgress.done);
    // router.events.on('routeChangeError', NProgress.done);

    router.events.on('routeChangeStart', (url, { shallow }) => {
      console.log(`routing to ${url}`, `is shallow routing: ${shallow}`);
      window.location.href = url;
      throw new Error('no route');
    });

    Router.beforePopState(({ url, as, options }) => {
      console.log({ as });
      window.location.href = as;
      return false;
    });

    // return () => {
    //   router.events.off('routeChangeStart', NProgress.start);
    //   router.events.off('routeChangeComplete', NProgress.done);
    //   router.events.off('routeChangeError', NProgress.done);
    // };
  }, [router]);

  usePageView(jitsuClient);

  // const invite = router.query['sid'] !== undefined && router.query['oid'] !== undefined;
  // const sid = router.query['sid'];
  // const oid = router.query['oid'];
  //
  // useEffect(() => {
  //   if (invite) {
  //     api.call('seller/company', { path: { company: sid } }).then(({ data }) => {
  //       store.ui.withData({ sid, oid, company: data }).showModal('invitation');
  //     });
  //   }
  // }, []);

  return (
    <JitsuProvider client={jitsuClient}>
      <ApolloProvider client={graphQLClient(headers)}>
        <StoreProvider
          initialData={initialData}
          host={host}
          cookies={cookies}
          userAgent={userAgent}
          pathname={pathname}
          geo={geo}
          headers={headers}
        >
          <YMaps query={{ load: 'package.full' }}>
            <Component {...pageProps} externalProductRelink={externalProductRelink} />
          </YMaps>
        </StoreProvider>
      </ApolloProvider>
    </JitsuProvider>
  );
}

export default MyApp;

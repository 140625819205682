import React from 'react';
import * as S from './filter-input.style';
import styled from 'styled-components';

type Props = {
  title: string;
  value: string;
  min: number;
  max: number;
  onChange(value: string): void;
  className?: string;
};

const Component = (props: Props) => {
  const { className, ...otherProps } = props;

  const onAccept = (value) => {
    if (value === props.value) return;

    props.onChange(value);
  };

  return (
    <S.StyledFilterInput className={props.className}>
      <S.FilterInputName>{props.title}</S.FilterInputName>
      <S.FilterInput
        mask="num ₽"
        lazy={false}
        blocks={{
          num: {
            mask: Number,
            scale: 2,
            radix: ',',
            mapToRadix: ['.'],
            thousandsSeparator: ' ',
          },
        }}
        value={props.value}
        unmask
        onAccept={onAccept}
      />
    </S.StyledFilterInput>
  );
};

export const CategoryFilterInput = styled(Component)``;

import React from "react";

export const IconTwitter = () => {
  return (
    <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.26931 4.41143L8.30394 4.98237L7.72687 4.91246C5.62633 4.64447 3.79125 3.73563 2.23316 2.20924L1.47143 1.45188L1.27522 2.01116C0.859731 3.2579 1.12518 4.57455 1.99079 5.46009C2.45245 5.94946 2.34857 6.01937 1.55222 5.72808C1.27522 5.63487 1.03285 5.56496 1.00977 5.59991C0.928979 5.68147 1.20597 6.74179 1.42526 7.16125C1.72534 7.74384 2.33703 8.31477 3.00643 8.65268L3.57196 8.92067L2.90256 8.93232C2.25624 8.93232 2.23316 8.94397 2.30241 9.18866C2.53324 9.94602 3.44501 10.75 4.46065 11.0995L5.17622 11.3442L4.55298 11.7171C3.62967 12.2531 2.54478 12.556 1.45988 12.5793C0.940521 12.591 0.513489 12.6376 0.513489 12.6725C0.513489 12.7891 1.92154 13.4416 2.74098 13.6979C5.1993 14.4553 8.11928 14.129 10.3121 12.8357C11.8702 11.9152 13.4283 10.0858 14.1554 8.31477C14.5478 7.37098 14.9402 5.64652 14.9402 4.81924C14.9402 4.28326 14.9749 4.21335 15.6212 3.5725C16.0021 3.19965 16.3598 2.79183 16.4291 2.67532C16.5445 2.45393 16.533 2.45393 15.9444 2.65201C14.9633 3.00156 14.8248 2.95496 15.3096 2.43063C15.6674 2.05777 16.0944 1.38197 16.0944 1.18389C16.0944 1.14893 15.9213 1.20719 15.7251 1.31206C15.5173 1.42858 15.0557 1.60335 14.7094 1.70822L14.0862 1.9063L13.5207 1.52179C13.209 1.31206 12.7705 1.07902 12.5396 1.00911C11.951 0.845986 11.0508 0.86929 10.5199 1.05572C9.07721 1.58005 8.16544 2.93165 8.26931 4.41143Z"
        fill="currentColor"
      />
    </svg>
  );
};

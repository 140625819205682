import React from "react";

export const IconClock = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99935 1.66675C14.6018 1.66675 18.3327 5.39758 18.3327 10.0001C18.3327 14.6026 14.6018 18.3334 9.99935 18.3334C5.39685 18.3334 1.66602 14.6026 1.66602 10.0001H3.33268C3.33268 13.6817 6.31768 16.6667 9.99935 16.6667C13.681 16.6667 16.666 13.6817 16.666 10.0001C16.666 6.31841 13.681 3.33341 9.99935 3.33341C7.70768 3.33341 5.68602 4.48925 4.48685 6.25008H6.66602V7.91675H1.66602V2.91675H3.33268V5.00008C4.85268 2.97508 7.27352 1.66675 9.99935 1.66675ZM10.8327 5.83341V9.65425L13.5352 12.3567L12.356 13.5359L9.16602 10.3442V5.83341H10.8327Z"
        fill="currentColor"
      />
    </svg>
  );
};

import styled from 'styled-components';
import { device, scrollbar, theme } from '@components/ui/theme';
import { Wrapper } from '@components/ui/wrapper/wrapper.style';

export const HowItWorksTriangle = styled.div`
  width: 184px;
  height: 126px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding-left: 40px;
  position: relative;

  @media (max-width: 1237px) {
    width: 127px;
    padding-left: 20px;
  }
`;

export const HowItWorksTriangleSvg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
`;

export const HowItWorksTriangleText = styled.div`
  font-size: 18px;
  line-height: 120%;
  color: #ffffff;
  width: 81px;
  z-index: 1;

  @media (max-width: 1237px) {
    font-size: 15px;
  }
`;

export const HowItWorksElementLeft = styled.div`
  align-self: flex-start;
`;

export const HowItWorksElementRight = styled.div`
  margin-left: 16px;
`;

export const HowItWorksElementIcon = styled.div`
  width: 36px;
  height: 36px;
  background: #fafafa;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  color: #e54e8d;
`;

export const HowItWorksElementTitle = styled.div`
  font-size: 17px;
  line-height: 120%;
  color: ${theme.colors.black};
  margin-top: 8px;
`;

export const HowItWorksElementDescription = styled.div`
  font-size: 14px;
  line-height: 140%;
  color: ${theme.colors.greyPrimary};
  margin-top: 10px;
  max-width: 250px;
`;

export const HowItWorksElementDivider = styled.div`
  width: 1px;
  height: 68px;
  background: #f6f5f7;
  margin-left: 35px;
  margin-right: 35px;
`;

export const StyledHowItWorksElement = styled.div`
  display: flex;
  height: 74px;

  @media (max-width: 1191px) {
    ${HowItWorksElementIcon} {
      width: 32px;
      height: 32px;
      font-size: 15px;
    }

    ${HowItWorksElementRight} {
      margin-left: 12px;
    }

    ${HowItWorksElementDescription} {
      margin-top: 8px;
      font-size: 13px;
    }

    ${HowItWorksElementTitle} {
      font-size: 16px;
    }
  }

  @media (max-width: 1105px) {
    ${HowItWorksElementTitle} {
      font-size: 15px;
    }

    ${HowItWorksElementDescription} {
      font-size: 12px;
    }
  }
`;

export const HowItWorksTitle = styled.div``;

export const HowItWorksChevron = styled.img``;

export const HowItWorksElements = styled.div`
  padding-top: 20px;
  padding-bottom: 34px;
  margin-left: 60px;
  display: flex;
  align-items: flex-start;
`;

export const HowItWorksWrapper = styled(Wrapper)<{ isWithoutSlider?: boolean }>`
  @media ${device.mobileM} {
    display: ${({ isWithoutSlider }) => (isWithoutSlider ? 'none' : 'block')};
    background-color: #fff;
  }
`;

export const HowItWorksBlockTitle = styled.h2`
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  color: ${theme.colors.black};

  @media (max-width: 986px) {
    margin-top: 50px;
  }

  @media (max-width: 480px) {
    margin-top: 25px;
    font-size: 20px;
  }
`;

export const StyledHowItWorks = styled.section<{ isWithoutSlider?: boolean; lessMargin?: boolean }>`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(99, 47, 117, 0.09);
  border-radius: 3px;
  height: 126px;
  display: flex;
  margin-top: -63px;
  position: relative;
  z-index: 1;
  margin-bottom: ${(props) => (props.isWithoutSlider ? '25px' : '80px')};

  @media (max-width: 1429px) {
    ${HowItWorksElements} {
      margin-left: 50px;
    }
    ${HowItWorksElementDivider} {
      margin: 0 40px;
    }
  }

  @media (max-width: 1418px) {
    ${HowItWorksElementDivider} {
      margin: 0 20px;
    }
  }

  @media (max-width: 1250px) {
    ${HowItWorksElements} {
      margin-left: 20px;
      padding-right: 34px;
    }

    ${StyledHowItWorksElement}:not(:last-child) {
      margin-right: 20px;
    }
  }

  @media (max-width: 1105px) {
    ${HowItWorksElements} {
      padding-top: 25px;
      padding-bottom: 33px;
      margin-left: 15px;
    }

    ${StyledHowItWorksElement} {
      &:first-child {
        max-width: 209px;
      }

      &:nth-child(2) {
        max-width: 244px;
      }

      &:last-child {
        max-width: 274px;
      }
    }
  }

  @media (max-width: 1000px) {
    margin-bottom: 35px;
  }

  @media (max-width: 986px) {
    box-shadow: none;
    background: none;
    display: flex;
    align-items: center;
    overflow: auto;
    width: calc(100% + 60px);
    position: relative;
    left: -30px;
    padding: 25px;
    margin-bottom: 10px;
    height: fit-content;

    &.mainPage {
      margin-top: 10px;
    }

    ${HowItWorksElements} {
      margin: 0;
      padding: 0;
      flex-wrap: nowrap;
      width: 100%;
      height: fit-content;
      justify-content: flex-start;
      gap: 15px;

      ${StyledHowItWorksElement} {
        flex-basis: 33%;
        padding: 20px 20px 30px 20px;
        margin-right: 0;
        height: 160px;
        flex-direction: column;
        background: #ffffff;
        box-shadow: 0px 2px 25px rgba(49, 18, 59, 0.08);
        border-radius: 3px;

        &:first-child ${HowItWorksElementDescription} {
          max-width: 100%;
        }
        &:last-child ${HowItWorksElementDescription} {
          max-width: 100%;
        }

        ${HowItWorksElementRight} {
          width: 100%;
          margin-left: 0;
        }

        ${HowItWorksElementTitle} {
          margin-top: 15px;
          font-size: 17px;
        }
        ${HowItWorksElementDescription} {
          margin-top: 10px;
          font-size: 14px;

          max-width: 100%;
        }

        ${HowItWorksElementIcon} {
          width: 30px;
          height: 30px;
          font-size: 15px;
          line-height: 13px;
        }
      }
    }
  }

  @media (max-width: 986px) {
    margin-top: -85px;
    margin-bottom: ${({ isWithoutSlider }) => (isWithoutSlider ? '10px' : '25px')};
    ${scrollbar.disable};
    ${HowItWorksElements} {
      ${StyledHowItWorksElement} {
        min-width: 303px;
      }
    }
  }

  @media (max-width: 882px) {
    width: calc(100% + 60px);
    position: relative;
    left: -30px;
    padding: 25px 30px;

    ${HowItWorksElements} {
      width: fit-content;
    }
  }

  @media (max-width: 768px) {
    margin-top: -16px;
  }

  @media (max-width: 600px) {
    width: calc(100% + 40px);
    left: -20px;
    padding: 25px 20px;
    margin-bottom: 10px;

    &.mainPage {
      margin-bottom: 25px;
    }
  }

  @media (max-width: 480px) {
    &.mainPage {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 20px;
      padding-bottom: 35px;

      ${StyledHowItWorksElement} {
        padding-bottom: 25px;
        height: 139px;

        ${HowItWorksElementTitle} {
          margin-top: 12px;
          font-size: 15px;
        }

        ${HowItWorksElementIcon} {
          width: 20px;
          height: 20px;
          font-size: 11px;
          font-weight: 700;
        }

        ${HowItWorksElementDescription} {
          margin-top: 8px;
          font-size: 13px;
        }
      }
    }
  }
`;

import styled from 'styled-components';
import { theme } from '@components/ui/theme';

export const PopularCategoryLeft = styled.div`
  margin-right: 30px;
`;

export const PopularCategoryRight = styled.div`
  width: 100px;
  height: 100px;
  position: relative;
  margin-left: auto;
  flex-shrink: 0;
`;

export const PopularCategoryTitle = styled.div`
  font-size: 17px;
  line-height: 120%;
  color: ${theme.colors.black};

  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  max-width: 140px;
  box-orient: vertical;

  @media (max-width: 480px) {
    padding: 0 30px;
    max-width: unset;
  }
`;

export const PopularCategorySubtitle = styled.div`
  font-size: 13px;
  line-height: 140%;
  color: ${theme.colors.greyPrimary};
  margin-top: 14px;

  &.offers {
    margin-top: 5px;
  }
`;

export const StyledPopularCategory = styled.a<{ isCatalogueLink?: boolean }>`
  width: 100%;
  padding: 30px 30px 40px 30px;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  box-shadow: 0px 2px 25px rgba(49, 18, 59, 0.08);

  &:hover {
    box-shadow: 0px 2px 12px rgba(99, 47, 117, 0.14);

    ${PopularCategoryTitle} {
      color: ${theme.colors.primary};
    }
  }

  @media (max-width: 1050px) {
    width: 345px;
  }

  @media (max-width: 480px) {
    padding: 0;
    min-width: 160px;
    width: 160px;
    min-height: 151px;
    border: none;
    height: 151px !important;
    display: ${({ isCatalogueLink }) => (isCatalogueLink ? 'none' : 'flex')};
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    text-align: center;

    ${PopularCategoryRight} {
      margin-bottom: 12px;
      margin-left: 0;
      width: ${({ isCatalogueLink }) => (isCatalogueLink ? '78px' : '70px')};
      height: ${({ isCatalogueLink }) => (isCatalogueLink ? '78px' : '70px')};

      svg {
        width: 78px;
        height: 78px;
      }
    }

    ${PopularCategoryLeft} {
      margin-right: 0;
    }

    ${PopularCategoryTitle} {
      padding: 0 30px;

      font-size: 14px;
      line-height: 16px;
    }

    ${PopularCategorySubtitle} {
      display: none;
      margin-top: 10px;
      font-size: 11px;
      line-height: 15px;

      &.offers {
        margin-top: 0;
      }
    }
  }

  @media (max-width: 380px) {
    min-width: 140px;
    width: 140px;
  }
`;

import React from "react";

export const IconYoutube = () => {
  return (
    <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.9322 2.42517C17.7298 1.6476 17.1334 1.0353 16.3761 0.827493C15.0037 0.449829 9.49998 0.449829 9.49998 0.449829C9.49998 0.449829 3.99628 0.449829 2.62378 0.827493C1.86648 1.0353 1.27008 1.6476 1.06768 2.42517C0.699982 3.83442 0.699982 6.77483 0.699982 6.77483C0.699982 6.77483 0.699982 9.71514 1.06768 11.1245C1.27008 11.9021 1.86648 12.5144 2.62378 12.7223C3.99628 13.0998 9.49998 13.0998 9.49998 13.0998C9.49998 13.0998 15.0037 13.0998 16.3761 12.7223C17.1334 12.5144 17.7298 11.9021 17.9322 11.1245C18.3 9.71514 18.3 6.77483 18.3 6.77483C18.3 6.77483 18.3 3.83442 17.9322 2.42517Z"
        fill="currentColor"
      />
      <path d="M7.85001 9.80005V4.30005L12.25 7.05015L7.85001 9.80005Z" fill="#302E30" />
    </svg>
  );
};

import styled from 'styled-components';
import { theme } from '@components/ui/theme';

export const StyledLayoutButtons = styled.div`
  display: flex;
`;

export const LayoutButton = styled.div<{ active?: boolean }>`
  color: ${(props) => (props.active ? theme.colors.primary : theme.colors.greyPrimary)};

  pointer-events: ${(props) => (props.active ? 'none' : 'auto')};
  cursor: ${(props) => (props.active ? 'default' : 'pointer')};

  &:hover {
    color: ${(props) => (props.active ? theme.colors.primary : theme.colors.black)};
  }

  &:not(:first-child) {
    margin-left: 10px;
  }
`;

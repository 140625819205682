import { DropdownButton } from '@components/ui/dropdown-button/dropdown-button';
import React, { useEffect, useState } from 'react';
import { Tag } from '@components/ui/tag/tag';
import Link from 'next/link';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores/root';

type Props = {
  tags: TagType[];
  limit?: number;
  canShowMore?: boolean;
  linkPrefix?: string;
  currentTag?: string;
  isRelink?: boolean;
  rowsLengths?: number;
  noSlice?: boolean;
};

export const Tags = observer((props: Props) => {
  const { ui } = useStore();
  const [limit, setLimit] = useState(props.limit);
  const [showMore, setShowMore] = useState(false);
  const [tagsToShow, setTagsToShow] = useState(limit > 0 ? props.tags.slice(0, limit) : props.tags);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    const tags = showMore ? props.tags : props.tags.slice(0, limit);
    setTagsToShow(tags);
  }, [showMore, props.tags, limit]);

  useEffect(() => {
    if (props.noSlice) return;

    const rowsLengths = props.rowsLengths ? props.rowsLengths : 2;
    const lengths = props?.tags.map((t) => String(t.name).length * 10 + 41); // суммарная длина всех тегов
    const width = ui.viewport > 1440 ? 1440 * rowsLengths : (ui.viewport - 60) * 2; // длина двойного контейнера с тегами (отступы включены)

    let count = 0;
    let finalLength = 0;

    lengths.forEach((len) => {
      const tagLength = len + 16; // длина тега
      const totalLength = width - 148 - 65 - (count + 2) * 16; // длина имеющихся тегов ( с учётом Все, Показать ещё и gap между тегами)

      if (finalLength + tagLength < totalLength) {
        count += 1;
        finalLength += len;
      }
    });

    if (!props.limit && ui.viewport > 1000) setLimit(count);
  }, [props.tags, props.limit, ui.viewport, props.rowsLengths, props.noSlice]);

  const hasMoreTags = limit !== 0 && props.tags.length > limit;

  return (
    <>
      {tagsToShow.map(
        (tag) =>
          tag &&
          (props.linkPrefix || props.isRelink ? (
            <Link
              legacyBehavior
              key={tag.id ?? tag.url}
              href={props.isRelink ? tag.url : `${props.linkPrefix}${tag.slug}`}
              passHref
            >
              <Tag
                count={tag.count ?? 0}
                active={props.isRelink ? false : tag.slug == props.currentTag}
              >
                {props.isRelink ? tag.text : tag.name}
              </Tag>
            </Link>
          ) : (
            <Tag
              key={tag.id ?? tag.url}
              count={tag.count ?? 0}
              active={tag.slug == props.currentTag}
            >
              {tag.name}
            </Tag>
          ))
      )}

      {hasMoreTags && props.canShowMore && (
        <DropdownButton state={showMore} onClick={toggleShowMore} />
      )}
    </>
  );
});

import React, { ForwardedRef } from 'react';
import * as S from './category-menu-item.style';
import classNames from 'classnames';
import { Image } from '@components/Image';
import { imageLoader } from '../../../../../utils/image-loader';

type Props = {
  href?: string;
  name?: string;
  onClick?(): void;
  active?: boolean;
  image: string;
};

export const CategoryMenuItem = React.forwardRef(
  (props: Props, ref: ForwardedRef<HTMLAnchorElement>) => {
    return (
      <S.StyledCategoryMenuItem
        ref={ref}
        href={props.href}
        onClick={props.onClick}
        className={classNames({ active: props.active })}
      >
        <S.CategoryMenuItemImage>
          {props.image ? (
            <Image
              style={{ objectFit: 'contain' }}
              fill
              loader={imageLoader({
                src: props.image,
                width: 64,
              })}
              src={`s3:/${props.image}`}
              alt={props.name}
              title={props.name}
            />
          ) : (
            <Image
              fill
              style={{ objectFit: 'contain' }}
              src="s3://pics/nophotosmall.svg"
              alt={props.name}
              title={props.name}
            />
          )}
        </S.CategoryMenuItemImage>
        <S.CategoryMenuItemText>{props.name}</S.CategoryMenuItemText>
      </S.StyledCategoryMenuItem>
    );
  }
);

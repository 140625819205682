import React, { useEffect, useMemo, useRef, useState } from 'react';
import Link from 'next/link';
import * as S from './product-large-style';
import { ProductCardType } from '@components/product/elements/product-card/product-card';
import { Icon } from '@components/ui/icons/icon';
import { formatPlural } from '../../../../utils/formaters';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores/root';
import { NoSSR } from '@components/NoSSR';
import { Image } from '@components/Image';
import { Button } from '@components/ui/button/button';
import { PopoverProductCart } from '@components/popovers/popover-product-cart/popover-product-cart';
import { Label } from '@components/ui/label/label';
import { CountPicker } from '@components/ui/count-picker/count-picker';
import { RatingStars } from '@components/ui/rating-stars/rating-stars';
import { ProductCardSlider } from '@components/product/elements/product-card/slider/product-card-slider';
import { ProductCardDescription } from '@components/product/elements/product-card-description/product-card-description';
import { PopoverProductOffers } from '@components/popovers/popover-product-offers/popover-product-offers';
import { Product } from '../../../../gql/generated';
import { WH } from '@components/WH';

export type ProductLargeType = ProductCardType & {
  reviewsCount?: number;
  offersCount?: number;
  offers?: Product['offers'];
};

type Props = ProductLargeType & {
  productsCount: number;
  shortDescription?: string;
};

export const ProductLarge: FCC<Props> = observer((props) => {
  const { favorites, cart, modal, ui } = useStore();
  const [priceMargin, setPriceMargin] = useState(0);
  const [selectedImage, setSelectedImage] = useState(0);
  const infoRef = useRef<HTMLDivElement>(null);
  const imageContaineRef = useRef<HTMLAnchorElement>(null);
  const descriptionRef = useRef<HTMLDivElement>(null);

  const isFavorite = favorites.has(props.id);
  const isInCart = cart.has(props.id);
  const item = cart.items.get(props.id);

  const ratingCountPlural = formatPlural(props.ratingCount ?? 0, ['оценка', 'оценки', 'оценок']);
  const offersCountPlural = formatPlural(props.offersCount, [
    'предложение',
    'предложения',
    'предложений',
  ]);
  const reviewsPlural = formatPlural(props.reviewsCount, ['отзыв', 'отзыва', 'отзывов']);
  const oldPrice = useMemo(() => (props.price / (100 - props.discount)) * 100, []);

  const addToFavorites = (): void => {
    if (isFavorite) return favorites.remove(props.id);

    favorites.add({
      id: props.id,
      name: props.name,
      slug: props.slug,
      price: props.price,
      priceFrom: props.priceFrom,
      priceTo: props.priceTo,
      images: props.images,
      hit: props.hit,
      rating: props.rating,
      discount: props.discount,
      ratingCount: props.ratingCount,
    });
  };
  const addToCart = (): void => {
    cart.addItem({
      id: props.id,
      name: props.name,
      price: props.price,
      slug: props.slug,
      images: props.images,
      priceFrom: props.priceFrom,
      priceTo: props.priceTo,
      hit: props.hit,
      rating: props.rating,
      discount: props.discount,
      ratingCount: props.ratingCount,
    });
  };
  const increaseCount = () => {
    cart.increaseCount(item.id);
  };
  const decreaseCount = () => {
    cart.decreaseCount(item.id);
  };

  // @ts-ignore
  const openOfferModal = () =>
    modal.show('oneClickOrder', {
      ...props,
      image: props.images[0],
    });

  useEffect(() => {
    if (props.shortDescription && props.attributes.length > 0) return;

    const imageHeight = imageContaineRef.current.clientHeight;
    const infoHeight = infoRef.current.clientHeight;
    const largerHeight = Math.max(imageHeight, infoHeight);

    setPriceMargin(largerHeight - infoHeight - 16);
  }, [ui.viewport, props.shortDescription, props.attributes.length]);

  const image = (
    <Image
      src={`s3:/${props.images[selectedImage]}`}
      width={270}
      height={224}
      style={{ objectFit: 'contain' }}
      alt={props.name}
      title={props.name}
    />
  );

  return (
    <S.StyledProductLarge
      className={(props.attributes.length === 0 || !props.shortDescription) && 'negativeMargin'}
      isSingle={props.productsCount === 1}
      priceMargin={priceMargin}
      noContent={!props.shortDescription && props.attributes.length === 0}
    >
      <S.ProductLargeLabels>
        {props.hit && <Label color="purple">Хит</Label>}
        {props.discount > 0 && <Label color="pink">-{props.discount}%</Label>}
      </S.ProductLargeLabels>

      <S.ProductLargeFavorites onClick={addToFavorites}>
        <Icon icon={isFavorite ? 'heartFilled' : 'heart'} />
      </S.ProductLargeFavorites>

      <Link legacyBehavior prefetch={false} href={`/p/${props.slug}`} passHref>
        <S.ProductLargeImage
         
          ref={imageContaineRef}
          className={props?.images.length === 0 && 'noImage'}
        >
          {props?.images[0] ? (
            <>
              {image}
              <ProductCardSlider
                length={props.images.length}
                selectedIndex={selectedImage}
                setSelectedIndex={setSelectedImage}
              />
            </>
          ) : (
            <Icon icon="noPhoto" />
          )}
        </S.ProductLargeImage>
      </Link>

      <S.ProductLargeInfo ref={infoRef}>
        <Link legacyBehavior prefetch={false} href={`/p/${props.slug}`} passHref>
          <a>
            <S.ProductLargeTitle>{props.name}</S.ProductLargeTitle>
          </a>
        </Link>

        <S.ProductLargeRating>
          <RatingStars
            rating={props.rating}
            votes={props.ratingCount}
            hideStarsOnTabletM
            hideStarsOnTabletS
            hideStarsOnMobileS
            hideStarsOnMobileM
          />
          <NoSSR>
            <S.ProductLargeRatingValue
              className={`clickable favorites ${isFavorite && 'active'}`}
              onClick={addToFavorites}
            >
              <Icon icon={isFavorite ? 'heartFilled' : 'heart'} />

              {isFavorite ? 'В избранном' : 'В избранное'}
            </S.ProductLargeRatingValue>
          </NoSSR>

          <Link legacyBehavior prefetch={false} href={`/p/${props.slug}/reviews`} passHref>
            <S.ProductLargeRatingValue className="favorites">
              <Icon icon="message" />
              {props.reviewsCount} {reviewsPlural}
            </S.ProductLargeRatingValue>
          </Link>
        </S.ProductLargeRating>

        {props.shortDescription && (
          <S.ProductLargeDescription>
            <S.ProductLargeSubtitle>Описание</S.ProductLargeSubtitle>

            <S.ProductLargeDescriptionText
              ref={descriptionRef}
              dangerouslySetInnerHTML={{
                __html:
                  props.shortDescription +
                  `<a href="/p/${props.slug}">Читать далее</a>`,
              }}
            >
              {/* <ProductCardDescription */}
              {/*   desktopWidth={742} */}
              {/*   maxWidthParam={0} */}
              {/*   description={props.shortDescription} */}
              {/*   descriptionRef={descriptionRef} */}
              {/* /> */}
            </S.ProductLargeDescriptionText>
          </S.ProductLargeDescription>
        )}

        {props.attributes && props.attributes.length > 0 && (
          <S.ProductLargeProperties>
            <S.ProductLargeSubtitle>Характеристики</S.ProductLargeSubtitle>

            {props.attributes.slice(0, 5).map((attr) => (
              <WH>
                <S.ProductLargeProperty key={attr.name + attr.value + Math.random()}>
                  {attr.name}: {attr.value}
                </S.ProductLargeProperty>
              </WH>
            ))}
          </S.ProductLargeProperties>
        )}
      </S.ProductLargeInfo>

      <S.ProductLargePrice>
        <S.ProductLargePriceMain>
          <S.ProductLargePriceValue>
            {props.price.toLocaleString('ru-RU')} ₽
          </S.ProductLargePriceValue>
          {props.discount > 0 && (
            <S.ProductLargePriceDiscount>
              {Number(oldPrice.toFixed(0)).toLocaleString('ru-RU')} ₽
            </S.ProductLargePriceDiscount>
          )}
        </S.ProductLargePriceMain>

        {props.priceFrom !== props.priceTo && (
          <S.ProductLargePriceRange>
            от {Number(props.priceFrom).toLocaleString('ru-RU')} ₽ до{' '}
            {Number(props.priceTo).toLocaleString('ru-RU')} ₽
          </S.ProductLargePriceRange>
        )}

        {/* <S.ProductLargePriceOffers>
          {props.offersCount && (
            <PopoverProductOffers
              offersCount={props.offersCount}
              image={props.images[0]}
              zIndex={3}
              name={props.name}
              slug={props.slug}
              target={
                <div>
                  {props.offersCount} {offersCountPlural}
                </div>
              }
              align="right"
            />
          )}
        </S.ProductLargePriceOffers> */}

        <Button onClick={openOfferModal} outline>
          Хочу купить
        </Button>

        <S.ProductLargePriceToCart>
          {!isInCart ? (
            <Button onClick={addToCart}>В корзину</Button>
          ) : (
            <CountPicker count={item.count} onMinus={decreaseCount} onPlus={increaseCount} />
          )}
        </S.ProductLargePriceToCart>
      </S.ProductLargePrice>

      <S.ProductLargePriceResponsive>
        <S.ProductLargePriceMain>
          <S.ProductLargePriceValue>
            {props.price.toLocaleString('ru-RU')} ₽
            <S.ProductLargePriceDiscount>
              {Number(oldPrice.toFixed(0)).toLocaleString('ru-RU')} ₽
            </S.ProductLargePriceDiscount>
          </S.ProductLargePriceValue>

          {props.priceFrom !== props.priceTo && (
            <S.ProductLargePriceRange>
              от {Number(props.priceFrom).toLocaleString('ru-RU')} ₽ до{' '}
              {Number(props.priceTo).toLocaleString('ru-RU')} ₽
            </S.ProductLargePriceRange>
          )}
        </S.ProductLargePriceMain>

        <S.ProductLargePriceToCart>
          <PopoverProductCart productID={props.id} isInCart={isInCart} addToCart={addToCart} />
        </S.ProductLargePriceToCart>
      </S.ProductLargePriceResponsive>

      <Button outline className="responsive" onClick={openOfferModal}>
        Хочу купить
      </Button>
    </S.StyledProductLarge>
  );
});

import React from "react";

export const IconCycle = () => {
  return (
    <div>
      <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M37.0307 19.0001C37.0307 22.6795 35.9286 26.1015 34.0385 28.9529L28.674 19.3001H33.6641H33.964L33.9641 19.0001C33.9643 16.066 33.1021 13.1964 31.4846 10.7484C29.8671 8.30028 27.5657 6.38165 24.8665 5.23102C22.1674 4.08039 19.1896 3.74853 16.3033 4.2767C13.5445 4.78156 10.9856 6.05091 8.91643 7.93655L7.39423 5.19597C10.6383 2.46089 14.7485 0.960839 18.997 0.966766H18.9974C28.9572 0.966766 37.0307 9.04029 37.0307 19.0001ZM0.964063 19.0001C0.964063 15.3207 2.06619 11.8987 3.95627 9.04733L9.32079 18.7001H4.33073H4.03075L4.03073 19.0001C4.03049 21.9342 4.89272 24.8038 6.51021 27.2518C8.12771 29.6999 10.4291 31.6185 13.1283 32.7692C15.8274 33.9198 18.8052 34.2517 21.6915 33.7235C24.4503 33.2186 27.0092 31.9493 29.0784 30.0636L30.6006 32.8042C27.3565 35.5393 23.2462 37.0394 18.9978 37.0334H18.9974C9.03758 37.0334 0.964063 28.9599 0.964063 19.0001Z" fill="#E64E8D" stroke="white" strokeWidth="0.6"/>
      </svg>
    </div>
  );
};
import { useCallback, useEffect } from "react";

export const useEsc = (callback: () => void) => {
  const keyHandler = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      callback();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', keyHandler);

    return () => {
      document.removeEventListener('keydown', keyHandler);
    };
  }, []);
};

import React from 'react';
import { IconCart } from './icon-cart';
import { IconCheckboxCircle } from './icon-checkbox-circle';
import { IconChevronDown } from './icon-chevron-down';
import { IconChevronLeft } from './icon-chevron-left';
import { IconChevronRight } from './icon-chevron-right';
import { IconChevronUp } from './icon-chevron-up';
import { IconDelivery } from './icon-delivery';
import { IconEye } from './icon-eye';
import { IconHeart } from './icon-heart';
import { IconMessage } from './icon-message';
import { IconPlay } from './icon-play';
import { IconReturn } from './icon-return';
import { IconShare } from './icon-share';
import { IconShield } from './icon-shield';
import { IconThumbDown } from './icon-thumb-down';
import { IconThumbUp } from './icon-thumb-up';
import { IconTruck } from './icon-truck';
import { IconUser } from './icon-user';
import { IconWallet } from './icon-wallet';
import { IconStarFilled } from '@components/ui/icons/icon-star-filled';
import { IconCartPlus } from '@components/ui/icons/icon-cart-plus';
import { IconMapPin } from '@components/ui/icons/map-pin';
import { IconMagnifier } from '@components/ui/icons/icon-magnifier';
import { IconQuestionAnswer } from '@components/ui/icons/icon-question-answer';
import { IconVK } from '@components/ui/icons/icon-vk';
import { IconFacebook } from '@components/ui/icons/icon-facebook';
import { IconTwitter } from '@components/ui/icons/icon-twitter';
import { IconOK } from '@components/ui/icons/icon-ok';
import { IconYoutube } from '@components/ui/icons/icon-youtube';
import { IconInstagram } from '@components/ui/icons/icon-instagram';
import { IconLogo } from '@components/ui/icons/icon-logo';
import { IconHeartFilled } from '@components/ui/icons/icon-heart-filled';
import { IconArrows } from '@components/ui/icons/icon-arrows';
import { IconBurger } from '@components/ui/icons/icon-burger';
import { IconGrid } from '@components/ui/icons/icon-grid';
import { IconClose } from '@components/ui/icons/icon-close';
import { IconUserNobg } from '@components/ui/icons/icon-user-nobg';
import { IconDone } from '@components/ui/icons/icon-done';
import { IconMinus } from '@components/ui/icons/icon-minus';
import { IconPlus } from '@components/ui/icons/icon-plus';
import { IconStar } from '@components/ui/icons/icon-star';
import { IconInfo } from '@components/ui/icons/icon-info';
import { IconWrong } from '@components/ui/icons/icon-wrong';
import { IconOkay } from '@components/ui/icons/icon-okay';
import { IconMap } from '@components/ui/icons/icon-map';
import { IconMail } from '@components/ui/icons/icon-mail';
import { IconClock } from '@components/ui/icons/icon-clock';
import styled from 'styled-components';
import { IconLoupeMenu } from '@components/ui/icons/icon-loupe-menu';
import { IconArrow } from '@components/ui/icons/icon-arrow';
import { IconEqualizer } from './equalizer';
import { IconAttention } from '@components/ui/icons/attention';
import { NoteIcon } from '@components/ui/icons/note';
import { IconCycle } from '@components/ui/icons/icon-cycle';
import { IconTrash } from '@components/ui/icons/icon-trash';
import { IconNophoto } from '@components/ui/icons/nophoto';

export const Icons = {
  cart: IconCart,
  cartPlus: IconCartPlus,
  checkboxCircle: IconCheckboxCircle,
  chevronDown: IconChevronDown,
  chevronLeft: IconChevronLeft,
  chevronRight: IconChevronRight,
  chevronUp: IconChevronUp,
  delivery: IconDelivery,
  equalizer: IconEqualizer,
  eye: IconEye,
  heart: IconHeart,
  heartFilled: IconHeartFilled,
  message: IconMessage,
  play: IconPlay,
  return: IconReturn,
  share: IconShare,
  shield: IconShield,
  thumbDown: IconThumbDown,
  thumbUp: IconThumbUp,
  trash: IconTrash,
  truck: IconTruck,
  user: IconUser,
  icon: IconWallet,
  starFilled: IconStarFilled,
  mapPin: IconMapPin,
  magnifier: IconMagnifier,
  questionAnswer: IconQuestionAnswer,
  vk: IconVK,
  facebook: IconFacebook,
  twitter: IconTwitter,
  ok: IconOK,
  youtube: IconYoutube,
  instagram: IconInstagram,
  noPhoto: IconNophoto,
  logo: IconLogo,
  arrows: IconArrows,
  burger: IconBurger,
  grid: IconGrid,
  close: IconClose,
  userNoBg: IconUserNobg,
  done: IconDone,
  minus: IconMinus,
  plus: IconPlus,
  star: IconStar,
  info: IconInfo,
  wrong: IconWrong,
  okay: IconOkay,
  map: IconMap,
  mail: IconMail,
  clock: IconClock,
  iconLoupeMenu: IconLoupeMenu,
  arrow: IconArrow,
  attention: IconAttention,
  wallet: IconWallet,
  note: NoteIcon,
  cycle: IconCycle,
};

type Props = {
  className?: string;
  onClick?(): void;
  icon: keyof typeof Icons;
};

const Component = (props: Props) => {
  const Component = Icons[props.icon];

  return (
    <div onClick={props.onClick} className={props.className}>
      <Component />
    </div>
  );
};

export const Icon = styled(Component)``;

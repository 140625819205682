import styled from 'styled-components';

export const StyledShowMoreButton = styled.a`
  margin-top: 20px;
  font-size: 15px;
  line-height: 140%;
  background: #f9f7fa;
  color: #302e30;
  width: 100%;
  height: 60px;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #9ca0a9;
    margin-left: 5px;
  }

  &:hover {
    background: #fafafa;
  }
`;

import styled from 'styled-components';
import { theme } from '@components/ui/theme';

export const StyledProductCardSlider = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const ProductCardSliderImage = styled.div`
  position: relative;
  width: 232px;
  height: 174px;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 90%;
  }
`;

export const Indicators = styled.div`
  bottom: -13px;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 4px;
`;

export const Indicator = styled.div<{ active: boolean }>`
  flex: 1;
  height: 100%;
  border-bottom: 1px solid ${(props) => (props.active ? theme.colors.primary : '#C4C4C4')};
  transition: border-bottom-color 0.2s;
`;

import * as React from "react";

/**
 * The hook return percent of document scroll.
 * @return {number}
 */
export const useScrollPercent = () => {
  const [percent, setPercent] = React.useState(0);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = React.useCallback(() => {
    if (typeof window !== 'undefined') {
      const h = document.documentElement;
      const b = document.body;
      const st = 'scrollTop';
      const sh = 'scrollHeight';
      setPercent((h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100);
    }
  },[]);

  return percent;
};

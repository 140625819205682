import { scrollbar } from '@components/ui/theme';
import styled from 'styled-components';
import {
  Labels,
  PackagePrice,
  PackagePriceButtons,
  StyledPackage,
} from '@components/product/elements/available-package/available-package.style';
import { StyledLastViewedCard } from '@components/blocks/last-viewed-products/last-viewed-card/last-viewed-card-style';
import { ControlButton } from '@components/modals/images-slider/images-slider.style';
import { BlockTitle } from '@components/ui/block-title/block-title';
import { ProductAvailablePackage } from '@components/product/elements/available-package/available-package';

export const StyledLastViewedProducts = styled.div`
  ${StyledPackage} ${Labels} {
    left: 0;
  }

  ${ProductAvailablePackage} {
    padding: 25px 25px 37px 25px;
  }

  ${PackagePrice} {
    flex-shrink: unset;
  }

  @media (max-width: 1000px) {
    ${BlockTitle} {
      margin-bottom: 20px;
    }

    position: relative;
    width: 100vw;
    left: -30px;
    padding: 45px 30px 50px 30px;
    background: #f9f7fa;

    &.slider ${BlockTitle} {
      margin-bottom: 35px;
    }
  }

  @media (max-width: 600px) {
    left: -20px;
    padding: 45px 20px 50px 20px;
  }

  @media (max-width: 525px) {
    ${BlockTitle} {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 480px) {
    ${BlockTitle} {
      margin-bottom: 25px;
    }

    padding: 35px 20px 40px 20px;
  }
`;

export const ResponsiveProductCardsWrapper = styled.div`
  overflow: scroll;
  ${scrollbar.disable};
  position: relative;

  width: 100vw;
  left: -30px;
  padding: 0 30px;

  @media (max-width: 600px) {
    padding: 13px 20px;
    margin: -13px 0;
    width: 100vw;
    left: -20px;
  }

  @media (max-width: 525px) {
    padding: 0;
    margin: -10px 0;
  }
`;

export const ResponsiveProductCardsContainer = styled.div`
  display: flex;
  gap: 20px;
  width: fit-content;

  ${StyledLastViewedCard} {
    min-width: 453px;
  }

  @media (max-width: 1000px) {
    ${StyledLastViewedCard} {
      min-width: 250px;
      max-width: 250px;
    }
  }

  @media (max-width: 525px) {
    padding: 10px 20px;
  }

  @media (max-width: 480px) {
    gap: 10px;
  }

  @media (max-width: 430px) {
    ${StyledLastViewedCard} {
      min-width: 220px;
      max-width: 220px;
    }
  }
`;

export const LastViewedProductsTwice = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;

  ${PackagePrice} {
    width: fit-content;
    margin-left: 0;
  }

  ${PackagePriceButtons} {
    justify-content: flex-end;
    opacity: 0;

    button {
      cursor: default;
    }
  }

  ${StyledPackage}:hover {
    box-shadow: 0 2px 12px rgba(99, 47, 117, 0.14);

    ${PackagePriceButtons} {
      opacity: 1;

      button {
        cursor: pointer;
      }
    }
  }
`;

export const LastViewedSingle = styled.div`
  display: flex;
  justify-content: flex-start;

  ${PackagePrice} {
    width: fit-content;
    margin-left: 45px;
  }

  ${PackagePriceButtons} {
    justify-content: flex-end;
    opacity: 0;

    button {
      cursor: default;
    }
  }

  ${StyledPackage}:hover ${PackagePriceButtons} {
    opacity: 1;

    button {
      cursor: pointer;
    }
  }
`;

export const Prev = styled(ControlButton)`
  left: 9px;

  &.swiper-button-disabled {
    display: none;
  }

  @media (max-width: 1273px) {
    left: 10px;
  }
`;

export const Next = styled(ControlButton)`
  right: 9px;

  &.swiper-button-disabled {
    display: none;
  }

  @media (max-width: 1273px) {
    right: 10px;
  }
`;

export const LastViewedItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 430px;
`;

export const LastViewedItems = styled.div<{ isLarge: boolean }>`
  display: ${({ isLarge }) => (isLarge ? 'block' : 'grid')};
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 18px;

  overflow: scroll;
  ${scrollbar.disable};
  ${scrollbar.alignSidesDesktop};
  padding: 15px 30px;
  margin: -15px 0;

  ${LastViewedItem} {
    min-width: ${({ isLarge }) => (isLarge ? 'unset' : '430px')};

    @media (max-width: 1000px) {
      min-width: 430px;
    }
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    display: ${({ isLarge }) => (isLarge ? 'flex' : 'grid')};
    position: relative;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    justify-content: space-between;
  }

  @media (max-width: 1000px) {
    display: flex;
  }

  @media (max-width: 600px) {
    ${scrollbar.alignSidesMobile};
  }
`;

export const LastViewedSliderContainer = styled.div`
  position: relative;

  .swiper-slide.large {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .swiper {
    padding: 10px;
  }

  ${LastViewedItems} {
    margin: -25px 0;
  }

  ${PackagePriceButtons} {
    display: none;
  }
`;

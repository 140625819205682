import styled from 'styled-components';
import { theme } from '@components/ui/theme';
import { SearchResultPrice } from '../../elements/search-result-element/search-result-element.style';

export const SearchResultBlock = styled.div`
  display: flex;
  width: 100%;
`;

export const SearchResultWrapper = styled.div``;

export const SearchResultTitle = styled.div`
  font-size: 14px;
  color: ${theme.colors.greyPrimary};
  width: 80px;
  text-align: right;
  line-height: 140%;
  margin-right: 20px;
`;

export const SearchResultContent = styled.div`
  flex: 1;
`;

export const StyledSearchResult = styled.div`
  width: 766px;
  margin: 0 auto;
  position: relative;

  ${SearchResultWrapper} {
    &:not(:last-child) {
      &:after {
        display: block;
        content: '';
        width: 670px;
        height: 1px;
        background: #f1eff2;
        margin-left: auto;
        margin-top: 12px;
        margin-bottom: 12px;
      }
    }
  }

  @media (max-width: 865px) {
    width: unset;
  }

  @media (max-width: 568px) {
    ${SearchResultBlock} {
      flex-direction: column;

      &:first-child {
        margin-bottom: 12px;
      }
    }

    ${SearchResultWrapper}:not(:last-child):after {
      display: none;
    }

    ${SearchResultTitle} {
      margin: 0 0 12px 0;
      text-align: left;
    }

    ${SearchResultPrice} {
      margin-left: 14px;
    }
  }
`;

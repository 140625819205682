import styled from 'styled-components';
import { RelinkItem } from '@components/layout/elements/relink-item/relink-item';
import { StyledRelinkItemRight } from '@components/layout/elements/relink-item/style';

export const StyledRelinkItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 25px;
  margin-top: 50px;

  ${RelinkItem} {
    flex: 1;
    min-width: 475px;

    @media (max-width: 520px) {
      min-width: unset;

      flex-wrap: wrap;
      justify-content: center;

      padding-bottom: 50px;

      ${StyledRelinkItemRight} {
        margin-top: 20px;
      }
    }
  }
`;

import React from 'react';
import * as S from './show-more-button.style';
import styled from 'styled-components';

type Props = {
  count?: number;
  className?: string;
  onClick?(e: React.MouseEvent<HTMLAnchorElement>): void;
  children?: any;
  href?: string;
};

const Component: FCC<Props> = React.forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  return (
    <S.StyledShowMoreButton
      ref={ref}
      className={props.className}
      onClick={props.onClick}
      href={props.href}
    >
      {props.children}
      {props.count && <span> ({props.count})</span>}
    </S.StyledShowMoreButton>
  );
});

export const ShowMoreButton = styled(Component)``;

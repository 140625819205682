import React from "react";
import * as S from "./search-result.style";
import { SearchQuery } from "../../../../../gql/generated";
import { SearchResultElement } from "@components/layout/header/elements/search-result-element/search-result-element";

type SearchResult = SearchQuery['search']['suggest'];

type Props = {
  tags: SearchResult['tags'];
  products: SearchResult['products'];
  categories: SearchResult['categories'];
  searchString: string;
};

export const SearchResult = (props: Props) => {
  return (
    <S.StyledSearchResult>
      {props.tags?.length > 0 && (
        <S.SearchResultWrapper>
          <S.SearchResultBlock>
            <S.SearchResultTitle className='noImage'>Часто ищут</S.SearchResultTitle>
            <S.SearchResultContent>
              {props.tags.map((tag) => (
                <SearchResultElement
                  type="tag"
                  key={tag.id}
                  name={tag.name}
                  slug={tag.slug}
                  searchString={props.searchString}
                />
              ))}
            </S.SearchResultContent>
          </S.SearchResultBlock>
        </S.SearchResultWrapper>
      )}

      {props.products?.length > 0 && (
        <S.SearchResultWrapper>
          <S.SearchResultBlock>
            <S.SearchResultTitle>Товары</S.SearchResultTitle>
            <S.SearchResultContent>
              {props.products.map((product) => (
                <SearchResultElement
                  type="product"
                  key={product.id}
                  name={product.name}
                  slug={product.slug}
                  image={product.images[0]}
                  price={product.price}
                  searchString={props.searchString}
                />
              ))}
            </S.SearchResultContent>
          </S.SearchResultBlock>
        </S.SearchResultWrapper>
      )}

      {props.categories?.length > 0 && (
        <S.SearchResultWrapper>
          <S.SearchResultBlock>
            <S.SearchResultTitle>Категории</S.SearchResultTitle>
            <S.SearchResultContent>
              {props.categories.map((category) => (
                <SearchResultElement
                  type="category"
                  key={category.id}
                  name={category.name}
                  slug={category.slug}
                  image={category.image}
                  searchString={props.searchString}
                />
              ))}
            </S.SearchResultContent>
          </S.SearchResultBlock>
        </S.SearchResultWrapper>
      )}
    </S.StyledSearchResult>
  );
};

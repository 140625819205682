import React from "react";

export const IconChevronLeft = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.28247 10L12.4075 14.125L11.2291 15.3033L5.9258 10L11.2291 4.69668L12.4075 5.87501L8.28247 10Z"
        fill="currentColor"
      />
    </svg>
  );
};

import styled from 'styled-components';
import { theme } from '@components/ui/theme';
import { Button } from '@components/ui/button/button';

export const StyledPagination = styled.nav`
  width: 100%;
  display: flex;

  margin-top: 30px;

  ${Button} {
    width: 175px;
    margin-left: auto;
  }

  ul {
    display: flex;
    //gap: 16px;
    li {
      font-size: 14px;
      color: ${theme.colors.black};

      cursor: pointer;

      a {
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.selected {
        color: ${theme.colors.primary};
      }

      &:hover {
        background: #fafafa;
        border-radius: 3px;
      }

      &.previous {
        margin-right: 8px;
      }

      &.next {
        margin-left: 8px;
      }

      &.previous,
      &.next {
        //display: none;
        width: 42px;
        height: 42px;
        border: 1px solid #d9dde7;
        box-sizing: border-box;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 20px;
          height: 20px;
        }

        &:hover {
          border-color: #302e30;
          background: transparent;
        }
      }
    }
  }

  @media (max-width: 1070px) {
    ul {
      gap: 0;
    }
  }

  @media (max-width: 560px) {
    margin-top: 25px;

    ${Button} {
      display: none;
    }

    ul {
      li {
        &.previous,
        &.next {
          display: none;
        }

        &.selected {
          background: #f9f7fa;
          border-radius: 3px;
        }

        a {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
`;

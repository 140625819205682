import React from "react";
import * as S from "./dropdown-button.style";
import { Icon } from "@components/ui/icons/icon";

type Props = {
  state: boolean;
  closedText?: string;
  openedText?: string;
  onClick?(): void;
};

export const DropdownButton: FCC<Props> = ({
  closedText = 'Показать ещё',
  openedText = 'Скрыть',
  ...props
}) => {
  const text = props.state ? openedText : closedText;
  const icon = props.state ? 'chevronUp' : 'chevronDown';

  return (
    <S.StyledButton className={props.state && 'largeMargin'} onClick={props.onClick}>
      {text} <Icon icon={icon} />
    </S.StyledButton>
  );
};

import styled from 'styled-components';
import { device, theme } from '@components/ui/theme';
import { Label } from '@components/ui/label/label';
import { CartButton } from '@components/popovers/popover-product-cart/popover-product-cart.style';

export const ProductCardImage = styled.a<{ noPhoto: boolean }>`
  margin-bottom: 30px;
  position: relative;
  width: 192px;
  height: 127px;
  align-self: center;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  transform: ${({ noPhoto }) => (noPhoto ? 'scale(0.8)' : 'none')};

  @media ${device.tabletM} {
    display: none;
  }
`;

export const ProductCardImageSlider = styled.div`
  width: 192px;
  height: 127px;
  display: none;
  margin: 0 auto;

  @media ${device.tabletM} {
    display: block;
  }

  .swiper-pagination {
    bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .swiper-pagination-bullet {
      background: #d9dde7;
      opacity: 1;
      width: 5px;
      height: 5px;
    }

    .swiper-pagination-bullet-active {
      background: #9ca0a9;
    }
  }

  @media (max-width: 480px) {
    //width: 100px;
    //height: 100px;

    .swiper-pagination {
      bottom: 5px;
    }

    img {
      min-width: 100px;
      min-height: 100px;
    }
  }
`;

export const ProductCardImageSlide = styled.div`
  width: 192px;
  height: 127px;
  margin-bottom: 30px;
  position: relative;

  @media (max-width: 480px) {
    margin-bottom: 17px;
  }
`;

export const ProductCardFavorites = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;

  z-index: 2;

  color: ${theme.colors.greyPrimary};

  cursor: pointer;

  &:hover {
    svg path {
      fill: ${theme.colors.primary};
    }
  }

  &.active:hover svg path {
    fill: ${theme.colors.primaryHover};
  }

  svg {
    width: 100%;
    height: auto;
  }
`;

export const ProductCardTitle = styled.a`
  width: fit-content;
  font-size: 15px;
  line-height: 140%;
  color: ${theme.colors.black};
  word-break: break-word;

  cursor: pointer;
  max-height: 60px;
  overflow: hidden;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media ${device.tabletM} {
    margin-top: 35px;
  }

  @media (max-width: 480px) {
    margin-top: 30px;
  }
`;

export const ProductCardRating = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

export const ProductCardRatingStar = styled.div`
  color: ${theme.colors.primary};
`;

export const ProductCardRatingScore = styled.div`
  font-size: 12px;
  color: #302e30;
  margin-left: 6px;
`;

export const ProductCardRatingValue = styled.div`
  font-size: 12px;
  color: #9ca0a9;
  margin-left: 9px;
`;

export const ProductCardPrice = styled.div`
  margin-top: auto;
`;

export const ProductCardOldPrice = styled.div`
  font-size: 14px;
  line-height: 140%;
  font-weight: 400;
  color: #686a70;
  text-decoration-line: line-through;
`;

export const ProductCardPriceBlock = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 4px;
`;

export const ProductCardPriceBlockLeft = styled.div``;

export const ProductCardPriceBlockRight = styled.div`
  position: relative;
  top: -2px;
`;

export const ProductCardPriceValue = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #302e30;

  span {
    margin-right: 11px;
  }
`;

export const ProductCardPriceFrom = styled.div`
  font-size: 12px;
  line-height: 130%;
  color: #686a70;
  margin-top: 2px;
  display: flex;
  flex-wrap: wrap;

  span:first-child {
    margin-right: 5px;
  }
`;

export const ProductCardCartButton = styled.div``;

export const Labels = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 2;

  ${Label} {
    &:not(:first-child) {
      margin-top: 6px;
    }
  }

  @media (max-width: 480px) {
    top: 0;
    left: 0;
  }
`;

export const StyledProductCard = styled.article`
  padding: 40px 20px 35px 20px;
  border-radius: 3px;
  background: white;
  width: 272px;
  min-height: 385px;
  position: relative;
  display: flex;
  flex-direction: column;

  &:hover {
    box-shadow: 0px 2px 12px rgba(99, 47, 117, 0.09);

    ${ProductCardTitle} {
      color: ${theme.colors.primary};
    }
  }

  ${CartButton} button {
    width: 40px !important;
  }

  @media ${device.tabletM} {
    padding: 40px 20px 35px 20px;

    ${ProductCardFavorites} {
      top: 20px;
      right: 20px;
    }
  }

  @media ${device.tabletS} {
    ${ProductCardCartButton} button {
      width: 34px !important;
      height: 34px !important;
    }
  }

  @media ${device.mobileM} {
    padding: 20px 0 0 0 !important;
    width: 212px;
    min-height: 274px;

    &:hover {
      box-shadow: none;
    }

    ${ProductCardImage} {
      height: 100px;

      img {
        height: 100px !important;
        width: 100px !important;
      }
    }

    ${ProductCardFavorites} {
      top: 0px;
      right: 0px;
      width: 30px;
      height: 30px;
    }

    ${ProductCardTitle} {
      font-size: 14px;
      line-height: 19px;
      max-height: 64px;
    }

    ${ProductCardRating} {
      ${ProductCardRatingStar} svg {
        width: 15px;
        height: 15px;
      }

      ${ProductCardRatingScore}, ${ProductCardRatingValue} {
        font-size: 12px;
      }
    }

    ${ProductCardPrice} {
      ${ProductCardOldPrice} {
        display: none;
      }

      ${ProductCardPriceValue} {
        font-size: 18px;
        line-height: 21px;
        flex-direction: column-reverse !important;
        align-items: flex-start !important;

        ${ProductCardOldPrice} {
          margin-left: 0 !important;
        }
      }

      ${ProductCardPriceFrom} {
        margin-top: 2px;
        font-size: 12px;
        line-height: 15px;
      }
    }

    ${ProductCardCartButton} button {
      width: 34px !important;
      height: 34px !important;
    }
  }
`;

export const Bullets = styled.div`
  width: 100%;
  position: absolute;
  bottom: -13px;
  display: flex;
  justify-content: center;
  gap: 8px;
`;

export const Bullet = styled.div<{ active: boolean }>`
  opacity: 1;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: ${(props) => (props.active ? '#9ca0a9' : '#d9dde7')};
`;

import React from "react";

export const IconMail = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.49935 2.5H17.4993C17.7204 2.5 17.9323 2.5878 18.0886 2.74408C18.2449 2.90036 18.3327 3.11232 18.3327 3.33333V16.6667C18.3327 16.8877 18.2449 17.0996 18.0886 17.2559C17.9323 17.4122 17.7204 17.5 17.4993 17.5H2.49935C2.27834 17.5 2.06637 17.4122 1.91009 17.2559C1.75381 17.0996 1.66602 16.8877 1.66602 16.6667V3.33333C1.66602 3.11232 1.75381 2.90036 1.91009 2.74408C2.06637 2.5878 2.27834 2.5 2.49935 2.5V2.5ZM16.666 6.03167L10.0593 11.9483L3.33268 6.01333V15.8333H16.666V6.03167ZM3.75852 4.16667L10.0502 9.71833L16.251 4.16667H3.75852Z"
        fill="currentColor"
      />
    </svg>
  );
};

import styled from 'styled-components';
import { theme } from '@components/ui/theme';

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  font-size: 15px;

  padding: 3px 5px;

  &.circle {
    border-radius: 50%;
  }

  &.rounded {
    border: 1px solid #e64e8d;
    border-radius: 3px;

    color: ${theme.colors.primary};

    svg {
      width: 100%;
      height: auto;
    }

    &.fill {
      background: #e64e8d;
      color: white;
    }

    &.secondary {
      border-color: #27ae60;
      color: #27ae60;
      &.fill {
        background-color: transparent;
        color: #27ae60;
      }
    }

    &:hover {
      border-color: #f25292;
      background: #f25292;
      color: white;

      &.secondary {
        background: #27ae60;
        border-color: #27ae60;
        color: white;
        &.fill {
          background: #27ae60;
          color: white;
        }
      }
    }
  }

  &:disabled {
    color: #9ca0a9;
  }
`;

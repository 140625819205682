import React from "react";

export const IconArrow = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.9766 10L6.85156 5.875L8.0299 4.69667L13.3332 10L8.0299 15.3033L6.85156 14.125L10.9766 10Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { Likes } from '@components/product/elements/likes/likes';
import { IconUser } from '@components/ui/icons/icon-user';
import { RatingStars } from '@components/ui/rating-stars/rating-stars';
import { Image } from '@components/Image';
import Link from 'next/link';
import React from 'react';
import * as S from './review.style';
import { ReviewProps } from './review.types';
import { imageLoader } from '../../../../utils/image-loader';

export const ReviewDesktop: FCC<ReviewProps> = (props) => {
  const formattedText = props.text?.replaceAll('/n', '<br />') ?? '';

  return (
    <S.StyledReview className={props.className}>
      <Link legacyBehavior prefetch={false} href={`/p/${props.product.slug}`} passHref>
        <S.ReviewLeft>
          {props.product.image ? (
            <Image
              fill
              loader={imageLoader({
                width: 96,
                src: props.product.image,
              })}
              style={{ objectFit: 'contain' }}
              src={`s3:/${props.product.image}`}
              alt={props.name}
              title={props.name}
            />
          ) : (
            <Image
              fill
              style={{ objectFit: 'contain' }}
              src="s3://pics/nophotocard.svg"
              alt={props.name}
              title={props.name}
            />
          )}
        </S.ReviewLeft>
      </Link>

      <S.ReviewRight>
        <S.ReviewInfo>
          <Link legacyBehavior prefetch={false} href={`/p/${props.product.slug}`} passHref>
            <S.ReviewTitle>{props.product.name}</S.ReviewTitle>
          </Link>
          <S.ReviewRating>
            <RatingStars rating={props.rating} votes={50} hideVotes />
          </S.ReviewRating>
        </S.ReviewInfo>

        <S.ReviewUser>
          <S.ReviewUserAvatar>
            <IconUser />
          </S.ReviewUserAvatar>
          <S.ReviewUserName>{props.name}</S.ReviewUserName>
        </S.ReviewUser>

        <S.ReviewCategory>
          <S.ReviewCategoryTitle>Комментарий</S.ReviewCategoryTitle>
          <S.ReviewCategoryText
            dangerouslySetInnerHTML={{ __html: formattedText }}
          ></S.ReviewCategoryText>
        </S.ReviewCategory>

        <S.ReviewLikes>
          <Likes likesCount={props.likes} dislikesCount={props.dislikes} />
        </S.ReviewLikes>
      </S.ReviewRight>
    </S.StyledReview>
  );
};

import React, { useEffect, useState } from 'react';
import * as S from './catalogue.style';
import { motionSlideRTL } from '@components/ui/animations/animations';
import { useStore } from '@stores/root';
import { Image } from '@components/Image';
import { Scrollbar } from '@components/ui/scrollbar/scrollbar';
import Link from 'next/link';
import { CategoryMenuProduct } from '@components/layout/header/elements/category-menu-product/category-menu-product';
import { InitialDataQuery } from '../../../../../../../gql/generated';
import { Search } from '@components/layout/header/blocks/search/search';
import { useRouter } from 'next/router';
import { SearchResults } from '@components/layout/header/blocks/search-results/search-results';
import { imageLoader } from '../../../../../../../utils/image-loader';
import { Icon } from '@components/ui/icons/icon';

type Props = {
  categories: InitialDataQuery['categories'];
};

export const MobileCatalogue = (props: Props) => {
  const { ui } = useStore();
  const router = useRouter();
  const [searchValue, setSearchValue] = useState(String(router?.query?.query ?? ''));
  const [searchIsFocused, setSearchIsFocused] = useState(false);
  const [searchModal, setSearchModal] = useState(false);

  const onBurgerClick = (back = false) => {
    if (back) {
      return ui.toggleMobileCatalogue();
    }

    ui.setState({
      mobileMenu: {
        showCatalogue: false,
        show: false,
        showMenuAfterCatalogue: true,
      },
    });
    ui.toggleBodyOverflow('auto');
  };

  useEffect(() => {
    setSearchModal(true);
  }, [searchValue]);

  return (
    <Scrollbar>
      <SearchResults
        searchString={searchValue}
        setSearchString={setSearchValue}
        isFocused={searchIsFocused}
        isModal={searchModal}
        setIsModal={setSearchModal}
      />

      <S.MobileCatalogue {...motionSlideRTL}>
        <S.MenuBurger onClick={() => onBurgerClick()} className="active">
          <div></div>
          <div></div>
          <div></div>
        </S.MenuBurger>
        <S.MobileCatalogueTitle>
          <S.MobileCatalogueGoBack onClick={() => onBurgerClick(true)}>
            <Icon icon="chevronLeft" />
          </S.MobileCatalogueGoBack>
          Каталог товаров
        </S.MobileCatalogueTitle>

        <S.MobileCatalogueSearchContainer>
          <Search
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setIsFocused={setSearchIsFocused}
          />
        </S.MobileCatalogueSearchContainer>

        {/* {props.categories.length === 1 && ( */}
        {/*   <S.MobileCatalogueItems className="products"> */}
        {/*     {props.categories[0].kindProducts.map((product) => ( */}
        {/*       <Link legacyBehavior  prefetch={false} href={`/p/${product.slug}`} key={product.slug}> */}
        {/*         <CategoryMenuProduct product={product} /> */}
        {/*       </Link> */}
        {/*     ))} */}
        {/*   </S.MobileCatalogueItems> */}
        {/* )} */}

        {props.categories.length > 1 && (
          <S.MobileCatalogueItems>
            {props.categories.map((category) => (
              <Link
                legacyBehavior
                prefetch={false}
                href={`/c/${category.slug}`}
                key={category.slug}
              >
                <S.MobileCatalogueItem key={category.slug}>
                  <S.MobileCatalogueItemImage>
                    {category.image ? (
                      <Image
                        width={82}
                        height={69}
                        style={{ objectFit: 'contain' }}
                        loader={imageLoader({
                          src: category.image,
                          width: 96,
                        })}
                        src={`s3:/${category.image}`}
                        alt={category.name}
                        title={category.name}
                      />
                    ) : (
                      <Image
                        fill
                        style={{ objectFit: 'contain' }}
                        src="s3://pics/nophotosmall.svg"
                        alt={category.name}
                        title={category.name}
                      />
                    )}
                  </S.MobileCatalogueItemImage>
                  <S.MobileCatalogueItemName>{category.name}</S.MobileCatalogueItemName>
                </S.MobileCatalogueItem>
              </Link>
            ))}
          </S.MobileCatalogueItems>
        )}
      </S.MobileCatalogue>
    </Scrollbar>
  );
};

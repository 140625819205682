export const getDomain = () => {
  const nodes = window.location.hostname.split('.');
  const level = (process.env.NEXT_PUBLIC_DOMAIN_LEVEL as any) ?? 2;
  return nodes.splice(Math.max(0, nodes.length - level)).join('.');
};

export const getDomainFromHost = (host: string) => {
  const nodes = host.split('.');
  const level = (process.env.NEXT_PUBLIC_DOMAIN_LEVEL as any) ?? 2;
  return nodes.splice(Math.max(0, nodes.length - level)).join('.');
};

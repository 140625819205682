import styled from 'styled-components';
import React from 'react';
import { theme } from '../theme';

const StyledIconHeartFilled = styled.div`
  &:hover {
    svg path {
      fill: ${theme.colors.primaryHover};
    }
  }
`;

export const IconHeartFilled = () => {
  return (
    <StyledIconHeartFilled>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.001 7.28655C17.35 5.17755 20.98 5.24755 23.243 7.51455C25.505 9.78255 25.583 13.3945 23.479 15.7505L14.999 24.2425L6.52101 15.7505C4.41701 13.3945 4.49601 9.77655 6.75701 7.51455C9.02201 5.25055 12.645 5.17455 15.001 7.28655Z"
          fill="#E54E8D"
        />
      </svg>
    </StyledIconHeartFilled>
  );
};

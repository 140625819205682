import React from "react";
import * as S from "./popover-cart.style";
import { Popover, Props } from "components/ui/popover/popover";
import { Button } from "@components/ui/button/button";
import { observer } from "mobx-react-lite";
import { useStore } from "@stores/root";
import { PopoverCartProductItem } from "@components/product/elements/popover-product-item/popover-cart-product-item";
import { useRouter } from "next/router";

export const PopoverCart = observer((props: Omit<Props, 'children'>) => {
  const { cart } = useStore();
  const router = useRouter();
  const oldPrice =
    cart.oldSum !== cart.sum ? Number(cart.oldSum.toFixed(0)).toLocaleString('ru-RU') + ' ₽' : '';
  const price = `${cart.sum.toLocaleString('ru-RU')} ₽`;

  const onButtonClick = () => router.push('/cart');

  return (
    <Popover target={props.target} disabled={cart.size < 1} align="right">
      <S.Wrapper>
        <S.Products>
          {cart.itemsArray.map((item) => (
            <PopoverCartProductItem key={item.id} {...item} />
          ))}
        </S.Products>
        <S.Footer>
          <S.FooterButton>
            <Button onClick={onButtonClick}>В корзину</Button>
          </S.FooterButton>

          <S.FooterPrices>
            <S.FooterPrice>Итого: {price}</S.FooterPrice>

            {oldPrice.trim() !== '' && <S.FooterOldPrice>{oldPrice}</S.FooterOldPrice>}
          </S.FooterPrices>
        </S.Footer>
      </S.Wrapper>
    </Popover>
  );
});

import { MainVideoImage, MainVideoText } from './../../elements/video/video.style';
import styled from 'styled-components';
import { BlockTitle } from '@components/ui/block-title/block-title';
import { MainVideo } from '@components/main/elements/video/video';
import { Grid } from '@components/ui/grid/grid';
import { ShowMoreButton } from '@components/ui/show-more-button/show-more-button';
import { device } from '@components/ui/theme';
import { StyledShowMoreButton } from '@components/ui/show-more-button/show-more-button.style';

export const MainVideosContent = styled.div`
  margin-top: 40px;

  ${Grid} {
    grid-row-gap: 40px;
    grid-column-gap: 20px;
  }

  ${MainVideo} {
    //&:first-child,
    //&:nth-child(6) {
    //  grid-column: span 12 / span 12;
    //}

    &:only-child {
      grid-column: span 24 / span 24;
      ${MainVideoImage} {
        height: 400px;
      }
    }

    grid-column: span 8 / span 8;
  }

  ${ShowMoreButton} {
    margin-top: 40px;
  }

  @media (max-width: 1000px) {
    ${ShowMoreButton} {
      margin-top: 85px;
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
`;

export const StyledMainVideos = styled.section`
  margin-top: 80px;

  ${BlockTitle} {
    margin-bottom: 40px;
  }

  @media ${device.tabletM} {
    margin-top: 50px;

    ${BlockTitle} {
      margin-bottom: 35px;
    }

    ${Grid} {
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 70px;
      column-gap: 24px;
    }

    ${MainVideo}, ${MainVideo}:first-child, ${MainVideo}:nth-child(6) {
      grid-column: unset !important;
    }

    ${MainVideoText} {
      margin-top: 15px;
      font-size: 13px;
      line-height: 18px;
      max-height: 36px;
      padding-right: 5px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  @media ${device.tabletS} {
    ${Grid} {
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 70px;
      grid-column-gap: 20px;
    }

    ${MainVideosContent} ${StyledShowMoreButton} {
      margin-top: 24px;
      height: 42px;
    }
  }

  @media (max-width: 590px) {
    margin-top: 35px;

    ${BlockTitle} {
      margin-bottom: 25px;
    }

    ${Grid} {
      grid-template-columns: 1fr 1fr;
      row-gap: 70px;
      column-gap: 16px;
    }

    ${MainVideoText} {
      margin-top: 15px;
      font-size: 14px;
    }
  }

  @media (max-width: 500px) {
    ${Grid} {
      grid-template-columns: 1fr;
      grid-row-gap: 40px;
    }
  }
`;

export const MainVideosEmbeded = styled.div`
  iframe {
    aspect-ratio: 16 / 9;
    width: 100%;

    @media ${device.tabletS} {
      margin-bottom: 40px;
    }
  }
`;

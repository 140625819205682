import React, { useEffect, useRef } from 'react';
import * as S from './popover.style';
import classNames from 'classnames';

export type Props = {
  target: React.ReactNode;
  children: React.ReactNode;
  disabled?: boolean;
  placement?: 'top' | 'bottom';
  align?: 'left' | 'center' | 'right';
  zIndex?: number;
};

export const Popover = (props: Props) => {
  const [isHover, setHover] = React.useState<boolean>(false);
  const [scrollY, setScrollY] = React.useState(0);
  const placement = props.placement ?? 'bottom';
  const align = props.align ?? 'center';
  const ref = useRef<HTMLDivElement>(null);

  const timeout = useRef<NodeJS.Timeout>(null);

  const classes = classNames(placement);

  const resetTimer = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  };

  const showPopover = () => {
    resetTimer();
    setHover(true);
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    if (!process.browser) return;

    const fn = () => {
      if (Math.abs(scrollY - window.scrollY) > 300) {
        hidePopover();
      }
    };

    document.addEventListener('scroll', fn);

    return () => document.removeEventListener('scroll', fn);
  }, []);

  const hidePopover = () => {
    resetTimer();
    timeout.current = setTimeout(() => setHover(false), 250);
  };

  return (
    <S.StyledPopover
      ref={ref}
      className={`${classNames(align)} POPOVER`}
      onMouseOver={showPopover}
      onClick={showPopover}
      onMouseLeave={hidePopover}
      zIndex={props.zIndex}
    >
      {props.target && props.target}
      {isHover && !props.disabled && (
        <S.DropContent className={`${classes} ${props.align}`}>{props.children}</S.DropContent>
      )}
    </S.StyledPopover>
  );
};

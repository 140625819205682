import React from "react";

export const IconMagnifier = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.93945 1.68945C14.4935 1.68945 18.1895 5.38545 18.1895 9.93945C18.1895 14.4935 14.4935 18.1895 9.93945 18.1895C5.38545 18.1895 1.68945 14.4935 1.68945 9.93945C1.68945 5.38545 5.38545 1.68945 9.93945 1.68945ZM9.93945 16.3561C13.4842 16.3561 16.3561 13.4842 16.3561 9.93945C16.3561 6.39379 13.4842 3.52279 9.93945 3.52279C6.39379 3.52279 3.52279 6.39379 3.52279 9.93945C3.52279 13.4842 6.39379 16.3561 9.93945 16.3561ZM17.7174 16.4212L20.3106 19.0135L19.0135 20.3106L16.4212 17.7174L17.7174 16.4212V16.4212Z"
        fill="currentColor"
      />
    </svg>
  );
};

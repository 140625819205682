import React from "react";

export const IconFacebook = () => {
  return (
    <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.21981 18.2016V9.22477H8.69782L9.02621 6.13129H6.21981L6.22402 4.58297C6.22402 3.77615 6.30068 3.34383 7.45952 3.34383H9.00867V0.25H6.53031C3.5534 0.25 2.50561 1.75067 2.50561 4.27433V6.13164H0.649994V9.22512H2.50561V18.2016H6.21981Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { makeAutoObservable } from 'mobx';
import { Brand, Categories, InitialDataQuery, Project } from '../gql/generated';
import RootStore from '@stores/root';

export class ProjectStore {
  name: string;
  id: string;
  brand: Brand;
  postfix: string | null;
  domain = 'atol.atol-market.ru';
  title: string;
  hostName = 'atol-market';
  pathname = '/';
  type: 'brand' | 'type' | null = null;
  countOffers = 0;
  sellersCount = 0;
  productsCount = 0;
  favicon: {
    path: '';
  };
  phone: string | null;
  yandexVerification: string | null;
  googleVerification: string | null;
  yandexMetrika: string | null;
  googleAnalytics: string | null;
  monogeo: string | null;
  matomoId: number | null;
  clid: number | null;
  mailTop: string | null;
  updatedAt: Date | null;

  categories: InitialDataQuery['categories'] = [];

  root: RootStore;

  constructor(root: RootStore) {
    makeAutoObservable(this);
    this.root = root;
  }

  hydrate = (
    data: Partial<Project>,
    categories: Partial<Categories>,
    brand: Brand,
    host: string,
    pathname: string
  ) => {
    const [zone, hostName] = host.split('.').reverse();

    Object.assign(this, { ...data, categories, brand, hostName, pathname });
    this.updatedAt = new Date(data.updatedAt);
  };

  get logoLetters() {
    const splittedName = this.hostName.split('-');

    if (splittedName.length === 1) {
      return splittedName[0][0];
    }

    return splittedName[0][0] + splittedName[1][0];
  }
}

import React from 'react';
import { formatPlural } from 'utils/formaters';
import * as S from './rating-stars.style';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores/root';
import styled from 'styled-components';

type Props = {
  rating: number;
  votes?: number;
  hideVotes?: boolean;
  hideRating?: boolean;
  coeff?: number;
  hideStarsOnTabletM?: boolean;
  hideStarsOnTabletS?: boolean;
  hideStarsOnMobileM?: boolean;
  hideStarsOnMobileS?: boolean;
  className?: string;
};

const Component = observer((props: Props) => {
  const { ui } = useStore();
  const plural = formatPlural(props.votes, ['оценка', 'оценки', 'оценок']);

  const STAR_WIDTH = props.coeff ? 17 * props.coeff : 17;
  const STAR_MARGIN = props.coeff ? 7 * props.coeff : 7;

  const rating = Number(props.rating.toFixed(1));
  const starsCount = Math.floor(rating);
  const lastStarPart = rating - starsCount;
  const lastStarPartWidth = lastStarPart === 0 ? 0 : STAR_WIDTH * lastStarPart;
  const spanWidth =
    starsCount * STAR_WIDTH + (starsCount - 1) * STAR_MARGIN + lastStarPartWidth + STAR_MARGIN;

  /**
   * Пояснение:
   *
   * STAR_WIDTH - длина звёздочки в пикселях (взято из Figma)
   * STAR_MARGIN - длина отступа между звёздочками в пикселях (взято из Figma)
   *
   * starsCount - количество звёздочек, которые будут полностью заполнены
   *
   * lastStarPart - закрашенная часть последней звёздочки, выраженная в десятичной дроби
   *
   * lastStarPartWidth - длинна последней части звёздочки, выраженная в пикселях.
   * В случае, если lastStarPart будет равняться нулю, то мы просто приравниваем его к STAR_WIDTH, т.к. иначе в результате умножения получим 0
   *
   * spanWidth - конечная длина закрашиваемых звёздочек:
   * Мы умножаем количество закрашенных звёздочек на их длинну starsCount * STAR_WIDTH
   * Затем к этому мы прибавляем отступы между звёздочками (starsCount - 1) * STAR_MARGIN
   * После чего прибавляем длинну последней звёздочки, а также отступ до неё (lastStarPartWidth + STAR_MARGIN)
   */

  return (
    <S.Rating className={props.className}>
      <S.Stars
        hideStarsOnTabletM={props.hideStarsOnTabletM}
        hideStarsOnTabletS={props.hideStarsOnTabletS}
        hideStarsOnMobileM={props.hideStarsOnMobileM}
        hideStarsOnMobileS={props.hideStarsOnMobileS}
        className={props.hideStarsOnMobileM && 'hideStarsOnMobileM'}
        coeff={props.coeff}
      >
        <span style={{ width: props.rating > 0 ? spanWidth : 0 }}>
          <span />
        </span>
      </S.Stars>

      {!props.hideRating && <S.RatingNumber>{props.rating.toFixed(1)}</S.RatingNumber>}

      {!props.hideVotes && (
        <S.Votes>
          {props.votes} {plural}
        </S.Votes>
      )}
    </S.Rating>
  );
});

export const RatingStars = styled(Component)``;

import React from "react";

export const IconDelivery = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 2.75L11.3354 2.07918L11 1.91147L10.6646 2.07918L11 2.75ZM19.25 6.875H20V6.41147L19.5854 6.20418L19.25 6.875ZM19.25 15.125L19.5854 15.7958L20 15.5885V15.125H19.25ZM11 19.25L10.6646 19.9208L11 20.0885L11.3354 19.9208L11 19.25ZM2.75 15.125H2V15.5885L2.41459 15.7958L2.75 15.125ZM2.75 6.875L2.41459 6.20418L2 6.41147V6.875H2.75ZM14.8333 11.9167C14.8333 12.3309 15.1691 12.6667 15.5833 12.6667C15.9975 12.6667 16.3333 12.3309 16.3333 11.9167H14.8333ZM15.5833 8.70833H16.3333V8.24481L15.9187 8.03751L15.5833 8.70833ZM7.66874 3.91251C7.29826 3.72727 6.84775 3.87744 6.66251 4.24792C6.47727 4.61841 6.62744 5.06891 6.99792 5.25415L7.66874 3.91251ZM10.25 18.7917C10.25 19.2059 10.5858 19.5417 11 19.5417C11.4142 19.5417 11.75 19.2059 11.75 18.7917H10.25ZM10.6646 3.42082L18.9146 7.54582L19.5854 6.20418L11.3354 2.07918L10.6646 3.42082ZM18.5 6.875V15.125H20V6.875H18.5ZM18.9146 14.4542L10.6646 18.5792L11.3354 19.9208L19.5854 15.7958L18.9146 14.4542ZM11.3354 18.5792L3.08541 14.4542L2.41459 15.7958L10.6646 19.9208L11.3354 18.5792ZM3.5 15.125V6.875H2V15.125H3.5ZM3.08541 7.54582L11.3354 3.42082L10.6646 2.07918L2.41459 6.20418L3.08541 7.54582ZM18.9146 6.20418L10.6646 10.3292L11.3354 11.6708L19.5854 7.54582L18.9146 6.20418ZM11.3354 10.3292L3.08541 6.20418L2.41459 7.54582L10.6646 11.6708L11.3354 10.3292ZM16.3333 11.9167V8.70833H14.8333V11.9167H16.3333ZM15.9187 8.03751L7.66874 3.91251L6.99792 5.25415L15.2479 9.37915L15.9187 8.03751ZM11.75 18.7917V11H10.25V18.7917H11.75Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { createContext, useContext } from 'react';
import { CartStore } from './cart';
import { enableStaticRendering } from 'mobx-react-lite';
import FavoritesStore from '@stores/favorites';
import GeoStore from '@stores/geo';
import { ModalStore } from '@stores/modal';
import { ProjectStore } from '@stores/project';
import LastViewedStore from '@stores/lastViewed';
import { UIStore } from '@stores/ui';
import CookiesStore from '@stores/cookies';
import { ProductCardType } from '@components/product/elements/product-card/product-card';
import { CurrentGeoQuery } from '../gql/generated';
import { IncomingHttpHeaders } from 'http2';

const { makeAutoObservable } = require('mobx');

enableStaticRendering(typeof window === 'undefined');

export default class RootStore {
  favorites: FavoritesStore;
  geo: GeoStore;
  lastViewed: LastViewedStore;
  project: ProjectStore;
  modal = new ModalStore();
  cart = new CartStore();
  ui = new UIStore();
  cookie = new CookiesStore();

  constructor() {
    this.project = new ProjectStore(this);
    this.geo = new GeoStore(this);
    this.lastViewed = new LastViewedStore(this);
    this.favorites = new FavoritesStore(this);

    makeAutoObservable(this);
  }

  hydrate = (
    host: string,
    cookies: Record<string, unknown>,
    userAgent: string,
    pathname: string,
    geo: CurrentGeoQuery['geo'],
    headers: IncomingHttpHeaders,
    data?
  ) => {
    this.project.hydrate(data.project, data.categories, data.brand, host, pathname);
    this.geo.hydrate(geo, host, headers);
    this.cookie.hydrate(cookies);
    this.ui.hydrate(userAgent);
  };
}

const StoreContext = createContext(undefined);

export const useStore = (): RootStore => useContext(StoreContext);

export const withStore = (Component) => (props) => {
  return <Component {...props} store={useStore()} />;
};

let store: RootStore;

// function to initialize the store
export function initializeStore(
  host: string,
  initialData,
  cookies: Record<string, unknown>,
  userAgent: string,
  pathname: string,
  geo: CurrentGeoQuery['geo'],
  headers: IncomingHttpHeaders
): RootStore {
  const _store = store ?? new RootStore();

  if (initialData) {
    _store.hydrate(host, cookies, userAgent, pathname, geo, headers, initialData);
  }

  if (typeof window === 'undefined') return _store;

  if (!store) store = _store;

  return _store;
}

export const StoreProvider = ({
  children,
  initialData,
  host,
  cookies,
  userAgent,
  pathname,
  geo,
  headers,
}) => {
  const store = initializeStore(host, initialData, cookies, userAgent, pathname, geo, headers);
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

export { store };

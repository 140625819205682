import React from "react";
import * as S from "./footer-anchor.style";
import { useScrollPercent } from "hooks/useScrollPercent";
import { IconLongArrow } from "@components/ui/icons/icon-longArrow";
import { useStore } from "@stores/root";
import { useRouter } from "next/router";
import { observer } from "mobx-react-lite";

type Props = {
  viewport: number;
};

export const FooterAnchor: FCC<Props> = observer((props) => {
  const { pathname } = useRouter();
  const { ui } = useStore();
  const percent = useScrollPercent();

  if (props.viewport < 480) return null;

  return Math.floor(percent) > 25 ? (
    <S.StyledFooterAnchor largerBottom={pathname.indexOf('/p') >= 0 && ui.viewport <= 767} href="#">
      <IconLongArrow />
    </S.StyledFooterAnchor>
  ) : null;
});

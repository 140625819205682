import styled from 'styled-components';
import { scrollbar, theme } from '@components/ui/theme';
import { Icon } from '@components/ui/icons/icon';

export const MobileMenuItems = styled.div`
  padding-bottom: 80px;
  margin-top: 30px;
  max-height: calc(100% - 68px);
  overflow: auto;
  ${scrollbar.disable};

  @media (max-width: 480px) {
    margin-top: 25px;
  }
`;

export const MobileMenuItem = styled.a`
  font-size: 17px;
  line-height: 120%;
  color: ${theme.colors.black};
  border-bottom: 1px solid #f9f7fa;
  padding-bottom: 15px;

  display: flex;
  align-items: center;

  ${Icon} {
    margin-right: 15px;
    margin-top: -1px;
    color: ${theme.colors.black};
    svg {
      width: 23px;
      height: 23px;
    }
  }

  &:not(:first-child) {
    margin-top: 20px;
  }

  @media (max-width: 480px) {
    padding-bottom: 10px;
    font-size: 15px;
    line-height: 21px;

    ${Icon} {
      margin-right: 10px;
    }

    &:not(:first-child) {
      margin-top: 15px;
    }
  }
`;

export const MobileMenuItemArrow = styled.div`
  margin-left: auto;

  ${Icon} svg {
    width: 22px;
    height: 22px;
  }
`;

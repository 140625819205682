import { IconThumbDown } from "@components/ui/icons/icon-thumb-down";
import { IconThumbUp } from "@components/ui/icons/icon-thumb-up";
import React from "react";
import * as S from "./likes.style";

type Props = {
  likesCount: number;
  dislikesCount: number;
};

export const Likes = (props: Props) => {
  return (
    <S.StyledLikes>
      <S.LikeBlock>
        <S.LikeIcon>
          <IconThumbUp />
        </S.LikeIcon>
        <S.LikeBox>
          <S.LikeBoxText>+{props.likesCount}</S.LikeBoxText>
        </S.LikeBox>
      </S.LikeBlock>

      <S.LikeBlock dislike>
        <S.LikeIcon>
          <IconThumbDown />
        </S.LikeIcon>
        <S.LikeBox>
          <S.LikeBoxText>+{props.dislikesCount}</S.LikeBoxText>
        </S.LikeBox>
      </S.LikeBlock>
    </S.StyledLikes>
  );
};

import React from "react";
import * as S from "./header-text.style";

type Props = {
  className?: string;
};

export const CategoryHeaderText: FCC<Props> = (props) => {
  return (
    <S.StyledHeaderText className={props.className}>
      <S.CategoryHeaderDescription>{props.children}</S.CategoryHeaderDescription>
    </S.StyledHeaderText>
  );
};

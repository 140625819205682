import { makeAutoObservable, observable, runInAction } from "mobx";
import { ListType } from "../pages/c/[slug]";
import { setCookie } from "cookies-next";
import { getDomain } from "../utils/domain";
import cookie from "js-cookie";

export type ListKeys =
  | 'categoryMainProducts'
  | 'categoryVariations'
  | 'tagProducts'
  | 'searchProducts'
  | 'catalogProducts'
  | 'headerMenuProducts'
  | 'mainPageProductCategories'
  | 'productVariations';

export default class CookiesStore {
  @observable listTypes: Record<ListKeys, ListType> = {
    categoryMainProducts: ListType.LARGE,
    categoryVariations: ListType.LIST,
    tagProducts: ListType.LARGE,
    searchProducts: ListType.CARDS,
    catalogProducts: ListType.CARDS,
    headerMenuProducts: ListType.CARDS,
    mainPageProductCategories: ListType.CARDS,
    productVariations: ListType.LIST,
  };

  constructor() {
    makeAutoObservable(this);
  }

  hydrate(cookies: Record<string, unknown>) {
    const cookiesToUse = process.browser ? cookie.get() : cookies;
    const listTypesKeys = new Set(Object.keys(this.listTypes));

    runInAction(() => {
      Object.keys(cookiesToUse).forEach((key) => {
        if (listTypesKeys.has(key)) {
          this.listTypes[key] = Number(cookiesToUse[key]);
        }
      });
    });
  }

  setListType = (key: ListKeys, listType: ListType) => {
    this.listTypes[key] = listType;
    setCookie(key, String(listType), {
      path: '/',
      domain: getDomain(),
      maxAge: 60 * 60 * 24 * 30 * 12,
    });
  };
}

import styled from 'styled-components';
import { theme } from '@components/ui/theme';
import { Icon } from '@components/ui/icons/icon';
import { Label } from '@components/ui/label/label';
import { StyledCountPicker } from '@components/ui/count-picker/count-picker.style';
import { RatingStars } from '@components/ui/rating-stars/rating-stars';

export const ProductLargeImage = styled.a`
  padding: 20px 40px;
  min-width: 350px;
  height: 264px;
  margin-right: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProductLargeFavorites = styled.div`
  color: #9ca0a9;
  position: absolute;
  top: 10px;
  right: 0;

  display: none;

  @media (max-width: 550px) {
    display: block;
  }
`;

export const ProductLargeLabels = styled.div`
  z-index: 2;
  position: absolute;
  top: 40px;
  left: 0;

  @media (min-width: 1051px) {
    top: unset;
  }

  ${Label} {
    &:not(:first-child) {
      margin-top: 6px;
    }
  }
`;

export const ProductLargeInfo = styled.div`
  margin-right: 90px;
  flex: 1;

  @media (max-width: 480px) {
    margin-top: 10px;
  }
`;

export const ProductLargeTitle = styled.div`
  margin-bottom: 17px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${theme.colors.black};
  cursor: pointer;
`;

export const ProductLargeRating = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  overflow: hidden;

  ${RatingStars} {
    margin-right: 17px;
  }
`;

export const ProductLargeRatingValue = styled.a`
  color: #9ca0a9;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;

  ${Icon} svg {
    width: 20px;
    height: auto;
  }

  &.favorites,
  .reviews {
    cursor: pointer;

    &:hover {
      color: ${theme.colors.primary};

      svg path {
        fill: ${theme.colors.primary};
      }
    }

    &.active {
      color: ${theme.colors.primary};
    }
  }

  &:not(:last-child) {
    margin-right: 17px;
  }

  &:not(.primary) ${Icon} {
    margin-right: 4px;
  }

  &.primary {
    color: ${theme.colors.primary};

    svg {
      width: 16px;
    }

    span {
      margin-left: 6px;
      color: ${theme.colors.black};
    }
  }

  &.clickable {
    cursor: pointer ${Icon} svg {
      width: 24px;
      height: 21px;
    }
  }
`;

export const ProductLargeRatingStar = styled.div`
  color: #e54e8d;

  span {
    margin-left: 6px;
    color: ${theme.colors.black};
  }
`;

export const ProductLargeDescription = styled.div`
  margin-bottom: 10px;
`;

export const ProductLargeDescriptionText = styled.div`
  color: #686a70;
  font-size: 13px;
  line-height: 150%;
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    color: ${theme.colors.primary};
    cursor: pointer;
    display: block;
    margin-top: 5px;
  }
`;

export const ProductLargeProperties = styled.ul``;

export const ProductLargeProperty = styled.li`
  font-size: 13px;
  line-height: 18px;
  color: #686a70;

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`;

export const ProductLargePrice = styled.div``;

export const ProductLargePriceResponsive = styled.div`
  display: none;
`;

export const ProductLargePriceMain = styled.div`
  margin-bottom: 6px;
  display: flex;
  align-items: center;
`;

export const ProductLargePriceValue = styled.div`
  margin-right: 15px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: ${theme.colors.black};
`;

export const ProductLargePriceDiscount = styled.div`
  color: #686a70;
  font-size: 17px;
  line-height: 20px;
  text-decoration: line-through;

  @media (max-width: 480px) {
    display: none;
  }
`;

export const ProductLargePriceRange = styled.div`
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 15px;
  color: #686a70;
`;

export const ProductLargePriceOffers = styled.div`
  margin-bottom: 20px;
  line-height: 21px;
  color: ${theme.colors.black};
  font-size: 15px;
  position: relative;

  > div > div {
    border-bottom: 1px dotted ${theme.colors.black};
    line-height: 18px;
  }

  @media (max-width: 768px) {
    > div > div {
      border: none;
      color: #686a70;
    }
  }
`;

export const ProductLargePriceToCart = styled.div`
  margin-top: 15px;
  width: min-content;

  @media (min-width: 551px) {
    ${StyledCountPicker} {
      height: 42px;

      button {
        height: 100%;
      }
    }
  }
`;

export const ProductLargeSubtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 19px;
  color: ${theme.colors.black};
`;

export const StyledProductLarge = styled.article<{
  isSingle: boolean;
  priceMargin: number;
  noContent: boolean;
}>`
  width: 100%;
  position: relative;
  padding: 40px 0;
  display: flex;
  align-items: flex-start;

  button.responsive {
    display: none;
  }

  border-bottom: 1px solid #d9dde7;

  // &:last-of-type {
  //   border-bottom: ${({ isSingle }) => (isSingle ? 'none' : '1px solid #f1eff2')};
  // }

  &:hover {
    ${ProductLargeTitle} {
      color: ${theme.colors.primary};
    }
  }

  @media (min-width: 891px) {
    &.negativeMargin ${ProductLargeImage} {
      height: 192px;
    }
  }

  @media (max-width: 1050px) {
    padding: 25px 0;

    &:first-child {
      ${ProductLargeLabels} {
        top: 0;
      }
      padding: 0 0 25px 0;
    }

    ${ProductLargeLabels} {
      top: 25px;
    }

    ${ProductLargeInfo} {
      margin-right: 50px;
    }

    ${ProductLargeImage} {
      margin-right: 20px;
      min-width: 250px;
      width: 250px;
      height: 188px;
      align-items: flex-start;

      img {
        width: 200px !important;
        height: 158px !important;
      }
    }

    ${ProductLargeRatingValue} {
      &:first-child {
        margin-right: 10px;
      }

      &:not(:first-child):not(:last-child) {
        margin-right: 15px;
      }
    }

    ${ProductLargePriceValue} {
      font-size: 20px;
    }
    ${ProductLargePriceDiscount} {
      font-size: 15px;
    }
    ${ProductLargePriceRange}, ${ProductLargePriceOffers} {
      font-size: 12px;
    }

    ${ProductLargePriceOffers} {
      margin-bottom: 20px;
    }
    ${ProductLargeTitle} {
      font-size: 18px;
    }
    ${ProductLargeRatingValue}:not(.primary) svg {
      width: 22px;
      height: 22px;
    }
  }

  @media (max-width: 890px) {
    flex-wrap: wrap;
    ${ProductLargeImage} {
      align-self: flex-start;
      margin-right: 20px;
      width: 230px;
      height: 188px;
    }

    ${ProductLargeInfo} {
      flex-basis: calc(100% - 270px);
      margin-right: 0;
      width: auto;
    }

    ${ProductLargeTitle}, ${ProductLargePriceMain} {
      margin-bottom: 10px;
    }
    ${ProductLargeDescriptionText}, ${ProductLargeProperty} {
      font-size: 14px;
    }
    ${ProductLargeDescriptionText} {
      line-height: 140%;
    }
    ${ProductLargePriceRange} {
      margin-bottom: 5px;
    }

    ${ProductLargePrice} {
      margin-top: 20px;
      margin-left: 270px;
    }

    &.negativeMargin {
      ${ProductLargePrice} {
        margin-top: ${({ priceMargin }) => `-${priceMargin}px`};
      }
      ${ProductLargeRating} {
        margin-bottom: ${({ noContent }) => (noContent ? '0' : '20px')};
      }
    }
  }

  @media (max-width: 768px) {
    padding: 25px 0;

    ${ProductLargeLabels} {
      top: 25px;
    }
  }

  @media (max-width: 650px) {
    ${ProductLargeRating} {
      flex-wrap: wrap;
    }
  }

  @media (max-width: 550px) {
    //padding: 0;
    //border: none;
    flex-direction: column;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: 25px;
      //border: none;
    }
    &:first-child {
      //padding-bottom: 0;
    }

    button.responsive {
      margin-top: 15px;
      display: block;
    }

    ${ProductLargeImage} {
      padding: 20px 0 0 0;
      align-self: center;
      margin-right: 0;
      margin-bottom: 20px;
      width: 160px;
      height: 160px;
      min-width: unset;

      &.noImage {
        transform: scale(0.7);
      }
    }

    ${ProductLargeTitle} {
      margin-bottom: 7px;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
    }

    ${ProductLargeInfo} {
      margin-right: 0;
      width: 100%;
      max-width: 100%;
    }

    ${ProductLargeDescriptionText}, ${ProductLargeProperty} {
      font-size: 13px;
    }

    ${ProductLargePrice} {
      display: none;
    }

    ${ProductLargePriceResponsive} {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      ${ProductLargePriceMain} {
        margin-bottom: 0;
        flex-direction: column;
        align-items: flex-start;
      }

      ${ProductLargePriceValue} {
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
        margin-right: 0;
        display: flex;
        flex-direction: column-reverse;

        ${ProductLargePriceDiscount} {
          font-size: 11px;
          line-height: 140%;
          margin-left: 0;
          font-weight: 400;
        }
      }

      ${ProductLargePriceRange} {
        margin-bottom: 0;
        font-size: 11px;
        line-height: 15px;
      }

      ${ProductLargePriceToCart} {
        margin-top: 0;
      }
    }

    ${ProductLargeRating} {
      margin-bottom: 12px;

      ${ProductLargeRatingValue} {
        &.clickable {
          display: none;
        }

        &:not(:last-child) {
          margin-right: 13px;
        }
        &:first-child {
          margin-right: 6px;
        }
      }
    }

    ${ProductLargeLabels}, ${ProductLargeFavorites} {
      top: 0;
    }

    ${ProductLargeDescription} {
      -webkit-line-clamp: 3;
    }

    ${ProductLargeDescriptionText} {
      line-height: 150%;
    }

    ${ProductLargeSubtitle} {
      margin-bottom: 10px;
      font-size: 13px;
    }

    ${ProductLargeProperties} {
      margin-top: 15px;
    }

    ${ProductLargeProperty}:not(:last-child) {
      margin-bottom: 7px;
    }

    ${ProductLargePriceMain} {
      margin-top: 20px;
    }
  }

  @media (max-width: 480px) {
    ${ProductLargePriceValue} {
      margin-bottom: 3px;
    }

    ${ProductLargeTitle} {
      font-size: 16px;
    }
  }

  @media (max-width: 400px) {
    ${ProductLargeDescriptionText}, ${ProductLargeProperty} {
      font-size: 13px;
    }
  }
`;

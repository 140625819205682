import React from "react";

type Props = {
  list: {
    url: string;
    name: string;
  }[];
};

export const BreadcrumbsSchema = (props: Props) => {
  return (
    <span className="hidden" itemScope itemType="https://schema.org/BreadcrumbList">
      {props.list.map((item, index) => (
        <span
          key={index}
          itemProp="itemListElement"
          itemScope
          itemType="https://schema.org/ListItem"
        >
          {index === props.list.length - 1 ? (
            <>
              <span itemProp="name">{item.name}</span>
              <span itemProp="position">{index + 1}</span>
            </>
          ) : (
            <>
              <a itemProp="item" href={item.url}>
                <span itemProp="name">{item.name}</span>
              </a>
              <span itemProp="position">{index + 1}</span>
            </>
          )}
        </span>
      ))}
    </span>
  );
};

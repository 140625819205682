import React from "react";
import * as S from "./about.style";
import { useStore } from "@stores/root";
import { AnchorLinkButton } from "@components/main/elements/anchor-link-button/anchor-link-button";
import { WH } from '@components/WH';

type Props = {
  showTitle?: boolean;
  text?: string;
};

const defaultText = `Каталог описаний и цен на шрифты, картриджи, терминалы сбора данных. Наша задача — помочь подобрать и купить нужную модель по лучшей цене в интернет-магазинах. В каталоге можно найти всю необходимую для выбора информацию — подробные описания, поиск товара по названию, отзывы пользователей, фотогалереи товаров, обзоры, инструкции, рейтинг товаров и многое другое. Перепечатка любых материалов разрешена только с письменного согласия редакции.`;

export const About = (props: Props) => {
  const { project } = useStore();
  const domain = `${project.domain[0].toUpperCase()}${project.domain.slice(1)}`

  return (
    <S.StyledAbout>
      {props.showTitle && (
        <S.MainAboutTitle>
          <span>{domain}</span> — <br/> cпециализированный маркетплейс
        </S.MainAboutTitle>
      )}
      <WH>
        <S.MainAboutText>{props.text ?? defaultText}</S.MainAboutText>
      </WH>

      <AnchorLinkButton />
    </S.StyledAbout>
  );
};

import styled from 'styled-components';

export const SelectIcon = styled.div`
  margin-right: 12px;
`;

export const StyledSelect = styled.div`
  width: 176px;
  height: 24px;

  display: flex;
  align-items: center;

  div {
    cursor: pointer;
  }
`;

import React, { useEffect, useState } from 'react';
import * as S from './videos.style';
import { BlockTitle } from '@components/ui/block-title/block-title';
import { Grid } from '@components/ui/grid/grid';
import { MainVideo } from '@components/main/elements/video/video';
import { ShowMoreButton } from '@components/ui/show-more-button/show-more-button';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores/root';

type Props = {
  videos: Video[];
  title?: string;
  className?: string;
  isKeyword?: boolean;
};

const calculateSliceCount = (viewport: number) => {
  if (viewport >= 769)
    return {
      state: 6,
      toShow: 6,
    };

  if (viewport >= 501)
    return {
      state: 4,
      toShow: 4,
    };

  return {
    state: 4,
    toShow: 2,
  };
};

export const Component = observer((props: Props) => {
  const { ui } = useStore();
  const [sliceCount, setSliceCount] = useState(calculateSliceCount(ui.viewport).state);
  const [videosToShow, setVideosToShow] = useState<Video[]>(
    props.videos.slice(1, calculateSliceCount(ui.viewport).state + 1)
  );

  const [firstVideo] = props.videos;

  useEffect(() => {
    setVideosToShow(props.videos.slice(1, sliceCount));
  }, [sliceCount, props.videos]);

  useEffect(() => {
    setSliceCount(calculateSliceCount(ui.viewport).state + 1);
  }, [ui.viewport]);

  const showMore = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setSliceCount(sliceCount + calculateSliceCount(ui.viewport).toShow);
  };

  return (
    <S.StyledMainVideos className={props.className}>
      <BlockTitle>{props.title ? props.title : 'Видео'}</BlockTitle>

      {!!firstVideo && (
        <S.MainVideosEmbeded>
          <iframe
            src={`https://www.youtube.com/embed/${firstVideo.id}`}
            title={`${firstVideo.title}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </S.MainVideosEmbeded>
      )}

      <S.MainVideosContent>
        <Grid>
          {videosToShow.map((video, index) => (
            <MainVideo key={video.id + index} {...video} isKeyword={props.isKeyword} />
          ))}
        </Grid>

        {props.videos.length > sliceCount && (
          <ShowMoreButton onClick={showMore} href="#">
            Показать ещё
          </ShowMoreButton>
        )}
      </S.MainVideosContent>
    </S.StyledMainVideos>
  );
});

export const MainVideos = styled(Component)``;

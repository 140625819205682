import styled from 'styled-components';
import { theme } from '@components/ui/theme';

export const StyledPopoverProductItem = styled.div`
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-bottom: 1px solid #efedf0;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ProductThumb = styled.a`
  margin-left: 25px;
  margin-right: 20px;
  min-width: 58px;
  width: 58px;
  height: 58px;
  grid-column: 1;
  grid-row: span 3;
  object-fit: scale-down;
  border-radius: 3px;
  position: relative;
`;

export const ProductName = styled.a`
  width: 220px;
  font-size: 15px;
  line-height: 140%;
  color: ${theme.colors.black};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ProductPrice = styled.div`
  margin-right: 15px;
  margin-left: auto;
  width: max-content;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  color: ${theme.colors.black};
`;

export const ProductRemoveButton = styled.div`
  color: ${theme.colors.greyPrimary};
  cursor: pointer;

  &:hover {
    color: ${theme.colors.primary};
  }
`;

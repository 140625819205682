import React from "react";
import ReactTooltip from "react-tooltip";
import * as S from "./tooltip.style";
import { NoSSR } from "@components/NoSSR";

export const Tooltip = () => {
  return (
    <S.StyledTooltip>
      <NoSSR>
        <ReactTooltip delayShow={200} effect="solid" className="customTooltip" />
      </NoSSR>
    </S.StyledTooltip>
  );
};

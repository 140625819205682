import { makeAutoObservable, runInAction } from 'mobx';
import type { ProductCardType } from '@components/product/elements/product-card/product-card';
import { fetchProducts } from '../utils/products';
import { waitAsync } from '../utils/wait';
import RootStore from '@stores/root';

const MAX_ITEMS = 10;

export default class LastViewedStore {
  _products: Map<string, ProductCardType> = new Map();
  initialized = false;

  root: RootStore;

  constructor(root: RootStore) {
    this.root = root;
    makeAutoObservable(this);

    if (!process.browser) return;

    window.addEventListener('storage', this.syncData, {
      capture: false,
      once: false,
      passive: true,
    });

    this.getData();
  }

  hydrate(lastViewed: ProductCardType[]) {
    const newMap = new Map<string, ProductCardType>();

    for (const product of lastViewed) {
      newMap.set(product.id, product);
    }

    this._products = newMap;
    this.initialized = true;
  }

  /**
   * Количество позиций
   */
  get count(): number {
    return this._products.size;
  }

  get sum() {
    return this.products.reduce((accum, curr) => accum + curr.price, 0);
  }

  get products(): ProductCardType[] {
    return [...this._products.values()];
  }

  get productsReversed(): ProductCardType[] {
    return [...this._products.values()].reverse();
  }

  /**
   * Добавить в избранное
   * @param product
   */
  async add(product: ProductCardType): Promise<void> {
    while (!this.initialized) {
      await waitAsync(100);
    }

    const alreadyHasProduct = this._products.has(product.id);

    if (alreadyHasProduct) {
      runInAction(() => {
        this._products.delete(product.id);
      });
    }

    if (this._products.size >= MAX_ITEMS) {
      runInAction(() => {
        this._products.delete(this.products[0].id);
      });
    }

    runInAction(() => {
      this._products.set(product.id, product);
    });

    this.saveData();
  }

  /**
   * Проверить наличие в избранном
   * @param productOrID
   */
  has(productOrID: ProductCardType | string): boolean {
    const id = typeof productOrID === 'string' ? productOrID : productOrID.id;
    return this._products.has(id);
  }

  /**
   * Убрать из избранного
   * @param productOrID
   */
  remove(productOrID: ProductCardType | string): void {
    const id = typeof productOrID === 'string' ? productOrID : productOrID.id;

    this._products.delete(id);

    this.saveData();
  }

  /**
   * Вызывается, когда извне был изменен localStorage, синкаем избранное
   * @param event
   * @private
   */
  protected syncData = (event: StorageEvent): void => {
    if (event.storageArea !== localStorage || event.key !== 'lastViewed') {
      return;
    }

    this.getData();
  };

  protected async getData(): Promise<void> {
    try {
      const ids: string[] = JSON.parse(window.localStorage.getItem('lastViewed') ?? '[]');
      const newMap = new Map<string, ProductCardType>(this._products);

      const products = await fetchProducts(ids);

      const sortedProducts = products.reduce((accum, curr) => {
        const index = ids.indexOf(curr.id);
        accum[index] = curr;

        return accum;
      }, []);

      for (const product of sortedProducts) {
        newMap.set(product.id, product);
      }

      runInAction(() => {
        this._products = newMap;
        this.initialized = true;
      });
    } catch {
      return;
    }
  }

  /**
   * Сохраняем изменения
   * @private
   */
  protected saveData(): void {
    const data = JSON.stringify([...this._products.values()].map(({ id }) => id));
    window.localStorage.setItem('lastViewed', data);
  }
}

import styled from 'styled-components';
import { theme } from '@components/ui/theme';

export const StyledTapBar = styled.div`
  width: 100%;
  height: 74px;
  position: fixed;
  align-items: center;
  justify-content: space-around;
  bottom: 0;
  box-shadow: 0px -1px 12px rgba(118, 104, 122, 0.15);
  z-index: 6;
  background: white;
  padding: 0px 16px;

  display: none;

  @media (max-width: 992px) {
    display: flex;
  }

  @media (max-width: 480px) {
    height: 68px;
  }
`;

export const TapBarItemIcon = styled.div`
  position: relative;

  svg {
    width: 30px;
    height: 30px;
  }
`;

export const TapBarItemIconValue = styled.div`
  padding-top: 1px;
  top: -2px;
  right: -2px;
  width: 17px;
  height: 17px;
  border: 1px solid #fff;
  font-weight: 500;
  border-radius: 50%;
  background-color: ${theme.colors.primary};
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: absolute;
`;

export const TapBarItemText = styled.div`
  font-size: 12px;

  color: ${theme.colors.greySecondary};
`;

export const TapBarItem = styled.a<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${TapBarItemIcon} {
    color: ${(props) => (props.active ? theme.colors.primary : 'black')};

    svg {
      path,
      rect {
        fill: ${(props) => (props.active ? theme.colors.primary : 'black')};
      }
    }
  }

  ${TapBarItemText} {
    color: ${(props) => (props.active ? theme.colors.primary : theme.colors.greySecondary)};
  }
`;

import React from "react";

export const IconWallet = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6668 5.83333V4.16667H3.33342V15.8333H16.6668V14.1667H10.0001C9.77907 14.1667 9.56711 14.0789 9.41083 13.9226C9.25455 13.7663 9.16675 13.5543 9.16675 13.3333V6.66667C9.16675 6.44565 9.25455 6.23369 9.41083 6.07741C9.56711 5.92113 9.77907 5.83333 10.0001 5.83333H16.6668ZM2.50008 2.5H17.5001C17.7211 2.5 17.9331 2.5878 18.0893 2.74408C18.2456 2.90036 18.3334 3.11232 18.3334 3.33333V16.6667C18.3334 16.8877 18.2456 17.0996 18.0893 17.2559C17.9331 17.4122 17.7211 17.5 17.5001 17.5H2.50008C2.27907 17.5 2.06711 17.4122 1.91083 17.2559C1.75455 17.0996 1.66675 16.8877 1.66675 16.6667V3.33333C1.66675 3.11232 1.75455 2.90036 1.91083 2.74408C2.06711 2.5878 2.27907 2.5 2.50008 2.5ZM10.8334 7.5V12.5H16.6668V7.5H10.8334ZM12.5001 9.16667H15.0001V10.8333H12.5001V9.16667Z"
        fill="currentColor"
      />
    </svg>
  );
};

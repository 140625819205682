import { StyledTag } from '@components/ui/tag/tag.style';
import { device, scrollbar } from '@components/ui/theme';
import styled from 'styled-components';

export const StyledCategoryTags = styled.section`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  max-width: 100%;
  position: relative;
  margin-top: 35px;
  padding-right: 65px;

  @media ${device.tabletM} {
    margin-top: 24px;
    overflow-x: scroll;
    max-width: unset;
    ${scrollbar.disable};
    ${scrollbar.alignSidesDesktop};

    flex-wrap: nowrap;
    align-items: center;

    ${StyledTag}, button {
      min-width: max-content;
    }
  }

  @media (max-width: 600px) {
    ${scrollbar.alignSidesMobile}
  }

  @media (max-width: 480px) {
    margin-top: 20px;
  }
`;
export const CategoryViewSelector = styled.div`
  position: absolute;
  right: 0;
  top: 8px;

  @media (max-width: 1460px) {
    display: none;
  }
`;

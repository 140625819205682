import React, { useCallback, useEffect, useState } from 'react';
import * as S from './products-filter.style';
import { useRouter } from 'next/router';
import debounce from 'lodash/debounce';
import { CategoryFilterInputs } from '@components/category/blocks/filter-inputs/filter-inputs';
import { Select } from '@components/ui/select/select';
import { SelectWithIcon } from '@components/ui/select-with-icon/select-with-icon';
import { LayoutButtons } from '@components/category/blocks/layout-buttons/layout-buttons';
import { Modal } from '@components/ui/modal/modal';
import { Icon } from '@components/ui/icons/icon';
import { Button } from '@components/ui/button/button';
import { useStore } from '@stores/root';
import { observer } from 'mobx-react-lite';
import { ListKeys } from '@stores/cookies';
import { Popover } from '@components/ui/popover/popover';
import { NoSSR } from '@components/NoSSR';

type Props = {
  priceRange: [number, number];
  useLargeCards?: boolean;
  canSubmit?: boolean;
  setCanSubmit?: React.Dispatch<boolean>;
  listKey: ListKeys;
  prefix?: string;
  id: number | string;
};

type RouterQuery = {
  perPage: number;
};

const perPageSelectOptions = [
  { value: 25, label: 'Показывать по 25' },
  { value: 50, label: 'Показывать по 50' },
  { value: 100, label: 'Показывать по 100' },
];

const sortOptions = [
  { value: 'offersCount', label: 'Сначала популярные', direction: 'desc' },
  { value: 'priceASC', label: 'Сначала дешевле', direction: 'asc' },
  { value: 'priceDESC', label: 'Сначала дороже', direction: 'desc' },
  { value: 'avgRating', label: 'Сначала с высоким рейтингом', direction: 'desc' },
];

export const ProductsFilter = observer(
  React.forwardRef((props: Props, ref: React.Ref<HTMLDivElement>) => {
    const router = useRouter();
    const { query } = router;
    const { ui } = useStore();
    const prefix = props.prefix ?? '';
    const [priceFrom, priceTo] = props.priceRange;
    const [searchPriceFrom, searchPriceTo] = [
      query[`${prefix}priceFrom`] ?? priceFrom,
      query[`${prefix}priceTo`] ?? priceTo,
    ];
    const [pricesRange, setPricesRange] = useState([priceFrom, priceTo]);
    const [searchPrices, setSearchPrices] = useState([
      Number(searchPriceFrom),
      Number(searchPriceTo),
    ]);

    const [perPage, setPerPage] = useState<SelectOption>(
      query[`${prefix}perPage`]
        ? perPageSelectOptions.find(
            (option) => option.value === Number(query[`${prefix}perPage`])
          ) ?? perPageSelectOptions[0]
        : perPageSelectOptions[0]
    );
    const [sort, setSort] = useState<SelectOption>(
      query[`${prefix}orderBy`]
        ? sortOptions.find((option) => {
            const isPrice =
              option.value.startsWith('price') &&
              option.value.replace('ASC', '').replace('DESC', '') ===
                (query[`${prefix}orderBy`] as string);

            if (isPrice) {
              return (
                `${query[`${prefix}orderBy`]}${query[`${prefix}direction`]
                  .toString()
                  .toUpperCase()}` === option.value
              );
            }

            return option.value === (query[`${prefix}orderBy`] as string);
          })
        : sortOptions[0]
    );
    const [isModal, setIsModal] = useState(false);
    const [finalQueries, setFinalQueries] = useState({});

    useEffect(() => {
      const [from, to] = searchPrices;

      setFinalQueries({
        [`${prefix}priceFrom`]: from,
        [`${prefix}priceTo`]: to,
        [`${prefix}perPage`]: perPage,
        ...getSortObject(sort),
      });
    }, [searchPriceFrom, searchPriceTo, perPage, sort.value, sort, searchPrices, pricesRange]);

    useEffect(() => {
      const [priceFrom, priceTo] = props.priceRange;
      const dataSource = ui.viewport <= 765 ? finalQueries : query;
      const [searchPriceFrom, searchPriceTo] = [
        dataSource[`${prefix}priceFrom`] ?? priceFrom,
        dataSource[`${prefix}priceTo`] ?? priceTo,
      ];
      setPricesRange([priceFrom, priceTo]);

      if (!props.canSubmit && ui.viewport <= 765) return;


      setSearchPrices([Number(searchPriceFrom), Number(searchPriceTo)]);

      setSort(
        dataSource[`${prefix}orderBy`]
          ? sortOptions.find((option) => {
              const isPrice =
                option.value.startsWith('price') &&
                option.value.replace('ASC', '').replace('DESC', '') ===
                  (dataSource[`${prefix}orderBy`] as string);

              if (isPrice) {
                return (
                  `${dataSource[`${prefix}orderBy`]}${query[`${prefix}direction`]
                    .toString()
                    .toUpperCase()}` === option.value
                );
              }

              return option.value === (dataSource[`${prefix}orderBy`] as string);
            })
          : sortOptions[0]
      );
      setPerPage(
        dataSource[`${prefix}perPage`]
          ? perPageSelectOptions.find(
              (option) => option.value === Number(dataSource[`${prefix}perPage`])
            ) ?? perPageSelectOptions[0]
          : perPageSelectOptions[0]
      );
    }, [props.id, query, props.priceRange]);

    const onPricesChange = (values: number[]) => {
      const [priceFrom, priceTo] = values;

      if (priceFrom === priceTo) return null;

      setSearchPrices(values);

      debouncedRouterQuery({
        ...router.query,
        [`${prefix}priceFrom`]: priceFrom,
        [`${prefix}priceTo`]: priceTo,
      });
    };

    const onPerPageChange = (data: SelectOption) => {
      setPerPage(data);
      props.setCanSubmit(true);
      if (ui.viewport <= 765) return;
      router.replace(
        { query: { ...router.query, [`${prefix}perPage`]: data.value, [`${prefix}page`]: 1 } },
        null,
        {
          shallow: true,
        }
      );
    };

    const getSortObject = (data: SelectOption) => {
      let sortObj = {};

      switch (data.value) {
        case 'priceASC':
          sortObj = {
            [`${prefix}orderBy`]: 'price',
            [`${prefix}direction`]: 'asc',
          };
          break;

        case 'priceDESC':
          sortObj = {
            [`${prefix}orderBy`]: 'price',
            [`${prefix}direction`]: 'desc',
          };
          break;

        case 'offersCount':
          sortObj = {
            [`${prefix}orderBy`]: 'offersCount',
            [`${prefix}direction`]: 'desc',
          };
          break;

        case 'avgRating':
          sortObj = {
            [`${prefix}orderBy`]: 'avgRating',
            [`${prefix}direction`]: 'desc',
          };
          break;

        default:
          sortObj = {
            [`${prefix}orderBy`]: data.value,
            [`${prefix}direction`]: 'asc',
          };
          break;
      }

      return sortObj;
    };

    const onSortChange = (data: SelectOption) => {
      setSort(data);

      props.setCanSubmit(true);
      router.replace(
        { query: { ...router.query, ...getSortObject(data), [`${prefix}page`]: 1 } },
        null,
        {
          shallow: true,
        }
      );
    };

    // const orderChangeHandler = (value: OrderingValue) => {
    //   router.push({ query: { ...router.query, ...value } }, {}, { shallow: true });
    // };

    const debouncedRouterQuery = useCallback(
      debounce((oldQuery, newQuery, disableReturn = false) => {
        props.setCanSubmit(true);
        if (ui.viewport <= 765 && !disableReturn) return;

        router.replace({ query: { ...oldQuery, ...newQuery, [`${prefix}page`]: 1 } }, null, {
          shallow: true,
        });
      }, 300),
      []
    );

    return (
      <S.StyledProductsFilter ref={ref}>
        {ui.viewport >= 700 &&
          (ui.viewport > 1000 ? (
            <CategoryFilterInputs
              pricesRange={pricesRange}
              searchPrices={searchPrices}
              onPricesChange={onPricesChange}
            />
          ) : (
            <Popover
              align="left"
              zIndex={3}
              target={
                <S.FilterModalButton className="price">
                  <Icon icon="equalizer" />
                  Фильтры по цене
                </S.FilterModalButton>
              }
            >
              <S.PopoverPriceFilters>
                <CategoryFilterInputs
                  pricesRange={pricesRange}
                  searchPrices={searchPrices}
                  onPricesChange={onPricesChange}
                />
              </S.PopoverPriceFilters>
            </Popover>
          ))}

        <S.FilterModalButton onClick={() => setIsModal(true)}>
          <Icon icon="equalizer" />
          Фильтры
        </S.FilterModalButton>

        {isModal && (
          <Modal withoutOverflow onClose={() => setIsModal(false)} title="Фильтры">
            <NoSSR>
              <S.FilterModal>
                <CategoryFilterInputs
                  pricesRange={pricesRange}
                  searchPrices={searchPrices}
                  onPricesChange={onPricesChange}
                />

                <Select
                  options={perPageSelectOptions}
                  onChange={onPerPageChange}
                  value={perPage}
                  isSearchable={false}
                />

                {props.setCanSubmit && (
                  <S.FilterModalFooter>
                    <Button
                      disabled={!props.canSubmit}
                      onClick={() => {
                        setIsModal(false);
                        debouncedRouterQuery(router.query, finalQueries, true);
                        props.setCanSubmit(false);
                      }}
                    >
                      Применить
                    </Button>
                  </S.FilterModalFooter>
                )}
              </S.FilterModal>
            </NoSSR>
          </Modal>
        )}

        <S.Right>
          <Select
            isSearchable={false}
            options={perPageSelectOptions}
            onChange={onPerPageChange}
            value={perPage}
          />
          <SelectWithIcon options={sortOptions} value={sort} onChange={onSortChange} />
          <LayoutButtons useLargeCards={props.useLargeCards} listKey={props.listKey} />
        </S.Right>
      </S.StyledProductsFilter>
    );
  })
);

import React from 'react';
import * as S from './label.style';
import styled from 'styled-components';

const colors = {
  purple: '#825BD3',
  pink: '#E54E8D',
};

type Props = {
  color: keyof typeof colors;
  className?: string;
};

const Component: FCC<Props> = (props) => {
  return (
    <S.StyledLabel color={colors[props.color]} className={props.className}>
      {props.children}
    </S.StyledLabel>
  );
};

export const Label = styled(Component)``;

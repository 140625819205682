import styled from 'styled-components';
import { theme } from '@components/ui/theme';

export const StyledCountPicker = styled.div`
  width: 107px;
  height: 32px;

  display: flex;

  background: #fafafa;
  border-radius: 3px;
  overflow: hidden;
`;

const CountPickerButton = styled.button`
  width: 32px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;

  border-radius: 3px;
`;

export const CountPickerButtonMinus = styled(CountPickerButton)`
  background: ${theme.colors.primary};

  &:hover {
    background: ${theme.colors.primaryHover};
  }

  &:disabled {
    background: #f9f7fa;
  }
`;

export const CountPickerButtonPlus = styled(CountPickerButton)`
  background: ${theme.colors.purple};

  &:hover {
    background: ${theme.colors.purpleHover};
  }

  &:disabled {
    background: #f9f7fa;
  }
`;

export const CountPickerNumber = styled.div`
  background: #fafafa;
  border-radius: 3px;
  flex: 1;
  font-size: 15px;
  line-height: 140%;
  color: ${theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: center;
`;

import React from "react";
import * as S from "./tags-block.style";
import { BlockTitle } from "@components/ui/block-title/block-title";
import { Tags } from "@components/blocks/tags/tags";
import { observer } from "mobx-react-lite";
import { useStore } from "@stores/root";

type Props = {
  tags: TagType[];
  title?: string;
  limit?: number;
  canShowMore?: boolean;
  linkPrefix?: string;
  isRelink?: boolean;
  calcLimits?: boolean;
  rowsLength?: number;
};

export const TagsBlock = observer((props: Props) => {
  const { ui } = useStore();

  return (
    <S.StyledTagsBlock>
      {props.title && <BlockTitle>{props.title}</BlockTitle>}
      <S.TagsList>
        <Tags
          tags={props.tags}
          limit={props.limit}
          canShowMore={props.canShowMore}
          linkPrefix={props.linkPrefix}
          isRelink={props.isRelink}
          rowsLengths={props.rowsLength}
        />
      </S.TagsList>
    </S.StyledTagsBlock>
  );
});

import React, { useMemo, useRef } from "react";
import * as S from "./last-viewed-products.style";
import { observer } from "mobx-react-lite";
import { useStore } from "@stores/root";
import { LastViewedCard } from "./last-viewed-card/last-viewed-card";
import { Swiper, SwiperSlide } from "swiper/react";
import { IconChevronLeft } from "@components/ui/icons/icon-chevron-left";
import { IconChevronRight } from "@components/ui/icons/icon-chevron-right";

type Props = {
  size: number;
  excluded: Set<string>;
};

export const LastViewedProductsSlider: FCC<Props> = observer((props) => {
  const { lastViewed, ui } = useStore();
  const prevButton = useRef(null);
  const nextButton = useRef(null);

  const generateProducts = useMemo(() => {
    const arr = [];
    const lw = lastViewed.products.filter((p) => !props.excluded.has(p.id));
    let tempArr = [];

    lw.forEach((product, idx) => {
      if (tempArr.length === 2) {
        arr.push(tempArr);
        tempArr = [];
      }

      tempArr.push(product);

      if (tempArr.length === 2) {
        arr.push(tempArr);
        tempArr = [];
      }

      if (idx === lw.length - 1 && tempArr.length > 0) arr.push([product]);
    });

    return arr;
  }, [props.excluded, lastViewed.products]);

  return (
    <S.LastViewedSliderContainer>
      <S.LastViewedItems isLarge={props.size > 4}>
        {ui.viewport > 1000 && props.size <= 4 ? (
          <>
            {lastViewed.products.map(
              (p) => !props.excluded.has(p.id) && <LastViewedCard key={p.id} {...p} />
            )}
          </>
        ) : ui.viewport > 1000 ? (
          <>
            <S.Prev ref={prevButton}>
              <IconChevronLeft />
            </S.Prev>
            <S.Next ref={nextButton}>
              <IconChevronRight />
            </S.Next>
            <Swiper
              slidesPerView={ui.viewport > 1089 ? 3 : 2}
              spaceBetween={40}
              navigation={{
                prevEl: prevButton.current,
                nextEl: nextButton.current,
              }}
              onBeforeInit={(swiper: any) => {
                swiper.params.navigation.prevEl = prevButton.current;
                swiper.params.navigation.nextEl = nextButton.current;
              }}
            >
              {generateProducts.map((products) => (
                <SwiperSlide key={`last-viewed-key-${Math.random()}`}>
                  <S.LastViewedItem>
                    <LastViewedCard {...products[0]} />
                    {products[1] && <LastViewedCard {...products[1]} />}
                  </S.LastViewedItem>
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        ) : (
          <>
            {generateProducts.map((products) => (
              <S.LastViewedItem key={`last-viewed-key-swipe-${Math.random()}`}>
                <LastViewedCard {...products[0]} />
                {products[1] && <LastViewedCard {...products[1]} />}
              </S.LastViewedItem>
            ))}
          </>
        )}
      </S.LastViewedItems>
    </S.LastViewedSliderContainer>
  );
});

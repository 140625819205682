import classNames from "classnames";
import React, { forwardRef, HTMLProps } from "react";
import styles from "./text-with-icon.module.css";

type Props = {
  icon: () => JSX.Element | null;
  className?: string;
  textClassName?: string;
  href?: string;
  onClick?(): void;
  notLink?: boolean;
} & HTMLProps<HTMLAnchorElement>;

export const TextWithIcon: FCC<Props> = forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  const containerClassNames = classNames(styles.container, props.className);
  const textClassName = classNames(styles.text, props.textClassName);

  if (props.notLink) {
    return (
      <div className={containerClassNames} onClick={props.onClick}>
        <div className={styles.icon}>
          <props.icon />
        </div>
        <div className={textClassName}>{props.children}</div>
      </div>
    );
  }

  return (
    <a ref={ref} href={props.href} className={containerClassNames} onClick={props.onClick}>
      <div className={styles.icon}>
        <props.icon />
      </div>
      <div className={textClassName}>{props.children}</div>
    </a>
  );
});

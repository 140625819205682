import styled from 'styled-components';
import { device, theme } from '@components/ui/theme';

export const InputElement = styled.input<{ disableClose: boolean }>`
  width: 100%;
  border: 1px solid #d9dde7;
  box-sizing: border-box;
  border-radius: 3px;
  padding: ${({ disableClose }) =>
    disableClose ? '17px 40px 17px 17px' : '17px calc(72px + 17px) 17px 17px'};
  height: 54px;
  transition: all ease-in-out 0.2s;

  font-size: 15px;

  color: ${theme.colors.black};

  &::placeholder {
    color: ${theme.colors.greySecondary};
  }

  &:hover {
    border-color: ${theme.colors.black};
  }

  &:focus {
    box-shadow: none;
    outline: none;
    border-color: ${theme.colors.purple};
  }
`;

export const Divider = styled.div`
  margin: 0 11px 0 14px;
  width: 1px;
  height: 22px;
  background: #d9dde7;
`;

export const Icon = styled.div``;

export const IconContainers = styled.div<{ disableClose: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 16px;
  display: flex;
  align-items: center;

  ${Divider}, ${Icon}.close {
    display: ${({ disableClose }) => (disableClose ? 'none' : 'block')};
  }
`;

export const StyledInput = styled.div<{ notEmpty?: boolean }>`
  position: relative;

  &:hover {
    ${Icon}.searchicon {
      color: ${(props) => (props.notEmpty ? '#825BD3' : theme.colors.black)};
    }
  }

  ${Icon} {
    color: ${(props) => (props.notEmpty ? '#825BD3' : '#686A70')};
    pointer-events: ${(props) => (props.notEmpty ? 'auto' : 'none')};
    svg {
      cursor: ${(props) => (props.notEmpty ? 'pointer' : 'auto')};
    }

    &.close {
      color: #686a70;
    }
  }

  @media ${device.mobileM} {
    ${InputElement} {
      padding: 11px;
      font-size: 14px;
      line-height: 19px;
    }
  }
`;

import styled from 'styled-components';
import { theme } from '@components/ui/theme';
import { Button } from '@components/ui/button/button';
import { Label } from '@components/ui/label/label';
import { CartButton } from '@components/popovers/popover-product-cart/popover-product-cart.style';

export const PackageImage = styled.a`
  position: relative;
  width: 120px;
  min-width: 120px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: image;
`;

export const PackageDescription = styled.div`
  margin-top: 15px;
  font-size: 13px;
  line-height: 18px;
  color: #686a70;
`;

export const PackageDescriptionShowMore = styled.span`
  color: ${theme.colors.primary};
  cursor: pointer;
  display: inline;
`;

export const PackageInfo = styled.div`
  flex: 1;
  margin-left: 20px;
  grid-area: info;
`;

export const PackageInfoTitle = styled.a`
  font-size: 17px;
  line-height: 120%;
  color: #302e30;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const PackageInfoRating = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const PackageInfoRatingStar = styled.div`
  color: ${theme.colors.primary};
`;

export const PackageInfoRatingScore = styled.div`
  font-size: 12px;
  color: #302e30;
  margin-left: 6px;
`;

export const PackageInfoRatingValue = styled.div`
  font-size: 12px;
  color: #9ca0a9;
  margin-left: 15px;

  &.offers {
    margin-left: 14px;
    width: fit-content;
  }

  @media (max-width: 450px) {
    &.offers {
      margin-top: 2px;
      flex-basis: 100%;
      margin-left: 0;
    }
  }
`;

export const PackageInfoRatingFavorites = styled.div<{ isFavorite: boolean }>`
  color: ${({ isFavorite }) => (isFavorite ? theme.colors.primary : theme.colors.greyPrimary)};
  margin-left: 15px;
  font-size: 12px;
  cursor: pointer;

  svg {
    width: 22px;
    height: 22px;

    path {
      fill: ${({ isFavorite }) => (isFavorite ? theme.colors.primary : '#686A70')};
    }
  }

  &:hover {
    color: ${({ isFavorite }) => (isFavorite ? theme.colors.primaryHover : '#686A70')};
    svg path {
      fill: ${({ isFavorite }) => (isFavorite ? theme.colors.primaryHover : theme.colors.primary)};
    }
  }
`;

export const PackagePrice = styled.div`
  margin-left: 80px;
  width: 302px;
  flex-shrink: 0;
  grid-area: price;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-self: flex-start;

  @media (max-width: 1075px) {
    margin-left: 30px;
  }

  @media (max-width: 961px) {
    margin-left: 5px;
  }
`;

export const PackagePriceTop = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export const PackagePriceOld = styled.div`
  margin-right: 10px;
  align-self: flex-end;

  font-size: 14px;
  color: #9ca0a9;
  text-decoration: line-through;
`;

export const PackagePriceMain = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #302e30;
  text-align: right;
`;

export const PackagePriceFrom = styled.div`
  font-size: 12px;
  line-height: 130%;
  color: #686a70;
  text-align: right;
  margin-top: 5px;
  flex-basis: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const PackagePriceFromOffers = styled.span`
  margin-left: 10px;
`;

export const PackagePriceButtons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;

  ${Button} {
    margin-right: 16px;
  }
`;

export const Labels = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  ${Label} {
    &:not(:first-child) {
      margin-top: 6px;
    }
  }
`;

export const StyledPackage = styled.article<{
  disableResponsivePrice?: boolean;
  isHoverable?: boolean;
  withBorder?: boolean;
}>`
  padding: 30px 0 40px 36px;
  box-sizing: border-box;
  display: flex;
  border: ${({ withBorder }) => (withBorder ? '1px solid #F9F7FA' : 'none')};
  border-bottom: 1px solid #f9f7fa;

  &.altPadding {
    padding: 25px 25px 37px 25px;
  }

  ${Labels} {
    left: -36px;

    &.responsive {
      display: none;
    }
  }

  ${PackagePrice} {
    width: ${({ isHoverable }) => (isHoverable ? '135px' : '302px')};
  }

  ${PackagePriceButtons} {
    justify-content: ${({ isHoverable }) => (isHoverable ? 'flex-end' : 'flex-start')};

    button[type='submit'] {
      display: ${({ isHoverable }) => (isHoverable ? 'none' : 'block')};
    }

    ${CartButton} {
      opacity: ${({ isHoverable }) => (isHoverable ? '0' : '1')};
      transition: all ease-in 0.1s;
    }
  }

  &:hover {
    box-shadow: ${({ isHoverable }) =>
      isHoverable ? '0px 2px 12px rgba(99, 47, 117, 0.14)' : 'none'};

    ${CartButton} {
      opacity: 1;
    }
    ${PackageInfoTitle} {
      color: ${theme.colors.primary};
    }
  }

  @media (max-width: 900px) {
    display: grid;
    grid-template-columns: 163px 1fr 1fr;
    grid-template-areas:
      'image  info info'
      'image  info info'
      'image  price price'
      'image  price price';

    ${PackageInfo} {
      margin-left: 0;
    }

    ${PackagePrice} {
      margin-top: 8px;
      margin-left: 0;
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

      ${PackagePriceTop} {
        align-items: flex-end;
      }
    }
  }

  @media (max-width: 890px) {
    padding: 20px;

    ${PackagePrice} {
      width: unset;
    }
  }

  @media (max-width: 730px) {
    ${PackagePrice} {
      margin-left: 15px;
    }
  }

  @media (max-width: 720px) {
    display: grid;
    padding: 16px;

    grid-template-areas:
      'image info info'
      'price price price';

    ${PackagePriceButtons} {
      margin-top: 0;
    }

    ${PackagePrice} {
      flex-direction: row;
      margin-left: 0;

      ${PackagePriceTop} {
        justify-content: flex-start;

        ${PackagePriceOld} {
          margin-left: 10px;
          order: 1;
        }

        ${PackagePriceFrom} {
          margin-top: 0;
          text-align: left;
          order: 2;
          justify-content: flex-start;
        }

        ${PackagePriceFromOffers} {
          margin-top: 2px;
          margin-left: 0;
        }
      }
    }
  }

  @media (max-width: 533px) {
    padding: 15px 0;
    border: none !important;
    border-bottom: 1px solid #f9f7fa !important;
    grid-template-columns: 78px 1fr 1fr;
    position: relative;

    ${Labels} {
      display: none;

      &.responsive {
        display: block;
        left: unset;
        top: 15px;
        right: 0;
      }
    }

    ${PackagePrice} {
      margin-top: 8px;
      align-items: center;
      display: ${({ disableResponsivePrice }) => (disableResponsivePrice ? 'none' : 'flex')};
      // Используется на странице характеристик и отзывов товара
    }

    ${PackagePriceTop} {
      margin-top: 8px;
      min-width: unset;
    }

    ${PackageImage} {
      margin-right: 20px;
      width: 58px;
      min-width: 58px;
      height: 58px;
    }

    ${PackageInfo} {
      line-height: 17px;
    }

    ${PackageInfoTitle} {
      font-size: 14px;
      line-height: 17px;
    }

    ${PackageInfoRatingStar} svg {
      width: 16px;
      height: 16px;
    }

    ${PackageInfoRatingValue} {
      width: unset;
    }

    ${PackageInfoRatingFavorites} {
      margin-left: 9px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    ${PackageInfoRating} {
      margin-top: 8px;
    }

    ${PackageInfoRating}, ${PackageInfoTitle} {
      padding-right: 40px;
    }

    ${PackagePriceMain} {
      font-size: 18px;
      line-height: 22px;
    }

    ${PackagePriceOld}, ${PackagePriceFrom} {
      font-size: 11px;
      line-height: 15px;
    }

    ${PackagePriceButtons} button {
      height: 34px !important;
      line-height: 14px !important;

      &.rounded {
        width: 34px !important;
      }
    }
  }

  @media (max-width: 479px) {
    ${PackagePriceButtons} button.outline {
      display: none;
    }

    ${PackageInfoRating} {
      flex-wrap: wrap;
    }
  }
`;

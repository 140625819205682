import React from 'react';
import * as S from './faq.style';
import { BlockTitle } from '@components/ui/block-title/block-title';
import styled from 'styled-components';
import { Keyword } from '../../../gql/generated';
import { ShowMoreButton } from '@components/ui/show-more-button/show-more-button';
import Link from 'next/link';
import { formatBrandName } from '../../../utils/formaters';
import { useStore } from '@stores/root';

type Props = {
  title?: string;
  className?: string;
  questions: Pick<Keyword, 'slug' | 'text'>[];
};

const questions = [
  'Когда привезут мой заказ?',
  'Как получить бесплатную доставку?',
  'Как понять, что заказ крупногабаритный?',
  'Когда привезут мой заказ?',
  'А поднимут ли мне заказ на этаж?',
  'Как понять, что заказ крупногабаритный?',
  'Когда привезут мой заказ?',
  'Когда привезут мой заказ?',
];

const Component = ({ title = 'Часто спрашивают', ...props }: Props) => {
  const { project } = useStore();

  if (!props.questions || !(props.questions.length > 0)) {
    return null;
  }

  return (
    <S.StyledFaq className={props.className}>
      <BlockTitle>{title}</BlockTitle>

      <S.FaqItems>
        {props.questions.map((question) => (
          <Link
            legacyBehavior
            prefetch={false}
            href={`/q/${question.slug}`}
            passHref
            key={question.slug}
          >
            <S.FaqItem>{formatBrandName(question.text, project.name)}?</S.FaqItem>
          </Link>
        ))}
      </S.FaqItems>

      {props.questions.length > 12 && (
        <Link legacyBehavior prefetch={false} href="/q" passHref>
          <ShowMoreButton>Все вопросы</ShowMoreButton>
        </Link>
      )}
    </S.StyledFaq>
  );
};

export const FAQ = styled(Component)``;

import styled from 'styled-components';
import { theme } from '@components/ui/theme';
import { IMaskInput } from 'react-imask';

export const StyledFilterInput = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterInputName = styled.div`
  font-size: 14px;
  color: ${theme.colors.black};
`;

export const FilterInput = styled(IMaskInput)`
  width: 125px;
  height: 42px;
  padding: 0 13px;
  background: transparent;
  font-size: 14px;
  border: 1px solid #9ca0a9;
  box-sizing: border-box;
  border-radius: 3px;

  &:not(:focus):not(:hover) {
    color: #686a70;
  }

  margin-left: 10px;

  &:focus,
  &:focus:hover {
    outline: none;
    border-color: ${theme.colors.purple};
  }

  &:hover {
    border-color: ${theme.colors.black};
  }
`;

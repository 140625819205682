import styled from 'styled-components';
import { theme } from '@components/ui/theme';
import { InputSearch } from '@components/ui/input-search/input-search';

export const StyledSearch = styled.div`
  width: 100%;
  position: relative;

  ${InputSearch} {
    height: 42px;

    &::placeholder {
      color: #9ca0a9;
    }
  }

  @media (max-width: 1000px) {
    input {
      font-size: 14px;
    }
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 42px;
  padding: 11px;
  border: 1px solid #d9dde7;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 14px;

  color: ${theme.colors.black};

  &::placeholder {
    color: ${theme.colors.greyPrimary};
  }

  &:hover {
    border-color: #686a70;
  }

  &:focus {
    border-color: #825bd3;
    outline: none;
  }
`;

export const MagnifierIcon = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${theme.colors.greySecondary};
`;

import styled from 'styled-components';

export const StyledSelect = styled.div`
  width: fit-content;
  height: 42px;

  div {
    cursor: pointer;
  }
`;

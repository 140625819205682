import React from "react";

export const IconStar = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 4.30198L17.7124 10.8233L17.8297 11.1053L18.1341 11.1297L25.1744 11.6941L19.8104 16.289L19.5785 16.4876L19.6493 16.7847L21.2881 23.6549L15.2606 19.9733L15 19.8141L14.7394 19.9733L8.71186 23.6549L10.3506 16.7847L10.4215 16.4876L10.1896 16.289L4.82558 11.6941L11.8659 11.1297L12.1703 11.1053L12.2876 10.8233L15 4.30198Z"
        fill="white"
        stroke="#9CA0A9"
      />
    </svg>
  );
};

import React, { useCallback, useEffect } from 'react';
import * as S from './main.style';
import { Icon } from '@components/ui/icons/icon';
import { useStore } from '@stores/root';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { WH } from '@components/WH';
import { useState } from 'react';
import { NoSSR } from '@components/NoSSR';

type Props = {};

export const MobileMenuMainPage = (props: Props) => {
  const { ui, geo, modal } = useStore();
  const router = useRouter();
  const [curGeo, setCurGeo] = useState('')

  useEffect(() => {
    router.events.on('routeChangeStart', onRouteChangeStart);

    const curGeoName = document.querySelector('meta[name=geo]').getAttribute('content');
    setCurGeo(curGeoName);

    return () => router.events.off('routeChangeStart', onRouteChangeStart);
  }, []);

  const onRouteChangeStart = useCallback(() => ui.setShowMobileMenu(false), []);

  return (
    <S.MobileMenuItems>
      <S.MobileMenuItem onClick={() => modal.show('geo')}>
        <NoSSR>
          <Icon icon="mapPin" /> Ваш город: {curGeo}
        </NoSSR>
      </S.MobileMenuItem>
      <S.MobileMenuItem onClick={ui.toggleMobileCatalogue}>
        <Icon icon="iconLoupeMenu" /> Каталог
        <S.MobileMenuItemArrow>
          <Icon icon="arrow" />
        </S.MobileMenuItemArrow>
      </S.MobileMenuItem>

      <Link legacyBehavior prefetch={false} href="/about" passHref>
        <S.MobileMenuItem>О сервисе</S.MobileMenuItem>
      </Link>

      <Link legacyBehavior prefetch={false} href="/info/sellers" passHref>
        <S.MobileMenuItem>Продавцам</S.MobileMenuItem>
      </Link>
      <Link legacyBehavior prefetch={false} href="/info/customers" passHref>
        <S.MobileMenuItem>Покупателям</S.MobileMenuItem>
      </Link>
      <Link legacyBehavior prefetch={false} href="/info/delivery" passHref>
        <S.MobileMenuItem>Оплата и доставка</S.MobileMenuItem>
      </Link>

      <Link legacyBehavior prefetch={false} href="/info/garanties" passHref>
        <S.MobileMenuItem>Гарантия и возврат</S.MobileMenuItem>
      </Link>

      <Link legacyBehavior prefetch={false} href="/contacts" passHref>
        <S.MobileMenuItem>Контакты</S.MobileMenuItem>
      </Link>

      <Link legacyBehavior prefetch={false} href="/info/guide" passHref>
        <S.MobileMenuItem>Как это работает</S.MobileMenuItem>
      </Link>
    </S.MobileMenuItems>
  );
};

import React from 'react';
import * as S from './tags.style';
import { Tags } from '@components/blocks/tags/tags';
import { Tag } from '@components/ui/tag/tag';
import Link from 'next/link';
import { LayoutButtons } from '@components/category/blocks/layout-buttons/layout-buttons';
import { ListType } from '../../../../pages/c/[slug]';
import { ListKeys } from '@stores/cookies';

type Props = {
  tags: TagType[];
  linkPrefix?: string;
  currentTag?: string;
  categorySlug?: string;
  productsCount?: number;
  listType?: ListType;
  setListType?: (value: ListType) => void;
  useLargeCards?: boolean;
  listKey: ListKeys;
};

export const CategoryTags = (props: Props) => {
  return (
    <S.StyledCategoryTags>
      <Link legacyBehavior prefetch={false} href={`/c/${props.categorySlug}`} passHref>
        <Tag active={!props.currentTag}>Все</Tag>
      </Link>

      <Tags
        tags={props.tags}
        limit={8}
        canShowMore
        linkPrefix={props.linkPrefix}
        currentTag={props.currentTag}
        rowsLengths={1}
      />

      {typeof props.listType !== 'undefined' && typeof props.setListType !== 'undefined' && (
        <S.CategoryViewSelector>
          <LayoutButtons useLargeCards={props.useLargeCards} listKey={props.listKey} />
        </S.CategoryViewSelector>
      )}
    </S.StyledCategoryTags>
  );
};

import * as React from 'react';
import * as S from './style';
import { RelinkItem } from '@components/layout/elements/relink-item/relink-item';
import { WH } from '@components/WH';

type Props = {
  items: ExternalProductRelink[];
};

export const RelinkItems = (props: Props) => {
  return (
    <WH>
      <S.StyledRelinkItems>
        {props.items.map((item, index) => (
          <RelinkItem key={index} item={item} />
        ))}
      </S.StyledRelinkItems>
    </WH>
  );
};

import styled from 'styled-components';
import { IconButton } from '@components/ui/icon-button/icon-button';
import { device, theme } from '@components/ui/theme';
import { Wrapper } from '@components/ui/wrapper/wrapper.style';

export const PrevSlide = styled(IconButton)`
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(99, 47, 117, 0.09);
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -60px;
  margin: auto;
  z-index: 2;
  //opacity: 0;
  transition: opacity ease 0.3s;

  &:disabled {
    background-color: #fff;
  }

  &:hover:not(:disabled) {
    color: ${theme.colors.primary};
  }
`;

export const NextSlide = styled(IconButton)`
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(99, 47, 117, 0.09);
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -60px;
  margin: auto;
  z-index: 2;
  //opacity: 0;
  transition: opacity ease 0.3s;

  &:disabled {
    background-color: #fff;
  }

  &:hover:not(:disabled) {
    color: ${theme.colors.primary};
  }
`;

export const ArrowsContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${Wrapper} {
    height: 100%;
    position: relative;
  }

  @media (max-width: 1655px) {
    ${NextSlide} {
      right: -30px;
    }
    ${PrevSlide} {
      left: -30px;
    }
  }

  @media (max-width: 1584px) {
    ${NextSlide} {
      right: -15px;
    }
    ${PrevSlide} {
      left: -15px;
    }
  }

  @media (max-width: 1546px) {
    ${NextSlide} {
      right: -5px;
    }
    ${PrevSlide} {
      left: -5px;
    }
  }

  @media (max-width: 1482px) {
    ${NextSlide} {
      right: 0;
    }
    ${PrevSlide} {
      left: 0;
    }
  }
`;

export const BannderSlideSimulation = styled.div`
  display: none;
`;

export const StyledBannderSlider = styled.section`
  position: relative;
  overflow-x: visible;

  &:hover {
    ${PrevSlide}, ${NextSlide} {
      opacity: 1;
    }
  }

  .swiper-pagination {
    bottom: 82px;

    .swiper-pagination-bullet {
      width: 9px;
      height: 9px;
      opacity: 1;

      margin-left: 8px;
      margin-right: 8px;

      background: #d9dde7;

      &:hover {
        background: #9ca0a9;
      }

      &.swiper-pagination-bullet-active {
        background: #e64e8d;
      }
    }
  }

  @media (max-width: 1275px) {
    .swiper-pagination {
      bottom: 75px;
    }
  }

  @media (max-width: 1250px) {
    ${PrevSlide}, ${NextSlide} {
      display: none;
    }
  }

  @media ${device.tabletM} {
    .swiper-pagination {
      .swiper-pagination-bullet {
        margin-right: 16px;
        margin-left: 0;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  @media (max-width: 813px) {
    .swiper-pagination {
      bottom: 30px;
    }
  }

  @media ${device.mobileM} {
    .swiper-pagination {
      bottom: 25px;
      display: flex;
      height: 6px;
      justify-content: center;

      .swiper-pagination-bullet {
        height: 6px;
        width: 6px;
        background-color: #9ca0a9;
      }
    }
  }
`;

import React from 'react';
import * as S from './pagination.style';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import { Icon } from '@components/ui/icons/icon';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores/root';

type Props = {
  className?: string;
  pageCount?: number;
  loading?: boolean;
  page?: number;
  // setPage?(page: number): void;
  onPageChange?(selectedItem: { selected: number }): void;
};

const Component = observer((props: Props) => {
  const { ui } = useStore();

  const onClick = () => {
    if (props.loading) return false;
  };

  if (props.pageCount <= 1) return null;

  return (
    <S.StyledPagination className={props.className}>
      <ReactPaginate
        marginPagesDisplayed={ui.viewport > 1000 ? 1 : 1}
        pageCount={props.pageCount}
        pageRangeDisplayed={5}
        onClick={onClick}
        onPageChange={props.onPageChange}
        forcePage={props.page - 1}
        nextLabel={<Icon icon="chevronRight" />}
        previousLabel={<Icon icon="chevronLeft" />}
      />
      {/* <Button onClick={() => props.onPageChange({ selected: props.page + 1 })}>Показать ещё</Button> */}
    </S.StyledPagination>
  );
});

export const Pagination = styled(Component)``;

import React from 'react';
import * as S from './popover-favorites-product-item.style';
import Link from 'next/link';
import { Image } from '@components/Image';
import { imageLoader } from '../../../../utils/image-loader';
import { Icon } from '@components/ui/icons/icon';
import { useStore } from '@stores/root';
import { StoreProduct } from '../../../../types/store-product';

type Props = StoreProduct;

export const PopoverFavoritesProductItem = (props: Props) => {
  const { favorites } = useStore();

  const removeProductFromFavorites = () => {
    favorites.remove(props.id);
  };

  return (
    <S.StyledPopoverProductItem>
      <Link legacyBehavior key={props.id} href={`/p/${props.slug}`} passHref>
        <S.ProductThumb>
          {props.images[0] ? (
            <Image
              loader={imageLoader({
                src: props.images[0],
                width: 96,
              })}
              fill
              style={{ objectFit: 'contain' }}
              src={`s3:/${props.images[0]}`}
              alt={props.name}
              title={props.name}
            />
          ) : (
            <Image
              fill
              style={{ objectFit: 'contain' }}
              src="s3://pics/nophotosmall.svg"
              alt={props.name}
              title={props.name}
            />
          )}
        </S.ProductThumb>
      </Link>

      <Link legacyBehavior prefetch={false} href={`/p/${props.slug}`} passHref>
        <S.ProductName>{props.name}</S.ProductName>
      </Link>

      <S.ProductPrice>{Number(props.price.toFixed(0)).toLocaleString('ru-RU')} ₽</S.ProductPrice>

      <S.ProductRemoveButton onClick={removeProductFromFavorites}>
        <Icon icon="close" />
      </S.ProductRemoveButton>
    </S.StyledPopoverProductItem>
  );
};

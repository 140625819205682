import styled from 'styled-components';
import React from 'react';
import * as S from './h1.style';
import { WH } from '@components/WH';

type Props = {
  className?: string;
  subtitle?: string;
};

const Component: FCC<Props> = (props) => {
  return (
    <WH>
      <S.StyledH1 className={props.className}>
        <S.Title>{props.children}</S.Title>
        {props.subtitle && <S.SubTitle>{props.subtitle}</S.SubTitle>}
      </S.StyledH1>
    </WH>
  );
};

export const H1 = styled(Component)``;

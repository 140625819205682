import React from "react";
import * as S from "./popover-orders-style";
import { Popover, Props } from "@components/ui/popover/popover";

export const PopoverOrders: FCC<Omit<Props, 'children'>> = (props) => {
  return (
    <Popover target={props.target}>
      <S.StyledPopoverOrders>
        Вы не авторизованы. Для доступа к заказам необходимо{' '}
        <S.PopoverOrdersLink>войти</S.PopoverOrdersLink>
      </S.StyledPopoverOrders>
    </Popover>
  );
};

import React from "react";

export const IconPlus = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="20"
        y="11"
        width="2"
        height="16"
        rx="1"
        transform="rotate(90 20 11)"
        fill="currentColor"
      />
      <rect
        x="13"
        y="20"
        width="2"
        height="16"
        rx="1"
        transform="rotate(-180 13 20)"
        fill="currentColor"
      />
    </svg>
  );
};

type ImageLoaderArgs = {
  src: string;
  width?: number;
  quality?: number;
  useSrcOnly?: boolean;
};

// useSrcOnly нужен когда параметр src содержит в начале строку 'http:/'

export const imageLoader =
  ({ src, quality, width, useSrcOnly }: ImageLoaderArgs) =>
  () => {
    return `/_next/image?url=${encodeURI(useSrcOnly ? src : `http:/${src}`)}&w=${width || 256}&q=${
      quality || 75
    }`;
  };

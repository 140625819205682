import React from "react";

export const IconMap = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.33268 5.11922V15.8059L7.55351 13.9976L12.5535 16.4976L16.666 14.7334V4.04755L17.7518 3.58255C17.8153 3.55537 17.8844 3.54436 17.9531 3.5505C18.0218 3.55665 18.0879 3.57976 18.1455 3.61776C18.2031 3.65575 18.2503 3.70746 18.283 3.76822C18.3157 3.82898 18.3327 3.8969 18.3327 3.96589V15.8334L12.4993 18.3334L7.49935 15.8334L2.24685 18.0842C2.18344 18.1114 2.11428 18.1224 2.04557 18.1163C1.97686 18.1101 1.91075 18.087 1.85317 18.049C1.79559 18.011 1.74835 17.9593 1.71569 17.8986C1.68302 17.8378 1.66595 17.7699 1.66602 17.7009V5.83338L3.33268 5.11922ZM13.5352 9.36922L9.99935 12.9042L6.46352 9.36922C5.76434 8.66992 5.28822 7.77901 5.09536 6.80912C4.9025 5.83924 5.00157 4.83395 5.38003 3.92037C5.7585 3.00679 6.39936 2.22595 7.22159 1.67658C8.04381 1.12721 9.01048 0.833984 9.99935 0.833984C10.9882 0.833984 11.9549 1.12721 12.7771 1.67658C13.5993 2.22595 14.2402 3.00679 14.6187 3.92037C14.9971 4.83395 15.0962 5.83924 14.9033 6.80912C14.7105 7.77901 14.2344 8.66992 13.5352 9.36922ZM9.99935 10.5476L12.356 8.19005C12.8223 7.72392 13.1399 7.12998 13.2686 6.48334C13.3973 5.83671 13.3313 5.16644 13.079 4.55729C12.8268 3.94815 12.3995 3.42749 11.8514 3.06118C11.3032 2.69486 10.6587 2.49934 9.99935 2.49934C9.34003 2.49934 8.69553 2.69486 8.14734 3.06118C7.59916 3.42749 7.17192 3.94815 6.91965 4.55729C6.66739 5.16644 6.60144 5.83671 6.73014 6.48334C6.85884 7.12998 7.17641 7.72392 7.64268 8.19005L9.99935 10.5476Z"
        fill="currentColor"
      />
    </svg>
  );
};

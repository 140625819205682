import styled from 'styled-components';
import { theme } from '@components/ui/theme';

export const StyledRelinkItem = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(99, 47, 117, 0.14);
  border-radius: 3px;
  padding: 20px;
  display: flex;
  width: 100%;
  position: relative;
`;

export const StyledRelinkItemImage = styled.div`
  width: 187px;
  height: 140px;
  flex-shrink: 0;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;

  a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledRelinkItemLeft = styled.div``;

export const StyledRelinkItemRight = styled.div`
  margin-left: 20px;
`;

export const StyledRelinkItemName = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: ${theme.colors.black};
`;

export const StyledRelinkItemDescription = styled.div`
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;

  text-decoration-line: underline;
  color: ${theme.colors.black};
`;

export const StyledRelinkItemBanner = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.5);
  padding: 2px 7px;
  border-radius: 20px;
  color: white;
  letter-spacing: 2px;
  font-size: 10px;
`;

import styled from 'styled-components';
import { scrollbar, theme } from '@components/ui/theme';

export const Products = styled.div`
  box-sizing: border-box;
  margin: 25px 10px 0 0;
  padding-right: 15px;
  overflow-y: auto;
  max-height: 220px;
  width: 500px;
  ${scrollbar.vertical}
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const Footer = styled.div`
  padding: 0 25px;
  height: 72px;
  box-shadow: 0px -1px 12px rgba(118, 104, 122, 0.15);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FooterButton = styled.div``;

export const FooterPrices = styled.div`
  display: flex;
`;

export const FooterPrice = styled.div`
  font-size: 17px;
  font-weight: 500;
  line-height: 120%;
  color: ${theme.colors.black};
`;

export const FooterOldPrice = styled.div`
  margin-left: 15px;
  color: #686a70;
  font-size: 17px;
  line-height: 140%;
  text-decoration: line-through;
`;

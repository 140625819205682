import styled from 'styled-components';
import { ProductAvailablePackage } from '@components/product/elements/available-package/available-package';
import { ProductCard } from '@components/product/elements/product-card/product-card';
import { ProductCardImage } from '@components/product/elements/product-card/product-card.style';
import { StyledProductLarge } from '@components/product/elements/product-large/product-large-style';
import { Pagination } from '@components/ui/pagination/pagination';

export const StyledCategoryProductList = styled.section`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  position: relative;

  &.kind {
    margin-top: 35px;
  }

  &.list {
    display: block;

    ${ProductAvailablePackage} {
      border-bottom: 1px solid #f1eff2;
    }
  }

  @media (max-width: 1520px) {
    grid-template-columns: repeat(4, 1fr);

    ${ProductCard} {
      width: 100%;

      ${ProductCardImage} {
        width: 100%;
      }
    }
  }

  @media (max-width: 1050px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 745px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 400px) {
    grid-template-columns: 1fr;

    ${ProductCard} {
      margin: 0 auto;
    }
  }

  @media (max-width: 360px) {
    ${ProductCard} {
      width: 100%;
    }
  }
`;

export const CategoryProductLargeList = styled.section`
  margin-top: 60px;
  position: relative;

  ${StyledProductLarge}:first-child {
    padding-top: 0;
  }

  ${Pagination} {
    margin-top: 15px;
  }

  @media (max-width: 1000px) {
    margin-top: 35px;
  }

  @media (max-width: 480px) {
    margin-top: 30px;
  }
`;

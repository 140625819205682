import styled from 'styled-components';

export const DropContent = styled.div`
  position: absolute;
  //min-width: 300px;
  margin: 10px auto 0 auto;
  cursor: default;

  filter: drop-shadow(0 -1px 12px rgba(118, 104, 122, 0.15));
  border-radius: 3px;
  background: #ffffff;

  &.top {
    bottom: 100%;
    margin-top: 0px;
    margin-bottom: 10px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      border: 5px solid transparent;
      border-top: 5px solid #ffffff;
      margin: 0 auto;
      max-width: 5px;
    }

    &:after {
      content: '';
      position: absolute;
      height: 10px;
      top: 100%;
      left: 0;
      right: 0;
    }
  }

  &.bottom {
    top: 100%;

    &:before {
      content: '';
      position: absolute;
      height: 10px;
      bottom: 100%;
      left: 0;
      right: 0;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 100%;
      border: 5px solid transparent;
      border-bottom: 5px solid #ffffff;
      margin: 0 auto;
      max-width: 5px;
    }
  }

  &.left {
    &::after {
      right: unset;
      left: 15px;
    }
  }

  &.right {
    &::after {
      left: unset;
      right: 15px;
    }
  }
`;

export const StyledPopover = styled.div<{ zIndex?: number }>`
  position: relative;
  display: inline-grid;

  &.left {
    justify-items: flex-start;
  }

  ${DropContent} {
    z-index: ${({ zIndex }) => zIndex ?? 2};
  }

  &.center {
    justify-items: center;
  }

  &.right {
    justify-items: flex-end;

    ${DropContent}.top::before {
      right: 5px;
      left: unset;
    }
  }
`;

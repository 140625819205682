import React from "react";

export const IconLogo = () => {
  return (
    <svg width="70" height="20" viewBox="0 0 70 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.3079 13.7838L22.5679 12.5472C22.8208 12.5472 23.0447 12.4883 23.2398 12.3705C23.4421 12.2528 23.6335 12.0484 23.8141 11.7574C23.9947 11.4596 24.1717 11.0543 24.3451 10.5417C24.5257 10.0221 24.7172 9.36745 24.9194 8.57771L25.4829 6.36437H30.6519L29.0264 13.7422H27.531L28.8856 7.62172H26.5991L26.2523 8.98297C26.0067 9.93205 25.7611 10.7218 25.5154 11.3522C25.2698 11.9757 24.9989 12.4641 24.7027 12.8174C24.4065 13.1707 24.0634 13.4201 23.6732 13.5655C23.2904 13.711 22.8352 13.7838 22.3079 13.7838Z" fill="white"/>
      <path d="M32.0843 13.8461C31.1957 13.8461 30.7515 13.4027 30.7515 12.516C30.7515 12.3913 30.7587 12.2632 30.7731 12.1315C30.7948 11.9999 30.8237 11.8579 30.8598 11.7055L31.6184 8.29715H33.0596L32.3769 11.4041C32.3263 11.605 32.3011 11.7782 32.3011 11.9237C32.3011 12.4156 32.5178 12.6615 32.9512 12.6615C33.1246 12.6615 33.298 12.6269 33.4714 12.5576C33.652 12.4814 33.8326 12.3705 34.0132 12.2251C34.1938 12.0796 34.36 11.896 34.5117 11.6743L35.2594 8.29715H36.7115L35.8554 12.1419C35.8482 12.1766 35.841 12.2112 35.8337 12.2458C35.8337 12.2736 35.8337 12.3013 35.8337 12.329C35.8337 12.4744 35.9204 12.5507 36.0938 12.5576L35.8337 13.7422C35.7037 13.763 35.5881 13.7803 35.487 13.7941C35.3931 13.808 35.3064 13.8149 35.2269 13.8149C34.9813 13.8149 34.7862 13.7664 34.6417 13.6694C34.4972 13.5725 34.425 13.4235 34.425 13.2226C34.425 13.188 34.425 13.1499 34.425 13.1083C34.4322 13.0668 34.4394 13.0148 34.4467 12.9524C34.4611 12.8832 34.4792 12.807 34.5009 12.7238C34.1758 13.091 33.8037 13.3716 33.3847 13.5655C32.9729 13.7526 32.5395 13.8461 32.0843 13.8461Z" fill="white"/>
      <path d="M39.8619 13.8461C39.2334 13.8461 38.7169 13.7179 38.3123 13.4616C37.915 13.1984 37.6332 12.8555 37.4671 12.4329C37.3081 12.0034 37.2648 11.5462 37.337 11.0612C37.3876 10.701 37.496 10.3616 37.6621 10.0429C37.8283 9.72422 38.0414 9.44712 38.3015 9.21158C38.5615 8.96912 38.8613 8.77861 39.2009 8.64006C39.5477 8.50151 39.9233 8.43224 40.3279 8.43224C40.6457 8.43224 40.9311 8.48073 41.184 8.57771C41.4368 8.66777 41.6499 8.79247 41.8233 8.9518C42.0039 9.11113 42.1376 9.29818 42.2242 9.51293C42.2676 8.97951 42.2242 8.55 42.0942 8.22441C41.9642 7.89189 41.7402 7.64943 41.4224 7.49702C41.1551 7.35847 40.8191 7.27534 40.4146 7.24763C40.0172 7.21992 39.5585 7.23377 39.0383 7.28919V6.2085C39.6524 6.13922 40.2231 6.13576 40.7505 6.19811C41.2851 6.26046 41.7511 6.40247 42.1484 6.62415C42.553 6.85276 42.8708 7.16103 43.102 7.54898C43.3332 7.93692 43.4488 8.42184 43.4488 9.00376C43.4488 9.53025 43.3982 10.0394 43.2971 10.5313C43.1959 11.0162 43.0334 11.4838 42.8094 11.9341C42.636 12.3013 42.4085 12.6303 42.1267 12.9213C41.8522 13.2053 41.5271 13.4304 41.1514 13.5967C40.7758 13.763 40.3459 13.8461 39.8619 13.8461ZM39.9919 12.7446C40.1942 12.7446 40.3893 12.7065 40.5771 12.6303C40.7649 12.5472 40.9347 12.4363 41.0864 12.2978C41.2381 12.1523 41.3646 11.9826 41.4657 11.7886C41.5668 11.5877 41.6319 11.3695 41.6608 11.134C41.7113 10.8222 41.6897 10.5451 41.5957 10.3027C41.509 10.0533 41.3646 9.85931 41.1623 9.72076C40.9672 9.58221 40.7324 9.51293 40.4579 9.51293C40.1834 9.51293 39.9269 9.58221 39.6885 9.72076C39.4501 9.85238 39.2515 10.0394 39.0925 10.2819C38.9336 10.5243 38.8288 10.8118 38.7783 11.1444C38.7349 11.4492 38.7602 11.7263 38.8541 11.9757C38.948 12.2181 39.0925 12.4086 39.2876 12.5472C39.4899 12.6788 39.7247 12.7446 39.9919 12.7446Z" fill="white"/>
      <path d="M46.6251 13.8461C46.011 13.8461 45.4981 13.7145 45.0863 13.4512C44.6818 13.188 44.3928 12.8451 44.2194 12.4225C44.046 11.993 43.9955 11.5288 44.0677 11.0301C44.1255 10.649 44.2447 10.2888 44.4253 9.94937C44.6059 9.60992 44.8443 9.30857 45.1405 9.04532C45.4439 8.78208 45.7907 8.57771 46.1808 8.43224C46.5709 8.27983 46.9935 8.20363 47.4487 8.20363C48.0627 8.20363 48.572 8.33525 48.9766 8.5985C49.3884 8.85482 49.681 9.19773 49.8544 9.62723C50.035 10.0498 50.0891 10.5174 50.0169 11.0301C49.9663 11.4041 49.8471 11.7609 49.6593 12.1004C49.4787 12.4398 49.2403 12.7412 48.9441 13.0044C48.6479 13.2677 48.3047 13.4755 47.9146 13.6279C47.5245 13.7734 47.0947 13.8461 46.6251 13.8461ZM46.7985 12.7342C47.0008 12.7342 47.1994 12.6927 47.3945 12.6095C47.5895 12.5195 47.7665 12.3982 47.9255 12.2458C48.0916 12.0865 48.2253 11.9029 48.3264 11.6951C48.4348 11.4873 48.507 11.2621 48.5431 11.0197C48.5937 10.6941 48.5684 10.4031 48.4673 10.1468C48.3734 9.89048 48.2217 9.68958 48.0122 9.5441C47.8099 9.3917 47.5679 9.3155 47.2861 9.3155C47.0766 9.3155 46.8743 9.36052 46.6793 9.45058C46.4915 9.53371 46.3145 9.65148 46.1483 9.80389C45.9894 9.95629 45.8557 10.1364 45.7474 10.3442C45.6462 10.5521 45.5776 10.7807 45.5415 11.0301C45.4909 11.3626 45.5162 11.657 45.6173 11.9133C45.7185 12.1696 45.8702 12.3705 46.0724 12.516C46.2819 12.6615 46.524 12.7342 46.7985 12.7342Z" fill="white"/>
      <path d="M50.4514 13.7422L51.6542 8.29715H56.704L55.4903 13.7422H54.0382L55.0135 9.36745H52.8787L51.9034 13.7422H50.4514Z" fill="white"/>
      <path d="M58.925 13.8461C58.506 13.8461 58.1448 13.7491 57.8414 13.5551C57.5452 13.3542 57.314 13.0875 57.1478 12.755C56.9889 12.4156 56.9094 12.0346 56.9094 11.612C56.9094 11.1617 56.9925 10.7322 57.1587 10.3235C57.3248 9.91473 57.5524 9.55103 57.8414 9.23237C58.1376 8.9137 58.4699 8.66431 58.8383 8.48419C59.214 8.29715 59.6077 8.20363 60.0195 8.20363C60.4096 8.20363 60.7419 8.30408 61.0164 8.50498C61.291 8.69895 61.4788 8.94834 61.5799 9.25315L61.7967 8.31793H63.2487L62.4035 12.1419C62.3963 12.1766 62.3891 12.2112 62.3818 12.2458C62.3746 12.2736 62.371 12.3013 62.371 12.329C62.371 12.4744 62.4577 12.5507 62.6311 12.5576L62.371 13.7422C62.241 13.763 62.1254 13.7803 62.0242 13.7941C61.9303 13.808 61.8472 13.8149 61.775 13.8149C61.5149 13.8149 61.3126 13.763 61.1682 13.6591C61.0237 13.5551 60.9514 13.3993 60.9514 13.1914C60.9514 13.1637 60.955 13.1326 60.9623 13.0979C60.9695 13.0564 60.9767 13.0113 60.9839 12.9628C60.9912 12.9074 61.002 12.852 61.0164 12.7966C60.7419 13.136 60.4168 13.3958 60.0412 13.5759C59.6655 13.756 59.2935 13.8461 58.925 13.8461ZM59.5752 12.6615C59.698 12.6615 59.828 12.6407 59.9653 12.5991C60.1026 12.5507 60.2362 12.4883 60.3663 12.4121C60.5035 12.329 60.6299 12.232 60.7455 12.1211C60.8611 12.0034 60.9587 11.8787 61.0381 11.7471L61.3199 10.4481C61.2982 10.2472 61.2332 10.0671 61.1248 9.9078C61.0164 9.74847 60.8828 9.62377 60.7239 9.53371C60.5649 9.43673 60.3952 9.38823 60.2145 9.38823C59.9834 9.38823 59.7594 9.44712 59.5427 9.56489C59.3332 9.67573 59.1454 9.82813 58.9792 10.0221C58.813 10.2091 58.6794 10.4239 58.5783 10.6664C58.4843 10.9019 58.4374 11.1444 58.4374 11.3938C58.4374 11.6293 58.4843 11.844 58.5783 12.038C58.6722 12.232 58.8022 12.3844 58.9684 12.4952C59.1417 12.6061 59.344 12.6615 59.5752 12.6615Z" fill="white"/>
      <path d="M66.4132 13.8461C65.7847 13.8461 65.2682 13.7179 64.8636 13.4616C64.4663 13.1984 64.1845 12.8555 64.0184 12.4329C63.8594 12.0034 63.8161 11.5462 63.8883 11.0612C63.9389 10.701 64.0473 10.3616 64.2134 10.0429C64.3796 9.72422 64.5927 9.44712 64.8528 9.21158C65.1129 8.96912 65.4127 8.77861 65.7522 8.64006C66.099 8.50151 66.4746 8.43224 66.8792 8.43224C67.1971 8.43224 67.4824 8.48073 67.7353 8.57771C67.9881 8.66777 68.2012 8.79247 68.3746 8.9518C68.5552 9.11113 68.6889 9.29818 68.7756 9.51293C68.8189 8.97951 68.7756 8.55 68.6455 8.22441C68.5155 7.89189 68.2915 7.64943 67.9737 7.49702C67.7064 7.35847 67.3704 7.27534 66.9659 7.24763C66.5685 7.21992 66.1098 7.23377 65.5897 7.28919V6.2085C66.2037 6.13922 66.7744 6.13576 67.3018 6.19811C67.8364 6.26046 68.3024 6.40247 68.6997 6.62415C69.1043 6.85276 69.4221 7.16103 69.6533 7.54898C69.8845 7.93692 70.0001 8.42184 70.0001 9.00376C70.0001 9.53025 69.9495 10.0394 69.8484 10.5313C69.7472 11.0162 69.5847 11.4838 69.3607 11.9341C69.1873 12.3013 68.9598 12.6303 68.678 12.9213C68.4035 13.2053 68.0784 13.4304 67.7028 13.5967C67.3271 13.763 66.8973 13.8461 66.4132 13.8461ZM66.5433 12.7446C66.7455 12.7446 66.9406 12.7065 67.1284 12.6303C67.3163 12.5472 67.486 12.4363 67.6377 12.2978C67.7895 12.1523 67.9159 11.9826 68.017 11.7886C68.1182 11.5877 68.1832 11.3695 68.2121 11.134C68.2626 10.8222 68.241 10.5451 68.1471 10.3027C68.0604 10.0533 67.9159 9.85931 67.7136 9.72076C67.5185 9.58221 67.2838 9.51293 67.0092 9.51293C66.7347 9.51293 66.4782 9.58221 66.2398 9.72076C66.0014 9.85238 65.8028 10.0394 65.6438 10.2819C65.4849 10.5243 65.3802 10.8118 65.3296 11.1444C65.2862 11.4492 65.3115 11.7263 65.4054 11.9757C65.4994 12.2181 65.6438 12.4086 65.8389 12.5472C66.0412 12.6788 66.276 12.7446 66.5433 12.7446Z" fill="white"/>
      <path d="M19.2307 9.61536C19.2307 14.9258 14.9258 19.2307 9.61536 19.2307C4.30494 19.2307 0 14.9258 0 9.61536C0 4.30494 4.30494 0 9.61536 0C14.9258 0 19.2307 4.30494 19.2307 9.61536Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.74245 5.04168C7.31212 5.37058 7.5073 6.09902 7.1784 6.66869L5.72731 9.18206C5.39841 9.75173 4.66997 9.94691 4.1003 9.61801C3.53063 9.28911 3.33545 8.56068 3.66435 7.99101L5.11544 5.47764C5.44434 4.90797 6.17278 4.71278 6.74245 5.04168Z" fill="#5B42C1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.2395 5.04168C11.8092 5.37058 12.0044 6.09902 11.6755 6.66869L6.79743 15.1177C6.46853 15.6874 5.7401 15.8826 5.17043 15.5537C4.60076 15.2248 4.40557 14.4963 4.73447 13.9267L9.61252 5.47764C9.94141 4.90797 10.6698 4.71278 11.2395 5.04168Z" fill="#5B42C1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.8291 5.04168C16.3988 5.37058 16.594 6.09902 16.2651 6.66869L12.6106 12.9984C12.2817 13.568 11.5533 13.7632 10.9836 13.4343C10.414 13.1054 10.2188 12.377 10.5477 11.8073L14.2021 5.47764C14.531 4.90797 15.2594 4.71278 15.8291 5.04168Z" fill="#5B42C1"/>
    </svg>
  );
};

import styled from 'styled-components';
import { theme } from '@components/ui/theme';
import { Loader } from '@components/ui/loader/loader';

export const StyledButton = styled.button`
  padding: 12px 20px;
  width: 100%;
  background: ${theme.colors.primary};
  border-radius: 3px;

  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #ffffff;

  white-space: nowrap;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.25s;

  ${Loader} {
    color: white;
    margin-left: 10px;

    width: 18px;
    height: 18px;
  }

  &:hover {
    background: ${theme.colors.primaryHover};
  }

  &:disabled {
    background: ${theme.colors.primaryDisabled};
  }

  &.outline {
    background: transparent;
    border: 1px solid ${theme.colors.primary};
    box-sizing: border-box;
    color: ${theme.colors.primary};
    padding: 10px 20px;

    &:hover {
      background: ${theme.colors.primary};
      color: white;
    }
  }

  &.secondary {
    background: #fafafa;
    color: ${theme.colors.greySecondary};

    &:hover {
      background: #f9f7fa;
      color: ${theme.colors.black};
    }
  }

  &:disabled {
    opacity: 0.5;
  }
`;

export const Icon = styled.div`
  margin-right: 4px;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;

  ${Loader} {
    margin-left: 10px;
  }
`;

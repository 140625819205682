import Layout from '@components/layout/layout';
import { useStore } from '@stores/root';
import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import { graphQLClient } from '../../utils/graphql-api';
import { GetServerSideProps } from 'next';
import { Wrapper } from '@components/ui/wrapper/wrapper.style';
import { CategoryProductsList } from '@components/category/blocks/category-products-list/category-products-list';
import { LastViewedProducts } from '@components/blocks/last-viewed-products/last-viewed-products';
import { CommonBlockWithTitle } from '@components/blocks/common-block-with-title';
import { FAQ } from '@components/blocks/faq/faq';
import { TagsBlock } from '@components/blocks/tags-block/tags-block';
import { CategoryReviews } from '@components/category/blocks/reviews/reviews';
import { H1 } from '@components/ui/h1/h1';
import { Breadcrumbs } from '@components/ui/breadcrumbs/breadcrumbs';
import plural from 'plural-ru';
import { CategoryHeaderText } from '@components/category/blocks/header-text/header-text';
import { CategoryTags } from '@components/category/blocks/tags/tags';
import { ProductsFilter } from '@components/category/blocks/products-filter/products-filter';
import {
  KindProductEnum,
  PageCategoryDocument,
  PageCategoryQuery,
  ProductsDocument,
} from '../../gql/generated';
import { CitiesBlock } from '@components/blocks/cities-block/tags-block';
import { NoSSR } from '@components/NoSSR';
import { Pagination } from '@components/ui/pagination/pagination';
import { BlockTitle } from '@components/ui/block-title/block-title';
import Head from 'next/head';
import { ProductCardsSlider } from '@components/product/blocks/product-cards-slider/product-cards-slider';
import { MainVideos } from '@components/main/blocks/videos/videos';
import { RelinkItems } from '@components/layout/elements/relink-items/relink-items';
import { MarketWidget } from '@components/MarketWidget';
import dayjs from 'dayjs';
import { CpaWidget } from '@components/CpaWidget';
import { WH } from '@components/WH';

type Params = {
  slug: string;
};

type Query = {
  direction?: string;
  priceFrom?: string;
  priceTo?: string;
  page?: number;
  perPage?: number;
  orderBy?: string;
};

const PER_PAGE = 25;
const RELATED_PRODUCTS_SLUG = 'soputstvuushhie-tovary';
const KindProductPrefix = 'kp-';
const VariationProductPrefix = 'vp-';

export const getServerSideProps: GetServerSideProps = async (queryData) => {
  const { req, res } = queryData;

  const params = queryData.params as Params;
  const query = queryData.query as Query;

  const vpSkip =
    ((query[`${VariationProductPrefix}page`] ?? 1) - 1) *
    (query[`${VariationProductPrefix}perPage`] ?? PER_PAGE);
  const kpSkip =
    ((query[`${KindProductPrefix}page`] ?? 1) - 1) *
    (query[`${KindProductPrefix}perPage`] ?? PER_PAGE);

  const isRelatedCategory = params.slug.trim().toLowerCase() === 'soputstvuushhie-tovary';
  const categoryQuery = graphQLClient(req.headers).query({
    query: PageCategoryDocument,
    variables: {
      slug: params.slug,
      vpPrice: {
        from: query[`${VariationProductPrefix}priceFrom`]
          ? Number(query[`${VariationProductPrefix}priceFrom`])
          : undefined,
        to: query[`${VariationProductPrefix}priceTo`]
          ? Number(query[`${VariationProductPrefix}priceTo`])
          : undefined,
      },
      kindPriceRangeKinds: isRelatedCategory ? KindProductEnum.Related : KindProductEnum.Product,
      variantPriceRangeKinds: isRelatedCategory ? KindProductEnum.Related : KindProductEnum.Variant,
      vpDirection: query[`${VariationProductPrefix}direction`],
      vpOrderBy: query[`${VariationProductPrefix}orderBy`],
      vpSkip,
      vpTake: query[`${VariationProductPrefix}perPage`]
        ? Number(query[`${VariationProductPrefix}perPage`])
        : PER_PAGE,

      kpPrice: {
        from: query[`${KindProductPrefix}priceFrom`]
          ? Number(query[`${KindProductPrefix}priceFrom`])
          : undefined,
        to: query[`${KindProductPrefix}priceTo`]
          ? Number(query[`${KindProductPrefix}priceTo`])
          : undefined,
      },
      kpProductsKind: [!isRelatedCategory ? KindProductEnum.Product : KindProductEnum.Related],
      kpDirection: query[`${KindProductPrefix}direction`] ?? 'desc',
      kpOrderBy: (query[`${KindProductPrefix}orderBy`] as string) ?? 'offersCount',
      kpSkip,
      kpTake: query[`${KindProductPrefix}perPage`]
        ? Number(query[`${KindProductPrefix}perPage`])
        : PER_PAGE,
      relinkURL: `/c/${params.slug}`,
    },
    context: {
      headers: {
        'update-cache': req.headers['update-cache'] ? 'true' : 'false',
      },
    },
  });

  const pageData = await categoryQuery;

  if (!pageData.data.category) {
    return {
      notFound: true,
    };
  }

  res.setHeader('Last-Modified', dayjs(pageData.data.category.updatedAt).toString());

  return {
    props: {
      ...pageData.data,
      page: query.page ?? 1,
    },
  };
};

type Props = PageCategoryQuery & {
  externalProductRelink: ExternalProductRelink[];
};

export enum ListType {
  LIST,
  CARDS,
  LARGE,
}

export default observer((props: Props) => {
  const { geo, project, ui } = useStore();
  const tags = props.category?.tags ?? [];
  const router = useRouter();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [products, setProducts] = useState(props.products);
  const [kindProducts, setKindProducts] = useState(props.kindProducts);
  const [listType, setListType] = useState(ListType.LIST);
  const [topListType, setTopListType] = useState(ListType.LARGE);
  const [loading, setLoading] = useState(false);
  const scrollToRef = useRef<HTMLDivElement>(null);
  const [canSubmitVariations, setCanSubmitVariations] = useState(false);

  const [firstTags, setFirstTags] = useState(tags.slice(0, 15));
  const [secondTags, setSecondTags] = useState(tags.slice(15, 30));
  const [restTags, setRestTags] = useState(tags.slice(30, 60));

  const isRelatedCategory = props.category.slug.trim().toLowerCase() === 'soputstvuushhie-tovary';

  const querySize = Object.keys(router.query).length;

  // Основные товары
  useEffect(() => {
    if (isFirstRender) return;
    if (ui.viewport <= 765 && !canSubmitVariations) return;

    setLoading(true);

    const skip =
      ((Number(router.query[`${KindProductPrefix}page`]) ?? 1) - 1) *
      Number(router.query[`${KindProductPrefix}perPage`] ?? PER_PAGE);

    const kindProduct = isRelatedCategory ? [KindProductEnum.Related] : [KindProductEnum.Product];
    const variables = {
      categoryId: props.category.id,
      price: {
        from: router.query[`${KindProductPrefix}priceFrom`]
          ? Number(router.query[`${KindProductPrefix}priceFrom`])
          : undefined,
        to: router.query[`${KindProductPrefix}priceTo`]
          ? Number(router.query[`${KindProductPrefix}priceTo`])
          : undefined,
      },
      direction: (router.query[`${KindProductPrefix}direction`] as string) ?? 'desc',
      orderBy: (router.query[`${KindProductPrefix}orderBy`] as string) ?? 'offersCount',
      skip,
      take: router.query[`${KindProductPrefix}perPage`]
        ? Number(router.query[`${KindProductPrefix}perPage`])
        : PER_PAGE,
      kindProduct,
    };

    graphQLClient()
      .query({
        query: ProductsDocument,
        variables,
      })
      .then((result) => setKindProducts(result.data.products))
      .finally(() => {
        setLoading(false);
        setCanSubmitVariations(false);
      });
  }, [
    router.query['kp-page'],
    router.query['kp-perPage'],
    router.query['kp-priceFrom'],
    router.query['kp-priceTo'],
    router.query['kp-direction'],
    router.query['kp-orderBy'],
  ]);

  // Вариации
  useEffect(() => {
    if (isFirstRender) return;
    if (ui.viewport <= 765 && !canSubmitVariations) return;

    setLoading(true);

    const skip =
      ((Number(router.query[`${VariationProductPrefix}page`]) ?? 1) - 1) *
      Number(router.query[`${VariationProductPrefix}perPage`] ?? PER_PAGE);

    const variables = {
      categoryId: props.category.id,
      price: {
        from: router.query[`${VariationProductPrefix}priceFrom`]
          ? Number(router.query[`${VariationProductPrefix}priceFrom`])
          : undefined,
        to: router.query[`${VariationProductPrefix}priceTo`]
          ? Number(router.query[`${VariationProductPrefix}priceTo`])
          : undefined,
      },
      direction: (router.query[`${VariationProductPrefix}direction`] as string) ?? 'desc',
      orderBy: (router.query[`${VariationProductPrefix}orderBy`] as string) ?? 'offersCount',
      skip,
      take: router.query[`${VariationProductPrefix}perPage`]
        ? Number(router.query[`${VariationProductPrefix}perPage`])
        : PER_PAGE,
      kindProduct: KindProductEnum.Variant,
    };

    graphQLClient()
      .query({
        query: ProductsDocument,
        variables,
      })
      .then((result) => setProducts(result.data.products))
      .finally(() => {
        setLoading(false);
        setCanSubmitVariations(false);
      });
  }, [
    router.query['vp-page'],
    router.query['vp-perPage'],
    router.query['vp-priceFrom'],
    router.query['vp-priceTo'],
    router.query['vp-direction'],
    router.query['vp-orderBy'],
  ]);

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    setKindProducts(props.kindProducts);
    setProducts(props.products);
  }, [props.category.id]);

  useEffect(() => {
    const tags = props.category?.tags ?? [];

    setFirstTags(tags.slice(0, 15));
    setSecondTags(tags.slice(15, 30));
    setRestTags(tags.slice(30, 60));
  }, [props.category.tags]);

  const vpPageCount = Math.ceil(
    products.totalItemCount /
      (router.query[`${VariationProductPrefix}perPage`]
        ? Number(router.query[`${VariationProductPrefix}perPage`])
        : PER_PAGE)
  );

  const kpPageCount = Math.ceil(
    kindProducts.totalItemCount /
      (router.query[`${KindProductPrefix}perPage`]
        ? Number(router.query[`${KindProductPrefix}perPage`])
        : PER_PAGE)
  );

  const breadCrumbs = [
    {
      name: 'Каталог',
      url: `/catalog/`,
    },
    {
      name: `${props?.category?.name} ${project?.name}`,
      url: `/c/${props?.category?.slug}`,
    },
  ];

  const onPageChange =
    (prefix: string) =>
    ({ selected }: { selected: number }) => {
      const el = document.querySelector(
        prefix === VariationProductPrefix ? '.variations' : '.kindProducts'
      );
      el.scrollIntoView();
      window.scrollTo({
        top: window.scrollY - 200,
      });

      setLoading(true);
      setCanSubmitVariations(true);
      return router.replace(
        { query: { ...router.query, [`${prefix}page`]: selected + 1 } },
        {},
        { shallow: true }
      );
    };

  const h1 = geo.slug
    ? `${props.category.name} ${project.name} ${geo.cityName}`
    : `${props.category.name} ${project.name}`;

  const offersPlural = plural(
    props.category.offersCount,
    '%d предложение',
    '%d предложения',
    '%d предложений'
  );
  const sellersPlural = plural(
    props.category.sellersCount,
    '%d поставщик',
    '%d поставщиков',
    '%d поставщиков'
  );

  const meta = {
    title: `${props.category.name} ${project.name} купить ${
      geo.cityName
    } по цене от ${props.kindPriceRange.minPrice.toLocaleString(
      'ru-RU'
    )} ₽ на специализированном маркетплейсе`,
    description: `${props.category.name} на маркетплейсе ${
      project.name
    } ${offersPlural} от ${sellersPlural} по ценам от ${props.kindPriceRange.minPrice.toLocaleString(
      'ru-RU'
    )} ₽ до ${props.kindPriceRange.maxPrice.toLocaleString('ru-RU')} ₽ Оперативная доставка ${
      geo.cityName
    }!`,
  };

  const headerSnippet = `${props.category.name} ${
    project.name
  } купить: ${offersPlural} от ${sellersPlural} по ценам от ${props.kindPriceRange.minPrice.toLocaleString(
    'ru-RU'
  )} ₽ до ${props.kindPriceRange.maxPrice.toLocaleString('ru-RU')} ₽`;

  const aboutBlockText = `${props.category.name} ${project.name} на маркетплейсе «${
    project.domain
  }» ${
    project.name
  } представлены в максимальном ассортименте. Сколько стоит? Цены начинаются от ${props.kindPriceRange.minPrice.toLocaleString(
    'ru-RU'
  )} ₽. На страницах товаров указаны подробные технические характеристики (${
    props.category.attributeNames
  } и другие), отзывы, сертификаты соответствия, инструкция применения, условия покупки, гарантии и условия доставки ${
    geo.cityName
  }. ${props.category.name} ${
    project.name
  } легко купить онлайн на нашем сайте, добавив товар в корзину, и заказать с доставкой по указанному адресу или оформить самовывоз со складов наших продавцов. Некоторые товары доступны только по предзаказу, узнать их стоимость, а также оптовые цены удобнее по телефону .`;
  const countPlural = plural(props.category.productsCount, 'товар', 'товара', 'товаров');
  return (
    <Layout
      updatedAt={props.category.updatedAt}
      meta={meta}
      currentCategory={props.category.name}
      aboutBlockText={aboutBlockText}
    >
      {querySize > 1 && (
        <Head>
          <link
            rel="canonical"
            href={`https://${geo.slug ? `${geo.slug}.` : ''}${project.domain}/c/${
              props.category.slug
            }`}
          />
        </Head>
      )}
      <Wrapper>
        <Breadcrumbs list={breadCrumbs} />

        <H1 subtitle={`(${props.category.productsCount.toLocaleString('ru-RU')} ${countPlural})`}>
          {h1}
        </H1>
        <CategoryHeaderText className="kindProducts">{headerSnippet}</CategoryHeaderText>

        {firstTags.length > 0 && (
          <CategoryTags
            tags={firstTags}
            linkPrefix="/t/"
            useLargeCards
            categorySlug={props.category.slug}
            listKey="categoryMainProducts"
          />
        )}

        <ProductsFilter
          id={props.category.id}
          priceRange={[props.kindPriceRange.minPrice, props.kindPriceRange.maxPrice]}
          ref={scrollToRef}
          setCanSubmit={setCanSubmitVariations}
          canSubmit={canSubmitVariations}
          listKey="categoryMainProducts"
          useLargeCards
          prefix={KindProductPrefix}
        />

        <CpaWidget searchText={`${props.category.name} ${project.name}`} name="category" />

        <CategoryProductsList
          products={kindProducts.items}
          categoryId={props.category.id}
          listKey="categoryMainProducts"
          withoutPagination
          isKind
          loading={loading}
        />

        <Pagination
          page={Number(router.query[`${KindProductPrefix}page`] ?? 1)}
          pageCount={kpPageCount}
          onPageChange={onPageChange(KindProductPrefix)}
          loading={loading}
        />

        <RelinkItems items={props.externalProductRelink.slice(0, 2)} />
      </Wrapper>

      {/* <Wrapper>
        {products.items.length > 0 && (
          <CommonBlockWithTitle className="variations">
            <BlockTitle>Возможные вариации</BlockTitle>

            <ProductsFilter
              id={`vp-${props.category.id}`}
              priceRange={[props.variantPriceRange.minPrice, props.variantPriceRange.maxPrice]}
              ref={scrollToRef}
              setCanSubmit={setCanSubmitVariations}
              canSubmit={canSubmitVariations}
              listKey="categoryVariations"
              prefix={VariationProductPrefix}
            />

            <CategoryProductsList
              products={products.items}
              loading={loading}
              listKey="categoryVariations"
            />

            <Pagination
              page={Number(router.query[`${VariationProductPrefix}page`] ?? 1)}
              pageCount={vpPageCount}
              onPageChange={onPageChange(VariationProductPrefix)}
              loading={loading}
            />
          </CommonBlockWithTitle>
        )}

        {props.category.slug !== RELATED_PRODUCTS_SLUG &&
          props.category.relatedProducts.length > 0 && (
            <ProductCardsSlider
              products={props.category.relatedProducts}
              title="Сопутствующие товары"
            />
          )}
      </Wrapper> */}

      {props.reviews.items.length > 0 && <CategoryReviews reviews={props.reviews.items} />}

      <Wrapper>
        <CommonBlockWithTitle>{/* <MainVideos /> */}</CommonBlockWithTitle>
        {props.category.videos.length > 0 && (
          <MainVideos videos={props.category.videos} title="Видео" />
        )}

        <NoSSR>
          <CommonBlockWithTitle>
            <LastViewedProducts />
          </CommonBlockWithTitle>
        </NoSSR>

        {secondTags.length > 0 && (
          <TagsBlock linkPrefix="/t/" tags={restTags} limit={11} canShowMore />
        )}

        <CommonBlockWithTitle>
          <FAQ questions={props.questions} />
        </CommonBlockWithTitle>

        {props.relink && props.relink.length > 0 && (
          <TagsBlock canShowMore limit={13} title="Смотрите также" tags={props.relink} isRelink />
        )}

        {props.category.tags.length > 0 && (
          <TagsBlock
            limit={13}
            canShowMore
            title="Эту категорию искали как"
            tags={props.category.tags}
            linkPrefix="/t/"
          />
        )}

        <WH>
          <CitiesBlock title="Города" tags={props.cities} />
        </WH>

        <RelinkItems items={props.externalProductRelink.slice(2, 4)} />
      </Wrapper>
    </Layout>
  );
});

import axios from 'axios';
import { IncomingMessage } from 'http';
import { getDomainFromHost } from './domain';

export const getExternalRelink = async (
  req: IncomingMessage,
  url: string
): Promise<ExternalProductRelink[]> => {

  // return [];

  
  const { host } = req.headers;

  const domain = getDomainFromHost(host);

  const [formattedURL] = url.split('?');

  if (formattedURL == '/robots.txt' || formattedURL.includes('sitemap')) {
    return [];
  }

  const relinkURL = `https://relink.live.revoluterra.ru/external_pages_targets`;

  // return [
  //   {
  //     url: '/t/noirot-e-3-750',
  //     domain: 'target-domain.ru',
  //     type: 'link',
  //     text: 'noirot e 3 750',
  //     image_url: 'https://ballu-market.ru/images/be/3f/63/63/9c94c1c0.webp',
  //     link: 'target-domain.ru',
  //   },
  //   {
  //     url: '/t/noirot-e-3-750',
  //     domain: 'target-domain.ru',
  //     type: 'link',
  //     text: 'noirot e 3 750',
  //     image_url: 'https://ballu-market.ru/images/be/3f/63/63/9c94c1c0.webp',
  //     link: 'target-domain.ru',
  //   },
  //   {
  //     url: '/t/noirot-e-3-750',
  //     domain: 'target-domain.ru',
  //     type: 'link',
  //     text: 'noirot e 3 750',
  //     image_url: 'https://ballu-market.ru/images/be/3f/63/63/9c94c1c0.webp',
  //     link: 'target-domain.ru',
  //   },
  //   {
  //     url: '/t/noirot-e-3-750',
  //     domain: 'target-domain.ru',
  //     type: 'link',
  //     text: 'noirot e 3 750',
  //     image_url: 'https://ballu-market.ru/images/b3/96/cd/18/c7693649.webp',
  //     link: 'target-domain.ru',
  //   },
  // ];

  try {
    const t0 = Date.now();

    const data = await axios.post(relinkURL, { domain, url: formattedURL }, { timeout: 200 });

    // if (data.data.message) {
    //   console.log(`Внешняя перелинковка товаров не отработала: ${data.data.message}`);
    //   return [];
    // }

    const t1 = Date.now();
    const time = ((t1 - t0) / 1000).toFixed(2);

    console.log(`Внешняя перелинковка (${domain}, ${formattedURL}): ${time} seconds`);

    return data.data;
  } catch (e) {
    console.log(`Внешняя перелинковка товаров не отработала: ${e.message}`);
    return [];
  }
};

import styled from 'styled-components';
import { motion } from 'framer-motion';
import { scrollbar } from '@components/ui/theme';

export const StyledSearchResults = styled(motion.div)`
  width: 100%;
  min-height: 239px;
  background: white;
  position: absolute;
  padding-bottom: 30px;
  z-index: 3;
  box-shadow: 0px 2px 12px rgba(99, 47, 117, 0.14);

  &.fixedHeader {
    position: fixed;
    top: 90px;
    padding-top: 30px;
  }
`;

export const StyledModalSearchResults = styled.div`
  margin-top: 20px;
  padding-right: 7px;
  padding-bottom: 25px;
  height: fit-content;
  overflow: auto;
  ${scrollbar.vertical}
`;

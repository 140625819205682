export const getLogoFontSize = (name: string, viewport: number) => {
  let isUpperCase: boolean;
  const isWide = name.length > 10 && name.length <= 17;
  const isExtraWide = name.length > 17;

  for (let i = 0; i < name.length; i+=1) {
    if ((name[i].toUpperCase() === name[i]) && isUpperCase !== false) isUpperCase = true;

    else {
      isUpperCase = false;
      break;
    }
  }

  const getWideObj = (value: number) => ({
    value,
    isUpperCase
  });

  const getWidesResults = (standard: number, standardUpperCase: number, wide: number, wideUpperCase: number, extraWide: number, extraWideUpperCase: number) => {
    if (isWide && isUpperCase) return getWideObj(wideUpperCase);
    if (isWide) return getWideObj(wide);

    if (isUpperCase && isExtraWide) return getWideObj(extraWideUpperCase);
    if (isExtraWide) return getWideObj(extraWide);

    if (isUpperCase) return getWideObj(standardUpperCase);
    return getWideObj(standard);
  };

  if (viewport > 768) return getWidesResults(30, 24,26,22,24,24);

  if (viewport > 480) return getWidesResults(24, 24, 24, 20, 20, 20)

  return getWidesResults(22, 19, 20, 17, 16, 16)
};

import styled from 'styled-components';
import { theme } from '@components/ui/theme';
import { IconButton } from '@components/ui/icon-button/icon-button';

export const MainVideoPlayButton = styled(IconButton)`
  position: absolute;
  inset: 0;
  margin: auto;
  background: ${theme.colors.primary};
  opacity: 0.94;
  border-radius: 50%;
  color: white;

  svg {
    width: 14px;
    height: 14px;
    position: relative;
    right: -1px;
  }
`;

export const MainVideoImage = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  background: black;

  display: flex;
  justify-content: center;
  height: 261.8px;

  &.isKeyword {
    max-height: 192.63px;
  }

  span {
    position: unset !important;
  }

  .custom-img {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }
`;

export const MainVideoText = styled.a`
  margin-top: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;

  font-size: 15px;
  line-height: 140%;
  color: ${theme.colors.black};
`;

export const StyledMainVideo = styled.article`
  width: 100%;
  cursor: pointer;

  &:hover {
    ${MainVideoPlayButton} {
      background: ${theme.colors.primaryHover};
    }

    ${MainVideoText} {
      color: ${theme.colors.primary};
    }
  }
`;

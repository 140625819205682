import styled from 'styled-components';
import { device } from '@components/ui/theme';

export const Body = styled.div`
  display: flex;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;

  @media (max-width: 600px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`;

export const PageContainer = styled.div`
  flex: 1;

  @media (max-width: 992px) {
    margin-top: 135px;
  }

  &.fixedContainer {
    margin-top: 92px;

    @media ${device.tabletM} {
      margin-top: 93px;
    }

    @media ${device.mobileM} {
      margin-top: 124.78px;
    }
  }
`;

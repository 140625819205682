import React from "react";

export const IconQuestionAnswer = () => {
  return (
    <svg
      width="500"
      height="504"
      viewBox="0 0 500 504"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M140.916 302.365L66.666 361.085V101.038C66.666 96.5888 68.422 92.3215 71.5476 89.1752C74.6732 86.0288 78.9124 84.2612 83.3327 84.2612H333.333C337.753 84.2612 341.992 86.0288 345.118 89.1752C348.243 92.3215 349.999 96.5888 349.999 101.038V302.365H140.916ZM129.383 268.81H316.666V117.816H99.9993V292.047L129.383 268.81ZM183.333 335.919H353.949L383.333 359.155V184.924H399.999C404.42 184.924 408.659 186.692 411.784 189.838C414.91 192.985 416.666 197.252 416.666 201.702V428.194L342.416 369.473H199.999C195.579 369.473 191.34 367.706 188.214 364.559C185.089 361.413 183.333 357.146 183.333 352.696V335.919Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { theme } from '@components/ui/theme';
import styled from 'styled-components';

export const StyledFooterAnchor = styled.a<{ largerBottom: boolean }>`
  bottom: 24px;
  left: 17px;
  width: 34px;
  height: 34px;
  background-color: ${theme.colors.primary};
  box-shadow: 0px 1px 5px rgba(229, 78, 141, 0.15);
  border-radius: 50%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  @media screen and (max-width: 992px) {
    bottom: 95px;
  }

  @media (max-width: 767px) {
    bottom: ${({ largerBottom }) => (largerBottom ? '165px' : '95px')};
  }

  @media (max-width: 480px) {
    bottom: ${({ largerBottom }) => (largerBottom ? '155px' : '95px')};
  }
`;

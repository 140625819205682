import styled from 'styled-components';
import { scrollbar } from '@components/ui/theme';

export const StyledScrollbar = styled.div`
  height: 100%;

  * {
    ${scrollbar.vertical}
  }
`;

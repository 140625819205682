import classNames from "classnames";
import React from "react";
import * as S from "./icon-button.style";

type Props = {
  width?: number;
  height?: number;
  circle?: boolean;
  rounded?: boolean;
  fill?: boolean;
  secondary?: boolean;
  className?: string;
  ref?: any;
  ariaLabel?: string;
  disabled?: boolean;
  onClick?(): void;
  style?: any;
};

export const IconButton: FCC<Props> = React.forwardRef(
  (
    {
      width = 44,
      height = 40,
      circle,
      rounded,
      fill,
      children,
      className,
      onClick,
      secondary,
      ariaLabel,
      style,
      disabled,
    },
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const classes = classNames(
      {
        circle,
        rounded,
        fill,
        secondary,
      },
      className
    );

    return (
      <S.Button
        type="button"
        className={classes}
        style={{ ...style, width, height }}
        ref={ref}
        onClick={onClick}
        aria-label={ariaLabel}
        disabled={disabled}
      >
        {children}
      </S.Button>
    );
  }
);

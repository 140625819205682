import styled from 'styled-components';
import { motion } from 'framer-motion';
import { theme } from '@components/ui/theme';
import { PopularCategory } from '@components/main/elements/popular-category/popular-category';

export const StyledCategoriesMenu = styled(motion.div)`
  width: 100%;
  min-height: 239px;
  //max-height: 420px;
  background: white;
  position: absolute;
  z-index: 3;
  box-shadow: 0px 2px 16px rgba(49, 18, 59, 0.1);
  border-top: 1px solid #ecebed;
  //overflow: hidden;
  display: none;

  @media (max-width: 992px) {
    display: none;
  }

  &.fixedHeader {
    position: fixed;
    top: 90px;
  }
`;

export const CategoriesMenuContent = styled.div<{ moreThanSix?: boolean }>`
  max-width: 1075px;
  margin-inline: auto;
  display: grid;
  //grid-template-columns: repeat(3, 330px);
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-row-gap: ${(props) => (props.moreThanSix ? '15px' : '20px')};

  padding-top: 10px;
  padding-bottom: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
  grid-column-gap: 40px;

  justify-content: center;

  max-height: 420px;
  overflow: ${({ moreThanSix }) => (moreThanSix ? 'auto' : 'unset')};

  ${PopularCategory} {
    width: 100%;
  }

  &.products {
    max-width: 1210px;
    grid-template-columns: repeat(auto-fill, minmax(577px, 1fr));
    grid-row-gap: 10px;
    grid-column-gap: 40px;
    padding-right: 10px;

    margin-inline: auto;

    @media (max-width: 650px) {
      grid-template-columns: 1fr;
    }
  }

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #f9f7fa;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #9ca0a9;
    border-radius: 3px;
  }
`;

export const CategoryMenuLink = styled.a<{ active?: boolean }>`
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0px 12px;
  color: ${(props) => (props.active ? theme.colors.primary : '#3c3a3d')};
  background: ${(props) => (props.active ? '#f9f7fa' : 'none')};
  border-radius: 3px;
  font-size: 14px;
  line-height: 140%;

  &:hover {
    background: #f9f7fa;
    color: ${theme.colors.primary};
  }
`;

export const CategoryMenuProduct = styled.a`
  background: #ffffff;
  border: 1px solid #f9f7fa;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 15px;
  padding-bottom: 23px;
  display: flex;

  &:hover {
    box-shadow: 0px 2px 12px rgba(99, 47, 117, 0.14);
  }
`;

export const CategoryMenuProductImage = styled.div`
  width: 140px;
  height: 140px;
  position: relative;
  flex-shrink: 0;
`;

export const CategoryMenuProductName = styled.div`
  margin-left: 15px;
`;

import styled from 'styled-components';
import { theme } from '@components/ui/theme';
import { motion } from 'framer-motion';

export const StyledLoading = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10;
`;

export const LoadingIndicator = styled.div`
  .loader {
    width: 48px;
    height: 48px;
    border: 3px solid ${theme.colors.primary};
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

import React from 'react';
import pb from '@bitpatty/imgproxy-url-builder';
import { useStore } from '@stores/root';

{
  /* <Image
src={`s3:/${props.images[selectedImage]}`}
width={270}
height={224}
style={{ objectFit: 'contain' }}
alt={props.name}
title={props.name}
/> */
}

export const Image = ({ src, width, height, style = {}, alt, title, ...rest }: any) => {
  let tmp = pb().format('webp');

  const { project } = useStore();

  if (width !== undefined || height !== undefined) {
    tmp = tmp.resize({
      width,
      height,
    });
  }

  const url = tmp.build({
    // Fix http path to images
    path: ( src.startsWith('http://images/') ? `s3${src.substr(4)}` : src),
    baseUrl: `https://static.${project.domain}`,
    signature: {
      key: 'bea575daa0d0a14753ac10e0c4c90346f77ff0f6508b84a8b899674e4e55a89668320fd0b64cd4a5d5c45eebf01d29ceec9fbf151bf5612057d2b696823f44c9',
      salt: '093c1c0fa57a071171b6a8571727686d1d5deb3bb6b23499146620a7a607485416b807ec2f944a58334f38d2aa036f2f8f1ff664936b055b93ea9816ed9083f9',
    },
  });

  return <img src={url} alt={alt} title={title} style={style} />;
};

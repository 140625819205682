import React from "react";

export const IconReturn = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.1433 5.83333H9.16667C7.84059 5.83333 6.56882 6.36012 5.63113 7.2978C4.69345 8.23548 4.16667 9.50725 4.16667 10.8333C4.16667 12.1594 4.69345 13.4312 5.63113 14.3689C6.56882 15.3066 7.84059 15.8333 9.16667 15.8333H16.6667V17.5H9.16667C7.39856 17.5 5.70286 16.7976 4.45262 15.5474C3.20238 14.2971 2.5 12.6014 2.5 10.8333C2.5 9.06522 3.20238 7.36953 4.45262 6.11929C5.70286 4.86905 7.39856 4.16667 9.16667 4.16667H15.1433L13.03 2.05333L14.2083 0.875L18.3333 5L14.2083 9.125L13.03 7.94667L15.1433 5.83333Z"
        fill="currentColor"
      />
    </svg>
  );
};

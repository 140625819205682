import React from "react";

export const IconEye = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.47921 15.5001C8.94858 18.4552 11.9902 20.4801 15.4999 20.4801C19.0096 20.4801 22.0512 18.4552 23.5206 15.5001C22.0512 12.545 19.0096 10.5201 15.4999 10.5201C11.9902 10.5201 8.94858 12.545 7.47921 15.5001ZM25.6199 15.5001C24.0283 19.5389 20.0999 22.4001 15.4999 22.4001C10.8999 22.4001 6.97148 19.5389 5.37988 15.5001C6.97148 11.4613 10.8999 8.6001 15.4999 8.6001C20.0999 8.6001 24.0283 11.4613 25.6199 15.5001Z" fill="#9CA0A9"/>
      <path d="M18.5111 15.4999C18.5111 17.1628 17.163 18.5108 15.5002 18.5108C13.8373 18.5108 12.4893 17.1628 12.4893 15.4999C12.4893 13.837 13.8373 12.489 15.5002 12.489C17.163 12.489 18.5111 13.837 18.5111 15.4999Z" fill="#302E30"/>
    </svg>
  );
};

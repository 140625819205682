import React from "react";
import * as S from "./how-it-works.style";

type Props = {
  id: number;
  title: string;
  description: string;
};

export const HowItWorksElement = (props: Props) => {
  return (
    <S.StyledHowItWorksElement>
      <S.HowItWorksElementLeft>
        <S.HowItWorksElementIcon>{props.id}</S.HowItWorksElementIcon>
      </S.HowItWorksElementLeft>
      <S.HowItWorksElementRight>
        <S.HowItWorksElementTitle>{props.title}</S.HowItWorksElementTitle>
        <S.HowItWorksElementDescription>{props.description}</S.HowItWorksElementDescription>
      </S.HowItWorksElementRight>
    </S.StyledHowItWorksElement>
  );
};

import React, { useCallback, useEffect, useRef } from 'react';
import * as S from './modal.style';
import { Icon } from '@components/ui/icons/icon';
import { useStore } from '@stores/root';
import { motionOpacity } from '@components/ui/animations/animations';
import { useEsc } from '../../../hooks/useEsc';
import classNames from 'classnames';

export type ModalProps = {
  title?: string;
  outsideButton?: boolean;
  centerContent?: boolean;
  hideBG?: boolean;
  onClose?(): void;
  dontCloseOnClickOutside?: boolean;
  withoutOverflow?: boolean;
  fullScreen?: boolean;
  noBg?: boolean;
  disableOverflowOnContent?: boolean;
  largePadding?: boolean;
  responsiveNoPaddingRight?: boolean;
  responsiveToPaddingBottom?: boolean;
  useModalContainerOverflow?: boolean;
  noMinHeight?: boolean;
  noFullOnMobile?: boolean;
};

export const Modal: FCC<ModalProps> = (props) => {
  const { modal } = useStore();
  const containerRef = useRef<HTMLDivElement>(null);

  const closeModal = useCallback((event: MouseEvent) => {
    const isClickedInside = containerRef.current.contains(event.target as Node);

    if (isClickedInside) return;

    onClose();
  }, []);

  const onClose = () => {
    if (props.onClose) {
      props.onClose();
    }

    modal.close();
  };

  useEsc(onClose);

  useEffect(() => {
    // document.addEventListener('mousedown', closeModal);

    return () => {
      // document.removeEventListener('mousedown', closeModal);
    };
  }, []);

  const className = classNames({ hideBG: props.hideBG });

  return (
    <S.StyledModal
      className={`
       ${props.responsiveNoPaddingRight && 'noPaddingRight'}
         ${props.responsiveToPaddingBottom && 'noPaddingBottom'}
         ${props.useModalContainerOverflow && 'containerOverflow'}
    `}
      {...motionOpacity}
    >
      <S.ModalBG onClick={onClose} />
      <div className="temp">
        <S.ModalBody
          className={`
          ${props.largePadding && 'largePadding'}
        
        `}
          noBg={props.noBg}
          noMinHeight={props.noMinHeight}
          ref={containerRef}
          outsideButton={props.outsideButton}
          fullScreen={props.fullScreen}
          noFullOnMobile={props.noFullOnMobile}
        >
          <S.Close onClick={onClose}>
            <Icon icon="close" />
          </S.Close>
          {props.title && <S.ModalTitle>{props.title}</S.ModalTitle>}

          <S.ModalContent
            withoutOverflow={props.withoutOverflow}
            centerContent={props.centerContent}
          >
            {props.children}
          </S.ModalContent>
        </S.ModalBody>
      </div>
    </S.StyledModal>
  );
};

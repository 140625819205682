import React from "react";
import * as S from "./anchor-link-button-style";

export const AnchorLinkButton = () => {
  return (
    <S.StyledAnchorLinkButton href='#'>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.421051 7.99984L1.75684 9.33563L7.05263 4.04932V15.5788H8.94737V4.04932L14.2337 9.34511L15.5789 7.99984L8 0.420898L0.421051 7.99984Z" fill="white"/>
      </svg>
    </S.StyledAnchorLinkButton>
  )
}
import React from "react";

export const IconArrows = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.95 16.05L6.99995 21L2.04995 16.05L3.46395 14.636L6.00095 17.172L5.99995 4L7.99995 4L7.99995 17.172L10.536 14.636L11.95 16.05ZM21.95 7.95L20.536 9.364L18 6.828L18 20L16 20L16 6.828L13.464 9.364L12.05 7.95L17 3L21.95 7.95Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { Wrapper } from '@components/ui/wrapper/wrapper.style';
import React, { useEffect, useRef, useState } from 'react';
import * as S from './header.style';
import { Icon } from '@components/ui/icons/icon';
import Link from 'next/link';
import { CatalogueButton } from '@components/layout/header/elements/catalogue-button/catalogue-button';
import { Search } from '@components/layout/header/blocks/search/search';
import { LocationButton } from '@components/layout/buttons/location-button/location-button';
import { useStore } from '@stores/root';
import { observer } from 'mobx-react-lite';
import { CategoriesMenu } from '@components/layout/header/blocks/categories-menu/categories-menu';

import { SearchResults } from '@components/layout/header/blocks/search-results/search-results';
import { PopoverCart } from '@components/popovers/popover-cart/popover-cart';
import { PopoverFavorites } from '@components/popovers/popover-favorites/popover-favorites';
import { MobileMenu } from '@components/layout/header/blocks/mobile-menu/mobile-menu';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { InitialDataQuery } from '../../../gql/generated';
import { PopoverOrders } from '@components/popovers/popover-orders/popover-orders';
import { getLogoFontSize } from '../../../utils/logo';

type Props = {
  projectName: string;
  postfix?: string;
  showHeaderBanner?: boolean;
  categories: InitialDataQuery['categories'];
  noHeaderGreyZone?: boolean;
};

export const Header = observer((props: Props) => {
  const { favorites, cart, ui, project } = useStore();
  const router = useRouter();

  const projectNameRef = useRef<HTMLDivElement>();
  const [projectNameWidth, setProjectNameWidth] = useState(0);
  const [showCategoriesMenu, setShowCategoriesMenu] = useState(false);
  const [searchValue, setSearchValue] = useState((router.query.query as string) ?? '');
  const [searchIsFocused, setSearchIsFocused] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const [fixedHeader, setFixedHeader] = useState(false);

  const isProductPage = router.pathname.indexOf('/p') >= 0;

  const toggleCategoriesMenu = () => {
    setShowCategoriesMenu(!showCategoriesMenu);
  };

  const headerClassName = classNames({ fixedHeader: ui.header.fixed });

  useEffect(() => {
    if (projectNameRef.current) {
      setProjectNameWidth(projectNameRef.current.clientWidth);
    }
  }, []);

  getLogoFontSize(project.name, ui.viewport);

  return (
    <S.StyledHeader className={isProductPage && 'productPage'}>
      {!ui.mobileMenu.showCatalogue && (
        <S.MenuBurger
          onClick={ui.toggleMobileMenu}
          className={classNames({ active: ui.mobileMenu.show })}
        >
          <div></div>
          <div></div>
          <div></div>
        </S.MenuBurger>
      )}

      <MobileMenu categories={props.categories} />
      <S.HeaderTop>
        <Wrapper>
          <S.HeaderTopContent>
            <LocationButton />

            <S.Links>
              <Link legacyBehavior prefetch={false} href="/about">
                О сервисе
              </Link>
              {/* <Link legacyBehavior prefetch={false} href="/info/sellers">
                Продавцам
              </Link> */}
              <Link legacyBehavior prefetch={false} href="/info/customers">
                Покупателям
              </Link>
              <Link legacyBehavior prefetch={false} href="/info/delivery">
                Оплата и доставка
              </Link>
              <Link legacyBehavior prefetch={false} href="/info/garanties">
                Гарантия и возврат
              </Link>
              <Link legacyBehavior prefetch={false} href="/contacts">
                Контакты
              </Link>
            </S.Links>
          </S.HeaderTopContent>
        </Wrapper>
      </S.HeaderTop>
      <S.HeaderBottom className={headerClassName}>
        {/* <div style={{
          background: "rgb(111, 94, 226)",
          color: "#fff",
          textAlign: "center",
          borderBottom: "1px solid #767676",
          padding: "6px"
        }}>Вы можете взять этот сайт в аренду, по данному вопросу пишите в телеграм <a href="https://t.me/DanilaLidopad">@DanilaLidopad</a>
        </div> */}
        <Wrapper>
          <S.HeaderBottomContent>
            <S.LogoBlock>
              <Link legacyBehavior prefetch={false} href="/" passHref>
                <S.Logo>{project.logoLetters}</S.Logo>
              </Link>
              <Link legacyBehavior prefetch={false} href="/" passHref>
                <S.LogoMarketNameBlock>
                  {props.projectName && (
                    <S.LogoMarketName
                      fontSize={getLogoFontSize(project.name, ui.viewport).value}
                      isUpperCase={getLogoFontSize(project.name, ui.viewport).isUpperCase}
                      ref={projectNameRef}
                    >
                      {props.projectName}
                    </S.LogoMarketName>
                  )}
                  {props.postfix && <S.LogoMarketSubname>{props.postfix}</S.LogoMarketSubname>}
                </S.LogoMarketNameBlock>
              </Link>
              {!(ui.viewport <= 480 && props.projectName.length > 10) && (
                <>
                  <S.LogoDivider />
                  <S.LogoMarketDescription>специализированный маркетплейс</S.LogoMarketDescription>
                </>
              )}
            </S.LogoBlock>

            <S.CatalogueButton>
              <CatalogueButton onClick={toggleCategoriesMenu} active={showCategoriesMenu} />
            </S.CatalogueButton>

            <S.SearchBox>
              <Search
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                setIsFocused={(state: boolean) => {
                  setSearchIsFocused(state);
                  setSearchModal(state);
                }}
              />
            </S.SearchBox>

            <S.HeaderBottomButtons>
              <S.HeaderBottomButton disabled>
                <PopoverOrders
                  target={
                    <>
                      <S.HeaderBottomButtonIcon href="#">
                        <Icon icon="delivery" />
                        {/* Хак для метрики Accessibility */}
                        <span style={{ opacity: 0, position: 'absolute' }}>Заказы</span>
                        {/* <S.HeaderBottomCountIcon>1</S.HeaderBottomCountIcon> */}
                      </S.HeaderBottomButtonIcon>
                      <S.HeaderBottomButtonText href="#">Заказы</S.HeaderBottomButtonText>
                    </>
                  }
                />
              </S.HeaderBottomButton>

              <S.HeaderBottomButton className="favorites" disabled={favorites.count < 1}>
                <PopoverFavorites
                  target={
                    <>
                      {favorites.count > 1 ? (
                        <Link legacyBehavior prefetch={false} href="/favorites" passHref>
                          <S.HeaderBottomButtonIcon className="favorites">
                            <Icon icon="heart" />
                            {/* Хак для метрики Accessibility */}
                            <span style={{ opacity: 0, position: 'absolute' }}>Избранное</span>
                            {favorites.count > 0 && (
                              <S.HeaderBottomCountIcon>{favorites.count}</S.HeaderBottomCountIcon>
                            )}
                          </S.HeaderBottomButtonIcon>
                        </Link>
                      ) : (
                        <S.DisabledHeaderBottomButtonIcon className="favorites">
                          <Icon icon="heart" />
                          {/* Хак для метрики Accessibility */}
                          <span style={{ opacity: 0, position: 'absolute' }}>Избранное</span>
                          {favorites.count > 0 && (
                            <S.HeaderBottomCountIcon>{favorites.count}</S.HeaderBottomCountIcon>
                          )}
                        </S.DisabledHeaderBottomButtonIcon>
                      )}
                      {favorites.count > 1 ? (
                        <Link legacyBehavior prefetch={false} href="/favorites" passHref>
                          <S.HeaderBottomButtonText>Избранное</S.HeaderBottomButtonText>
                        </Link>
                      ) : (
                        <S.DisabledHeaderBottomButtonText>
                          Избранное
                        </S.DisabledHeaderBottomButtonText>
                      )}
                    </>
                  }
                />
              </S.HeaderBottomButton>

              <S.HeaderBottomButton className="cart">
                <PopoverCart
                  target={
                    <>
                      <Link legacyBehavior prefetch={false} href="/cart" passHref>
                        <S.HeaderBottomButtonIcon className="cart">
                          <Icon icon="cart" />
                          {/* Хак для метрики Accessibility */}
                          <span style={{ opacity: 0, position: 'absolute' }}>Корзина</span>
                          {cart.items.size > 0 && (
                            <S.HeaderBottomCountIcon>{cart.items.size}</S.HeaderBottomCountIcon>
                          )}
                        </S.HeaderBottomButtonIcon>
                      </Link>
                      <Link legacyBehavior prefetch={false} href="/cart" passHref>
                        <S.HeaderBottomButtonText>Корзина</S.HeaderBottomButtonText>
                      </Link>
                    </>
                  }
                />
              </S.HeaderBottomButton>

              <S.HeaderBottomButton>
                <S.HeaderBottomButtonIcon href="#">
                  <Icon icon="userNoBg" />
                  {/* Хак для метрики Accessibility */}
                  <span style={{ opacity: 0, position: 'absolute' }}>Вход</span>
                </S.HeaderBottomButtonIcon>
                <S.HeaderBottomButtonText href="#">Вход</S.HeaderBottomButtonText>
              </S.HeaderBottomButton>
            </S.HeaderBottomButtons>
          </S.HeaderBottomContent>
        </Wrapper>
      </S.HeaderBottom>

      <CategoriesMenu
        show={showCategoriesMenu}
        setShow={setShowCategoriesMenu}
        categories={props.categories}
        className={classNames({ fixedHeader: ui.header.fixed })}
      />
      {/* searchIsFocused */}
      <SearchResults
        setIsModal={setSearchModal}
        isModal={searchModal}
        setSearchString={setSearchValue}
        searchString={searchValue}
        isFocused={searchIsFocused}
      />
    </S.StyledHeader>
  );
});

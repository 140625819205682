import React from 'react';
import * as S from './video.style';
import styled from 'styled-components';
import { IconPlay } from '@components/ui/icons/icon-play';
import { Image } from '@components/Image';
import { useStore } from '@stores/root';

import { VideoSchema } from '@components/schema/video/video-schema';
import { imageLoader } from '../../../../utils/image-loader';
import classNames from 'classnames';

type Props = Video & {
  className?: string;
  isKeyword?: boolean;
};

const Component = (props: Props) => {
  const { modal } = useStore();

  const openVideo = () => {
    modal.show('video', props);
  };

  const imageClassNames = classNames({
    isKeyword: props.isKeyword,
  });

  return (
    <S.StyledMainVideo className={props.className} onClick={openVideo}>
      <VideoSchema video={props} />
      <S.MainVideoImage className={imageClassNames}>
        {props.thumb ? (
          <Image
            loader={imageLoader({
              src: `http:/${props.thumb}`,
              useSrcOnly: true,
              width: 640,
            })}
            fill
            className="custom-img"
            src={`s3:/${props.thumb}`}
            alt={props.title}
            title={props.title}
          />
        ) : (
          <Image
            fill
            style={{ objectFit: 'cover' }}
            src="s3://pics/nophotobig.svg"
            alt={props.title}
            title={props.title}
          />
        )}
        <S.MainVideoPlayButton ariaLabel="Воспроизвести" circle width={50} height={50}>
          <IconPlay />
        </S.MainVideoPlayButton>
      </S.MainVideoImage>
      <S.MainVideoText
        href={`https://www.youtube.com/embed/${props.id}`}
        onClick={(e) => e.preventDefault()}
      >
        {props.title}
      </S.MainVideoText>
    </S.StyledMainVideo>
  );
};

export const MainVideo = styled(Component)``;

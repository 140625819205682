import React from "react";

export const IconVK = () => {
  return (
    <svg width="19" height="10" viewBox="0 0 19 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3631 9.9149C10.3631 9.9149 10.6808 9.88026 10.8435 9.70873C10.9924 9.55157 10.9872 9.25498 10.9872 9.25498C10.9872 9.25498 10.9674 7.87007 11.6225 7.66558C12.2681 7.46448 13.0971 9.00487 13.9768 9.59719C14.6414 10.045 15.1458 9.94701 15.1458 9.94701L17.4967 9.9149C17.4967 9.9149 18.726 9.84055 18.1432 8.89164C18.095 8.8139 17.8032 8.18947 16.3957 6.90679C14.9212 5.56413 15.1192 5.78129 16.8942 3.45845C17.9753 2.04396 18.4075 1.1804 18.2723 0.811146C18.1441 0.457946 17.3487 0.551738 17.3487 0.551738L14.7025 0.567793C14.7025 0.567793 14.5062 0.541599 14.3608 0.626941C14.2187 0.710594 14.1266 0.905783 14.1266 0.905783C14.1266 0.905783 13.7083 2.00003 13.1496 2.93119C11.9711 4.89491 11.5003 4.99884 11.3075 4.87716C10.859 4.59241 10.9709 3.73476 10.9709 3.12553C10.9709 1.2218 11.2653 0.428372 10.3984 0.223043C10.1109 0.1546 9.89916 0.109817 9.16316 0.102212C8.21885 0.0929171 7.42001 0.105592 6.96722 0.32275C6.66593 0.467241 6.43351 0.790021 6.57555 0.808611C6.75029 0.831425 7.14627 0.913388 7.35631 1.19392C7.62746 1.55641 7.618 2.36928 7.618 2.36928C7.618 2.36928 7.7738 4.61015 7.25387 4.88815C6.89749 5.07911 6.40855 4.68958 5.35749 2.90753C4.81948 1.99496 4.41318 0.986055 4.41318 0.986055C4.41318 0.986055 4.33484 0.797626 4.19453 0.696229C4.02495 0.573708 3.78822 0.535684 3.78822 0.535684L1.27378 0.551738C1.27378 0.551738 0.895881 0.561878 0.75729 0.723268C0.634193 0.866069 0.747821 1.16266 0.747821 1.16266C0.747821 1.16266 2.71651 5.68412 4.94602 7.96301C6.99046 10.0518 9.31122 9.9149 9.31122 9.9149H10.3631Z"
        fill="currentColor"
      />
    </svg>
  );
};

import styled from 'styled-components';
import { theme } from '@components/ui/theme';

export const StyledReadMoreText = styled.div`
  font-size: 13px;
  line-height: 150%;
  color: #686a70;
`;

export const ReadMoreButton = styled.a`
  cursor: pointer;
  color: ${theme.colors.primary};
  margin-left: 5px;
`;

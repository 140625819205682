import styled from 'styled-components';
import { scrollbar, theme } from '@components/ui/theme';

export const StyledBreadcrumbs = styled.nav`
  display: flex;
  row-gap: 5px;
  flex-wrap: wrap;
  margin-bottom: 40px;

  @media (max-width: 1000px) {
    margin-bottom: 25px;
  }

  @media (max-width: 480px) {
    ${scrollbar.disable};
    ${scrollbar.alignSidesMobile};
    overflow: auto;
    flex-wrap: nowrap;
  }
`;

export const BreadCrumbItem = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: ${theme.colors.greyPrimary};

  display: flex;

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &:not(:last-child) {
    &:after {
      content: '—';
      display: block;
      margin-left: 2.5px;
      margin-right: 2.5px;
    }
  }

  @media (max-width: 480px) {
    width: max-content;
    white-space: nowrap;
  }

  @media (max-width: 460px) {
    margin: 2px 0;
  }
`;

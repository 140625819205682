import { makeAutoObservable, runInAction } from 'mobx';
import { fetchProducts } from '../utils/products';

/**
 * Корзина
 */

export type CartItem = {
  id: string;
  name: string;
  slug: string;
  price: number;
  priceFrom: number;
  priceTo: number;
  count: number;
  hit?: boolean;
  images: string[];
  offersCount?: number;
  rating: number;
  discount: number;
  ratingCount: number;
};

export class CartStore {
  items = new Map<string, CartItem>();

  constructor() {
    makeAutoObservable(this);

    if (!process.browser) return;

    window.addEventListener('storage', this.syncData, {
      capture: false,
      once: false,
      passive: true,
    });

    this.getData();
  }

  addItem = (item: Omit<CartItem, 'count'>) => {
    const alreadyHasItem = this.items.has(item.id);

    if (alreadyHasItem) {
      return;
    }

    this.items.set(item.id, { ...item, count: 1 });

    this.save();
  };

  removeItem(id: string): void {
    this.items.delete(id);

    this.save();
  }

  removeItems(ids: string[]): void {
    for (const id of ids) {
      this.items.delete(id);
    }

    this.save();
  }

  clear = () => {
    this.items = new Map();
    window.localStorage.setItem('cart', '{}');
  };

  increaseCount(id: string): void {
    const item = this.items.get(id);
    item.count += 1;

    this.save();
  }

  decreaseCount(id: string): void {
    const item = this.items.get(id);

    if (item.count === 1) {
      return this.removeItem(id);
    }

    item.count -= 1;

    this.save();
  }

  has(id: string): boolean {
    return this.items.has(id);
  }

  syncData = (event: StorageEvent) => {
    if (event.storageArea !== localStorage || event.key !== 'cart') {
      return;
    }

    this.getData();
  };

  protected async getData(): Promise<void> {
    try {
      const storageProducts: Record<string, string> = JSON.parse(
        window.localStorage.getItem('cart') ?? '[]'
      );
      const newMap = new Map<string, CartItem>();

      const products = await fetchProducts(Object.keys(storageProducts));

      for (const product of products) {
        newMap.set(product.id, {
          ...product,
          count: Number(storageProducts[product.id]),
        });
      }

      runInAction(() => {
        this.items = newMap;
      });
    } catch {
      return;
    }
  }

  get itemsArray() {
    return [...this.items.values()];
  }

  get size() {
    let size = 0;

    this.items.forEach((item) => {
      size += item.count;
    });

    return size;
  }

  get sum() {
    return this.itemsArray.reduce((accum, curr) => accum + curr.price * curr.count, 0);
  }

  get oldSum() {
    return this.itemsArray.reduce((accum, curr) => {
      const oldPrice = ((curr.price * curr.count) / (100 - curr.discount)) * 100;
      return accum + oldPrice;
    }, 0);
  }

  get discountSum() {
    return this.oldSum - this.sum;
  }

  get productsCount() {
    return this.itemsArray.reduce((accum, curr) => accum + curr.count, 0);
  }

  protected save(): void {
    const data = JSON.stringify(
      [...this.items.values()].reduce((accum, curr) => {
        accum[curr.id] = curr.count;
        return accum;
      }, {})
    );
    window.localStorage.setItem('cart', data);
  }
}

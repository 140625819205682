import styled from 'styled-components';
import { theme } from '@components/ui/theme';

export const ResKitProductSlider = styled.div`
  margin-right: 40px;
  width: 130px;
  min-width: 130px;
  img {
    width: 130px !important;
    height: 100px !important;
  }
`;

export const ResKitProductSlide = styled.div``;

export const ResKitProductBullets = styled.div<{
  hideOnMobileM?: boolean;
  hideOnMobileS?: boolean;
}>`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;

  @media (min-width: 321px) and (max-width: 480px) {
    display: ${(props) => (props.hideOnMobileM ? 'none' : 'flex')};
  }

  @media (min-width: 1px) and (max-width: 321px) {
    display: ${(props) => (props.hideOnMobileS ? 'none' : 'flex')};
  }
`;

export const ResKitProductBullet = styled.div<{ active: boolean }>`
  width: 5px;
  height: 5px;
  background: ${({ active }) => (active ? '#9CA0A9' : '#D9DDE7')};
  border-radius: 50%;
`;

export const ResKitProductMain = styled.div`
  flex: 1;
`;

export const ResKitProductName = styled.a`
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 140%;
  color: #302e30;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  &.responsive {
    display: none;
  }
`;

export const ResKitProductRatingsBlock = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;

  &.responsive,
  &.mobile {
    display: none;
  }
`;

export const ResKitProductRatings = styled.div`
  margin-left: 5px;
  color: #9ca0a9;
  font-size: 11px;
  line-height: 140%;
`;

export const ResKitProductRatingStar = styled.div`
  margin-right: 7px;
  color: ${theme.colors.primary};
`;

export const ResKitProductRatingScore = styled.div`
  font-size: 11px;
  color: ${theme.colors.black};
`;

export const ResKitProductOffers = styled.div`
  margin-left: 10px;
  font-size: 11px;
  line-height: 140%;
  color: #686a70;
`;

export const ResKitProductFavorites = styled.div<{ isFavorite: boolean }>`
  margin-left: 10px;
  color: ${({ isFavorite }) => (isFavorite ? theme.colors.primary : '#9CA0A9')};
  font-size: 11px;
  line-height: 140%;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${({ isFavorite }) => (isFavorite ? theme.colors.primary : '#686a70')};
    }
  }
`;

export const ResKitProductDescription = styled.div`
  margin-top: 15px;
  font-size: 13px;

  &.responsive {
    display: none;
  }
`;

export const ResKitProductButtons = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;

  button {
    width: fit-content;
  }

  &.responsive {
    display: none;
  }

  @media (min-width: 756px) {
    button:last-child {
      margin-left: 15px;
      border-color: ${theme.colors.purple};
      color: ${theme.colors.purple};

      &:hover {
        background-color: ${theme.colors.purple};
        color: #fff;
      }
    }
  }
`;

export const ResKitProductPriceBlock = styled.div`
  margin-left: 40px;
  min-width: 150px;
`;

export const ResKitProductPriceTop = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const ResKitProductPrice = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 120%;
  color: ${theme.colors.black};
`;

export const ResKitProductOldPrice = styled.div`
  margin-left: 10px;
  font-size: 14px;
  line-height: 140%;
  color: #9ca0a9;
  text-decoration: line-through;
`;

export const ResKitProductPriceRange = styled.div`
  margin-top: 5px;
  font-size: 12px;
  line-height: 130%;
  color: #686a70;
`;

export const ResKitProductLabels = styled.div`
  margin-top: 15px;
  display: flex;
  gap: 7px;

  &.responsive {
    display: none;
  }
`;

export const StyledResponsiveKitProduct = styled.div`
  display: flex;
  padding-bottom: 30px;
  &:not(:last-child) {
    margin-bottom: 30px;
    border-bottom: 1px solid #d9dde7;
  }

  @media (max-width: 780px) {
    ${ResKitProductPriceBlock} {
      margin-right: 0;
    }

    ${ResKitProductRatingsBlock} {
      flex-wrap: wrap;
      row-gap: 10px;
    }
  }

  @media (max-width: 755px) {
    flex-wrap: wrap;

    ${ResKitProductName} {
      display: none;

      &.responsive {
        margin-bottom: 20px;
        display: block;
        flex-basis: 100%;
      }
    }

    ${ResKitProductDescription}, ${ResKitProductButtons} {
      display: none;

      &.responsive {
        display: block;
      }
    }

    ${ResKitProductButtons} {
      margin-top: 20px;
      flex-basis: 100%;
      button {
        width: 100%;
      }
    }
    ${ResKitProductBullets}, ${ResKitProductMain} {
      display: none;
    }
    ${ResKitProductSlider} {
      margin-right: 20px;
      width: 110px;
      height: 85px;
      min-width: 110px;
      img {
        height: 85px !important;
      }
    }
    ${ResKitProductDescription} {
      flex-basis: 100%;
    }
    ${ResKitProductRatingsBlock} {
      margin-top: 10px;
      display: none;

      &.responsive {
        display: flex;
      }
    }
    ${ResKitProductPriceBlock} {
      margin-left: 0;
      flex: 1;
    }
    ${ResKitProductLabels} {
      margin-left: 15px;
      display: none;

      &.responsive {
        display: flex;
      }
    }
  }

  //@media (max-width: 650px) {
  //    &:not(:last-of-type) {
  //        margin-bottom: 30px;
  //        padding-bottom: 0;
  //        border: none;
  //    }
  //}

  @media (max-width: 480px) {
    ${ResKitProductRatingsBlock} {
      margin-top: 15px;
      &.responsive {
        display: none;
      }

      &.mobile {
        display: flex;
      }
    }
    ${ResKitProductLabels} {
      margin-top: 10px;
      margin-left: 0;
      display: flex;

      &.responsive {
        display: none;
      }
    }
  }
`;

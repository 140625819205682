import styled from 'styled-components';
import { theme } from '@components/ui/theme';

export const StyledLikes = styled.div`
  display: flex;
`;

export const LikeIcon = styled.div``;

export const LikeBox = styled.div`
  margin-left: 9px;
  //width: 26px;
  height: 26px;

  padding-left: 7px;
  padding-right: 7px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 3px;
`;

export const LikeBoxText = styled.div`
  font-size: 12px;
  font-weight: bold;
  line-height: 120%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #27ae60;
`;

export const LikeBlock = styled.div<{ dislike?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:first-child) {
    margin-left: 14px;
  }

  ${LikeIcon} {
    color: #9ca0a9;
  }

  ${LikeBox} {
    background: ${({ dislike }) => (dislike ? '#F8E1EB' : '#F0F8E1')};
  }

  ${LikeBoxText} {
    color: ${({ dislike }) => (dislike ? '#E73535' : '#27AE60')};
  }

  &:hover {
    ${LikeIcon} {
      color: ${theme.colors.greySecondary};
    }

    ${LikeBox} {
      background: ${({ dislike }) => (dislike ? theme.colors.primary : '#27AE60 ')};
    }

    ${LikeBoxText} {
      color: white;
    }
  }
`;

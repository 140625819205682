import React, { useState } from 'react';
import * as S from './last-viewed-products.style';
import { BlockTitle } from '@components/ui/block-title/block-title';
import { Product } from '../../../gql/generated';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores/root';
import { ProductAvailablePackage } from '@components/product/elements/available-package/available-package';
import { LastViewedProductsSlider } from '@components/blocks/last-viewed-products/last-viewed-products-slider';
import { LastViewedCard } from '@components/blocks/last-viewed-products/last-viewed-card/last-viewed-card';

type Props = {
  products?: Product[];
  excludeIDs?: string[];
  withCommonBlockWithTitle?: boolean;
};

export const LastViewedProducts = observer((props: Props) => {
  const { lastViewed, ui } = useStore();
  const [excluded, _] = useState(new Set(props.excludeIDs));
  const set = new Set(props.excludeIDs);
  const size = lastViewed.products.filter((p) => !set.has(p.id)).length;

  const products = lastViewed.products.reverse();

  if (size === 0) return null;

  return (
    <S.StyledLastViewedProducts className={size > 2 && ui.viewport >= 526 && 'slider'}>
      <BlockTitle>Вы недавно смотрели</BlockTitle>

      {size === 1 && (
        <S.LastViewedSingle>
          {ui.viewport <= 1000 ? (
            <LastViewedCard {...products[0]} />
          ) : (
            <ProductAvailablePackage altPadding withBorder withoutExtInfo {...products[0]} />
          )}
        </S.LastViewedSingle>
      )}

      {size === 2 &&
        ui.viewport > 525 &&
        (ui.viewport >= 980 ? (
          <S.LastViewedProductsTwice>
            <ProductAvailablePackage withBorder withoutExtInfo {...products[0]} />
            <ProductAvailablePackage withBorder withoutExtInfo {...products[1]} />
          </S.LastViewedProductsTwice>
        ) : (
          <S.ResponsiveProductCardsWrapper>
            <S.ResponsiveProductCardsContainer>
              {products.map((p) => !excluded.has(p.id) && <LastViewedCard key={p.id} {...p} />)}
            </S.ResponsiveProductCardsContainer>
          </S.ResponsiveProductCardsWrapper>
        ))}

      {size > 2 && ui.viewport > 1000 && (
        <LastViewedProductsSlider excluded={excluded} size={size} />
      )}

      {size > 1 && ui.viewport <= 1000 && (
        <S.ResponsiveProductCardsWrapper>
          <S.ResponsiveProductCardsContainer>
            {products.map((p) => !excluded.has(p.id) && <LastViewedCard key={p.id} {...p} />)}
          </S.ResponsiveProductCardsContainer>
        </S.ResponsiveProductCardsWrapper>
      )}
    </S.StyledLastViewedProducts>
  );
});

import { Rating } from '@components/ui/rating-stars/rating-stars.style';
import styled from 'styled-components';
import { theme } from '@components/ui/theme';

export const ReviewTop = styled.div`
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
`;

export const ReviewLeft = styled.a`
  position: relative;
  width: 77px;
  height: 58px;
  flex-shrink: 0;
`;

export const ReviewRight = styled.div`
  margin-left: 16px;
  width: 100%;
`;

export const ReviewInfo = styled.div`
  display: flex;
  //align-items: center;
  justify-content: space-between;
`;

export const ReviewTitle = styled.a`
  font-size: 17px;
  line-height: 120%;

  color: ${theme.colors.black};

  &:hover {
    text-decoration: underline;
  }
`;

export const ReviewUser = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
`;

export const ReviewUserAvatar = styled.div`
  width: 37px;
  min-width: 37px;
  height: 37px;
  background: #d9dde7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

export const ReviewUserName = styled.div`
  font-size: 15px;
  line-height: 140%;
  color: #302e30;
  margin-left: 9px;
`;

export const ReviewRating = styled.div`
  margin-left: 16px;
  ${Rating} {
    height: 25px;
  }

  @media (max-width: 1000px) {
    margin-left: 0;
  }
`;

export const ReviewCategory = styled.div`
  margin-top: 16px;
`;

export const ReviewCategoryTitle = styled.div`
  font-size: 14px;
  line-height: 140%;
  color: #302e30;
`;

export const ReviewCategoryText = styled.div`
  font-size: 13px;
  line-height: 150%;
  color: #686a70;
  margin-top: 8px;
`;

export const ReviewLikes = styled.div`
  margin-top: 16px;
`;

export const StyledReview = styled.article`
  background: white;
  border-radius: 3px;
  width: 100%;
  padding: 30px;
  display: flex;
  box-sizing: border-box;

  &.responsive {
    flex-direction: column;

    ${ReviewTop} {
      ${ReviewLeft} {
        margin-right: 12px;
      }

      ${ReviewInfo} {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }

    ${ReviewUser} {
      margin-top: 19px;
    }
  }
`;

import styled from 'styled-components';
import { theme } from '../theme';

export const StyledButton = styled.button`
  background: #fafafa;
  border: 1px solid #fafafa;
  border-radius: 3px;
  padding: 8px 20px;
  min-width: max-content;

  display: flex;
  align-items: center;

  font-size: 14px;
  color: ${theme.colors.black};

  svg {
    margin-left: 7px;
  }

  &.largeMargin svg {
    margin-left: 10px;
  }

  &:hover {
    border: 1px solid ${theme.colors.primary};
  }
`;

import { makeAutoObservable, runInAction } from "mobx";
import type { StoreProduct } from "types/store-product";
import RootStore from "./root";
import { fetchProducts } from "../utils/products";

/**
 * Избранное
 */
export default class FavoritesStore {
  _initialized = false;

  _products: Map<string, StoreProduct> = new Map();
  root: RootStore;

  constructor(root: RootStore) {
    makeAutoObservable(this);
    this.root = root;

    if (!process.browser) return;
    window.addEventListener('storage', this.syncData, {
      capture: false,
      once: false,
      passive: true,
    });
    this.getData();
  }

  get isInitialized(): boolean {
    return this._initialized;
  }

  /**
   * Количество позиций
   */
  get count(): number {
    return this._products.size;
  }

  get sum() {
    return this.products.reduce((accum, curr) => accum + curr.price, 0);
  }

  get products(): StoreProduct[] {
    return [...this._products.values()];
  }

  /**
   * Добавить в избранное
   * @param product
   */
  add(product: StoreProduct): void {
    const alreadyHasProduct = this._products.has(product.id);

    if (alreadyHasProduct) {
      return;
    }

    this._products.set(product.id, product);

    this.saveData();
  }

  /**
   * Проверить наличие в избранном
   * @param productOrID
   */
  has(productOrID: StoreProduct | string): boolean {
    const id = typeof productOrID === 'string' ? productOrID : productOrID?.id;
    return this._products.has(id);
  }

  /**
   * Убрать из избранного
   * @param productOrID
   */
  remove(productOrID: StoreProduct | string): void {
    const id = typeof productOrID === 'string' ? productOrID : productOrID.id;

    this._products.delete(id);

    this.saveData();
  }

  /**
   * Вызывается, когда извне был изменен localStorage, синкаем избранное
   * @param event
   * @private
   */
  protected syncData = (event: StorageEvent): void => {
    if (event.storageArea !== localStorage || event.key !== 'favorites') {
      return;
    }

    this.getData();
  };

  /**
   * Обновляем данные о продуктах
   * @private
   */
  protected async getData(): Promise<void> {
    try {
      const ids: string[] = JSON.parse(window.localStorage.getItem('favorites') ?? '[]');
      const newMap = new Map<string, StoreProduct>();

      const products = await fetchProducts(ids);

      for (const product of products) {
        newMap.set(product.id, product);
      }

      runInAction(() => {
        this._products = newMap;
      });
    } catch {
      return;
    }
  }

  /**
   * Сохраняем изменения
   * @private
   */
  protected saveData(): void {
    const data = JSON.stringify([...this._products.values()].map(({ id }) => id));
    window.localStorage.setItem('favorites', data);
  }
}

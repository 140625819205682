import { Router } from 'next/router';
import throttle from 'lodash/throttle';
import { autorun, runInAction } from 'mobx';
import merge from 'lodash/merge';
import { getSelectorsByUserAgent } from 'react-device-detect';

const { makeAutoObservable } = require('mobx');

type UIStoreType = {
  mobileMenu: {
    show: boolean;
    showCatalogue: boolean;
    showMenuAfterCatalogue: boolean;
  };
};

export class UIStore {
  mobileMenu = {
    show: false,
    showCatalogue: false,
    component: null,
    showMenuAfterCatalogue: true,
  };

  fontsLoaded = false;

  header = {
    fixed: false,
  };

  route = {
    current: '',
    splittedCurrent: [],
  };

  scroll = {
    top: 0,
  };

  popoverProductCartId = '';
  viewport = 1920;

  constructor() {
    makeAutoObservable(this);

    if (!process.browser) return;

    this.viewport = Math.max(document.documentElement.clientWidth || 0);

    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onResize);

    document.addEventListener('click', (e: any) => {
      const el = e.target as HTMLElement;

      if (
        this.viewport <= 1000 &&
        el.tagName.toUpperCase() !== 'BUTTON' &&
        el.tagName.toUpperCase() !== 'SVG' &&
        el.tagName.toUpperCase() !== 'PATH' &&
        this.popoverProductCartId.length > 0
      ) {
        this.setPopoverProductId('');
      }
    });

    Router.events.on('routeChangeComplete', this.routeChangeComplete);

    this.routeChangeComplete();

    // autorun(() => {
    //   document.querySelector('body').style.overflow = this.fontsLoaded ? 'auto' : 'hidden';
    // });
  }

  hydrate = (userAgent: string) => {
    const defaultUserAgent = {
      isTablet: false,
      isMobile: false,
    };

    const selectors = getSelectorsByUserAgent(userAgent) ?? defaultUserAgent;

    if (selectors.isTablet) {
      this.viewport = 1000;

      if (process.browser) {
        document.body.classList.add('tablet');
      }
    } else if (selectors.isMobile && !selectors.isTablet) {
      this.viewport = 480;

      if (process.browser) {
        document.body.classList.add('mobile');
      }
    }
  };

  toggleMobileMenu = () => {
    this.mobileMenu.show = !this.mobileMenu.show;
    this.toggleBodyOverflow();
  };

  setState = (data: DeepPartial<UIStore>) => merge(this, data);

  toggleMobileCatalogue = () => {
    this.mobileMenu.showCatalogue = !this.mobileMenu.showCatalogue;
  };

  toggleBodyOverflow = (v?: string) => {
    const body = document.querySelector('body');
    const value = body.style.overflow;

    if (value === 'hidden') {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }

    if (v) body.style.overflow = v;
  };

  onScroll = () => {
    this.scroll.top = window.scrollY;

    if (
      window.scrollY >= 49 &&
      (!(this.route.splittedCurrent[1] === 'p') || window.innerWidth <= 768)
    ) {
      if (this.header.fixed) return;
      this.setFixedHeader(true);
    } else {
      if (!this.header.fixed) return;
      this.setFixedHeader(false);
    }
  };

  onResize = throttle(() => {
    runInAction(() => {
      this.viewport = Math.max(document.documentElement.clientWidth || 0);
    });
  }, 500);

  setFixedHeader = (state: boolean) => {
    this.header.fixed = state;
  };

  setPopoverProductId = (id: string) => {
    this.popoverProductCartId = id;
  };

  routeChangeComplete = () => {
    const route = window.location.pathname;
    const splittedRoute = route.split('/');
    this.route.current = route;
    this.route.splittedCurrent = splittedRoute;
  };

  toggleMobileCatalogueFromTapBar = () => {
    if (!this.mobileMenu.show) {
      this.mobileMenu.show = !this.mobileMenu.show;
      this.mobileMenu.showCatalogue = true;
      this.mobileMenu.showMenuAfterCatalogue = false;
      this.toggleBodyOverflow('hidden');
      return;
    }

    this.mobileMenu.showCatalogue = false;
    this.mobileMenu.show = false;
    this.mobileMenu.showMenuAfterCatalogue = true;
    this.toggleBodyOverflow('auto');
  };

  setShowMobileMenu = (state: boolean) => {
    this.mobileMenu.show = state;

    if (!state) {
      this.mobileMenu.showCatalogue = false;
    }
  };

  setFontsLoaded = (state: boolean) => {
    this.fontsLoaded = state;
  };
}

import styled from 'styled-components';
import { device, theme } from '@components/ui/theme';
import { BlockTitle } from '@components/ui/block-title/block-title';
import { ShowMoreButton } from '@components/ui/show-more-button/show-more-button';

export const FaqItem = styled.a`
  border-bottom: 1px solid #d9dde7;
  padding-bottom: 27px;

  font-size: 15px;
  line-height: 140%;
  color: ${theme.colors.black};

  cursor: pointer;

  &:hover {
    color: ${theme.colors.primary};
    border-color: ${theme.colors.primary};
  }
`;

export const FaqItems = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 30px;
  grid-column-gap: 40px;
`;

export const StyledFaq = styled.div`
  ${ShowMoreButton} {
    margin-top: 35px;
  }

  @media ${device.tabletM} {
    grid-row-gap: 20px;
    grid-column-gap: 24px;

    ${FaqItems} {
      grid-column-gap: 20px;
    }

    ${FaqItem} {
      padding-bottom: 15px;
      font-size: 15px;
      line-height: 21px;
    }

    ${BlockTitle} {
      margin-bottom: 35px;
    }
  }

  @media ${device.mobileM} {
    ${FaqItems} {
      grid-row-gap: 20px;
      grid-template-columns: 1fr;
    }

    ${BlockTitle} {
      margin-bottom: 25px;
    }

    ${ShowMoreButton} {
      margin-top: 25px;
      height: 40px;
    }
  }
`;

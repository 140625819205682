import { useEffect } from "react";
import { isMobile, isTablet } from "react-device-detect";

export const mobileEffect = () => useEffect(() => {
  if (isMobile && !isTablet) {
    document.body.classList.add('mobile');
  }

  if (isTablet) {
    document.body.classList.add('tablet');
  }
}, []);

import React, { useMemo, useRef, useState } from 'react';
import * as S from './responsive-kit-product-styles';
import { Product } from '../../../../gql/generated';
import Link from 'next/link';
import { Image } from '@components/Image';
import { useSwipeable } from 'react-swipeable';
import { RatingStars } from '@components/ui/rating-stars/rating-stars';
import { TextWithIcon } from '@components/ui/text-with-icon/text-with-icon';
import { IconHeartFilled } from '@components/ui/icons/icon-heart-filled';
import { IconHeart } from '@components/ui/icons/icon-heart';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores/root';
import plural from 'plural-ru';
import { Button } from '@components/ui/button/button';
import { Label } from '@components/ui/label/label';
import { ProductCardDescription } from '@components/product/elements/product-card-description/product-card-description';
import { ReadMoreText } from '@components/ui/read-more-text/read-more-text';
import { removeTags } from '../../../../utils/formaters';

type Props = Pick<
  Product,
  | 'images'
  | 'slug'
  | 'price'
  | 'id'
  | 'hit'
  | 'name'
  | 'priceFrom'
  | 'priceTo'
  | 'rating'
  | 'ratingCount'
  | 'discount'
> & {
  offersCount?: number;
  description?: string;
  shortDescription?: string;
};

export const ResponsiveKitProduct: FCC<Props> = observer((props) => {
  const imageSlides = useMemo(() => (props.images ? props.images?.slice(0, 5) : []), []);
  const [mobileImage, setMobileImage] = useState(0);
  const descriptionRef = useRef<HTMLDivElement>(null);

  const offersPlural = plural(
    props?.offersCount,
    '%d предложение',
    '%d предложения',
    '%d предложений'
  );
  const { favorites, cart, modal, ui } = useStore();

  const isFavorite = favorites.has(props.id);
  const isInCart = cart.has(props.id);

  const oldPrice = useMemo(() => (props.price / (100 - props.discount)) * 100, []);

  const addToFavorites = (): void => {
    if (isFavorite) {
      return favorites.remove(props.id);
    }
    favorites.add({
      id: props.id,
      name: props.name,
      slug: props.slug,
      price: props.price,
      priceFrom: props.priceFrom,
      priceTo: props.priceTo,
      images: props.images,
      hit: props.hit,
      rating: props.rating,
      ratingCount: props.ratingCount,
      discount: props.discount,
    });
  };

  const addToCart = (): void => {
    cart.addItem({
      id: props.id,
      name: props.name,
      price: props.price,
      slug: props.slug,
      images: props.images,
      priceFrom: props.priceFrom,
      priceTo: props.priceTo,
      hit: props.hit,
      rating: props.rating,
      discount: props.discount,
      ratingCount: props.ratingCount,
    });
  };

  const openOrderModal = () => {
    modal.show('oneClickOrder', {
      id: props.id,
      name: props.name,
      slug: props.slug,
      price: props.price,
      image: props.images[0],
      discount: props.discount,
    });
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (mobileImage >= imageSlides.length - 1) {
        return setMobileImage(0);
      }

      setMobileImage(mobileImage + 1);
    },
    onSwipedRight: () => {
      if (mobileImage === 0) {
        return setMobileImage(imageSlides.length - 1);
      }

      setMobileImage(mobileImage - 1);
    },
    preventScrollOnSwipe: true,
  });

  return (
    <S.StyledResponsiveKitProduct>
      <Link legacyBehavior prefetch={false} href={`/p/${props.slug}`} passHref>
        <S.ResKitProductName className="responsive">
          {props.name}
        </S.ResKitProductName>
      </Link>

      <S.ResKitProductSlider {...swipeHandlers}>
        {imageSlides?.length === 0 && (
          <Link legacyBehavior prefetch={false} href={`/p/${props.slug}`} passHref>
            <a>
              <Image
                src="s3://pics/nophotocard.svg"
                width={192}
                height={127}
                style={{ objectFit: 'contain' }}
                alt={props.name}
                title={props.name}
              />
            </a>
          </Link>
        )}

        {imageSlides?.length > 0 && (
          <S.ResKitProductSlide>
            <Link legacyBehavior prefetch={false} href={`/p/${props.slug}`} passHref>
              <a>
                <Image
                  src={`s3:/${imageSlides[mobileImage]}`}
                  width={192}
                  height={127}
                  style={{ objectFit: 'contain' }}
                  alt={props.name}
                  title={props.name}
                />
              </a>
            </Link>

            {imageSlides.length > 1 && (
              <S.ResKitProductBullets hideOnMobileM hideOnMobileS>
                {imageSlides.map((img, index) => (
                  <S.ResKitProductBullet key={img} active={index === mobileImage} />
                ))}
              </S.ResKitProductBullets>
            )}
          </S.ResKitProductSlide>
        )}
      </S.ResKitProductSlider>

      <S.ResKitProductMain>
        <Link legacyBehavior prefetch={false} href={`/p/${props.slug}`} passHref>
          <S.ResKitProductName>{props.name}</S.ResKitProductName>
        </Link>

        <S.ResKitProductRatingsBlock>
          {/* <S.ResKitProductRatingStar> */}
          {/*   <Icon icon="starFilled" /> */}
          {/* </S.ResKitProductRatingStar> */}

          <RatingStars
            coeff={0.95}
            rating={props.rating}
            votes={props.ratingCount}
            hideStarsOnTabletM={ui.viewport < 1000}
            hideStarsOnTabletS
            hideStarsOnMobileS
            hideStarsOnMobileM
          />

          {/* <S.ResKitProductRatingScore>{props?.rating?.toFixed(1)}</S.ResKitProductRatingScore> */}

          <S.ResKitProductFavorites isFavorite={isFavorite}>
            <TextWithIcon onClick={addToFavorites} icon={isFavorite ? IconHeartFilled : IconHeart}>
              {isFavorite ? 'В избранном' : 'В избранное'}
            </TextWithIcon>
          </S.ResKitProductFavorites>
          {props.offersCount > 0 && <S.ResKitProductOffers>{offersPlural}</S.ResKitProductOffers>}
        </S.ResKitProductRatingsBlock>
        {props.shortDescription && (
          <S.ResKitProductDescription ref={descriptionRef}>
            <ReadMoreText text={removeTags(props.shortDescription)} />
          </S.ResKitProductDescription>
        )}

        <S.ResKitProductButtons>
          <Button outline onClick={openOrderModal}>
            Хочу купить
          </Button>
          <Button outline onClick={addToCart}>
            В корзину
          </Button>
        </S.ResKitProductButtons>
      </S.ResKitProductMain>

      <S.ResKitProductPriceBlock>
        <S.ResKitProductPriceTop>
          <S.ResKitProductPrice>{props.price.toLocaleString('ru-RU')} ₽</S.ResKitProductPrice>
          {props.discount > 0 && (
            <S.ResKitProductOldPrice>
              {Number(oldPrice.toFixed(0)).toLocaleString('ru-RU')} ₽
            </S.ResKitProductOldPrice>
          )}
          {(props.hit || props.discount > 0) && (
            <S.ResKitProductLabels className="responsive">
              {props.discount > 0 && <Label color="pink">{props.discount}%</Label>}
              {props.hit && <Label color="purple">ХИТ</Label>}
            </S.ResKitProductLabels>
          )}
        </S.ResKitProductPriceTop>

        {props.priceTo !== props.priceFrom && (
          <S.ResKitProductPriceRange>
            от {props.priceFrom.toLocaleString('ru-RU')} до {props.priceTo.toLocaleString('ru-RU')}{' '}
            ₽
          </S.ResKitProductPriceRange>
        )}

        {(props.hit || props.discount > 0) && (
          <S.ResKitProductLabels>
            {props.discount > 0 && <Label color="pink">{props.discount}%</Label>}
            {props.hit && <Label color="purple">ХИТ</Label>}
          </S.ResKitProductLabels>
        )}

        <S.ResKitProductRatingsBlock className="responsive">
          {/* <S.ResKitProductRatingStar> */}
          {/*   <Icon icon="starFilled" /> */}
          {/* </S.ResKitProductRatingStar> */}

          <RatingStars
            rating={props.rating}
            votes={props.ratingCount}
            hideStarsOnTabletS
            hideStarsOnMobileS
            hideStarsOnMobileM
          />

          {/* <S.ResKitProductRatingScore>{props?.rating?.toFixed(1)}</S.ResKitProductRatingScore> */}

          <S.ResKitProductFavorites isFavorite={isFavorite}>
            <TextWithIcon onClick={addToFavorites} icon={isFavorite ? IconHeartFilled : IconHeart}>
              {isFavorite ? 'В избранном' : 'В избранное'}
            </TextWithIcon>
          </S.ResKitProductFavorites>
          {props.offersCount > 0 && <S.ResKitProductOffers>{offersPlural}</S.ResKitProductOffers>}
        </S.ResKitProductRatingsBlock>
      </S.ResKitProductPriceBlock>

      <S.ResKitProductRatingsBlock className="mobile">
        {/* <S.ResKitProductRatingStar> */}
        {/*   <Icon icon="starFilled" /> */}
        {/* </S.ResKitProductRatingStar> */}

        <RatingStars
          rating={props.rating}
          votes={props.ratingCount}
          hideStarsOnTabletS
          hideStarsOnMobileS
          hideStarsOnMobileM
        />

        {/* <S.ResKitProductRatingScore>{props?.rating?.toFixed(1)}</S.ResKitProductRatingScore> */}

        <S.ResKitProductFavorites isFavorite={isFavorite}>
          <TextWithIcon onClick={addToFavorites} icon={isFavorite ? IconHeartFilled : IconHeart}>
            {isFavorite ? 'В избранном' : 'В избранное'}
          </TextWithIcon>
        </S.ResKitProductFavorites>
        {props.offersCount > 0 && <S.ResKitProductOffers>{offersPlural}</S.ResKitProductOffers>}
      </S.ResKitProductRatingsBlock>

      {props.shortDescription && (
        <S.ResKitProductDescription className="responsive" ref={descriptionRef}>
          <ReadMoreText text={removeTags(props.shortDescription)} />
        </S.ResKitProductDescription>
      )}

      <S.ResKitProductButtons className="responsive">
        <Button outline onClick={openOrderModal}>
          Хочу купить
        </Button>
      </S.ResKitProductButtons>
    </S.StyledResponsiveKitProduct>
  );
});

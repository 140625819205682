import React from "react";

export const IconLoupeMenu = () => {
  return (
    <svg width="27" height="17" viewBox="0 0 27 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.4708 13.0736C18.2066 13.0736 18.9352 12.9287 19.6149 12.6471C20.2947 12.3655 20.9123 11.9528 21.4326 11.4326C21.9529 10.9123 22.3656 10.2947 22.6471 9.61491C22.9287 8.93515 23.0736 8.20659 23.0736 7.47083C23.0736 6.73506 22.9287 6.0065 22.6471 5.32674C22.3656 4.64698 21.9529 4.02934 21.4326 3.50907C20.9123 2.98881 20.2947 2.57611 19.6149 2.29454C18.9352 2.01298 18.2066 1.86806 17.4708 1.86806C15.9849 1.86806 14.5598 2.45835 13.5091 3.50907C12.4584 4.55979 11.8681 5.98488 11.8681 7.47083C11.8681 8.95677 12.4584 10.3819 13.5091 11.4326C14.5598 12.4833 15.9849 13.0736 17.4708 13.0736ZM23.3724 12.052L26.7154 15.395C26.8045 15.4812 26.8756 15.5843 26.9245 15.6983C26.9733 15.8122 26.999 15.9348 27 16.0588C27.001 16.1827 26.9772 16.3057 26.9302 16.4204C26.8832 16.5351 26.8138 16.6394 26.726 16.727C26.6383 16.8146 26.534 16.8838 26.4192 16.9307C26.3044 16.9776 26.1814 17.0011 26.0574 17C25.9334 16.9988 25.8109 16.9729 25.697 16.9239C25.5832 16.8749 25.4802 16.8037 25.3941 16.7145L22.0511 13.3715C20.55 14.5367 18.6612 15.0861 16.7693 14.9077C14.8774 14.7294 13.1246 13.8368 11.8676 12.4116C10.6107 10.9864 9.94415 9.13573 10.0037 7.23637C10.0632 5.33701 10.8443 3.53173 12.188 2.18802C13.5317 0.844311 15.337 0.06319 17.2364 0.00366889C19.1357 -0.0558522 20.9864 0.610702 22.4116 1.86764C23.8368 3.12458 24.7294 4.87741 24.9078 6.76932C25.0861 8.66122 24.5367 10.55 23.3715 12.0511L23.3724 12.052Z" fill="#302E30"/>
      <rect width="10" height="2" fill="#302E30"/>
      <rect y="7" width="6" height="2" fill="#302E30"/>
      <rect y="14" width="10" height="2" fill="#302E30"/>
    </svg>
  );
};

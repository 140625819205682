import React, { useId } from "react";
import * as S from "./select-with-icon.style";
import SelectComponent, { components } from "react-select";
import { theme } from "@components/ui/theme";
import { Icon } from "@components/ui/icons/icon";

type Props = {
  options: SelectOption[];
  value: SelectOption;
  onChange(data: SelectOption): void;
};

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    height: '100%',
    background: 'none',
  }),
  control: (provided, state) => ({
    ...provided,
    padding: '0',
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    fontSize: '14px',
    minHeight: '100%',
    height: '100%',
    background: 'none',
    cursor: 'pointer',
    color: state.isFocused ? theme.colors.primary : theme.colors.black,

    '&:hover': {
      'div': {
        borderColor: 'transparent'
      }
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '0',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    margin: '0',
    lineHeight: '15px',
    borderBottom: '1px dotted transparent',
    borderColor: state.selectProps.menuIsOpen ? 'transparent' : '#302E30',
    color: state.selectProps.menuIsOpen ? theme.colors.primary : theme.colors.black,
  }),
  indicatorsContainer: (provided, state) => ({
    display: 'none',
  }),
  indicatorSeparator: (provided, state) => ({}),
  dropdownIndicator: (provided, state) => ({
    width: '12px',
    svg: {
      width: '100%',
      height: 'auto',
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    background: '#FFFFFF',
    boxShadow: '0px 3px 20px rgba(0, 0, 0, 0.09)',
    borderRadius: '2px',
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '16px',
    color: theme.colors.black,
    padding: '14px 0',
    width: '281px',
    right: '-2px',
    zIndex: 3
  }),
  menuList: (provided, state) => ({
    padding: '0',
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '8px 25px',
    background: state.isSelected ? '#F9FAFC' : 'transparent',
    color: theme.colors.black,
    '&:hover': {
      background: '#F9FAFC',
    },
  }),
};

const Control = ({ children, ...props }) => (
  //@ts-ignore
  <components.Control {...props}>
    <S.SelectIcon>
      <Icon icon="arrows" />
    </S.SelectIcon>
    {children}
  </components.Control>
);

export const SelectWithIcon = (props: Props) => {
  const instanceId = useId()

  return (
    <S.StyledSelect>
      <SelectComponent
        instanceId={instanceId}
        value={props.value}
        options={props.options}
        styles={customStyles}
        onChange={props.onChange}
        isSearchable={false}
        components={{ Control }}
      />
    </S.StyledSelect>
  );
};

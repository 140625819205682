import styled from 'styled-components';
import { device, theme } from '@components/ui/theme';

export const Text = styled.div`
  color: ${theme.colors.black};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledTag = styled.a`
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: baseline;
  justify-content: center;
  background: #fafafa;
  border-radius: 3px;
  padding: 9px 20px;
  border: 1px solid transparent;
  box-sizing: border-box;

  cursor: pointer;

  &:hover {
    background: transparent;
    border-color: ${theme.colors.primary};
  }

  &.active {
    background: ${theme.colors.primary};
    border-color: ${theme.colors.primary};

    ${Text} {
      color: white;
    }
  }

  @media ${device.mobileM} {
    padding: 6px 12px;
    font-size: 13px;
    line-height: 18.2px;
  }
`;

export const Count = styled.div`
  color: ${theme.colors.greyPrimary};
`;

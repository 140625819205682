import classNames from 'classnames';
import React from 'react';
import * as S from './button.style';
import styled from 'styled-components';
import { Icon, Icons } from '@components/ui/icons/icon';
import { Loader } from '@components/ui/loader/loader';

type Props = {
  type?: 'submit' | 'button' | 'reset';
  className?: string;
  outline?: boolean;
  secondary?: boolean;
  onClick?(): void;
  icon?: keyof typeof Icons;
  disabled?: boolean;
  loading?: boolean;
};

const Component: FCC<Props> = ({ type = 'submit', ...props }) => {
  const classes = classNames(props.className, {
    outline: props.outline,
    secondary: props.secondary,
  });

  return (
    <S.StyledButton
      disabled={props.disabled}
      type={type}
      className={classes}
      onClick={props.onClick}
    >
      {props.icon && (
        <S.Icon>
          <Icon icon={props.icon} />
        </S.Icon>
      )}
      {props.children}

      {props.loading && <Loader color="white" />}
    </S.StyledButton>
  );
};

export const Button = styled(Component)``;

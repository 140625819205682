import { Likes } from '@components/product/elements/likes/likes';
import { IconUser } from '@components/ui/icons/icon-user';
import { RatingStars } from '@components/ui/rating-stars/rating-stars';
import { Image } from '@components/Image';
import Link from 'next/link';
import React from 'react';
import * as S from './review.style';
import { ReviewProps } from './review.types';
import { imageLoader } from '../../../../utils/image-loader';
import { ReadMoreText } from '@components/ui/read-more-text/read-more-text';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores/root';

export const ReviewResponsive: FCC<ReviewProps> = observer((props) => {
  const formattedText = props.text?.replaceAll('/n', '') ?? '';
  const { ui } = useStore();

  const getRowsCount = () => {
    if (ui.viewport > 850) return 6;
    if (ui.viewport > 550) return 4;
    return 6;
  };

  return (
    <S.StyledReview className="responsive">
      <S.ReviewTop>
        <Link legacyBehavior prefetch={false} href={`/p/${props.product.slug}`} passHref>
          <S.ReviewLeft>
            {props.product.image ? (
              <Image
                fill
                style={{ objectFit: 'contain' }}
                loader={imageLoader({
                  src: props.product.image,
                  width: 96,
                })}
                src={`s3:/${props.product.image}`}
                alt={props.name}
                title={props.name}
              />
            ) : (
              <Image
                fill
                style={{ objectFit: 'contain' }}
                src="s3://pics/nophotocard.svg"
                alt={props.name}
                title={props.name}
              />
            )}
          </S.ReviewLeft>
        </Link>

        <S.ReviewInfo>
          <Link legacyBehavior prefetch={false} href={`/p/${props.product.slug}`} passHref>
            <S.ReviewTitle>{props.product.name}</S.ReviewTitle>
          </Link>
          <S.ReviewRating>
            <RatingStars rating={props.rating} votes={50} hideVotes />
          </S.ReviewRating>
        </S.ReviewInfo>
      </S.ReviewTop>

      <S.ReviewUser>
        <S.ReviewUserAvatar>
          <IconUser />
        </S.ReviewUserAvatar>
        <S.ReviewUserName>{props.name}</S.ReviewUserName>
      </S.ReviewUser>

      <S.ReviewCategory>
        <S.ReviewCategoryTitle>Комментарий</S.ReviewCategoryTitle>
        <S.ReviewCategoryText>
          <ReadMoreText text={formattedText} rowsCount={getRowsCount()} />
        </S.ReviewCategoryText>
      </S.ReviewCategory>

      <S.ReviewLikes>
        <Likes likesCount={props.likes} dislikesCount={props.dislikes} />
      </S.ReviewLikes>
    </S.StyledReview>
  );
});

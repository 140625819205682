import styled from 'styled-components';

export const StyledLabel = styled.div<{ color: string }>`
  padding: 4px 7px 3px 7px;
  background: ${(props) => props.color};
  border-radius: 3px;
  color: white;
  text-transform: uppercase;
  width: fit-content;

  font-size: 10px;
  line-height: 12px;

  letter-spacing: 0.03em;
  color: #ffffff;
`;

import React from 'react';
import * as S from './popover-favorites.style';

import { Popover, Props } from 'components/ui/popover/popover';
import { Button } from '@components/ui/button/button';

import { useStore } from '@stores/root';
import { PopoverFavoritesProductItem } from '@components/product/elements/popover-favorites-product-item/popover-favorites-product-item';
import { Scrollbar } from '@components/ui/scrollbar/scrollbar';
import Link from 'next/link';

export const PopoverFavorites = (props: Omit<Props, 'children'>) => {
  const { favorites } = useStore();

  return (
    <Popover target={props.target}>
      {favorites.count < 1 ? (
        <S.EmptyPopoverText>У вас нет избранных товаров</S.EmptyPopoverText>
      ) : (
        <Scrollbar>
          <S.Products>
            {favorites.products.map((item) => (
              <PopoverFavoritesProductItem key={`${item.id}-favorites`} {...item} />
            ))}
          </S.Products>
          <S.Footer>
            <S.FooterButton>
              <Link legacyBehavior prefetch={false} href="/favorites" passHref>
                <a>
                  <Button>К списку избранного</Button>
                </a>
              </Link>
            </S.FooterButton>
          </S.Footer>
        </Scrollbar>
      )}
    </Popover>
  );
};

import React from "react";

export const IconShield = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 0.916626L18.5322 2.59046C18.9512 2.68396 19.25 3.05521 19.25 3.48513V12.6399C19.25 14.4787 18.3306 16.1965 16.8007 17.2159L11 21.0833L5.19933 17.2159C3.6685 16.1956 2.75 14.4787 2.75 12.6408V3.48513C2.75 3.05521 3.04883 2.68396 3.46775 2.59046L11 0.916626ZM11 2.79488L4.58333 4.22029V12.6399C4.58333 13.8655 5.19567 15.0104 6.21592 15.6905L11 18.8805L15.7841 15.6905C16.8043 15.0104 17.4167 13.8664 17.4167 12.6408V4.22029L11 2.79579V2.79488ZM15.081 7.53679L16.3781 8.83296L10.5444 14.6666L6.655 10.7772L7.95117 9.48104L10.5435 12.0734L15.081 7.53588V7.53679Z"
        fill="currentColor"
      />
    </svg>
  );
};

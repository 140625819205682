import React, { ChangeEvent } from "react";
import * as S from "./search.style";
import { InputSearch } from "@components/ui/input-search/input-search";
import { useRouter } from "next/router";

type Props = {
  searchValue: string;
  setSearchValue?(value: string): void;
  setIsFocused(state: boolean): void;
};

export const Search = (props: Props) => {
  const router = useRouter();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.setSearchValue(e.currentTarget.value);
  };

  const clearValue = () => {
    props.setSearchValue('');
  };

  const onSearchIconClick = () => {
    router.push({
      pathname: '/search',
      query: { query: props.searchValue },
    });
  };

  return (
    <S.StyledSearch>
      <InputSearch
        icon="magnifier"
        disableClose={!props.searchValue}
        onChange={onChange}
        value={props.searchValue}
        setValue={props.setSearchValue}
        placeholder="Я ищу ..."
        clearValue={clearValue}
        onFocus={() => props.setIsFocused(true)}
        onBlur={() => {
          if (window && window.innerWidth < 600) return null;

          props.setIsFocused(false)
        }}
        onSearchIconClick={onSearchIconClick}
        onSubmit={onSearchIconClick}
      />
    </S.StyledSearch>
  );
};

import React from "react";

export const IconThumbDown = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.83325 13.3333H2.49992C2.05789 13.3333 1.63397 13.1577 1.32141 12.8452C1.00885 12.5326 0.833253 12.1087 0.833253 11.6667V9.91333C0.833028 9.69553 0.875495 9.4798 0.958253 9.27833L3.53825 3.01667C3.60099 2.86396 3.70766 2.73333 3.84475 2.64134C3.98184 2.54936 4.14316 2.50016 4.30825 2.5H18.3333C18.5543 2.5 18.7662 2.5878 18.9225 2.74408C19.0788 2.90036 19.1666 3.11232 19.1666 3.33333V11.6667C19.1666 11.8877 19.0788 12.0996 18.9225 12.2559C18.7662 12.4122 18.5543 12.5 18.3333 12.5H15.4316C15.2982 12.5 15.1667 12.532 15.0482 12.5933C14.9298 12.6546 14.8277 12.7435 14.7508 12.8525L10.2066 19.2908C10.1491 19.3722 10.0644 19.4304 9.96781 19.4547C9.87118 19.479 9.76905 19.4679 9.67992 19.4233L8.16825 18.6667C7.74283 18.454 7.40298 18.1022 7.20514 17.6697C7.00731 17.2372 6.96342 16.7501 7.08075 16.2892L7.83325 13.3333ZM14.1666 11.1767V4.16667H4.86659L2.49992 9.91333V11.6667H7.83325C8.08709 11.6667 8.33756 11.7247 8.56556 11.8363C8.79356 11.9479 8.99307 12.11 9.14886 12.3104C9.30465 12.5108 9.4126 12.7442 9.46449 12.9926C9.51638 13.2411 9.51082 13.4982 9.44825 13.7442L8.69575 16.7008C8.6722 16.7931 8.68094 16.8906 8.72051 16.9771C8.76008 17.0637 8.8281 17.1341 8.91325 17.1767L9.46409 17.4517L13.3891 11.8917C13.5974 11.5967 13.8641 11.355 14.1666 11.1767ZM15.8333 10.8333H17.4999V4.16667H15.8333V10.8333Z"
        fill="currentColor"
      />
    </svg>
  );
};

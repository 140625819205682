import { StyledTag } from '@components/ui/tag/tag.style';
import { device, scrollbar } from '@components/ui/theme';
import styled from 'styled-components';

export const TagsList = styled.div`
  margin-top: 33px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 15px;
  column-gap: 15px;
`;

export const StyledTagsBlock = styled.section`
  margin-top: 70px;

  @media ${device.tabletM} {
    margin-top: 50px;

    ${TagsList} {
      margin-top: 35px;
      flex-wrap: nowrap;
      overflow-x: scroll;
      align-items: center;
      justify-content: flex-start;
      column-gap: 8px;

      ${scrollbar.disable}
      ${scrollbar.alignSidesDesktop}

      ${StyledTag} {
        min-width: max-content;
      }
    }
  }

  @media (max-width: 600px) {
    ${TagsList} {
      ${scrollbar.alignSidesMobile};
    }
  }

  @media ${device.mobileM} {
    margin-top: 35px;

    ${TagsList} {
      margin-top: 25px;
    }
  }
`;

import styled from 'styled-components';

export const StyledTooltip = styled.div`
  .customTooltip {
    background: white;
    box-shadow: 0px 2px 16px rgb(49 18 59 / 10%);
    padding: 12px;
    font-size: 13px;
    line-height: 140%;
    color: #686a70;

    &.show {
      opacity: 1;
    }

    &:after {
      border-top-color: white !important;
    }
  }
`;

import styled from 'styled-components';
import { device, theme } from '../theme';

export const TitleResponsiveInfo = styled.div`
  display: none;

  @media ${device.tabletM} {
    display: flex;
    align-items: flex-end;

    span {
      margin: 0 !important;
      min-width: max-content;
    }

    a {
      margin-left: 0;
      margin-top: 0;
    }
  }
`;

export const Title = styled.h2`
  font-weight: 500;
  font-size: 30px;
  line-height: 120%;
  color: #302e30;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  span,
  a {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #9ca0a9;
  }

  span {
    margin-left: 16px;
    margin-bottom: 4px;
  }

  a {
    text-decoration-line: underline;
    margin-top: 5px;
    color: ${theme.colors.primary};
    flex-basis: 100%;
    &:hover {
      text-decoration: none;
    }
  }

  @media ${device.tabletM} {
    font-size: 24px;
    line-height: 28px;
    justify-content: flex-start;

    .desktop {
      display: none;
    }

    ${TitleResponsiveInfo} {
      margin-left: 15px;
    }
  }

  @media ${device.mobileM} {
    font-size: 20px;
    line-height: 24px;
  }

  @media (max-width: 370px) {
    flex-direction: column;
    align-items: flex-start;

    a {
      margin-top: 5px;
      margin-left: 0;
    }
  }
`;

import React from "react";

export const IconOkay = () => {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M68.2831 17.6826L36.2275 46.8612L24.585 34.1943C23.4511 32.9878 21.5611 32.9126 20.2753 33.9681C19.0656 35.0988 18.9902 36.9837 20.0486 38.266L33.7327 53.1946C34.3376 53.873 35.1693 54.1749 36.0009 54.1749C36.7571 54.1749 37.4375 53.9487 38.0423 53.4207L72.366 22.131C73.5758 21.0003 73.6512 19.1154 72.5928 17.8331C71.4589 16.6267 69.5689 16.5514 68.2837 17.6822L68.2831 17.6826Z"
        fill="currentColor"
      />
      <path
        d="M38.42 71.6666C55.9598 71.6666 70.1731 57.492 70.1731 39.9999C70.1731 38.4921 70.0977 37.0594 69.871 35.6269C69.6442 33.9681 68.1322 32.8373 66.469 33.0635C64.8057 33.2897 63.6718 34.7975 63.8986 36.4562C64.05 37.6627 64.1254 38.8692 64.1254 39.9999C64.1254 54.0991 52.5582 65.6348 38.4204 65.6348C24.2827 65.6348 12.7155 54.0991 12.7155 39.9999C12.7155 25.9007 24.2827 14.365 38.4204 14.365C44.4688 14.365 50.3657 16.4761 54.9774 20.3969C56.2626 21.4524 58.1528 21.3015 59.2111 20.0198C60.2695 18.7381 60.1181 16.8531 58.8329 15.7977C53.1633 10.9717 45.9051 8.33325 38.4202 8.33325C20.8803 8.33325 6.66699 22.5079 6.66699 39.9999C6.66699 57.492 20.8803 71.6666 38.4202 71.6666L38.42 71.6666Z"
        fill="currentColor"
      />
    </svg>
  );
};

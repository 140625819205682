import React, { useEffect, useState } from "react";
import { HowItWorksTriangle } from "./how-it-works-triangle";
import { HowItWorksExpanded } from "./how-it-works-expanded";
import { observer } from "mobx-react-lite";
import { useStore } from "@stores/root";
import { useRouter } from 'next/router';

const data = [
  {
    id: 1,
    // background: '#feeec8',
    title: 'Выбираете товар',
    description: 'Добавьте интересующие вас товары в корзину',
  },
  {
    id: 2,
    // background: '#ceeffe',
    title: 'Оформляете заказ',
    description: 'Заполните все поля формы, чтобы получить предложение',
  },
  {
    id: 3,
    // background: '#d4f8c4',
    title: 'Получаете предложения',
    description: 'В ближайшее время с вами свяжется менеджер для уточнения деталей',
  },
  // {
  //   id: 4,
  //   // background: '#d8dafe',
  //   title: 'Выбираете лучшее',
  //   description: 'Общайтесь с продавцами и выбирайте оптимальный вариант',
  // },
];

type Props = {
  lessMargin?: boolean;
};

export const HowItWorks = observer((props: Props) => {
  const { ui, project } = useStore();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const router = useRouter()

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  if ((!process.browser || isFirstRender) && process.env.NODE_ENV === 'development')
    return <HowItWorksTriangle pathname={project.pathname} data={data} viewport={1920} />;

  if (ui.viewport <= 480 && router.pathname !== '/') return null;

  if (ui.viewport > 986)
    return (
      <HowItWorksTriangle
        viewport={ui.viewport}
        pathname={project.pathname}
        data={data}
        lessMargin={props.lessMargin}
      />
    );

  return (
    <HowItWorksExpanded pathname={router.pathname} data={data} lessMargin={props.lessMargin} />
  );
});

import React from 'react';
import dynamic from 'next/dynamic';
import { autorun, makeAutoObservable, runInAction } from 'mobx';
import { nanoid } from 'nanoid';
import debounce from 'lodash/debounce';

const importOptions = {
  ssr: false,
};

const ModalVideo = dynamic(() => import('@components/modals/video'), { ...importOptions });

const ModalGeo = dynamic(() => import('@components/modals/geo/geo'), { ...importOptions });

const ModalReview = dynamic(() => import('@components/modals/review/review'), { ...importOptions });

const ModalOneClickOrder = dynamic(
  () => import('@components/modals/one-click-order/one-click-order'),
  { ...importOptions }
);

const ModalPhoneConfirmation = dynamic(
  () => import('@components/modals/phone-confirmation/phone-confirmation'),
  { ...importOptions }
);

const ModalImagesSlider = dynamic(() => import('@components/modals/images-slider/images-slider'), {
  ...importOptions,
});

const ModalThankYou = dynamic(() => import('@components/modals/thank-you/thank-you'), {
  ...importOptions,
});

const SentAnswerModal = dynamic(() => import('@components/modals/sent-answer/sent-answer'), {
  ...importOptions,
});

const ContactsGeo = dynamic(() => import('@components/modals/contacts-geo/contacts-geo'), {
  ...importOptions,
});

const OffersModal = dynamic(() => import('@components/modals/offers/offers-modal'), {
  ...importOptions,
});

const ConfirmGeoModal = dynamic(() => import('@components/modals/confirm-geo/confirm-geo'), {
  ...importOptions,
});

const CreditModal = dynamic(() => import('@components/modals/credit/credit'), {
  ...importOptions,
});


const modals = {
  video: {
    component: ModalVideo,
  },
  geo: {
    component: ModalGeo,
  },
  review: {
    component: ModalReview,
  },
  oneClickOrder: {
    component: ModalOneClickOrder,
  },
  phoneConfirmation: {
    component: ModalPhoneConfirmation,
  },
  imageSlider: {
    component: ModalImagesSlider,
  },
  thankYou: {
    component: ModalThankYou,
  },
  sentAnswer: {
    component: SentAnswerModal,
  },
  contactsGeo: {
    component: ContactsGeo,
  },
  offers: {
    component: OffersModal,
  },
  confirmGeo: {
    component: ConfirmGeoModal,
  },
  credit: {
    component: CreditModal,
  },
};

export class ModalStore {
  private _components = new Map<string, JSX.Element>();
  private _body: HTMLBodyElement;

  constructor() {
    makeAutoObservable(this);

    if (!process.browser) return;

    autorun(() => {
      const body = document.querySelector('body');
      this._body = body;
      if (this._components.size > 0) {
        body.style.overflow = 'hidden';
      } else {
        body.style.overflow = 'auto';
      }
    });
  }

  show = <
    K extends keyof typeof modals,
    //@ts-ignore
    P extends Parameters<typeof modals[K]['component']>[number]
  >(
    modalKey: K,
    props?: P
  ) => {
    const modalID = nanoid();
    // @ts-ignore
    const component = React.createElement<P>(modals[modalKey].component, {
      ...props,
      destroy: this.closeByID.bind(null, modalID),
      key: modalID,
      modalID,
    });

    this._components.set(modalID, component);
    this.toggleBodyOverflow();
  };

  toggleBodyOverflow = (v?: 'auto' | 'hidden') => {
    const value = v ?? this._body.style.overflow;

    if (v) {
      this._body.style.overflow = value;
      return;
    }

    if (value === 'hidden') {
      this._body.style.overflow = 'auto';
    } else {
      this._body.style.overflow = 'hidden';
    }
  };

  showWithPromise = <
    K extends keyof typeof modals,
    //@ts-ignore
    P extends Parameters<typeof modals[K]['component']>[number]
  >(
    modalKey: K,
    props?: P
  ): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const modalID = nanoid();
      // @ts-ignore
      const component = React.createElement<P>(modals[modalKey].component, {
        ...props,
        key: modalID,
        modalID,
        destroy: this.closeByID.bind(null, modalID),
        promise: {
          resolve,
          reject,
        },
      });

      this._components.set(modalID, component);
      this.toggleBodyOverflow();
    });
  };

  close = debounce(
    () => {
      runInAction(() => {
        const key = [...this._components.keys()][this._components.size - 1];
        this._components.delete(key);
        this.toggleBodyOverflow('auto');
      });
    },
    500,
    {
      leading: true,
      trailing: false,
    }
  );

  closeByID = (id: string) => {
    this._components.delete(id);
  };
  get components() {
    return [...this._components.values()];
  }
}

import styled, { css } from 'styled-components';
import { device, scrollbar, theme } from '@components/ui/theme';
import { motion } from 'framer-motion';

export const Close = styled.div`
  position: absolute;
  top: 21px;
  right: 21px;

  cursor: pointer;

  &:hover {
    color: ${theme.colors.primary};
  }
`;

export const ModalBG = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(48, 46, 48, 0.8);

  position: absolute;
  inset: 0;
`;

export const ModalBody = styled.div<{
  outsideButton?: boolean;
  fullScreen?: boolean;
  noBg?: boolean;
  noMinHeight?: boolean;
  noFullOnMobile?: boolean;
}>`
  position: relative;

  background: ${({ noBg }) => (noBg ? 'none' : '#fff')};
  border-radius: ${(props) => (props.fullScreen ? '0px' : '4px')};

  padding: ${(props) => (props.fullScreen ? '0px' : '40px')};

  min-width: 550px;
  min-height: ${(props) => (props.noMinHeight ? 'unset' : '300px')};

  box-sizing: border-box;

  ${Close} {
    top: ${(props) => (props.outsideButton ? '-15px' : props.fullScreen ? '30px' : '21px')};
    right: ${(props) => (props.outsideButton ? '-40px' : props.fullScreen ? '30px' : '21px')};
    color: ${(props) => (props.outsideButton ? 'white' : theme.colors.greySecondary)};
    &:hover {
      color: ${theme.colors.primary};
    }

    svg {
      width: ${({ fullScreen }) => (fullScreen ? '40px' : '24px')};
      height: ${({ fullScreen }) => (fullScreen ? '40px' : '24px')};
    }
  }

  @media (max-width: 630px) {
    background-color: #fff;

    ${(props) =>
      props.noFullOnMobile &&
      css`
        min-width: unset !important;
        height: unset !important;
        width: unset !important;
      `}

    ${Close} {
      top: 15px;
      right: 15px;
      color: ${theme.colors.greySecondary};

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  @media ${device.mobileS} {
    ${(props) =>
      props.noFullOnMobile &&
      css`
        max-width: 300px;
      `}
  }
`;

export const ModalTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  color: ${theme.colors.black};
  margin-bottom: 20px;
`;
export const ModalContent = styled.div<{ centerContent?: boolean; withoutOverflow?: boolean }>`
  overflow-y: ${({ withoutOverflow }) => (withoutOverflow ? 'unset' : 'auto')};
  ${scrollbar.disable}
  display: flex;
  flex-direction: column;
  justify-content: ${({ centerContent }) => (centerContent ? 'center' : 'flex-start')};
  height: 100%;
`;

export const ScrollableModalContent = styled.div`
  ${scrollbar.vertical}
  overflow-x: scroll;
`;

export const StyledModal = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  background: rgba(48, 46, 48, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;

  &.containerOverflow {
    overflow: auto;
    .temp {
      overflow: auto;
      position: absolute;
      width: 100%;
      height: 100%;
    }

    ${ModalBody} {
      max-width: 550px;
      margin: 80px auto 60px auto;
    }
  }

  @media (max-width: 630px) {
    &.containerOverflow {
      ${ModalBody} {
        margin: 0 auto;
      }
    }
  }

  @media ${device.tabletM} {
    ${ModalBody} {
      padding: 40px;

      &.largePadding {
        padding: 30px 40px;
      }
    }
  }

  @media (max-width: 630px) {
    ${ModalBody} {
      padding: 25px 20px;
      width: 100vw;
      height: 100vh;
      min-width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    ${ModalTitle} {
      margin-bottom: 25px;
      font-size: 24px;
      max-width: calc(100% - 40px);
    }

    ${ModalContent} {
      max-height: 100%;
      flex: 1;
    }
  }

  @media (max-width: 480px) {
    ${ModalBody} {
      padding: 25px 20px;
    }

    &.noPaddingBottom ${ModalBody} {
      padding-bottom: 0;
    }
    &.noPaddingRight ${ModalBody} {
      padding-right: 0;
    }

    ${Close} {
      right: 16px;
    }
  }
`;

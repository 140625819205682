import { InputSearch } from "@components/ui/input-search/input-search";
import { Modal } from "@components/ui/modal/modal";
import { SearchQuery } from "gql/generated";
import { useRouter } from "next/router";
import React, { Dispatch, useEffect } from "react";
import { SearchResult } from "../search-result/search-result";
import * as S from "./search-results.style";

type Props = Readonly<{
  isModal: boolean;
  setIsModal: Dispatch<boolean>;
  result: SearchQuery['search']['suggest'];
  searchString: string;
  setSearchString: Dispatch<string>;
  debouncedSearch: (str: string) => void;
}>;

export const SearchResultsModal: FCC<Props> = ({
  isModal,
  result,
  searchString,
  setSearchString,
  setIsModal,
  debouncedSearch,
}) => {
  const onClose = () => setIsModal(false);
  const router = useRouter();

  useEffect(() => {
    debouncedSearch(searchString);
  }, [searchString, debouncedSearch]);

  if (!isModal) return null;

  return (
    <Modal fullScreen withoutOverflow onClose={onClose} title="Поиск">
      <InputSearch
        value={searchString}
        onChange={(e) => {
          setSearchString(e.target.value);
          if (e.target.value.trim().length === 0) return setIsModal(false);
        }}
        onSearchIconClick={() => {
          router.push({
            pathname: '/search',
            query: { query: searchString },
          });
        }}
        clearValue={() => {
          setIsModal(false);
          setSearchString('');
        }}
        focusOnRender
      />

      <S.StyledModalSearchResults>
        <SearchResult {...result} searchString={searchString} />
      </S.StyledModalSearchResults>
    </Modal>
  );
};

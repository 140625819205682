import React from 'react';
import { Image } from '@components/Image';
import * as S from './last-viewed-card-style';
import { Product } from '../../../../gql/generated';
import Link from 'next/link';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores/root';
import { PopoverProductCart } from '@components/popovers/popover-product-cart/popover-product-cart';

type Props = {
  images: Product['images'];
  price: Product['price'];
  priceFrom: Product['priceFrom'];
  priceTo: Product['priceTo'];
  name: Product['name'];
  slug: Product['slug'];
  id: Product['id'];
  rating: number;
  discount: number;
  ratingCount: number;
  hit?: Product['hit'];
  isSingle?: boolean;
};

export const LastViewedCard: FCC<Props> = observer((props) => {
  const { ui, cart } = useStore();

  const isInCart = cart.has(props.id);

  const addToCart = (): void => {
    cart.addItem({
      id: props.id,
      name: props.name,
      price: props.price,
      slug: props.slug,
      images: props.images,
      rating: props.rating,
      discount: props.discount,
      ratingCount: props.ratingCount,
      priceFrom: props.priceFrom,
      priceTo: props.priceTo,
      hit: props.hit,
    });
  };

  return (
    <S.StyledLastViewedCard>
      <Link legacyBehavior prefetch={false} href={`/p/${props.slug}`} passHref>
        <S.LastViewedCardImage>
          {props.images[0] ? (
            <Image
              width={116}
              height={88}
              src={`s3:/${props.images[0]}`}
              alt={props.name}
              title={props.name}
              style={{ objectFit: 'contain' }}
            />
          ) : (
            <Image
              src="s3://pics/nophotocard.svg"
              width={116}
              height={88}
              style={{ objectFit: 'contain' }}
              alt={props.name}
              title={props.name}
            />
          )}
        </S.LastViewedCardImage>
      </Link>

      <S.LastViewedCardInfo>
        <Link legacyBehavior prefetch={false} href={`/p/${props.slug}`} passHref>
          <S.LastViewedCardTitle>{props.name}</S.LastViewedCardTitle>
        </Link>
        <S.LastViewedCardPrice>{props.price.toLocaleString('ru-RU')} ₽</S.LastViewedCardPrice>
        {props.priceTo !== props.priceFrom && (
          <S.LastViewedCardPriceRange>
            от {props.priceFrom.toLocaleString('ru-RU')} ₽ до{' '}
            {props.priceTo.toLocaleString('ru-RU')} ₽
          </S.LastViewedCardPriceRange>
        )}

        <S.LastViewedCardCartButton>
          <PopoverProductCart
            addToCart={addToCart}
            isInCart={isInCart}
            productID={props.id}
            alignRight
          />
        </S.LastViewedCardCartButton>
      </S.LastViewedCardInfo>
    </S.StyledLastViewedCard>
  );
});

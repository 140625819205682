import React, { useCallback, useEffect, useRef } from 'react';
import * as S from './categories-menu.style';
import { AnimatePresence } from 'framer-motion';
import { motionOpacity } from '@components/ui/animations/animations';
import { Wrapper } from '@components/ui/wrapper/wrapper.style';
import { Category, InitialDataQuery, Product } from '../../../../../gql/generated';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { PopularCategory } from '@components/main/elements/popular-category/popular-category';
import { CategoryMenuItem } from '@components/layout/header/elements/category-menu-item/category-menu-item';
import classNames from 'classnames';

export const variants = {
  closed: {
    opacity: 0,
    transitionEnd: {
      display: 'none',
    },
  },

  open: {
    opacity: 1,
    display: 'block',
  },
};

type Props = {
  show: boolean;
  setShow(state: boolean): void;
  categories: InitialDataQuery['categories'];
  className?: string;
};

export const CategoriesMenu = (props: Props) => {
  const router = useRouter();

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.show) {
      setTimeout(() => {
        document.addEventListener('click', closeMenu);
      }, 25);
    } else {
      document.removeEventListener('click', closeMenu);
    }
  }, [props.show]);

  const closeMenu = useCallback((event: MouseEvent) => {
    const isClickedInside = containerRef.current.contains(event.target as Node);

    if (isClickedInside) return;

    props.setShow(false);
  }, []);

  const MenuItem = props.categories.length <= 6 ? PopularCategory : CategoryMenuItem;

  return (
    <S.StyledCategoriesMenu
      variants={variants}
      animate={props.show ? 'open' : 'closed'}
      transition={{ duration: 0.2 }}
      ref={containerRef}
      className={props.className}
    >
      <Wrapper>
        <noindex>
          <S.CategoriesMenuContent
            moreThanSix={props.categories.length > 6}
            className={classNames({ products: props.categories.length === 1 })}
          >
            {props.categories.map((category) => (
              <Link legacyBehavior key={category.id} href={`/c/${category.slug}`} passHref>
                <MenuItem
                  {...category}
                  onClick={() => props.setShow(false)}
                  active={router.query.slug === category.slug}
                />
              </Link>
            ))}
          </S.CategoriesMenuContent>
        </noindex>
      </Wrapper>
    </S.StyledCategoriesMenu>
  );
};

import React from "react";

export const IconOK = () => {
  return (
    <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.28474 12.2402L9.75057 14.6207C10.2557 15.1073 10.2557 15.8975 9.75057 16.3846C9.24595 16.8717 8.42806 16.8717 7.92396 16.3846L5.49956 14.0454L3.07728 16.3846C2.8247 16.6279 2.49375 16.7497 2.16279 16.7497C1.83235 16.7497 1.50192 16.6279 1.24935 16.3846C0.744727 15.8975 0.744727 15.1078 1.24882 14.6207L3.71492 12.2402C2.81705 12.0427 1.95112 11.6993 1.15354 11.2163C0.549952 10.8489 0.368637 10.0793 0.74895 9.49613C1.12821 8.91222 1.92578 8.73643 2.53043 9.1038C4.33619 10.2003 6.66241 10.2006 8.46923 9.1038C9.07387 8.73643 9.87119 8.91222 10.2512 9.49613C10.6315 10.0788 10.4497 10.8489 9.84611 11.2163C9.04854 11.6998 8.18261 12.0427 7.28474 12.2402Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.07788 4.50809C1.07788 6.85546 3.05625 8.7649 5.48883 8.7649C7.92194 8.7649 9.89978 6.85546 9.89978 4.50809C9.89978 2.15995 7.92194 0.25 5.48883 0.25C3.05625 0.25 1.07788 2.15995 1.07788 4.50809ZM7.31519 4.50792C7.31519 3.53575 6.49597 2.74522 5.48884 2.74522C4.4825 2.74522 3.66249 3.53575 3.66249 4.50792C3.66249 5.47933 4.4825 6.27036 5.48884 6.27036C6.49597 6.27036 7.31519 5.47933 7.31519 4.50792Z"
        fill="currentColor"
      />
    </svg>
  );
};

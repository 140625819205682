import styled from 'styled-components';
import Link from 'next/link';
import React from 'react';
import * as S from './block-title.style';

type Props = {
  className?: string;
  subtitle?: string;
  link?: {
    title: string;
    href: string;
  };
  subtitleLink?: string;
};

const Component: FCC<Props> = (props) => {
  const subTitle = props.subtitleLink ? (
    <Link legacyBehavior prefetch={false} href={props.subtitleLink} passHref>
      <a>{props.subtitle}</a>
    </Link>
  ) : (
    props.subtitle
  );

  return (
    <S.Title className={props.className}>
      {props.children}
      {props.subtitle && <span className="desktop">{subTitle}</span>}{' '}
      {props.link && (
        <Link legacyBehavior prefetch={false} href={props.link.href} passHref>
          <a className="desktop">{props.link.title}</a>
        </Link>
      )}
      {(props.subtitle || props.link) && (
        <S.TitleResponsiveInfo>
          {props.subtitle && <span>{subTitle}</span>}{' '}
          {props.link && (
            <Link legacyBehavior prefetch={false} href={props.link.href} passHref>
              <a>{props.link.title}</a>
            </Link>
          )}
        </S.TitleResponsiveInfo>
      )}
    </S.Title>
  );
};

export const BlockTitle = styled(Component)``;

const nonBreakingSpace = `\u00A0`;

export function formatPrice(price: number) {
  return `${(price ?? '?').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}${nonBreakingSpace}₽`;
}

/**
 * @param count
 * @param words например, ['продавец', 'продавца', 'продавцов']
 */
export function formatPlural(count: number, words: string[]): string {
  const cases = [2, 0, 1, 1, 1, 2];
  return words[count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]];
}

export const formatEllipsis = (input, length) =>
  input.length > length ? `${input.substring(0, length)} ...` : input;

export const removeTags = (text: string) => {
  let result = text;

  for (let i = 0; i < text.length; i += 1) {
    const firstIdx = result.search('<');
    const lastIdx = result.search('>');
    const partToRemove = result.slice(firstIdx, lastIdx + 1);
    result = result.replace(partToRemove, '');
  }

  return result;
};

export const formatDescription = (text: string) => text.replace(/\n/g, '<div></div>');

export const removeSpaces = (str: string) => str.replace(/\s/g, '');

export const formatBrandName = (text: string, brandName: string) => {
  const regexp = new RegExp(brandName, 'gmi');

  const formattedText = text[0].toUpperCase() + text.slice(1);
  return formattedText.replace(regexp, brandName);

  // let result = text;
  // const lowerCase = text.toLowerCase();
  // const isStartsByBrandName =
  //   text.slice(0, brandName.length).toLowerCase() === brandName.toLowerCase();
  //
  // if (!isStartsByBrandName) result = `${text[0].toUpperCase()}${text.slice(1)}`;
  //
  // const startBrandPoint = lowerCase.search(brandName.toLowerCase());
  // const endBrandPoint =
  //   text.length -
  //   [...lowerCase]
  //     .reverse()
  //     .join('')
  //     .search([...brandName.toLowerCase()].reverse().join(''));
  // const textBrandName = text.slice(startBrandPoint, endBrandPoint);
  //
  // return result.replace(textBrandName, brandName);
};

import styled from 'styled-components';
import { device, theme } from '@components/ui/theme';
import {
  FilterInput,
  FilterInputName,
} from '@components/category/elements/filter-input/filter-input.style';
import { StyledSelect } from '@components/ui/select/select.style';
import { FilterInputsName, StyledFilterInputs } from '../filter-inputs/filter-inputs.style';
import {
  SelectIcon,
  StyledSelect as StyledIconSelect,
} from '@components/ui/select-with-icon/select-with-icon.style';
import { StyledLayoutButtons } from '../layout-buttons/layout-buttons.style';
import { StyledModal } from '@components/ui/modal/modal.style';
import { CategoryFilterInput } from '@components/category/elements/filter-input/filter-input';
import { Image } from '@components/Image';
export const Right = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;

  & > div {
    &:not(:first-child) {
      margin-left: 25px;
    }
  }

  svg.css-tj5bde-Svg {
    padding-bottom: 1px;
  }
`;

export const PopoverPriceFilters = styled.div`
  padding: 25px;
`;

export const FilterModalButton = styled.div`
  align-items: center;
  font-size: 13px;
  transition: all ease 0.2s;
  cursor: pointer;
  display: none;

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    color: ${theme.colors.primary};

    svg path {
      fill: ${theme.colors.primary};
    }
  }

  svg {
    margin-right: 6px;
    path {
      transition: all ease 0.3s;
    }
  }

  @media (max-width: 1000px) {
    font-size: 14px;

    &.price {
      display: flex;
    }
  }
`;

export const FilterModalFooter = styled.div`
  margin-top: 25px;
  padding-top: 25px;
  background: #ffffff;
  height: 60px;
  width: 100%;
  border-top: 1px solid #d9dde7;
`;

export const FilterModal = styled.div`
  min-height: 196px;
`;

export const StyledProductsFilter = styled.section`
  width: 100%;
  height: 74px;
  background: #fafafa;
  border-radius: 2px;

  display: flex;
  align-items: center;

  padding: 0 25px;

  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 1180px) {
    .rheostat {
      display: none;
    }

    ${FilterInputName} {
      margin: 0 8px;
    }

    ${FilterInput} {
      margin-left: 0;
    }
  }

  @media ${device.tabletM} {
    padding: 0 20px;
    margin-top: 18px;
    margin-bottom: 24px;

    .rheostat {
      display: block;
    }

    ${StyledSelect} {
      div {
        min-height: unset;
      }

      .css-189846g-control {
        padding: 0 12px;
      }

      div div div div {
        font-size: 14px;
      }
    }

    ${StyledIconSelect} {
      max-width: 215px;
      width: fit-content;
      margin-left: 20px;

      div div div div {
        font-size: 14px;
        border-bottom: none;
      }
    }

    ${Right} {
      & > div {
        &:not(:first-child) {
          margin-left: 15px;

          ${SelectIcon} {
            margin-right: 10px;

            div svg {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 815px) {
    padding: 0 16px;
  }

  @media (max-width: 765px) {
    justify-content: space-between;

    ${Right} {
      margin-left: 0;
    }

    ${StyledModal} {
      ${StyledSelect}, ${StyledFilterInputs}, ${StyledLayoutButtons}, .rheostat {
        display: block;
      }

      ${StyledSelect} {
        width: 100%;
        height: 42px;

        .css-4xrj3l-control {
          background-color: #f9f7fa;
        }
      }

      ${StyledFilterInputs} {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        ${FilterInputsName} {
          margin-bottom: 15px;
          flex-basis: 100%;
          font-size: 15px;
        }

        ${FilterInputName} {
          font-size: 14px;
        }

        .first ${FilterInputName} {
          margin-left: 0;
          margin-right: 10px;
        }
        .second ${FilterInputName} {
          margin: 0 10px;
        }

        ${CategoryFilterInput} {
          flex-basis: 50% !important;
        }

        ${FilterInput} {
          height: 42px;
          flex: 1;
        }

        .rheostat {
          width: calc(100% - 11px);
          margin: 25px 0 40px 0;
          order: 4;
        }

        ${CategoryFilterInput} {
          flex-basis: 90px;
        }
      }
    }
  }

  @media (max-width: 700px) {
    ${FilterModalButton} {
      display: flex;
    }
    ${StyledSelect}:first-child {
      display: none;
    }
  }

  @media (max-width: 630px) {
    margin-top: 25px;
    margin-bottom: 25px;

    height: 54px;
  }

  @media (max-width: 466px) {
    ${StyledLayoutButtons} {
      display: none;
    }
  }
`;

import React, { useEffect, useState } from "react";
import * as S from "./reviews.styles";
import { BlockTitle } from "@components/ui/block-title/block-title";

import { Icon } from "@components/ui/icons/icon";
import { CategoryReview } from "@components/category/elements/review/review";
import { Wrapper } from "@components/ui/wrapper/wrapper.style";
import { PageCategoryQuery } from "../../../../gql/generated";

type Props = {
  reviews: PageCategoryQuery['reviews']['items'];
};

export const CategoryReviews = (props: Props) => {
  const [sliceCount, setSliceCount] = useState(8);
  const [reviewsToShow, setReviewsToShow] = useState<Props['reviews']>([]);

  useEffect(() => {
    setReviewsToShow(props.reviews.slice(0, sliceCount));
  }, [sliceCount]);

  const showMore = () => {
    setSliceCount(sliceCount + 8);
  };

  return (
    <S.Reviews>
      <Wrapper>
        <BlockTitle>Отзывы</BlockTitle>
        <S.Grid>
          {reviewsToShow.map((review, index) => (
            <CategoryReview key={index} {...review} />
          ))}
        </S.Grid>
        {sliceCount < props.reviews.length && (
          <S.MoreReviewsButton onClick={showMore}>
            <Icon icon="message" /> Показать ещё ({props.reviews.length - sliceCount})
          </S.MoreReviewsButton>
        )}
      </Wrapper>
    </S.Reviews>
  );
};

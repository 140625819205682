import React from "react";
import * as S from "./popover-product-cart.style";
import { CountPicker } from "@components/ui/count-picker/count-picker";
import { Popover } from "@components/ui/popover/popover";
import { useStore } from "@stores/root";
import { IconButton } from "@components/ui/icon-button/icon-button";
import { Icon } from "@components/ui/icons/icon";
import { observer } from "mobx-react-lite";

type Props = {
  productID: string;
  isInCart: boolean;
  addToCart: () => void;
  placement?: 'top' | 'bottom';
  alignRight?: boolean
};

export const PopoverProductCart = observer((props: Props) => {
  const { cart, ui } = useStore();
  const item = cart.items.get(props.productID);

  const increaseCount = () => {
    cart.increaseCount(item.id);
  };

  const decreaseCount = () => {
    cart.decreaseCount(item.id);
  };

  const setPopoverProductId = () => {
    ui.setPopoverProductId(props.productID);
  }

  const onClick = () => {
    props.addToCart();
    setPopoverProductId();
  }

  const onMouseEnter = () => {
    if (ui.viewport > 1000 || props.isInCart) { setPopoverProductId(); }
  }

  return (
    <>
      {!props.isInCart && ui.viewport > 1000 && (
        <S.CartButton onMouseEnter={onMouseEnter} onClick={onClick}>
          <IconButton width={40} className='POPOVER' ariaLabel="В корзину" rounded fill>
            <Icon icon="cart" />
          </IconButton>
        </S.CartButton>
      )}
      {(ui.viewport <= 1000 || props.isInCart) && (
        <Popover
          target={
            <S.PopoverProductCartWrapper>
              {props.isInCart && (
                <S.CartButton className='inCart' onMouseEnter={onMouseEnter} onClick={onClick}>
                  <IconButton className='POPOVER' ariaLabel="В корзину" rounded secondary>
                    <span>{cart.items.get(props.productID).count}</span>
                  </IconButton>
                </S.CartButton>
              )}
              {!props.isInCart && ui.viewport <= 1000 && (
                <S.CartButton onMouseEnter={onMouseEnter} onClick={onClick}>
                  <IconButton className='POPOVER' ariaLabel="В корзину" rounded fill>
                    <Icon icon="cart" />
                  </IconButton>
                </S.CartButton>
              )}
            </S.PopoverProductCartWrapper>
          }
          placement={props.placement ?? 'top'}
          align={props.alignRight ? 'right' : ui.viewport > 992 ? 'center' : 'right'}
        >
          {(props.productID === ui.popoverProductCartId) && (
            <S.StyledPopoverProductCart>
              <CountPicker count={item?.count} onMinus={decreaseCount} onPlus={increaseCount} />
            </S.StyledPopoverProductCart>
          )}
        </Popover>
      )}
    </>
  );
});

import React from "react";
import * as S from "./product-card-slider.style";

type Props = {
  active: boolean;
  setSelectedIndex: React.Dispatch<number>;
  idx: number;
  canChange: boolean;
};

export const ProductCardSliderIndicator: FCC<Props> = (props) => {
  return (
    <S.Indicator
      active={props.active}
      onMouseOver={() => {
        if (props.canChange) props.setSelectedIndex(props.idx);
      }}
    />
  );
};

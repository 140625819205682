import styled from 'styled-components';
import { device, theme } from '@components/ui/theme';
import { StyledSearchResults } from './blocks/search-results/search-results.style';
import { LocationButton } from '@components/layout/buttons/location-button/location-button.style';
import { DropContent } from '@components/ui/popover/popover.style';

export const HeaderTop = styled.div`
  width: 100%;
  background: ${theme.colors.black};

  @media screen and (max-width: 992px) {
    display: none;
  }
`;

export const HeaderTopContent = styled.div`
  display: flex;
  align-items: center;
  height: 49px;

  ${LocationButton} {
    height: 33px;
  }
`;

export const HeaderBottomGreyZone = styled.section<{ isHidden?: boolean }>`
  width: 100%;
  min-height: 92px;
  background: #f9f7fa;
  overflow: hidden;
  display: block;

  &.guide {
    margin-bottom: 60px;
    min-height: 30px;
  }

  @media (max-width: 768px) {
    display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  }
`;

export const HeaderBottomContent = styled.div`
  display: flex;
  align-items: center;
  height: 92px;
  position: relative;

  @media ${device.tabletM} {
    height: 96px;
  }

  @media (max-width: 992px) {
    display: block;
    height: unset;
    padding-top: 20px;
    padding-bottom: 16px;
  }

  @media (max-width: 480px) {
    padding-top: 20px;
  }
`;

export const SearchBox = styled.div`
  //width: 679px;
  flex: 1;
  margin-left: 20px;
  margin-right: 25px;

  @media (max-width: 992px) {
    margin-left: 0;
    width: 100%;
    margin-top: 20px;
  }
`;

export const HeaderBottom = styled.div`
  width: 100%;
  background: white;
  z-index: 5;
  position: relative;

  @media (max-width: 992px) {
    position: fixed;
    top: 0;
  }

  &.fixedHeader {
    position: fixed;
    top: 0;
    box-shadow: 0px 2px 12px rgba(99, 47, 117, 0.14);
    ${HeaderBottomContent} {
      height: 90px;
    }

    @media (max-width: 992px) {
      ${HeaderBottomContent} {
        height: unset;
      }

      ${SearchBox} {
        display: none;
      }
    }

    @media (max-width: 992px) {
      ${SearchBox} {
        display: none;
      }
    }
  }
`;

export const Links = styled.nav`
  font-size: 14px;
  line-height: 140%;
  color: #d9dde7;
  margin-left: auto;

  a {
    &:not(:first-child) {
      margin-left: 46px;
    }

    &:hover {
      color: #ffffff;
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 1200px) {
    &:not(:first-child) {
      a {
        margin-left: 25px;
      }
    }
  }

  @media ${device.tabletM} {
    font-size: 14px;
    line-height: 130%;
  }
`;

export const LogoBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const Logo = styled.a`
  background: linear-gradient(219.96deg, #6f5ee2 9.42%, #e04c8a 91%);
  height: 42px;
  width: 42px;
  border-radius: 50%;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 900;
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;

  @media (max-width: 992px) {
    width: 36px;
    height: 36px;
    font-size: 12px;
  }

  @media (max-width: 480px) {
    width: 32px;
    height: 32px;
    font-size: 10px;
    line-height: 12px;
  }
`;

export const LogoMarketNameBlock = styled.a`
  margin-left: 9px;
  cursor: pointer;

  @media (max-width: 992px) {
    margin-left: 10px;
  }

  @media (max-width: 480px) {
    margin-left: 7px;
  }
`;

export const LogoMarketName = styled.div<{ fontSize: number; isUpperCase: boolean }>`
  font-weight: ${({ isUpperCase }) => (isUpperCase ? 700 : 500)};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  line-height: 120%;
  color: ${theme.colors.black};

  @media (max-width: 480px) {
    line-height: 20px;
  }
`;

export const LogoMarketSubname = styled.div`
  font-size: 13px;
  line-height: 140%;
  color: ${theme.colors.greyPrimary};
  margin-top: -5px;

  @media (max-width: 992px) {
    font-size: 11px;
  }
`;

export const LogoDivider = styled.div`
  background: #efefef;
  height: 42px;
  width: 1px;
  margin-left: 10px;
  margin-right: 10px;

  @media (max-width: 992px) {
    margin: 0 13px;
    height: 30px;
  }

  @media (max-width: 480px) {
    margin: 0 8px;
  }
`;

export const LogoMarketDescription = styled.div`
  font-size: 13px;
  line-height: 140%;
  color: ${theme.colors.greyPrimary};
  max-width: 135px;

  @media (max-width: 480px) {
    font-size: 12px;
    line-height: 15px;
  }
`;

export const CatalogueButton = styled.div`
  margin-left: 30px;

  @media (max-width: 1000px) {
    margin-left: 20px;
  }

  @media (max-width: 992px) {
    display: none;
  }
`;

export const HeaderBottomButtons = styled.div`
  margin-left: 20px;
  display: flex;
  margin-left: auto;

  @media (max-width: 992px) {
    display: none;
  }
`;

export const HeaderBottomButtonIcon = styled.a`
  margin: 0 auto;
  width: 30px;
  height: 30px;
  position: relative;

  &.cart {
    margin: 0 12px 0 10px;
  }

  svg {
    width: 100%;
    height: auto;
  }
`;

export const DisabledHeaderBottomButtonIcon = styled.span`
  margin: 0 auto;
  width: 30px;
  height: 30px;
  position: relative;

  svg {
    width: 100%;
    height: auto;
  }
`;

export const HeaderBottomButtonText = styled.a`
  font-size: 13px;
  line-height: 140%;
  color: ${theme.colors.greySecondary};
  margin-top: auto;
`;

export const DisabledHeaderBottomButtonText = styled.span`
  font-size: 13px;
  line-height: 140%;
  color: ${theme.colors.greySecondary};
  margin-top: auto;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const HeaderBottomCountIcon = styled.div`
  position: absolute;
  top: -2px;
  right: -5px;
  background: #e64e8d;
  //border: 1px solid #ffffff;
  min-width: 16px;
  height: 16px;
  padding: 2px 5px;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
`;

export const HeaderBottomButton = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;

  &.cart {
    ${DropContent}.bottom::after {
      right: 20px;
      left: unset;
      margin: 0;
    }
  }

  &.favorites {
    ${DropContent}.bottom::after {
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  //pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  &:not(:first-child) {
    margin-left: 25px;
  }

  &:nth-child(2) {
    svg {
      width: 31.5px;
    }
  }

  &:nth-child(3) svg {
    width: 32px;
  }

  ${HeaderBottomButtonIcon}, ${DisabledHeaderBottomButtonIcon} {
    color: ${(props) => (props.disabled ? theme.colors.greyPrimary : theme.colors.black)};

    svg path {
      fill: ${(props) => (props.disabled ? theme.colors.greyPrimary : theme.colors.black)};
    }
  }

  ${HeaderBottomButtonText}, ${DisabledHeaderBottomButtonText} {
    color: ${(props) => (props.disabled ? theme.colors.greyPrimary : theme.colors.greySecondary)};
  }

  &:hover {
    ${HeaderBottomButtonIcon} {
      color: ${({ disabled }) => (disabled ? '#9CA0A9' : theme.colors.primary)};

      svg path {
        fill: ${({ disabled }) => (disabled ? '#9CA0A9' : theme.colors.primary)};
      }
    }

    ${HeaderBottomButtonText} {
      color: ${({ disabled }) => (disabled ? '#9CA0A9' : theme.colors.primary)};
    }
  }

  @media ${device.tabletM} {
    justify-content: center;
    ${HeaderBottomButtonText} {
      display: none;
    }
  }
`;

export const MenuBurger = styled.div`
  position: fixed;
  width: 24px;
  height: 24px;
  display: none;
  right: 30px;
  top: 24px;
  z-index: 7;

  div {
    position: absolute;
    width: 20px;
    height: 2px;
    border-radius: 1px;
    background: ${theme.colors.black};
    border-radius: 1px;
    top: 4px;
    left: 2px;

    transition: 0.25s;

    &:nth-child(2) {
      width: 15px;
      top: 11px;
      left: unset;
      right: 2px;
    }

    &:nth-child(3) {
      top: 18px;
    }
  }

  &.active {
    div {
      //background: #e64e8d;
      border-radius: 0;

      &:nth-child(1) {
        top: 6px;
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        top: 6px;
        transform: rotate(-45deg);
        width: 20px;
        margin-top: 0;
      }

      &:nth-child(3) {
        opacity: 0;
        top: 6px;
        transform: rotate(45deg);
      }
    }
  }

  @media (max-width: 992px) {
    display: block;
  }

  @media (max-width: 600px) {
    right: 20px;
  }
`;

export const StyledHeader = styled.header`
  position: relative;
  z-index: 5;

  @media (max-width: 992px) {
    ${StyledSearchResults} {
      top: 134px;
      left: 0;
      position: fixed;
    }

    &.productPage {
      ${MenuBurger}, ${HeaderBottom} {
        position: absolute;
      }
    }
  }

  @media (max-width: 767px) {
    &.productPage {
      ${MenuBurger}, ${HeaderBottom} {
        position: fixed;
      }
    }
  }
`;

import { Wrapper } from '@components/ui/wrapper/wrapper.style';
import React, { useRef, useState } from 'react';
import * as S from './bannder-slider.style';
import { BannerSlide } from '@components/layout/header/elements/banner-slide/banner-slide';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Icon } from '@components/ui/icons/icon';
import { Autoplay, Pagination } from 'swiper';
import { useStore } from '@stores/root';
import { WH } from '@components/WH';

type Props = {};

export const BannerSlider = (props: Props) => {
  const { project } = useStore();
  const prevButton = useRef<HTMLButtonElement | null>(null);
  const nextButton = useRef<HTMLButtonElement | null>(null);
  const [isSliderInit, setIsSliderInit] = useState(false);

  const slides = [
    {
      title:
        project.type === 'type'
          ? 'Поможем купить товары'
          : 'Поможем купить %br% товары бренда %br% «%brandname%» %br%',
      subtitle: 'на лучших условиях %br% %geo%',
      features: [
        project.type === 'type'
          ? 'Объединяем всех продавцов в одном месте'
          : 'Объединяем всех продавцов бренда в одном месте',
        'Обеспечиваем наиболее выгодные покупки',
        // 'Вы получите десятки реальных предложений в течение часа',
        'Это действительно удобно, просто попробуйте',
      ],
      image: '/pics/illustrations/banner1.svg',
      bg: '#F4F2FA',
      titleClass: '',
    },
    {
      title:
        project.type === 'type' ? 'Объединяем всех продавцов' : 'Объединяем всех продавцов бренда',
      subtitle: 'в одном месте',
      text:
        project.type === 'type'
          ? 'Наша цель - собрать самых активных продавцов на данной площадке, чтобы обеспечивать покупателей максимальным выбором лучших предложений со всего рынка.'
          : 'Наша цель - собрать самых активных продавцов "%brand%" на данной площадке, чтобы обеспечивать покупателей максимальным выбором лучших предложений со всего рынка.',
      image: '/pics/illustrations/banner2.svg',
      bg: '#F9F5FA',
      titleClass: '',
    },
    {
      title: 'Обеспечиваем наиболее',
      subtitle: 'выгодные покупки',
      text: 'Покупатели нашего сервиса выбирают наилучшие предложения по цене, доставке и вариациям товаров - благодаря большому количеству получаемых предложений.',
      image: '/pics/illustrations/banner3.svg',
      bg: '#F2F8FA',
      titleClass: 'noMargin',
    },
    {
      title: 'Это действительно удобно,',
      subtitle: 'просто попробуйте',
      text: 'Мы спроектировали весь процесс покупки так, чтобы у вас не возникало ни одного вопроса, оформите заказ и получите предложение.',
      image: '/pics/illustrations/banner4.svg',
      bg: '#F4F2FA',
      titleClass: 'noMargin',
    },
  ];

  // Состояние isSliderInit и BannerSlideSimulation нужны чтобы работал loop и при этом при подгрузке страницы слайдер не мелькал

  return (
    <S.StyledBannderSlider className={!isSliderInit && 'noInit'}>
      <S.ArrowsContainer>
        <Wrapper>
          <S.PrevSlide ariaLabel="Предыдущий слайд" width={38} height={38} ref={prevButton}>
            <Icon icon="chevronLeft" />
          </S.PrevSlide>

          <S.NextSlide ariaLabel="Следующий слайд" width={38} height={38} ref={nextButton}>
            <Icon icon="chevronRight" />
          </S.NextSlide>
        </Wrapper>
      </S.ArrowsContainer>

      <Swiper
        loop
        modules={[Pagination, Autoplay]}
        slidesPerView={1}
        navigation={{
          prevEl: prevButton.current,
          nextEl: nextButton.current,
        }}
        autoplay={{
          delay: 10000,
        }}
        pagination={{
          clickable: true,
        }}
        onAfterInit={() => setIsSliderInit(true)}
        onBeforeInit={(swiper: any) => {
          swiper.params.navigation.prevEl = prevButton.current;
          swiper.params.navigation.nextEl = nextButton.current;
        }}
      >
        {slides.map((slide, index) => {
          return (
            <SwiperSlide key={index}>
              <WH>
                <BannerSlide idx={index} {...slide} />
              </WH>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </S.StyledBannderSlider>
  );
};

export const motionOpacity = {
  initial: {
    opacity: 0,
  },

  animate: {
    opacity: 1,
  },

  exit: {
    opacity: 0,
  },

  transition: {
    duration: 0.2,
  },
};

export const motionHeight = {
  initial: {
    height: 0,
  },

  animate: {
    height: 'auto',
  },

  exit: {
    height: 0,
  },

  transition: {
    duration: 0.2,
  },
};

export const motionSlideRTL = {
  initial: {
    x: '100%',
  },

  animate: {
    x: '0%',
  },

  exit: {
    x: '100%',
  },

  transition: {
    duration: 0.2,
  },
};

export const motionSlideFromTop = {
  initial: {
    y: '-100%',
  },

  animate: {
    y: '0%',
  },

  exit: {
    y: '-100%',
  },

  transition: {
    duration: 0.2,
  },
};

export const motionSlideFromBottom = {
  initial: {
    y: '100%',
  },

  animate: {
    y: '0%',
  },

  exit: {
    y: '100%',
  },

  transition: {
    duration: 0.2,
  },
};

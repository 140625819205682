import styled from 'styled-components';
import { device, theme } from '@components/ui/theme';

export const MainAboutTitle = styled.div`
  font-size: 17px;
  line-height: 120%;
  color: ${theme.colors.black};
  margin-bottom: 20px;
  max-width: 1500px;
  padding: 0 30px;
  margin-left: auto;
  margin-right: auto;

  span {
    color: ${theme.colors.primary};
  }

  br {
    display: none;
  }

  @media (max-width: 600px) {
    padding: 0 20px;
  }

  @media (max-width: 438px) {
    br {
      display: block;
    }
  }
`;

export const MainAboutText = styled.div`
  font-size: 13px;
  line-height: 140%;
  color: ${theme.colors.greySecondary};
  max-width: 1500px;
  padding: 0 30px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 600px) {
    padding: 0 20px;
  }
`;

export const StyledAbout = styled.aside`
  padding: 60px 0 70px 0;
  padding-bottom: 50px;
  background: #fafafa;

  @media ${device.tabletM} {
    padding: 50px 0 40px 0;

    ${MainAboutTitle} {
      font-size: 17px;
      line-height: 20px;
    }

    ${MainAboutText} {
      font-size: 14px;
      line-height: 21px;
    }
  }

  @media ${device.mobileM} {
    padding: 30px 0;

    ${MainAboutTitle} {
      margin-bottom: 15px;
      font-size: 15px;
      line-height: 21px;
    }

    ${MainAboutText} {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

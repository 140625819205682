import React from "react";
import { ProductCardType } from "@components/product/elements/product-card/product-card";
import * as S from "./category-products-list.style";
import { ProductLarge } from "@components/product/elements/product-large/product-large";
import { Loading } from "@components/ui/loading/loading";

type Props = {
  products: ProductCardType[];
  totalProductsCount?: number;
  // categoryId?: string;
  // withoutPagination?: boolean;
  // page: number;
  // setPage: Dispatch<number>;
  loading?: boolean;
  // useInnerFetch?: boolean;
  // perPage?: number;
};

export const CategoryProductsLargeList: FCC<Props> = (props) => {
  // const perPage = props.perPage ? props.perPage : 8;
  //
  // const [products, setProducts] = useState(
  //   props.products.slice((props.page - 1) * perPage ?? 8, props.page * perPage)
  // );
  // const [loading, setLoading] = useState(false);
  // const [isFirstRender, setIsFirstRender] = useState(true);
  // const pageCount = Math.ceil(props.totalProductsCount / 8);

  // useEffect(() => {
  //   if (isFirstRender || !props.useInnerFetch) return;
  //
  //   setLoading(true);
  //   graphQLClient()
  //     .query({
  //       query: ProductsDocument,
  //       variables: {
  //         categoryId: Number(props.categoryId),
  //         kindProduct: [KindProductEnum.Product, KindProductEnum.Related],
  //         take: 8,
  //         skip: (props.page - 1) * 8,
  //       },
  //     })
  //     .then(({ data }) => {
  //       setProducts(data.products.items);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, [props.page, props.categoryId]);

  // useEffect(() => {
  //   setProducts(props.products.slice((props.page - 1) * perPage, props.page * perPage));
  // }, [props.products]);

  // useEffect(() => {
  //   setIsFirstRender(false);
  // }, []);

  return (
    <S.CategoryProductLargeList className="largeList">
      <Loading show={props.loading} />
      {props.products.map((p) => (
        <ProductLarge productsCount={props.totalProductsCount} key={`large-${p.id}`} {...p} />
      ))}

      {/* {!props.withoutPagination && ( */}
      {/*   <Pagination */}
      {/*     page={props.page} */}
      {/*     pageCount={pageCount} */}
      {/*     onPageChange={({ selected }) => { */}
      {/*       props.setPage(selected + 1); */}
      {/*       document.querySelector('.largeList').scrollIntoView() */}
      {/*       window.scrollTo({ */}
      {/*         top: window.scrollY - 400 */}
      {/*       }); */}
      {/*     }} */}
      {/*   /> */}
      {/* )} */}
    </S.CategoryProductLargeList>
  );
};

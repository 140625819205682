import { device } from './../../../../ui/theme';
import styled from 'styled-components';
import { theme } from '@components/ui/theme';

export const Relative = styled.div`
  position: relative;
  padding-top: 84px;
  height: 560px;
`;

export const SlideLeft = styled.div`
  z-index: 2;
`;

export const SlideSubtitle = styled.div`
  width: fit-content;
  font-weight: 500;
  font-size: 34px;
  z-index: 2;
  line-height: 120%;
  color: ${theme.colors.primary};
  position: relative;

  span {
    color: ${theme.colors.black};
  }

  @media (max-width: 560px) {
    margin-left: 5px;
    display: inline;

    span {
      display: block;
    }
  }

  @media (max-width: 480px) {
    &.first {
      margin-left: 0;
    }
  }

  //background: linear-gradient(257.69deg, #785ddb 11.97%, #e54e8d 93.96%);
  //-webkit-background-clip: text;
  //-webkit-text-fill-color: transparent;

  //&:after {
  //  content: '';
  //  display: block;
  //  width: 100%;
  //  height: 2px;
  //  background: linear-gradient(257.69deg, #785ddb 11.97%, #e54e8d 93.96%);
  //  position: absolute;
  //  bottom: 5px;
  //}
`;
const slideTitleStyles = `
  font-weight: 500;
  font-size: 34px;
  line-height: 120%;
  color: ${theme.colors.black};

  //span {
  //  text-transform: uppercase;
  //}
    
  @media (max-width: 560px) {
      &.noMargin {
          ${SlideSubtitle} {
            margin: 0;
              display: block;
          }
      }
  }  
    
  @media (max-width: 480px) {
    max-width: 320px;
      
    &.flex {
        display: flex;
        flex-wrap: wrap;
    }  
  } 
`;

export const SlideTitle = styled.div`
  ${slideTitleStyles}
`;

export const FirstSlideTitle = styled.h1`
  ${slideTitleStyles}
`;

export const SlideFeatures = styled.div`
  margin-top: 30px;
`;

export const SlideText = styled.div`
  margin-top: 30px;
  font-weight: 400;
  font-size: 15px;
  line-height: 160%;
  color: ${theme.colors.greySecondary};

  max-width: 650px;
`;

export const SlideFakePagination = styled.div`
  margin-top: 20px;
  display: none;
  width: 100%;
  height: 20px;
`;

export const SlideFeature = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 140%;
  color: ${theme.colors.greySecondary};

  &:not(:first-child) {
    margin-top: 9px;
  }

  //&:before {
  //  content: '';
  //  display: block;
  //  width: 4px;
  //  height: 4px;
  //  border-radius: 50%;
  //  background: #686a70;
  //  margin-right: 8px;
  //}
`;

export const SlideButton = styled.div`
  display: inline-block;
  margin-top: 32px;
`;

export const Illustration = styled.div`
  position: absolute;
  height: 100%;
  width: 700px;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
`;

export const StyledBannerSlide = styled.div<{ bg: string }>`
  position: relative;
  height: 560px;
  overflow: hidden;
  background-color: ${({ bg }) => bg};

  @media (max-width: 1250px) {
    height: 470px;

    ${SlideTitle}, ${FirstSlideTitle}, ${SlideSubtitle} {
      font-size: 30px;
    }

    ${SlideFeature}, ${SlideText} {
      font-size: 14px;
    }

    ${Relative} {
      padding-top: 40px;
    }
  }

  @media (max-width: 1170px) {
    ${Illustration} {
      width: 600px;
    }
  }

  @media ${device.tabletM} {
    height: 470px;

    ${Illustration} {
      width: 530px;
      bottom: -70px;
    }

    ${SlideText} {
      max-width: 450px;
    }

    ${Relative} {
      height: 390px;
    }
  }

  @media (max-width: 976px) {
    height: 390px;
  }

  @media (max-width: 813px) {
    ${SlideLeft} {
      z-index: 2;
    }

    ${Illustration} {
      width: 370px;
      bottom: -13px;
    }
  }

  @media (max-width: 754px) {
    ${Illustration} {
      width: 330px;
      bottom: -20px;
    }
  }

  @media ${device.tabletS} {
    ${Illustration} {
      height: 288px;
    }
  }

  @media (max-width: 745px) {
    ${Illustration} {
      height: auto;
    }

    height: fit-content;
  }

  @media (max-width: 703px) {
    ${Illustration} {
      width: 350px;
      bottom: 0;
    }

    ${SlideFeatures}, ${SlideText} {
      display: none;
    }
    height: 340px ${Relative} {
      height: 340px;
    }
  }

  @media (max-width: 665px) {
    ${SlideTitle} ${FirstSlideTitle}, ${SlideSubtitle} {
      font-size: 28px;
    }

    ${SlideFeature}:not(:first-child) {
      margin-top: 2px;
    }
  }

  @media (max-width: 558px) {
    ${Illustration} {
      width: 300px;
    }
  }

  @media ${device.mobileM} {
    text-align: left;
    min-height: 266px;
    height: max-content;
    padding-top: 30px;
    padding-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    ${Relative} {
      padding-top: 0;
      height: fit-content;
    }

    ${Illustration} {
      bottom: unset;
      top: -45px;
      right: 0;
      width: 241px;
    }

    &.slide-0 {
      ${Illustration} {
        top: -65px;
      }
      ${SlideButton} {
        margin-top: 30px;
      }
    }

    &.slide-1 ${Illustration} {
      top: -52px;
    }
    &.slide-2 ${Illustration} {
      top: -35px;
    }
    &.slide-3 ${Illustration} {
      top: -49px;
    }

    ${SlideText}, ${SlideFeatures} {
      display: none;
    }

    ${SlideLeft} {
      height: 195px;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
    }

    ${SlideButton} {
      margin-bottom: 40px;
      margin-top: 54px;
      width: 100%;

      button {
        text-transform: none;
        font-size: 14px;
        height: 40px;
      }
    }

    ${SlideTitle}, ${FirstSlideTitle}, ${SlideSubtitle} {
      font-size: 18px;
      line-height: 24px;
    }

    ${SlideTitle}, ${FirstSlideTitle} {
      max-width: 215px;
    }
  }

  @media (max-width: 462px) and (min-width: 443px) {
    &.slide-0 ${Illustration} {
      right: -20px;
    }
  }

  @media (max-width: 442px) and (min-width: 409px) {
    &.slide-0 ${Illustration} {
      transform: scale(0.8);
      top: -47px;
      right: -40px;
    }
  }

  @media (max-width: 408px) {
    min-height: 266px;

    &.slide-0 ${Illustration} {
      top: -48px;
      right: -40px;
    }
    &.slide-1 ${Illustration} {
      top: -36px;
      right: -25px;
    }
    &.slide-2 ${Illustration} {
      top: -21px;
      right: -25px;
    }
    &.slide-3 ${Illustration} {
      top: -34px;
      right: -20px;
    }

    ${SlideTitle}, ${FirstSlideTitle} {
      max-width: 176px;
      font-size: 18px;
    }
    ${SlideSubtitle} {
      font-size: 18px;
    }

    ${Illustration} {
      width: 219px;
      height: 163px;
    }
  }
`;

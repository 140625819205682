import React from 'react';
import styled from 'styled-components';
import { ReviewProps } from './review.types';
import { ReviewResponsive } from './reiview-responsive';
import { ReviewDesktop } from './review-desktop';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores/root';

const Component = observer((props: ReviewProps) => {
  const { ui } = useStore();

  if (ui.viewport > 1000) return <ReviewDesktop {...props} />;

  return <ReviewResponsive {...props} />;
});

export const CategoryReview = styled(Component)``;

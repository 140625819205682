import React from "react";
import ReactDOM from "react-dom";

type Props = {
  blockID: string;
};

export const Portal: FCC<Props> = (props) => {
  if (!process.browser) return null;

  return ReactDOM.createPortal(props.children, document.getElementById(props.blockID));
};

import React from "react";

export const IconUser = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.9035 16.0453C15.04 14.1617 12.1568 12.9534 8.92144 12.9534C5.68582 12.9534 2.80316 14.1611 0.939804 16.0449C0.50686 16.4825 0.683677 17.1913 1.24005 17.4549L1.3589 17.5112C1.75946 17.7009 2.23288 17.5918 2.55587 17.2883C4.04747 15.8867 6.34477 14.9883 8.92144 14.9883C11.4983 14.9883 13.7958 15.8869 15.2874 17.2887C15.6102 17.592 16.0833 17.7012 16.4837 17.5118L16.6028 17.4555C17.1596 17.1922 17.3367 16.4831 16.9035 16.0453Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.92144 8.96508C10.6521 8.96508 12.0552 7.56206 12.0552 5.83135C12.0552 4.10064 10.6521 2.69763 8.92144 2.69763C7.19073 2.69763 5.78772 4.10064 5.78772 5.83135C5.78772 7.56206 7.19073 8.96508 8.92144 8.96508ZM8.92144 10.9186C11.731 10.9186 14.0086 8.64094 14.0086 5.83135C14.0086 3.02176 11.731 0.744141 8.92144 0.744141C6.11185 0.744141 3.83423 3.02176 3.83423 5.83135C3.83423 8.64094 6.11185 10.9186 8.92144 10.9186Z"
        fill="currentColor"
      />
    </svg>
  );
};

import React, { forwardRef, HTMLProps } from 'react';
import * as S from './tag.style';
import cx from 'classnames';

type Props = {
  count?: number;
  active?: boolean;
  href?: string;
  onClick?(e: React.MouseEvent<HTMLAnchorElement>): void;
} & HTMLProps<HTMLAnchorElement>;

export const Tag: FCC<Props> = forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  const classes = cx('tag', { active: props.active });

  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!props.href) {
      e.preventDefault();
    }

    props.onClick?.(e);
  };

  return (
    <a ref={ref} className={classes} href={props.href ?? '#'} onClick={onClick}>
      <div className="tagText">{props.children}</div>
      {props.count > 0 && <div className="count">&nbsp;({props.count})</div>}
    </a>
  );
});

import React from "react";

export const IconMapPin = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 14.83L3.5225 10.8525C2.73584 10.0658 2.20012 9.0635 1.98308 7.97236C1.76604 6.88122 1.87744 5.75022 2.30319 4.72239C2.72893 3.69456 3.4499 2.81606 4.37493 2.19798C5.29995 1.5799 6.38749 1.25 7.5 1.25C8.61252 1.25 9.70005 1.5799 10.6251 2.19798C11.5501 2.81606 12.2711 3.69456 12.6968 4.72239C13.1226 5.75022 13.234 6.88122 13.0169 7.97236C12.7999 9.0635 12.2642 10.0658 11.4775 10.8525L7.5 14.83ZM10.5938 9.9687C11.2056 9.35683 11.6222 8.57728 11.791 7.72862C11.9597 6.87997 11.8731 6.00032 11.5419 5.20092C11.2108 4.40152 10.65 3.71827 9.93058 3.23756C9.21112 2.75684 8.36528 2.50027 7.5 2.50027C6.63473 2.50027 5.78888 2.75684 5.06943 3.23756C4.34997 3.71827 3.78922 4.40152 3.45807 5.20092C3.12693 6.00032 3.04026 6.87997 3.20903 7.72862C3.37781 8.57728 3.79444 9.35683 4.40625 9.9687L7.5 13.0625L10.5938 9.9687ZM7.5 8.12495C7.16848 8.12495 6.85054 7.99325 6.61612 7.75883C6.3817 7.52441 6.25 7.20647 6.25 6.87495C6.25 6.54343 6.3817 6.22549 6.61612 5.99107C6.85054 5.75665 7.16848 5.62495 7.5 5.62495C7.83152 5.62495 8.14947 5.75665 8.38389 5.99107C8.61831 6.22549 8.75 6.54343 8.75 6.87495C8.75 7.20647 8.61831 7.52441 8.38389 7.75883C8.14947 7.99325 7.83152 8.12495 7.5 8.12495Z"
        fill="currentColor"
      />
    </svg>
  );
};
